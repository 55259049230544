// Generated by purs bundle 0.13.3
var PS = {};
(function(exports) {
  "use string";

  exports._deleteEditorContent = function (node) {
    var selection = window.getSelection();
    node.innerHTML = "<br />";
    selection.setPosition(node, 0);
  };

  exports._markEditorErrPos = function (parts, node) {
    var selection = window.getSelection();
    var innerHTML = parts.before + "<span class=\"error-position\">" + parts.markText + "</span>" + parts.after;
    node.innerHTML = innerHTML;
    selection.setPosition(node.childNodes[0], parts.before.length);
  };

  exports._setEditorTextContent = function (textContent, node) {
    var selection = window.getSelection();
    var anchorOffset = selection.anchorOffset;
    node.textContent = textContent;
    try {
      selection.setPosition(node.childNodes[0], anchorOffset);
    } catch (error) {
      selection.setPosition(node.childNodes[0], 0);
    }
  };
})(PS["Component.Step"] = PS["Component.Step"] || {});
(function(exports) {
  "use string";

  exports._setFocus = function (elementId) {
    const el = document.getElementById(elementId);
    if (el) {
      // If setFocus is called in the action handling phase of the Halogen
      // lifecycle, this element might not be in the DOM until the next tic.
      setTimeout(function () {
        el.focus();
      }, 0);
    }
  };
})(PS["Component.Util"] = PS["Component.Util"] || {});
(function(exports) {
  "use strict";

  //------------------------------------------------------------------------------
  // Array creation --------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.range = function (start) {
    return function (end) {
      var step = start > end ? -1 : 1;
      var result = new Array(step * (end - start) + 1);
      var i = start, n = 0;
      while (i !== end) {
        result[n++] = i;
        i += step;
      }
      result[n] = i;
      return result;
    };
  };

  var replicateFill = function (count) {
    return function (value) {
      if (count < 1) {
        return [];
      }
      var result = new Array(count);
      return result.fill(value);
    };
  };

  var replicatePolyfill = function (count) {
    return function (value) {
      var result = [];
      var n = 0;
      for (var i = 0; i < count; i++) {
        result[n++] = value;
      }
      return result;
    };
  };

  // In browsers that have Array.prototype.fill we use it, as it's faster.
  exports.replicate = typeof Array.prototype.fill === "function" ? replicateFill : replicatePolyfill;

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  //------------------------------------------------------------------------------
  // Extending arrays ------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.cons = function (e) {
    return function (l) {
      return [e].concat(l);
    };
  };

  exports.snoc = function (l) {
    return function (e) {
      var l1 = l.slice();
      l1.push(e);
      return l1;
    };
  };

  //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports["uncons'"] = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }
          return nothing;
        };
      };
    };
  };

  exports.filter = function (f) {
    return function (xs) {
      return xs.filter(f);
    };
  };

  //------------------------------------------------------------------------------
  // Sorting ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.sortImpl = function (f) {
    return function (l) {
      return l.slice().sort(function (x, y) {
        return f(x)(y);
      });
    };
  };

  //------------------------------------------------------------------------------
  // Zipping ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.zipWith = function (f) {
    return function (xs) {
      return function (ys) {
        var l = xs.length < ys.length ? xs.length : ys.length;
        var result = new Array(l);
        for (var i = 0; i < l; i++) {
          result[i] = f(xs[i])(ys[i]);
        }
        return result;
      };
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];                          
  var Alt = function (Functor0, alt) {
      this.Functor0 = Functor0;
      this.alt = alt;
  };                                                       
  var alt = function (dict) {
      return dict.alt;
  };
  exports["Alt"] = Alt;
  exports["alt"] = alt;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayApply = function (fs) {
    return function (xs) {
      var l = fs.length;
      var k = xs.length;
      var result = new Array(l*k);
      var n = 0;
      for (var i = 0; i < l; i++) {
        var f = fs[i];
        for (var j = 0; j < k; j++) {
          result[n++] = f(xs[j]);
        }
      }
      return result;
    };
  };
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];
  var Semigroupoid = function (compose) {
      this.compose = compose;
  };
  var semigroupoidFn = new Semigroupoid(function (f) {
      return function (g) {
          return function (x) {
              return f(g(x));
          };
      };
  });
  var compose = function (dict) {
      return dict.compose;
  };
  exports["compose"] = compose;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];                
  var Category = function (Semigroupoid0, identity) {
      this.Semigroupoid0 = Semigroupoid0;
      this.identity = identity;
  };
  var identity = function (dict) {
      return dict.identity;
  };
  var categoryFn = new Category(function () {
      return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
      return x;
  });
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];                
  var Functor = function (map) {
      this.map = map;
  };
  var map = function (dict) {
      return dict.map;
  };
  var $$void = function (dictFunctor) {
      return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };
  var voidLeft = function (dictFunctor) {
      return function (f) {
          return function (x) {
              return map(dictFunctor)(Data_Function["const"](x))(f);
          };
      };
  };
  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["void"] = $$void;
  exports["voidLeft"] = voidLeft;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var $foreign = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];                
  var Apply = function (Functor0, apply) {
      this.Functor0 = Functor0;
      this.apply = apply;
  }; 
  var applyArray = new Apply(function () {
      return Data_Functor.functorArray;
  }, $foreign.arrayApply);
  var apply = function (dict) {
      return dict.apply;
  };
  var applyFirst = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"])(a))(b);
          };
      };
  };
  var applySecond = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
          };
      };
  };
  var lift2 = function (dictApply) {
      return function (f) {
          return function (a) {
              return function (b) {
                  return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b);
              };
          };
      };
  };
  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applyFirst"] = applyFirst;
  exports["applySecond"] = applySecond;
  exports["lift2"] = lift2;
  exports["applyArray"] = applyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];                
  var Applicative = function (Apply0, pure) {
      this.Apply0 = Apply0;
      this.pure = pure;
  };
  var pure = function (dict) {
      return dict.pure;
  };
  var unless = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (!v) {
                  return v1;
              };
              if (v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 62, column 1 - line 62, column 65): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var when = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 57, column 1 - line 57, column 63): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var liftA1 = function (dictApplicative) {
      return function (f) {
          return function (a) {
              return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
          };
      };
  }; 
  var applicativeArray = new Applicative(function () {
      return Control_Apply.applyArray;
  }, function (x) {
      return [ x ];
  });
  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["unless"] = unless;
  exports["when"] = when;
  exports["applicativeArray"] = applicativeArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Lazy"] = $PS["Control.Lazy"] || {};
  var exports = $PS["Control.Lazy"];               
  var Lazy = function (defer) {
      this.defer = defer;
  }; 
  var defer = function (dict) {
      return dict.defer;
  };
  var fix = function (dictLazy) {
      return function (f) {
          var go = defer(dictLazy)(function (v) {
              return f(go);
          });
          return go;
      };
  };
  exports["defer"] = defer;
  exports["Lazy"] = Lazy;
  exports["fix"] = fix;
})(PS);
(function(exports) {
  "use strict";

  var refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqBooleanImpl = refEq;
  exports.eqIntImpl = refEq;   
  exports.eqCharImpl = refEq;
  exports.eqStringImpl = refEq;
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];
  var Eq = function (eq) {
      this.eq = eq;
  }; 
  var eqString = new Eq($foreign.eqStringImpl);
  var eqInt = new Eq($foreign.eqIntImpl);
  var eqChar = new Eq($foreign.eqCharImpl);
  var eqBoolean = new Eq($foreign.eqBooleanImpl);
  var eq = function (dict) {
      return dict.eq;
  };
  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["eqBoolean"] = eqBoolean;
  exports["eqInt"] = eqInt;
  exports["eqChar"] = eqChar;
  exports["eqString"] = eqString;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];                
  var Discard = function (discard) {
      this.discard = discard;
  };
  var Bind = function (Apply0, bind) {
      this.Apply0 = Apply0;
      this.bind = bind;
  };
  var discard = function (dict) {
      return dict.discard;
  };                     
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  };
  var composeKleisli = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bind(dictBind)(f(a))(g);
              };
          };
      };
  };
  var discardUnit = new Discard(function (dictBind) {
      return bind(dictBind);
  });
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["discard"] = discard;
  exports["composeKleisli"] = composeKleisli;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["discardUnit"] = discardUnit;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];          
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 217, column 1 - line 217, column 51): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Just) {
              return new Just(v(v1.value0));
          };
          return Nothing.value;
      };
  });
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };
  var fromJust = function (dictPartial) {
      return function (v) {
          if (v instanceof Just) {
              return v.value0;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 268, column 1 - line 268, column 46): " + [ v.constructor.name ]);
      };
  }; 
  var eqMaybe = function (dictEq) {
      return new Data_Eq.Eq(function (x) {
          return function (y) {
              if (x instanceof Nothing && y instanceof Nothing) {
                  return true;
              };
              if (x instanceof Just && y instanceof Just) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0);
              };
              return false;
          };
      });
  };
  var applyMaybe = new Control_Apply.Apply(function () {
      return functorMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return Data_Functor.map(functorMaybe)(v.value0)(v1);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 67, column 1 - line 69, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindMaybe = new Control_Bind.Bind(function () {
      return applyMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return v1(v.value0);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 125, column 1 - line 127, column 28): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeMaybe = new Control_Applicative.Applicative(function () {
      return applyMaybe;
  }, Just.create);
  var altMaybe = new Control_Alt.Alt(function () {
      return functorMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Nothing) {
              return v1;
          };
          return v;
      };
  });
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
  exports["altMaybe"] = altMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["eqMaybe"] = eqMaybe;
})(PS);
(function(exports) {
  "use strict";

  var unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };                                         
  exports.ordIntImpl = unsafeCompareImpl;   
  exports.ordStringImpl = unsafeCompareImpl;
  exports.ordCharImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];              
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Ord = function (Eq0, compare) {
      this.Eq0 = Eq0;
      this.compare = compare;
  }; 
  var ordString = new Ord(function () {
      return Data_Eq.eqString;
  }, $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordInt = new Ord(function () {
      return Data_Eq.eqInt;
  }, $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordChar = new Ord(function () {
      return Data_Eq.eqChar;
  }, $foreign.ordCharImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var compare = function (dict) {
      return dict.compare;
  };
  var greaterThan = function (dictOrd) {
      return function (a1) {
          return function (a2) {
              var v = compare(dictOrd)(a1)(a2);
              if (v instanceof Data_Ordering.GT) {
                  return true;
              };
              return false;
          };
      };
  };
  var lessThan = function (dictOrd) {
      return function (a1) {
          return function (a2) {
              var v = compare(dictOrd)(a1)(a2);
              if (v instanceof Data_Ordering.LT) {
                  return true;
              };
              return false;
          };
      };
  };
  var lessThanOrEq = function (dictOrd) {
      return function (a1) {
          return function (a2) {
              var v = compare(dictOrd)(a1)(a2);
              if (v instanceof Data_Ordering.GT) {
                  return false;
              };
              return true;
          };
      };
  };
  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["lessThan"] = lessThan;
  exports["lessThanOrEq"] = lessThanOrEq;
  exports["greaterThan"] = greaterThan;
  exports["ordInt"] = ordInt;
  exports["ordString"] = ordString;
  exports["ordChar"] = ordChar;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];                
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var uncurry = function (f) {
      return function (v) {
          return f(v.value0)(v.value1);
      };
  };
  var snd = function (v) {
      return v.value1;
  };
  var functorTuple = new Data_Functor.Functor(function (f) {
      return function (m) {
          return new Tuple(m.value0, f(m.value1));
      };
  });                                                                                                   
  var fst = function (v) {
      return v.value0;
  }; 
  var eqTuple = function (dictEq) {
      return function (dictEq1) {
          return new Data_Eq.Eq(function (x) {
              return function (y) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
              };
          });
      };
  };
  var ordTuple = function (dictOrd) {
      return function (dictOrd1) {
          return new Data_Ord.Ord(function () {
              return eqTuple(dictOrd.Eq0())(dictOrd1.Eq0());
          }, function (x) {
              return function (y) {
                  var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                  if (v instanceof Data_Ordering.LT) {
                      return Data_Ordering.LT.value;
                  };
                  if (v instanceof Data_Ordering.GT) {
                      return Data_Ordering.GT.value;
                  };
                  return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
              };
          });
      };
  };
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
  exports["uncurry"] = uncurry;
  exports["ordTuple"] = ordTuple;
  exports["functorTuple"] = functorTuple;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Tuple = $PS["Data.Tuple"];
  var zip = $foreign.zipWith(Data_Tuple.Tuple.create);
  var uncons = $foreign["uncons'"](Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
      return function (xs) {
          return new Data_Maybe.Just({
              head: x,
              tail: xs
          });
      };
  });
  var sortBy = function (comp) {
      return function (xs) {
          var comp$prime = function (x) {
              return function (y) {
                  var v = comp(x)(y);
                  if (v instanceof Data_Ordering.GT) {
                      return 1;
                  };
                  if (v instanceof Data_Ordering.EQ) {
                      return 0;
                  };
                  if (v instanceof Data_Ordering.LT) {
                      return -1 | 0;
                  };
                  throw new Error("Failed pattern match at Data.Array (line 702, column 15 - line 705, column 13): " + [ v.constructor.name ]);
              };
          };
          return $foreign.sortImpl(comp$prime)(xs);
      };
  };
  var sort = function (dictOrd) {
      return function (xs) {
          return sortBy(Data_Ord.compare(dictOrd))(xs);
      };
  };
  var mapWithIndex = function (f) {
      return function (xs) {
          return $foreign.zipWith(f)($foreign.range(0)($foreign.length(xs) - 1 | 0))(xs);
      };
  };
  var some = function (dictAlternative) {
      return function (dictLazy) {
          return function (v) {
              return Control_Apply.apply((dictAlternative.Applicative0()).Apply0())(Data_Functor.map(((dictAlternative.Plus1()).Alt0()).Functor0())($foreign.cons)(v))(Control_Lazy.defer(dictLazy)(function (v1) {
                  return many(dictAlternative)(dictLazy)(v);
              }));
          };
      };
  };
  var many = function (dictAlternative) {
      return function (dictLazy) {
          return function (v) {
              return Control_Alt.alt((dictAlternative.Plus1()).Alt0())(some(dictAlternative)(dictLazy)(v))(Control_Applicative.pure(dictAlternative.Applicative0())([  ]));
          };
      };
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var last = function (xs) {
      return index(xs)($foreign.length(xs) - 1 | 0);
  };
  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var elemIndex = function (dictEq) {
      return function (x) {
          return findIndex(function (v) {
              return Data_Eq.eq(dictEq)(v)(x);
          });
      };
  };
  exports["some"] = some;
  exports["many"] = many;
  exports["last"] = last;
  exports["uncons"] = uncons;
  exports["index"] = index;
  exports["elemIndex"] = elemIndex;
  exports["findIndex"] = findIndex;
  exports["mapWithIndex"] = mapWithIndex;
  exports["sort"] = sort;
  exports["zip"] = zip;
  exports["replicate"] = $foreign.replicate;
  exports["length"] = $foreign.length;
  exports["cons"] = $foreign.cons;
  exports["snoc"] = $foreign.snoc;
  exports["filter"] = $foreign.filter;
})(PS);
(function(exports) {
  "use strict";

  exports.runEffectFn1 = function runEffectFn1(fn) {
    return function(a) {
      return function() {
        return fn(a);
      };
    };
  };

  exports.runEffectFn2 = function runEffectFn2(fn) {
    return function(a) {
      return function(b) {
        return function() {
          return fn(a, b);
        };
      };
    };
  };
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Uncurried"] = $PS["Effect.Uncurried"] || {};
  var exports = $PS["Effect.Uncurried"];
  var $foreign = $PS["Effect.Uncurried"];
  exports["runEffectFn1"] = $foreign.runEffectFn1;
  exports["runEffectFn2"] = $foreign.runEffectFn2;
})(PS);
(function(exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];
  var HeytingAlgebra = function (conj, disj, ff, implies, not, tt) {
      this.conj = conj;
      this.disj = disj;
      this.ff = ff;
      this.implies = implies;
      this.not = not;
      this.tt = tt;
  };
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
      return function (b) {
          return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
      };
  }, $foreign.boolNot, true);
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return new HeytingAlgebra(function (f) {
          return function (g) {
              return function (a) {
                  return conj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (g) {
              return function (a) {
                  return disj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (v) {
          return ff(dictHeytingAlgebra);
      }, function (f) {
          return function (g) {
              return function (a) {
                  return implies(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (a) {
              return not(dictHeytingAlgebra)(f(a));
          };
      }, function (v) {
          return tt(dictHeytingAlgebra);
      });
  };
  exports["ff"] = ff;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS);
(function(exports) {
  "use strict";

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];
  var Semigroup = function (append) {
      this.append = append;
  }; 
  var semigroupArray = new Semigroup($foreign.concatArray);
  var append = function (dict) {
      return dict.append;
  };
  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["semigroupArray"] = semigroupArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Monoid = function (Semigroup0, mempty) {
      this.Semigroup0 = Semigroup0;
      this.mempty = mempty;
  };                         
  var monoidArray = new Monoid(function () {
      return Data_Semigroup.semigroupArray;
  }, [  ]);
  var mempty = function (dict) {
      return dict.mempty;
  };
  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
  exports["monoidArray"] = monoidArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Monoid.Disj"] = $PS["Data.Monoid.Disj"] || {};
  var exports = $PS["Data.Monoid.Disj"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Disj = function (x) {
      return x;
  };
  var semigroupDisj = function (dictHeytingAlgebra) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
          };
      });
  };
  var monoidDisj = function (dictHeytingAlgebra) {
      return new Data_Monoid.Monoid(function () {
          return semigroupDisj(dictHeytingAlgebra);
      }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
  };
  exports["Disj"] = Disj;
  exports["monoidDisj"] = monoidDisj;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid_Disj = $PS["Data.Monoid.Disj"];                      
  var Newtype = function (unwrap, wrap) {
      this.unwrap = unwrap;
      this.wrap = wrap;
  };
  var wrap = function (dict) {
      return dict.wrap;
  };
  var unwrap = function (dict) {
      return dict.unwrap;
  };                        
  var newtypeDisj = new Newtype(function (v) {
      return v;
  }, Data_Monoid_Disj.Disj);
  var alaF = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (v) {
                      return function (f) {
                          var $96 = Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1));
                          var $97 = Data_Functor.map(dictFunctor)(wrap(dictNewtype));
                          return function ($98) {
                              return $96(f($97($98)));
                          };
                      };
                  };
              };
          };
      };
  };
  exports["unwrap"] = unwrap;
  exports["wrap"] = wrap;
  exports["Newtype"] = Newtype;
  exports["alaF"] = alaF;
  exports["newtypeDisj"] = newtypeDisj;
})(PS);
(function(exports) {
  "use strict";

  exports["null"] = null;

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };

  exports.notNull = function (x) {
    return x;
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Nullable"] = $PS["Data.Nullable"] || {};
  var exports = $PS["Data.Nullable"];
  var $foreign = $PS["Data.Nullable"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var toNullable = Data_Maybe.maybe($foreign["null"])($foreign.notNull);
  var toMaybe = function (n) {
      return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };
  exports["toMaybe"] = toMaybe;
  exports["toNullable"] = toNullable;
})(PS);
(function(exports) {
  "use strict";

  exports.new = function (val) {
    return function () {
      return { value: val };
    };
  };

  exports.read = function (ref) {
    return function () {
      return ref.value;
    };
  };

  exports["modify'"] = function (f) {
    return function (ref) {
      return function () {
        var t = f(ref.value);
        ref.value = t.state;
        return t.value;
      };
    };
  };

  exports.write = function (val) {
    return function (ref) {
      return function () {
        ref.value = val;
        return {};
      };
    };
  };
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});
(function(exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];                
  var Monad = function (Applicative0, Bind1) {
      this.Applicative0 = Applicative0;
      this.Bind1 = Bind1;
  };
  var unlessM = function (dictMonad) {
      return function (mb) {
          return function (m) {
              return Control_Bind.bind(dictMonad.Bind1())(mb)(function (v) {
                  return Control_Applicative.unless(dictMonad.Applicative0())(v)(m);
              });
          };
      };
  };
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (v1) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(v(v1));
                  });
              });
          };
      };
  };
  exports["Monad"] = Monad;
  exports["ap"] = ap;
  exports["unlessM"] = unlessM;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];                    
  var monadEffect = new Control_Monad.Monad(function () {
      return applicativeEffect;
  }, function () {
      return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
      return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
      return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
      return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Ref"] = $PS["Effect.Ref"] || {};
  var exports = $PS["Effect.Ref"];
  var $foreign = $PS["Effect.Ref"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];                
  var modify = function (f) {
      return $foreign["modify'"](function (s) {
          var s$prime = f(s);
          return {
              state: s$prime,
              value: s$prime
          };
      });
  };
  var modify_ = function (f) {
      return function (s) {
          return Data_Functor["void"](Effect.functorEffect)(modify(f)(s));
      };
  };
  exports["modify_"] = modify_;
  exports["new"] = $foreign["new"];
  exports["read"] = $foreign.read;
  exports["modify'"] = $foreign["modify'"];
  exports["write"] = $foreign.write;
})(PS);
(function(exports) {
  "use strict";

  exports.typeOf = function (value) {
    return typeof value;
  };
})(PS["Foreign"] = PS["Foreign"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  exports["typeOf"] = $foreign.typeOf;
})(PS);
(function(exports) {
  "use strict";

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };

  function toArrayWithKey(f) {
    return function (m) {
      var r = [];
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r.push(f(k)(m[k]));
        }
      }
      return r;
    };
  }
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});
(function(exports) {
  "use strict";

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Function.Uncurried"] = $PS["Data.Function.Uncurried"] || {};
  var exports = $PS["Data.Function.Uncurried"];
  var $foreign = $PS["Data.Function.Uncurried"];
  exports["runFn4"] = $foreign.runFn4;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Foreign.Object"] = $PS["Foreign.Object"] || {};
  var exports = $PS["Foreign.Object"];
  var $foreign = $PS["Foreign.Object"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];
  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  exports["lookup"] = lookup;
})(PS);
(function(exports) {
  "use strict";

  // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.Machine"] = $PS["Halogen.VDom.Machine"] || {};
  var exports = $PS["Halogen.VDom.Machine"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var Step = (function () {
      function Step(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Step.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Step(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Step;
  })();
  var unStep = Unsafe_Coerce.unsafeCoerce;
  var step = function (v, a) {
      return v.value2(v.value1, a);
  };
  var mkStep = Unsafe_Coerce.unsafeCoerce;
  var halt = function (v) {
      return v.value3(v.value1);
  };
  var extract = unStep(function (v) {
      return v.value0;
  });
  exports["Step"] = Step;
  exports["mkStep"] = mkStep;
  exports["unStep"] = unStep;
  exports["extract"] = extract;
  exports["step"] = step;
  exports["halt"] = halt;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGetAny = function (key, obj) {
    return obj[key];
  };

  exports.unsafeHasAny = function (key, obj) {
    return obj.hasOwnProperty(key);
  };

  exports.unsafeSetAny = function (key, val, obj) {
      obj[key] = val;
  };

  exports.forE = function (a, f) {
    var b = [];
    for (var i = 0; i < a.length; i++) {
      b.push(f(i, a[i]));
    }
    return b;
  };

  exports.forEachE = function (a, f) {
    for (var i = 0; i < a.length; i++) {
      f(a[i]);
    }
  };

  exports.forInE = function (o, f) {
    var ks = Object.keys(o);
    for (var i = 0; i < ks.length; i++) {
      var k = ks[i];
      f(k, o[k]);
    }
  };

  exports.diffWithIxE = function (a1, a2, f1, f2, f3) {
    var a3 = [];
    var l1 = a1.length;
    var l2 = a2.length;
    var i  = 0;
    while (1) {
      if (i < l1) {
        if (i < l2) {
          a3.push(f1(i, a1[i], a2[i]));
        } else {
          f2(i, a1[i]);
        }
      } else if (i < l2) {
        a3.push(f3(i, a2[i]));
      } else {
        break;
      }
      i++;
    }
    return a3;
  };

  exports.strMapWithIxE = function (as, fk, f) {
    var o = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      o[k] = f(k, i, a);
    }
    return o;
  };

  exports.diffWithKeyAndIxE = function (o1, as, fk, f1, f2, f3) {
    var o2 = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      if (o1.hasOwnProperty(k)) {
        o2[k] = f1(k, i, o1[k], a);
      } else {
        o2[k] = f3(k, i, a);
      }
    }
    for (var k in o1) {
      if (k in o2) {
        continue;
      }
      f2(k, o1[k]);
    }
    return o2;
  };

  exports.refEq = function (a, b) {
    return a === b;
  };

  exports.createTextNode = function (s, doc) {
    return doc.createTextNode(s);
  };

  exports.setTextContent = function (s, n) {
    n.textContent = s;
  };

  exports.createElement = function (ns, name, doc) {
    if (ns != null) {
      return doc.createElementNS(ns, name);
    } else {
      return doc.createElement(name)
    }
  };

  exports.insertChildIx = function (i, a, b) {
    var n = b.childNodes.item(i) || null;
    if (n !== a) {
      b.insertBefore(a, n);
    }
  };

  exports.removeChild = function (a, b) {
    if (b && a.parentNode === b) {
      b.removeChild(a);
    }
  };

  exports.parentNode = function (a) {
    return a.parentNode;
  };

  exports.setAttribute = function (ns, attr, val, el) {
    if (ns != null) {
      el.setAttributeNS(ns, attr, val);
    } else {
      el.setAttribute(attr, val);
    }
  };

  exports.removeAttribute = function (ns, attr, el) {
    if (ns != null) {
      el.removeAttributeNS(ns, attr);
    } else {
      el.removeAttribute(attr);
    }
  };

  exports.addEventListener = function (ev, listener, el) {
    el.addEventListener(ev, listener, false);
  };

  exports.removeEventListener = function (ev, listener, el) {
    el.removeEventListener(ev, listener, false);
  };

  exports.jsUndefined = void 0;
})(PS["Halogen.VDom.Util"] = PS["Halogen.VDom.Util"] || {});
(function(exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Foreign.Object.ST"] = $PS["Foreign.Object.ST"] || {};
  var exports = $PS["Foreign.Object.ST"];
  var $foreign = $PS["Foreign.Object.ST"];
  exports["new"] = $foreign["new"];
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.Util"] = $PS["Halogen.VDom.Util"] || {};
  var exports = $PS["Halogen.VDom.Util"];
  var $foreign = $PS["Halogen.VDom.Util"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var unsafeLookup = $foreign.unsafeGetAny;
  var unsafeFreeze = Unsafe_Coerce.unsafeCoerce;
  var pokeMutMap = $foreign.unsafeSetAny;
  var newMutMap = Foreign_Object_ST["new"];
  exports["newMutMap"] = newMutMap;
  exports["pokeMutMap"] = pokeMutMap;
  exports["unsafeFreeze"] = unsafeFreeze;
  exports["unsafeLookup"] = unsafeLookup;
  exports["unsafeGetAny"] = $foreign.unsafeGetAny;
  exports["unsafeHasAny"] = $foreign.unsafeHasAny;
  exports["unsafeSetAny"] = $foreign.unsafeSetAny;
  exports["forE"] = $foreign.forE;
  exports["forEachE"] = $foreign.forEachE;
  exports["forInE"] = $foreign.forInE;
  exports["diffWithIxE"] = $foreign.diffWithIxE;
  exports["diffWithKeyAndIxE"] = $foreign.diffWithKeyAndIxE;
  exports["strMapWithIxE"] = $foreign.strMapWithIxE;
  exports["refEq"] = $foreign.refEq;
  exports["createTextNode"] = $foreign.createTextNode;
  exports["setTextContent"] = $foreign.setTextContent;
  exports["createElement"] = $foreign.createElement;
  exports["insertChildIx"] = $foreign.insertChildIx;
  exports["removeChild"] = $foreign.removeChild;
  exports["parentNode"] = $foreign.parentNode;
  exports["setAttribute"] = $foreign.setAttribute;
  exports["removeAttribute"] = $foreign.removeAttribute;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
  exports["jsUndefined"] = $foreign.jsUndefined;
})(PS);
(function(exports) {
  "use strict";

  exports.eventListener = function (fn) {
    return function () {
      return function (event) {
        return fn(event)();
      };
    };
  };

  exports.addEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.addEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };

  exports.removeEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.removeEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.Event.EventTarget"] = $PS["Web.Event.EventTarget"] || {};
  var exports = $PS["Web.Event.EventTarget"];
  var $foreign = $PS["Web.Event.EventTarget"];
  exports["eventListener"] = $foreign.eventListener;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.DOM.Prop"] = $PS["Halogen.VDom.DOM.Prop"] || {};
  var exports = $PS["Halogen.VDom.DOM.Prop"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];                
  var Created = (function () {
      function Created(value0) {
          this.value0 = value0;
      };
      Created.create = function (value0) {
          return new Created(value0);
      };
      return Created;
  })();
  var Removed = (function () {
      function Removed(value0) {
          this.value0 = value0;
      };
      Removed.create = function (value0) {
          return new Removed(value0);
      };
      return Removed;
  })();
  var Attribute = (function () {
      function Attribute(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Attribute.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Attribute(value0, value1, value2);
              };
          };
      };
      return Attribute;
  })();
  var Property = (function () {
      function Property(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Property.create = function (value0) {
          return function (value1) {
              return new Property(value0, value1);
          };
      };
      return Property;
  })();
  var Handler = (function () {
      function Handler(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Handler.create = function (value0) {
          return function (value1) {
              return new Handler(value0, value1);
          };
      };
      return Handler;
  })();
  var Ref = (function () {
      function Ref(value0) {
          this.value0 = value0;
      };
      Ref.create = function (value0) {
          return new Ref(value0);
      };
      return Ref;
  })();
  var unsafeGetProperty = Halogen_VDom_Util.unsafeGetAny;
  var setProperty = Halogen_VDom_Util.unsafeSetAny;
  var removeProperty = function (key, el) {
      var v = Foreign.typeOf(Halogen_VDom_Util.unsafeGetAny(key, el));
      if (v === "string") {
          return Halogen_VDom_Util.unsafeSetAny(key, "", el);
      };
      if (key === "rowSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      if (key === "colSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      return Halogen_VDom_Util.unsafeSetAny(key, Halogen_VDom_Util.jsUndefined, el);
  };
  var propToStrKey = function (v) {
      if (v instanceof Attribute && v.value0 instanceof Data_Maybe.Just) {
          return "attr/" + (v.value0.value0 + (":" + v.value1));
      };
      if (v instanceof Attribute) {
          return "attr/:" + v.value1;
      };
      if (v instanceof Property) {
          return "prop/" + v.value0;
      };
      if (v instanceof Handler) {
          return "handler/" + v.value0;
      };
      if (v instanceof Ref) {
          return "ref";
      };
      throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 182, column 16 - line 187, column 16): " + [ v.constructor.name ]);
  };
  var propFromString = Unsafe_Coerce.unsafeCoerce;
  var buildProp = function (emit) {
      return function (el) {
          var removeProp = function (prevEvents) {
              return function (v, v1) {
                  if (v1 instanceof Attribute) {
                      return Halogen_VDom_Util.removeAttribute(Data_Nullable.toNullable(v1.value0), v1.value1, el);
                  };
                  if (v1 instanceof Property) {
                      return removeProperty(v1.value0, el);
                  };
                  if (v1 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v1.value0, prevEvents);
                      return Halogen_VDom_Util.removeEventListener(v1.value0, Data_Tuple.fst(handler), el);
                  };
                  if (v1 instanceof Ref) {
                      return Data_Unit.unit;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 169, column 5 - line 179, column 18): " + [ v1.constructor.name ]);
              };
          };
          var mbEmit = function (v) {
              if (v instanceof Data_Maybe.Just) {
                  return emit(v.value0)();
              };
              return Data_Unit.unit;
          };
          var haltProp = function (state) {
              var v = Foreign_Object.lookup("ref")(state.props);
              if (v instanceof Data_Maybe.Just && v.value0 instanceof Ref) {
                  return mbEmit(v.value0.value0(new Removed(el)));
              };
              return Data_Unit.unit;
          };
          var diffProp = function (prevEvents, events) {
              return function (v, v1, v11, v2) {
                  if (v11 instanceof Attribute && v2 instanceof Attribute) {
                      var $61 = v11.value2 === v2.value2;
                      if ($61) {
                          return v2;
                      };
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v11 instanceof Property && v2 instanceof Property) {
                      var v4 = Halogen_VDom_Util.refEq(v11.value1, v2.value1);
                      if (v4) {
                          return v2;
                      };
                      if (v2.value0 === "value") {
                          var elVal = unsafeGetProperty("value", el);
                          var $70 = Halogen_VDom_Util.refEq(elVal, v2.value1);
                          if ($70) {
                              return v2;
                          };
                          setProperty(v2.value0, v2.value1, el);
                          return v2;
                      };
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v11 instanceof Handler && v2 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v2.value0, prevEvents);
                      Effect_Ref.write(v2.value1)(Data_Tuple.snd(handler))();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, handler, events);
                      return v2;
                  };
                  return v2;
              };
          };
          var applyProp = function (events) {
              return function (v, v1, v2) {
                  if (v2 instanceof Attribute) {
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v2 instanceof Property) {
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v2 instanceof Handler) {
                      var v3 = Halogen_VDom_Util.unsafeGetAny(v2.value0, events);
                      if (Halogen_VDom_Util.unsafeHasAny(v2.value0, events)) {
                          Effect_Ref.write(v2.value1)(Data_Tuple.snd(v3))();
                          return v2;
                      };
                      var v4 = Effect_Ref["new"](v2.value1)();
                      var v5 = Web_Event_EventTarget.eventListener(function (ev) {
                          return function __do() {
                              var v5 = Effect_Ref.read(v4)();
                              return mbEmit(v5(ev));
                          };
                      })();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, new Data_Tuple.Tuple(v5, v4), events);
                      Halogen_VDom_Util.addEventListener(v2.value0, v5, el);
                      return v2;
                  };
                  if (v2 instanceof Ref) {
                      mbEmit(v2.value0(new Created(el)));
                      return v2;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 113, column 5 - line 135, column 15): " + [ v2.constructor.name ]);
              };
          };
          var patchProp = function (state, ps2) {
              var v = Halogen_VDom_Util.newMutMap();
              var onThis = removeProp(state.events);
              var onThese = diffProp(state.events, v);
              var onThat = applyProp(v);
              var v1 = Halogen_VDom_Util.diffWithKeyAndIxE(state.props, ps2, propToStrKey, onThese, onThis, onThat);
              var nextState = {
                  events: Halogen_VDom_Util.unsafeFreeze(v),
                  props: v1
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, nextState, patchProp, haltProp));
          };
          var renderProp = function (ps1) {
              var v = Halogen_VDom_Util.newMutMap();
              var v1 = Halogen_VDom_Util.strMapWithIxE(ps1, propToStrKey, applyProp(v));
              var state = {
                  events: Halogen_VDom_Util.unsafeFreeze(v),
                  props: v1
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, state, patchProp, haltProp));
          };
          return renderProp;
      };
  };
  exports["Attribute"] = Attribute;
  exports["Property"] = Property;
  exports["Handler"] = Handler;
  exports["Ref"] = Ref;
  exports["Created"] = Created;
  exports["Removed"] = Removed;
  exports["propFromString"] = propFromString;
  exports["buildProp"] = buildProp;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Bifunctor"] = $PS["Data.Bifunctor"] || {};
  var exports = $PS["Data.Bifunctor"];
  var Control_Category = $PS["Control.Category"];                
  var Bifunctor = function (bimap) {
      this.bimap = bimap;
  };
  var bimap = function (dict) {
      return dict.bimap;
  };
  var rmap = function (dictBifunctor) {
      return bimap(dictBifunctor)(Control_Category.identity(Control_Category.categoryFn));
  };
  exports["bimap"] = bimap;
  exports["Bifunctor"] = Bifunctor;
  exports["rmap"] = rmap;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.Types"] = $PS["Halogen.VDom.Types"] || {};
  var exports = $PS["Halogen.VDom.Types"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Text = (function () {
      function Text(value0) {
          this.value0 = value0;
      };
      Text.create = function (value0) {
          return new Text(value0);
      };
      return Text;
  })();
  var Elem = (function () {
      function Elem(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Elem.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Elem(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Elem;
  })();
  var Keyed = (function () {
      function Keyed(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Keyed.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Keyed(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Keyed;
  })();
  var Widget = (function () {
      function Widget(value0) {
          this.value0 = value0;
      };
      Widget.create = function (value0) {
          return new Widget(value0);
      };
      return Widget;
  })();
  var Grafted = (function () {
      function Grafted(value0) {
          this.value0 = value0;
      };
      Grafted.create = function (value0) {
          return new Grafted(value0);
      };
      return Grafted;
  })();
  var Graft = (function () {
      function Graft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Graft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Graft(value0, value1, value2);
              };
          };
      };
      return Graft;
  })();
  var unGraft = function (f) {
      return function ($58) {
          return f($58);
      };
  };           
  var graft = Unsafe_Coerce.unsafeCoerce;
  var bifunctorGraft = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return unGraft(function (v) {
              return graft(new Graft(function ($60) {
                  return f(v.value0($60));
              }, function ($61) {
                  return g(v.value1($61));
              }, v.value2));
          });
      };
  });
  var runGraft = unGraft(function (v) {
      var go = function (v2) {
          if (v2 instanceof Text) {
              return new Text(v2.value0);
          };
          if (v2 instanceof Elem) {
              return new Elem(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(go)(v2.value3));
          };
          if (v2 instanceof Keyed) {
              return new Keyed(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(go))(v2.value3));
          };
          if (v2 instanceof Widget) {
              return new Widget(v.value1(v2.value0));
          };
          if (v2 instanceof Grafted) {
              return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v.value0)(v.value1)(v2.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.Types (line 86, column 7 - line 86, column 27): " + [ v2.constructor.name ]);
      };
      return go(v.value2);
  });
  exports["Text"] = Text;
  exports["Elem"] = Elem;
  exports["Keyed"] = Keyed;
  exports["Widget"] = Widget;
  exports["Grafted"] = Grafted;
  exports["runGraft"] = runGraft;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.HTML.Core"] = $PS["Halogen.HTML.Core"] || {};
  var exports = $PS["Halogen.HTML.Core"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var HTML = function (x) {
      return x;
  };
  var ClassName = function (x) {
      return x;
  };
  var IsProp = function (toPropValue) {
      this.toPropValue = toPropValue;
  };
  var widget = function ($30) {
      return HTML(Halogen_VDom_Types.Widget.create($30));
  };
  var toPropValue = function (dict) {
      return dict.toPropValue;
  };
  var text = function ($31) {
      return HTML(Halogen_VDom_Types.Text.create($31));
  };                                                      
  var ref = function (f) {
      return Halogen_VDom_DOM_Prop.Ref.create(function ($32) {
          return f((function (v) {
              if (v instanceof Halogen_VDom_DOM_Prop.Created) {
                  return new Data_Maybe.Just(v.value0);
              };
              if (v instanceof Halogen_VDom_DOM_Prop.Removed) {
                  return Data_Maybe.Nothing.value;
              };
              throw new Error("Failed pattern match at Halogen.HTML.Core (line 104, column 21 - line 106, column 23): " + [ v.constructor.name ]);
          })($32));
      });
  };
  var prop = function (dictIsProp) {
      return function (v) {
          var $33 = Halogen_VDom_DOM_Prop.Property.create(v);
          var $34 = toPropValue(dictIsProp);
          return function ($35) {
              return $33($34($35));
          };
      };
  };           
  var newtypeHTML = new Data_Newtype.Newtype(function (n) {
      return n;
  }, HTML);
  var newtypeClassName = new Data_Newtype.Newtype(function (n) {
      return n;
  }, ClassName);
  var isPropString = new IsProp(Halogen_VDom_DOM_Prop.propFromString);  
  var handler = Halogen_VDom_DOM_Prop.Handler.create;
  var element = function (ns) {
      return function (name) {
          return function (props) {
              return function (children) {
                  return new Halogen_VDom_Types.Elem(ns, name, props, children);
              };
          };
      };
  };                                                                             
  var attr = function (ns) {
      return function (v) {
          return Halogen_VDom_DOM_Prop.Attribute.create(ns)(v);
      };
  };
  exports["widget"] = widget;
  exports["text"] = text;
  exports["element"] = element;
  exports["prop"] = prop;
  exports["attr"] = attr;
  exports["handler"] = handler;
  exports["ref"] = ref;
  exports["ClassName"] = ClassName;
  exports["newtypeHTML"] = newtypeHTML;
  exports["isPropString"] = isPropString;
  exports["newtypeClassName"] = newtypeClassName;
})(PS);
(function(exports) {
  "use strict";

  exports.split = function (sep) {
    return function (s) {
      return s.split(sep);
    };
  };

  exports.toLower = function (s) {
    return s.toLowerCase();
  };

  exports.trim = function (s) {
    return s.trim();
  };

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];                
  var $$null = function (s) {
      return s === "";
  };
  exports["null"] = $$null;
  exports["split"] = $foreign.split;
  exports["toLower"] = $foreign.toLower;
  exports["trim"] = $foreign.trim;
  exports["joinWith"] = $foreign.joinWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Query.Input"] = $PS["Halogen.Query.Input"] || {};
  var exports = $PS["Halogen.Query.Input"];
  var RefUpdate = (function () {
      function RefUpdate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RefUpdate.create = function (value0) {
          return function (value1) {
              return new RefUpdate(value0, value1);
          };
      };
      return RefUpdate;
  })();
  var Action = (function () {
      function Action(value0) {
          this.value0 = value0;
      };
      Action.create = function (value0) {
          return new Action(value0);
      };
      return Action;
  })();
  exports["RefUpdate"] = RefUpdate;
  exports["Action"] = Action;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.HTML.Properties"] = $PS["Halogen.HTML.Properties"] || {};
  var exports = $PS["Halogen.HTML.Properties"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var ref = (function () {
      var go = function (p) {
          return function (mel) {
              return new Data_Maybe.Just(new Halogen_Query_Input.RefUpdate(p, mel));
          };
      };
      return function ($11) {
          return Halogen_HTML_Core.ref(go($11));
      };
  })();
  var prop = function (dictIsProp) {
      return Halogen_HTML_Core.prop(dictIsProp);
  };
  var value = prop(Halogen_HTML_Core.isPropString)("value");
  var id_ = prop(Halogen_HTML_Core.isPropString)("id");      
  var classes = (function () {
      var $14 = prop(Halogen_HTML_Core.isPropString)("className");
      var $15 = Data_String_Common.joinWith(" ");
      var $16 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap(Halogen_HTML_Core.newtypeClassName));
      return function ($17) {
          return $14($15($16($17)));
      };
  })();
  var class_ = (function () {
      var $18 = prop(Halogen_HTML_Core.isPropString)("className");
      var $19 = Data_Newtype.unwrap(Halogen_HTML_Core.newtypeClassName);
      return function ($20) {
          return $18($19($20));
      };
  })();
  var attr = Halogen_HTML_Core.attr(Data_Maybe.Nothing.value);
  exports["attr"] = attr;
  exports["ref"] = ref;
  exports["class_"] = class_;
  exports["classes"] = classes;
  exports["id_"] = id_;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Component.Util"] = $PS["Component.Util"] || {};
  var exports = $PS["Component.Util"];
  var $foreign = $PS["Component.Util"];
  var Data_Array = $PS["Data.Array"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect_Uncurried = $PS["Effect.Uncurried"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];                
  var setFocus = Effect_Uncurried.runEffectFn1($foreign["_setFocus"]);
  var classNames_ = (function () {
      var $2 = Data_Functor.map(Data_Functor.functorArray)(function ($5) {
          return Halogen_HTML_Core.ClassName((function (v) {
              return v.name;
          })($5));
      });
      var $3 = Data_Array.filter(function (v) {
          return v.cond;
      });
      return function ($4) {
          return Halogen_HTML_Properties.classes($2($3($4)));
      };
  })();
  var classNames = (function () {
      var $6 = Data_Functor.map(Data_Functor.functorArray)(Halogen_HTML_Core.ClassName);
      return function ($7) {
          return Halogen_HTML_Properties.classes($6($7));
      };
  })();
  var className = function ($8) {
      return Halogen_HTML_Properties.class_(Halogen_HTML_Core.ClassName($8));
  };
  exports["className"] = className;
  exports["classNames"] = classNames;
  exports["classNames_"] = classNames_;
  exports["setFocus"] = setFocus;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.State.Class"] = $PS["Control.Monad.State.Class"] || {};
  var exports = $PS["Control.Monad.State.Class"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                
  var MonadState = function (Monad0, state) {
      this.Monad0 = Monad0;
      this.state = state;
  };
  var state = function (dict) {
      return dict.state;
  };
  var put = function (dictMonadState) {
      return function (s) {
          return state(dictMonadState)(function (v) {
              return new Data_Tuple.Tuple(Data_Unit.unit, s);
          });
      };
  };
  var modify_ = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
          });
      };
  };
  var modify = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              var s$prime = f(s);
              return new Data_Tuple.Tuple(s$prime, s$prime);
          });
      };
  };
  var gets = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(f(s), s);
          });
      };
  };
  var get = function (dictMonadState) {
      return state(dictMonadState)(function (s) {
          return new Data_Tuple.Tuple(s, s);
      });
  };
  exports["state"] = state;
  exports["MonadState"] = MonadState;
  exports["get"] = get;
  exports["gets"] = gets;
  exports["put"] = put;
  exports["modify"] = modify;
  exports["modify_"] = modify_;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];          
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var functorEither = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Left) {
              return new Left(m.value0);
          };
          if (m instanceof Right) {
              return new Right(f(m.value0));
          };
          throw new Error("Failed pattern match at Data.Either (line 38, column 1 - line 38, column 52): " + [ m.constructor.name ]);
      };
  });
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either (line 238, column 1 - line 238, column 64): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var bifunctorEither = new Data_Bifunctor.Bifunctor(function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return new Left(v(v2.value0));
              };
              if (v2 instanceof Right) {
                  return new Right(v1(v2.value0));
              };
              throw new Error("Failed pattern match at Data.Either (line 46, column 1 - line 48, column 36): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  });
  var applyEither = new Control_Apply.Apply(function () {
      return functorEither;
  }, function (v) {
      return function (v1) {
          if (v instanceof Left) {
              return new Left(v.value0);
          };
          if (v instanceof Right) {
              return Data_Functor.map(functorEither)(v.value0)(v1);
          };
          throw new Error("Failed pattern match at Data.Either (line 82, column 1 - line 84, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["functorEither"] = functorEither;
  exports["bifunctorEither"] = bifunctorEither;
  exports["applyEither"] = applyEither;
})(PS);
(function(exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function(exports) {
  "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Semiring"] = $PS["Data.Semiring"] || {};
  var exports = $PS["Data.Semiring"];
  var $foreign = $PS["Data.Semiring"];
  var Semiring = function (add, mul, one, zero) {
      this.add = add;
      this.mul = mul;
      this.one = one;
      this.zero = zero;
  };
  var zero = function (dict) {
      return dict.zero;
  };                                                                            
  var semiringInt = new Semiring($foreign.intAdd, $foreign.intMul, 1, 0);
  var one = function (dict) {
      return dict.one;
  };
  var add = function (dict) {
      return dict.add;
  };
  exports["add"] = add;
  exports["zero"] = zero;
  exports["one"] = one;
  exports["semiringInt"] = semiringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Monoid_Disj = $PS["Data.Monoid.Disj"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Data_Unit = $PS["Data.Unit"];                
  var Foldable = function (foldMap, foldl, foldr) {
      this.foldMap = foldMap;
      this.foldl = foldl;
      this.foldr = foldr;
  };
  var foldr = function (dict) {
      return dict.foldr;
  };
  var traverse_ = function (dictApplicative) {
      return function (dictFoldable) {
          return function (f) {
              return foldr(dictFoldable)((function () {
                  var $197 = Control_Apply.applySecond(dictApplicative.Apply0());
                  return function ($198) {
                      return $197(f($198));
                  };
              })())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
          };
      };
  };
  var for_ = function (dictApplicative) {
      return function (dictFoldable) {
          return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
      };
  };
  var foldl = function (dict) {
      return dict.foldl;
  };
  var length = function (dictFoldable) {
      return function (dictSemiring) {
          return foldl(dictFoldable)(function (c) {
              return function (v) {
                  return Data_Semiring.add(dictSemiring)(Data_Semiring.one(dictSemiring))(c);
              };
          })(Data_Semiring.zero(dictSemiring));
      };
  }; 
  var foldableMaybe = new Foldable(function (dictMonoid) {
      return function (f) {
          return function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v instanceof Data_Maybe.Just) {
                  return f(v.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 135, column 27): " + [ f.constructor.name, v.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(z)(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 135, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(v1.value0)(z);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 129, column 1 - line 135, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = new Foldable(function (dictMonoid) {
      return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);
  var foldMap = function (dict) {
      return dict.foldMap;
  };
  var any = function (dictFoldable) {
      return function (dictHeytingAlgebra) {
          return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Newtype.newtypeDisj)(Data_Newtype.newtypeDisj)(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
      };
  };
  var elem = function (dictFoldable) {
      return function (dictEq) {
          var $204 = any(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean);
          var $205 = Data_Eq.eq(dictEq);
          return function ($206) {
              return $204($205($206));
          };
      };
  };
  var notElem = function (dictFoldable) {
      return function (dictEq) {
          return function (x) {
              var $207 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
              var $208 = elem(dictFoldable)(dictEq)(x);
              return function ($209) {
                  return $207($208($209));
              };
          };
      };
  };
  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["elem"] = elem;
  exports["notElem"] = notElem;
  exports["length"] = length;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
})(PS);
(function(exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showCharImpl = function (c) {
    var code = c.charCodeAt(0);
    if (code < 0x20 || code === 0x7F) {
      switch (c) {
        case "\x07": return "'\\a'";
        case "\b": return "'\\b'";
        case "\f": return "'\\f'";
        case "\n": return "'\\n'";
        case "\r": return "'\\r'";
        case "\t": return "'\\t'";
        case "\v": return "'\\v'";
      }
      return "'\\" + code.toString(10) + "'";
    }
    return c === "'" || c === "\\" ? "'\\" + c + "'" : "'" + c + "'";
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(
      /[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
      function (c, i) {
        switch (c) {
          case "\"":
          case "\\":
            return "\\" + c;
          case "\x07": return "\\a";
          case "\b": return "\\b";
          case "\f": return "\\f";
          case "\n": return "\\n";
          case "\r": return "\\r";
          case "\t": return "\\t";
          case "\v": return "\\v";
        }
        var k = i + 1;
        var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
        return "\\" + c.charCodeAt(0).toString(10) + empty;
      }
    ) + "\"";
  };

  exports.showArrayImpl = function (f) {
    return function (xs) {
      var ss = [];
      for (var i = 0, l = xs.length; i < l; i++) {
        ss[i] = f(xs[i]);
      }
      return "[" + ss.join(",") + "]";
    };
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];
  var Show = function (show) {
      this.show = show;
  };
  var showString = new Show($foreign.showStringImpl);
  var showInt = new Show($foreign.showIntImpl);
  var showChar = new Show($foreign.showCharImpl);
  var show = function (dict) {
      return dict.show;
  };
  var showArray = function (dictShow) {
      return new Show($foreign.showArrayImpl(show(dictShow)));
  };
  exports["Show"] = Show;
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showChar"] = showChar;
  exports["showString"] = showString;
  exports["showArray"] = showArray;
})(PS);
(function(exports) {
  "use strict";
  /* global Symbol */

  var hasArrayFrom = typeof Array.from === "function";
  var hasStringIterator =
    typeof Symbol !== "undefined" &&
    Symbol != null &&
    typeof Symbol.iterator !== "undefined" &&
    typeof String.prototype[Symbol.iterator] === "function";
  var hasFromCodePoint = typeof String.prototype.fromCodePoint === "function";
  var hasCodePointAt = typeof String.prototype.codePointAt === "function";

  exports._unsafeCodePointAt0 = function (fallback) {
    return hasCodePointAt
      ? function (str) { return str.codePointAt(0); }
      : fallback;
  };

  exports._codePointAt = function (fallback) {
    return function (Just) {
      return function (Nothing) {
        return function (unsafeCodePointAt0) {
          return function (index) {
            return function (str) {
              var length = str.length;
              if (index < 0 || index >= length) return Nothing;
              if (hasStringIterator) {
                var iter = str[Symbol.iterator]();
                for (var i = index;; --i) {
                  var o = iter.next();
                  if (o.done) return Nothing;
                  if (i === 0) return Just(unsafeCodePointAt0(o.value));
                }
              }
              return fallback(index)(str);
            };
          };
        };
      };
    };
  };

  exports._singleton = function (fallback) {
    return hasFromCodePoint ? String.fromCodePoint : fallback;
  };

  exports._take = function (fallback) {
    return function (n) {
      if (hasStringIterator) {
        return function (str) {
          var accum = "";
          var iter = str[Symbol.iterator]();
          for (var i = 0; i < n; ++i) {
            var o = iter.next();
            if (o.done) return accum;
            accum += o.value;
          }
          return accum;
        };
      }
      return fallback(n);
    };
  };

  exports._toCodePointArray = function (fallback) {
    return function (unsafeCodePointAt0) {
      if (hasArrayFrom) {
        return function (str) {
          return Array.from(str, unsafeCodePointAt0);
        };
      }
      return fallback;
    };
  };
})(PS["Data.String.CodePoints"] = PS["Data.String.CodePoints"] || {});
(function(exports) {
  "use strict";

  exports.topInt = 2147483647;
  exports.bottomInt = -2147483648;

  exports.topChar = String.fromCharCode(65535);
  exports.bottomChar = String.fromCharCode(0);
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Bounded"] = $PS["Data.Bounded"] || {};
  var exports = $PS["Data.Bounded"];
  var $foreign = $PS["Data.Bounded"];
  var Data_Ord = $PS["Data.Ord"];                  
  var Bounded = function (Ord0, bottom, top) {
      this.Ord0 = Ord0;
      this.bottom = bottom;
      this.top = top;
  };
  var top = function (dict) {
      return dict.top;
  };                                            
  var boundedInt = new Bounded(function () {
      return Data_Ord.ordInt;
  }, $foreign.bottomInt, $foreign.topInt);
  var boundedChar = new Bounded(function () {
      return Data_Ord.ordChar;
  }, $foreign.bottomChar, $foreign.topChar);
  var bottom = function (dict) {
      return dict.bottom;
  };
  exports["bottom"] = bottom;
  exports["top"] = top;
  exports["boundedInt"] = boundedInt;
  exports["boundedChar"] = boundedChar;
})(PS);
(function(exports) {
  "use strict";

  exports.toCharCode = function (c) {
    return c.charCodeAt(0);
  };

  exports.fromCharCode = function (c) {
    return String.fromCharCode(c);
  };
})(PS["Data.Enum"] = PS["Data.Enum"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Enum"] = $PS["Data.Enum"] || {};
  var exports = $PS["Data.Enum"];
  var $foreign = $PS["Data.Enum"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Enum = function (Ord0, pred, succ) {
      this.Ord0 = Ord0;
      this.pred = pred;
      this.succ = succ;
  };
  var BoundedEnum = function (Bounded0, Enum1, cardinality, fromEnum, toEnum) {
      this.Bounded0 = Bounded0;
      this.Enum1 = Enum1;
      this.cardinality = cardinality;
      this.fromEnum = fromEnum;
      this.toEnum = toEnum;
  };
  var toEnum = function (dict) {
      return dict.toEnum;
  };              
  var fromEnum = function (dict) {
      return dict.fromEnum;
  };
  var toEnumWithDefaults = function (dictBoundedEnum) {
      return function (low) {
          return function (high) {
              return function (x) {
                  var v = toEnum(dictBoundedEnum)(x);
                  if (v instanceof Data_Maybe.Just) {
                      return v.value0;
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      var $54 = x < fromEnum(dictBoundedEnum)(Data_Bounded.bottom(dictBoundedEnum.Bounded0()));
                      if ($54) {
                          return low;
                      };
                      return high;
                  };
                  throw new Error("Failed pattern match at Data.Enum (line 158, column 33 - line 160, column 62): " + [ v.constructor.name ]);
              };
          };
      };
  };
  var defaultSucc = function (toEnum$prime) {
      return function (fromEnum$prime) {
          return function (a) {
              return toEnum$prime(fromEnum$prime(a) + 1 | 0);
          };
      };
  };
  var defaultPred = function (toEnum$prime) {
      return function (fromEnum$prime) {
          return function (a) {
              return toEnum$prime(fromEnum$prime(a) - 1 | 0);
          };
      };
  };
  var charToEnum = function (v) {
      if (v >= Data_Bounded.bottom(Data_Bounded.boundedInt) && v <= Data_Bounded.top(Data_Bounded.boundedInt)) {
          return new Data_Maybe.Just($foreign.fromCharCode(v));
      };
      return Data_Maybe.Nothing.value;
  };
  var enumChar = new Enum(function () {
      return Data_Ord.ordChar;
  }, defaultPred(charToEnum)($foreign.toCharCode), defaultSucc(charToEnum)($foreign.toCharCode));
  var boundedEnumChar = new BoundedEnum(function () {
      return Data_Bounded.boundedChar;
  }, function () {
      return enumChar;
  }, $foreign.toCharCode(Data_Bounded.top(Data_Bounded.boundedChar)) - $foreign.toCharCode(Data_Bounded.bottom(Data_Bounded.boundedChar)) | 0, $foreign.toCharCode, charToEnum);
  exports["toEnum"] = toEnum;
  exports["fromEnum"] = fromEnum;
  exports["toEnumWithDefaults"] = toEnumWithDefaults;
  exports["boundedEnumChar"] = boundedEnumChar;
})(PS);
(function(exports) {
  "use strict";

  exports.intDegree = function (x) {
    return Math.min(Math.abs(x), 2147483647);
  };

  // See the Euclidean definition in
  // https://en.m.wikipedia.org/wiki/Modulo_operation.
  exports.intDiv = function (x) {
    return function (y) {
      if (y === 0) return 0;
      return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
    };
  };

  exports.intMod = function (x) {
    return function (y) {
      if (y === 0) return 0;
      var yy = Math.abs(y);
      return ((x % yy) + yy) % yy;
    };
  };
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});
(function(exports) {
  "use strict";

  exports.intSub = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x - y | 0;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Ring"] = $PS["Data.Ring"] || {};
  var exports = $PS["Data.Ring"];
  var $foreign = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Ring = function (Semiring0, sub) {
      this.Semiring0 = Semiring0;
      this.sub = sub;
  };
  var sub = function (dict) {
      return dict.sub;
  };                  
  var ringInt = new Ring(function () {
      return Data_Semiring.semiringInt;
  }, $foreign.intSub);
  var negate = function (dictRing) {
      return function (a) {
          return sub(dictRing)(Data_Semiring.zero(dictRing.Semiring0()))(a);
      };
  };
  exports["negate"] = negate;
  exports["ringInt"] = ringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.CommutativeRing"] = $PS["Data.CommutativeRing"] || {};
  var exports = $PS["Data.CommutativeRing"];
  var Data_Ring = $PS["Data.Ring"];
  var CommutativeRing = function (Ring0) {
      this.Ring0 = Ring0;
  }; 
  var commutativeRingInt = new CommutativeRing(function () {
      return Data_Ring.ringInt;
  });
  exports["commutativeRingInt"] = commutativeRingInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.EuclideanRing"] = $PS["Data.EuclideanRing"] || {};
  var exports = $PS["Data.EuclideanRing"];
  var $foreign = $PS["Data.EuclideanRing"];
  var Data_CommutativeRing = $PS["Data.CommutativeRing"];  
  var EuclideanRing = function (CommutativeRing0, degree, div, mod) {
      this.CommutativeRing0 = CommutativeRing0;
      this.degree = degree;
      this.div = div;
      this.mod = mod;
  };
  var mod = function (dict) {
      return dict.mod;
  }; 
  var euclideanRingInt = new EuclideanRing(function () {
      return Data_CommutativeRing.commutativeRingInt;
  }, $foreign.intDegree, $foreign.intDiv, $foreign.intMod);
  var div = function (dict) {
      return dict.div;
  };
  exports["div"] = div;
  exports["mod"] = mod;
  exports["euclideanRingInt"] = euclideanRingInt;
})(PS);
(function(exports) {
  "use strict";

  exports.fromCharArray = function (a) {
    return a.join("");
  };

  exports.toCharArray = function (s) {
    return s.split("");
  };

  exports.singleton = function (c) {
    return c;
  };

  exports._charAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (s) {
          return i >= 0 && i < s.length ? just(s.charAt(i)) : nothing;
        };
      };
    };
  };

  exports.length = function (s) {
    return s.length;
  };

  exports._indexOf = function (just) {
    return function (nothing) {
      return function (x) {
        return function (s) {
          var i = s.indexOf(x);
          return i === -1 ? nothing : just(i);
        };
      };
    };
  };

  exports.take = function (n) {
    return function (s) {
      return s.substr(0, n);
    };
  };

  exports.drop = function (n) {
    return function (s) {
      return s.substring(n);
    };
  };
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});
(function(exports) {
  "use strict";

  exports.charAt = function (i) {
    return function (s) {
      if (i >= 0 && i < s.length) return s.charAt(i);
      throw new Error("Data.String.Unsafe.charAt: Invalid index.");
    };
  };
})(PS["Data.String.Unsafe"] = PS["Data.String.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.String.Unsafe"] = $PS["Data.String.Unsafe"] || {};
  var exports = $PS["Data.String.Unsafe"];
  var $foreign = $PS["Data.String.Unsafe"];
  exports["charAt"] = $foreign.charAt;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.String.CodeUnits"] = $PS["Data.String.CodeUnits"] || {};
  var exports = $PS["Data.String.CodeUnits"];
  var $foreign = $PS["Data.String.CodeUnits"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_Unsafe = $PS["Data.String.Unsafe"];                
  var uncons = function (v) {
      if (v === "") {
          return Data_Maybe.Nothing.value;
      };
      return new Data_Maybe.Just({
          head: Data_String_Unsafe.charAt(0)(v),
          tail: $foreign.drop(1)(v)
      });
  };                                                                                          
  var indexOf = $foreign["_indexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var charAt = $foreign["_charAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["uncons"] = uncons;
  exports["indexOf"] = indexOf;
  exports["singleton"] = $foreign.singleton;
  exports["fromCharArray"] = $foreign.fromCharArray;
  exports["toCharArray"] = $foreign.toCharArray;
  exports["length"] = $foreign.length;
  exports["take"] = $foreign.take;
  exports["drop"] = $foreign.drop;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});
(function(exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Unfoldable1"] = $PS["Data.Unfoldable1"] || {};
  var exports = $PS["Data.Unfoldable1"];
  var $foreign = $PS["Data.Unfoldable1"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Unfoldable1 = function (unfoldr1) {
      this.unfoldr1 = unfoldr1;
  };
  var unfoldable1Array = new Unfoldable1($foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["Unfoldable1"] = Unfoldable1;
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Unfoldable"] = $PS["Data.Unfoldable"] || {};
  var exports = $PS["Data.Unfoldable"];
  var $foreign = $PS["Data.Unfoldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];  
  var Unfoldable = function (Unfoldable10, unfoldr) {
      this.Unfoldable10 = Unfoldable10;
      this.unfoldr = unfoldr;
  };
  var unfoldr = function (dict) {
      return dict.unfoldr;
  };
  var unfoldableArray = new Unfoldable(function () {
      return Data_Unfoldable1.unfoldable1Array;
  }, $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["Unfoldable"] = Unfoldable;
  exports["unfoldr"] = unfoldr;
  exports["unfoldableArray"] = unfoldableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.String.CodePoints"] = $PS["Data.String.CodePoints"] || {};
  var exports = $PS["Data.String.CodePoints"];
  var $foreign = $PS["Data.String.CodePoints"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Unsafe = $PS["Data.String.Unsafe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var unsurrogate = function (lead) {
      return function (trail) {
          return (((lead - 55296 | 0) * 1024 | 0) + (trail - 56320 | 0) | 0) + 65536 | 0;
      };
  }; 
  var isTrail = function (cu) {
      return 56320 <= cu && cu <= 57343;
  };
  var isLead = function (cu) {
      return 55296 <= cu && cu <= 56319;
  };
  var uncons = function (s) {
      var v = Data_String_CodeUnits.length(s);
      if (v === 0) {
          return Data_Maybe.Nothing.value;
      };
      if (v === 1) {
          return new Data_Maybe.Just({
              head: Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s)),
              tail: ""
          });
      };
      var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
      var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
      var $21 = isLead(cu0) && isTrail(cu1);
      if ($21) {
          return new Data_Maybe.Just({
              head: unsurrogate(cu0)(cu1),
              tail: Data_String_CodeUnits.drop(2)(s)
          });
      };
      return new Data_Maybe.Just({
          head: cu0,
          tail: Data_String_CodeUnits.drop(1)(s)
      });
  };
  var unconsButWithTuple = function (s) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
          return new Data_Tuple.Tuple(v.head, v.tail);
      })(uncons(s));
  };
  var toCodePointArrayFallback = function (s) {
      return Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray)(unconsButWithTuple)(s);
  };
  var unsafeCodePointAt0Fallback = function (s) {
      var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
      var $25 = isLead(cu0) && Data_String_CodeUnits.length(s) > 1;
      if ($25) {
          var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
          var $26 = isTrail(cu1);
          if ($26) {
              return unsurrogate(cu0)(cu1);
          };
          return cu0;
      };
      return cu0;
  };
  var unsafeCodePointAt0 = $foreign["_unsafeCodePointAt0"](unsafeCodePointAt0Fallback);
  var toCodePointArray = $foreign["_toCodePointArray"](toCodePointArrayFallback)(unsafeCodePointAt0);
  var length = function ($52) {
      return Data_Array.length(toCodePointArray($52));
  };
  var indexOf = function (p) {
      return function (s) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (i) {
              return length(Data_String_CodeUnits.take(i)(s));
          })(Data_String_CodeUnits.indexOf(p)(s));
      };
  };
  var fromCharCode = (function () {
      var $53 = Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar));
      return function ($54) {
          return Data_String_CodeUnits.singleton($53($54));
      };
  })();
  var singletonFallback = function (v) {
      if (v <= 65535) {
          return fromCharCode(v);
      };
      var lead = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 55296 | 0;
      var trail = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 56320 | 0;
      return fromCharCode(lead) + fromCharCode(trail);
  };                                                                          
  var singleton = $foreign["_singleton"](singletonFallback);
  var takeFallback = function (n) {
      return function (v) {
          if (n < 1) {
              return "";
          };
          var v1 = uncons(v);
          if (v1 instanceof Data_Maybe.Just) {
              return singleton(v1.value0.head) + takeFallback(n - 1 | 0)(v1.value0.tail);
          };
          return v;
      };
  };
  var take = $foreign["_take"](takeFallback);
  var drop = function (n) {
      return function (s) {
          return Data_String_CodeUnits.drop(Data_String_CodeUnits.length(take(n)(s)))(s);
      };
  };   
  var codePointAtFallback = function ($copy_n) {
      return function ($copy_s) {
          var $tco_var_n = $copy_n;
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(n, s) {
              var v = uncons(s);
              if (v instanceof Data_Maybe.Just) {
                  var $44 = n === 0;
                  if ($44) {
                      $tco_done = true;
                      return new Data_Maybe.Just(v.value0.head);
                  };
                  $tco_var_n = n - 1 | 0;
                  $copy_s = v.value0.tail;
                  return;
              };
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_n, $copy_s);
          };
          return $tco_result;
      };
  };
  var codePointAt = function (v) {
      return function (v1) {
          if (v < 0) {
              return Data_Maybe.Nothing.value;
          };
          if (v === 0 && v1 === "") {
              return Data_Maybe.Nothing.value;
          };
          if (v === 0) {
              return new Data_Maybe.Just(unsafeCodePointAt0(v1));
          };
          return $foreign["_codePointAt"](codePointAtFallback)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value)(unsafeCodePointAt0)(v)(v1);
      };
  };
  exports["singleton"] = singleton;
  exports["codePointAt"] = codePointAt;
  exports["length"] = length;
  exports["indexOf"] = indexOf;
  exports["take"] = take;
  exports["drop"] = drop;
})(PS);
(function(exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */
  /* jshint -W083, -W098, -W003 */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};

    /*

  An awkward approximation. We elide evidence we would otherwise need in PS for
  efficiency sake.

  data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)

  */  
    var PURE    = "Pure";
    var THROW   = "Throw";
    var CATCH   = "Catch";
    var SYNC    = "Sync";
    var ASYNC   = "Async";
    var BIND    = "Bind";
    var BRACKET = "Bracket";
    var FORK    = "Fork";
    var SEQ     = "Sequential";

    /*

  data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)

  */  
    var MAP   = "Map";
    var APPLY = "Apply";
    var ALT   = "Alt";

    // Various constructors used in interpretation
    var CONS      = "Cons";      // Cons-list, for stacks
    var RESUME    = "Resume";    // Continue indiscriminately
    var RELEASE   = "Release";   // Continue with bracket finalizers
    var FINALIZER = "Finalizer"; // A non-interruptible effect
    var FINALIZED = "Finalized"; // Marker for finalization
    var FORKED    = "Forked";    // Reference to a forked fiber, with resumption stack
    var FIBER     = "Fiber";     // Actual fiber reference
    var THUNK     = "Thunk";     // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1  = _1;
      this._2  = _2;
      this._3  = _3;
    }

    function AffCtr(tag) {
      var fn = function (_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };
      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit    = 1024;
      var size     = 0;
      var ix       = 0;
      var queue    = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;
        while (size !== 0) {
          size--;
          thunk     = queue[ix];
          queue[ix] = void 0;
          ix        = (ix + 1) % limit;
          thunk();
        }
        draining = false;
      }

      return {
        isDraining: function () {
          return draining;
        },
        enqueue: function (cb) {
          var i, tmp;
          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers  = {};
      var fiberId = 0;
      var count   = 0;

      return {
        register: function (fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function (result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          });
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function () {
          return count === 0;
        },
        killAll: function (killError, cb) {
          return function () {
            if (count === 0) {
              return cb();
            }

            var killCount = 0;
            var kills     = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;
                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }
                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers  = {};
            fiberId = 0;
            count   = 0;

            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    }

    // Fiber state machine
    var SUSPENDED   = 0; // Suspended, pending a join.
    var CONTINUE    = 1; // Interpret the next instruction.
    var STEP_BIND   = 2; // Apply the next bind.
    var STEP_RESULT = 3; // Handle potential failure from a result.
    var PENDING     = 4; // An async effect is running.
    var RETURN      = 5; // The current stack has returned.
    var COMPLETED   = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0;

      // The current branch of the state machine.
      var status = SUSPENDED;

      // The current point of interest for the state machine branch.
      var step      = aff;  // Successful step
      var fail      = null; // Failure step
      var interrupt = null; // Asynchronous interrupt

      // Stack of continuations for the current fiber.
      var bhead = null;
      var btail = null;

      // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.
      var attempts = null;

      // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.
      var bracketCount = 0;

      // Each join gets a new id so they can be revoked.
      var joinId  = 0;
      var joins   = null;
      var rethrow = true;

      // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.
      function run(localRunTick) {
        var tmp, result, attempt;
        while (true) {
          tmp       = null;
          result    = null;
          attempt   = null;

          switch (status) {
          case STEP_BIND:
            status = CONTINUE;
            step   = bhead(step);
            if (btail === null) {
              bhead = null;
            } else {
              bhead = btail._1;
              btail = btail._2;
            }
            break;

          case STEP_RESULT:
            if (util.isLeft(step)) {
              status = RETURN;
              fail   = step;
              step   = null;
            } else if (bhead === null) {
              status = RETURN;
            } else {
              status = STEP_BIND;
              step   = util.fromRight(step);
            }
            break;

          case CONTINUE:
            switch (step.tag) {
            case BIND:
              if (bhead) {
                btail = new Aff(CONS, bhead, btail);
              }
              bhead  = step._2;
              status = CONTINUE;
              step   = step._1;
              break;

            case PURE:
              if (bhead === null) {
                status = RETURN;
                step   = util.right(step._1);
              } else {
                status = STEP_BIND;
                step   = step._1;
              }
              break;

            case SYNC:
              status = STEP_RESULT;
              step   = runSync(util.left, util.right, step._1);
              break;

            case ASYNC:
              status = PENDING;
              step   = runAsync(util.left, step._1, function (result) {
                return function () {
                  if (runTick !== localRunTick) {
                    return;
                  }
                  runTick++;
                  Scheduler.enqueue(function () {
                    // It's possible to interrupt the fiber between enqueuing and
                    // resuming, so we need to check that the runTick is still
                    // valid.
                    if (runTick !== localRunTick + 1) {
                      return;
                    }
                    status = STEP_RESULT;
                    step   = result;
                    run(runTick);
                  });
                };
              });
              return;

            case THROW:
              status = RETURN;
              fail   = util.left(step._1);
              step   = null;
              break;

            // Enqueue the Catch so that we can call the error handler later on
            // in case of an exception.
            case CATCH:
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead    = null;
              btail    = null;
              status   = CONTINUE;
              step     = step._1;
              break;

            // Enqueue the Bracket so that we can call the appropriate handlers
            // after resource acquisition.
            case BRACKET:
              bracketCount++;
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead  = null;
              btail  = null;
              status = CONTINUE;
              step   = step._1;
              break;

            case FORK:
              status = STEP_RESULT;
              tmp    = Fiber(util, supervisor, step._2);
              if (supervisor) {
                supervisor.register(tmp);
              }
              if (step._1) {
                tmp.run();
              }
              step = util.right(tmp);
              break;

            case SEQ:
              status = CONTINUE;
              step   = sequential(util, supervisor, step._1);
              break;
            }
            break;

          case RETURN:
            bhead = null;
            btail = null;
            // If the current stack has returned, and we have no other stacks to
            // resume or finalizers to run, the fiber has halted and we can
            // invoke all join callbacks. Otherwise we need to resume.
            if (attempts === null) {
              status = COMPLETED;
              step   = interrupt || fail || step;
            } else {
              // The interrupt status for the enqueued item.
              tmp      = attempts._3;
              attempt  = attempts._1;
              attempts = attempts._2;

              switch (attempt.tag) {
              // We cannot recover from an unmasked interrupt. Otherwise we should
              // continue stepping, or run the exception handler if an exception
              // was raised.
              case CATCH:
                // We should compare the interrupt status as well because we
                // only want it to apply if there has been an interrupt since
                // enqueuing the catch.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  status = RETURN;
                } else if (fail) {
                  status = CONTINUE;
                  step   = attempt._2(util.fromLeft(fail));
                  fail   = null;
                }
                break;

              // We cannot resume from an unmasked interrupt or exception.
              case RESUME:
                // As with Catch, we only want to ignore in the case of an
                // interrupt since enqueing the item.
                if (interrupt && interrupt !== tmp && bracketCount === 0 || fail) {
                  status = RETURN;
                } else {
                  bhead  = attempt._1;
                  btail  = attempt._2;
                  status = STEP_BIND;
                  step   = util.fromRight(step);
                }
                break;

              // If we have a bracket, we should enqueue the handlers,
              // and continue with the success branch only if the fiber has
              // not been interrupted. If the bracket acquisition failed, we
              // should not run either.
              case BRACKET:
                bracketCount--;
                if (fail === null) {
                  result   = util.fromRight(step);
                  // We need to enqueue the Release with the same interrupt
                  // status as the Bracket that is initiating it.
                  attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp);
                  // We should only coninue as long as the interrupt status has not changed or
                  // we are currently within a non-interruptable finalizer.
                  if (interrupt === tmp || bracketCount > 0) {
                    status = CONTINUE;
                    step   = attempt._3(result);
                  }
                }
                break;

              // Enqueue the appropriate handler. We increase the bracket count
              // because it should not be cancelled.
              case RELEASE:
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                // It has only been killed if the interrupt status has changed
                // since we enqueued the item, and the bracket count is 0. If the
                // bracket count is non-zero then we are in a masked state so it's
                // impossible to be killed.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                } else if (fail) {
                  step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                } else {
                  step = attempt._1.completed(util.fromRight(step))(attempt._2);
                }
                fail = null;
                bracketCount++;
                break;

              case FINALIZER:
                bracketCount++;
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                step     = attempt._1;
                break;

              case FINALIZED:
                bracketCount--;
                status = RETURN;
                step   = attempt._1;
                fail   = attempt._2;
                break;
              }
            }
            break;

          case COMPLETED:
            for (var k in joins) {
              if (joins.hasOwnProperty(k)) {
                rethrow = rethrow && joins[k].rethrow;
                runEff(joins[k].handler(step));
              }
            }
            joins = null;
            // If we have an interrupt and a fail, then the thread threw while
            // running finalizers. This should always rethrow in a fresh stack.
            if (interrupt && fail) {
              setTimeout(function () {
                throw util.fromLeft(fail);
              }, 0);
            // If we have an unhandled exception, and no other fiber has joined
            // then we need to throw the exception in a fresh stack.
            } else if (util.isLeft(step) && rethrow) {
              setTimeout(function () {
                // Guard on reathrow because a completely synchronous fiber can
                // still have an observer which was added after-the-fact.
                if (rethrow) {
                  throw util.fromLeft(step);
                }
              }, 0);
            }
            return;
          case SUSPENDED:
            status = CONTINUE;
            break;
          case PENDING: return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid    = joinId++;
          joins      = joins || {};
          joins[jid] = join;

          return function() {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function (/* unused */) {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
          case SUSPENDED:
            interrupt = util.left(error);
            status    = COMPLETED;
            step      = interrupt;
            run(runTick);
            break;
          case PENDING:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              if (status === PENDING) {
                attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
              }
              status   = RETURN;
              step     = null;
              fail     = null;
              run(++runTick);
            }
            break;
          default:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              status = RETURN;
              step   = null;
              fail   = null;
            }
          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();
          if (status === SUSPENDED) {
            run(runTick);
          }
          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function () {
          return status === SUSPENDED;
        },
        run: function () {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                run(runTick);
              });
            } else {
              run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId   = 0;
      var fibers    = {};

      // Table of currently running cancelers, as a product of `Alt` behavior.
      var killId    = 0;
      var kills     = {};

      // Error used for early cancelation on Alt branches.
      var early     = new Error("[ParAff] Early exit");

      // Error used to kill the entire tree.
      var interrupt = null;

      // The root pointer of the tree.
      var root      = EMPTY;

      // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.
      function kill(error, par, cb) {
        var step  = par;
        var head  = null;
        var tail  = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
          case FORKED:
            if (step._3 === EMPTY) {
              tmp = fibers[step._1];
              kills[count++] = tmp.kill(error, function (result) {
                return function () {
                  count--;
                  if (count === 0) {
                    cb(result)();
                  }
                };
              });
            }
            // Terminal case.
            if (head === null) {
              break loop;
            }
            // Go down the right side of the tree.
            step = head._2;
            if (tail === null) {
              head = null;
            } else {
              head = tail._1;
              tail = tail._2;
            }
            break;
          case MAP:
            step = step._2;
            break;
          case APPLY:
          case ALT:
            if (head) {
              tail = new Aff(CONS, head, tail);
            }
            head = step;
            step = step._1;
            break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;
          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      }

      // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.
      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null;

          // We should never continue if the entire tree has been interrupted.
          if (interrupt !== null) {
            return;
          }

          // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.
          if (head === null) {
            cb(fail || step)();
            return;
          }

          // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?
          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
          case MAP:
            if (fail === null) {
              head._3 = util.right(head._1(util.fromRight(step)));
              step    = head._3;
            } else {
              head._3 = fail;
            }
            break;
          case APPLY:
            lhs = head._1._3;
            rhs = head._2._3;
            // If we have a failure we should kill the other side because we
            // can't possible yield a result anymore.
            if (fail) {
              head._3 = fail;
              tmp     = true;
              kid     = killId++;

              kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(fail, null, null);
                  } else {
                    join(fail, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            } else if (lhs === EMPTY || rhs === EMPTY) {
              // We can only proceed if both sides have resolved.
              return;
            } else {
              step    = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
              head._3 = step;
            }
            break;
          case ALT:
            lhs = head._1._3;
            rhs = head._2._3;
            // We can only proceed if both have resolved or we have a success
            if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
              return;
            }
            // If both sides resolve with an error, we should continue with the
            // first error
            if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
              fail    = step === lhs ? rhs : lhs;
              step    = null;
              head._3 = fail;
            } else {
              head._3 = step;
              tmp     = true;
              kid     = killId++;
              // Once a side has resolved, we need to cancel the side that is still
              // pending before we can continue.
              kills[kid] = kill(early, step === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(step, null, null);
                  } else {
                    join(step, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            }
            break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      }

      // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.
      function run() {
        var status = CONTINUE;
        var step   = par;
        var head   = null;
        var tail   = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
          case CONTINUE:
            switch (step.tag) {
            case MAP:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(MAP, step._1, EMPTY, EMPTY);
              step = step._2;
              break;
            case APPLY:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(APPLY, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(ALT, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            default:
              // When we hit a leaf value, we suspend the stack in the `FORKED`.
              // When the fiber resolves, it can bubble back up the tree.
              fid    = fiberId++;
              status = RETURN;
              tmp    = step;
              step   = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
              tmp    = Fiber(util, supervisor, tmp);
              tmp.onComplete({
                rethrow: false,
                handler: resolve(step)
              })();
              fibers[fid] = tmp;
              if (supervisor) {
                supervisor.register(tmp);
              }
            }
            break;
          case RETURN:
            // Terminal case, we are back at the root.
            if (head === null) {
              break loop;
            }
            // If we are done with the right side, we need to continue down the
            // left. Otherwise we should continue up the stack.
            if (head._1 === EMPTY) {
              head._1 = step;
              status  = CONTINUE;
              step    = head._2;
              head._2 = EMPTY;
            } else {
              head._2 = step;
              step    = head;
              if (tail === null) {
                head  = null;
              } else {
                head  = tail._1;
                tail  = tail._2;
              }
            }
          }
        }

        // Keep a reference to the tree root so it can be cancelled.
        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      }

      // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.
      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;
        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];
            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);

        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }
              return nonCanceler;
            };
          });
        };
      }

      run();

      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY       = EMPTY;
    Aff.Pure        = AffCtr(PURE);
    Aff.Throw       = AffCtr(THROW);
    Aff.Catch       = AffCtr(CATCH);
    Aff.Sync        = AffCtr(SYNC);
    Aff.Async       = AffCtr(ASYNC);
    Aff.Bind        = AffCtr(BIND);
    Aff.Bracket     = AffCtr(BRACKET);
    Aff.Fork        = AffCtr(FORK);
    Aff.Seq         = AffCtr(SEQ);
    Aff.ParMap      = AffCtr(MAP);
    Aff.ParApply    = AffCtr(APPLY);
    Aff.ParAlt      = AffCtr(ALT);
    Aff.Fiber       = Fiber;
    Aff.Supervisor  = Supervisor;
    Aff.Scheduler   = Scheduler;
    Aff.nonCanceler = nonCanceler;

    return Aff;
  }();

  exports._pure = Aff.Pure;

  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._fork = function (immediate) {
    return function (aff) {
      return Aff.Fork(immediate, aff);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports.generalBracket = function (acquire) {
    return function (options) {
      return function (k) {
        return Aff.Bracket(acquire, options, k);
      };
    };
  };

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };  

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                        
  var MonadThrow = function (Monad0, throwError) {
      this.Monad0 = Monad0;
      this.throwError = throwError;
  };
  var MonadError = function (MonadThrow0, catchError) {
      this.MonadThrow0 = MonadThrow0;
      this.catchError = catchError;
  };
  var throwError = function (dict) {
      return dict.throwError;
  };                                                      
  var catchError = function (dict) {
      return dict.catchError;
  };
  var $$try = function (dictMonadError) {
      return function (a) {
          return catchError(dictMonadError)(Data_Functor.map(((((dictMonadError.MonadThrow0()).Monad0()).Bind1()).Apply0()).Functor0())(Data_Either.Right.create)(a))((function () {
              var $21 = Control_Applicative.pure(((dictMonadError.MonadThrow0()).Monad0()).Applicative0());
              return function ($22) {
                  return $21(Data_Either.Left.create($22));
              };
          })());
      };
  };
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
  exports["MonadError"] = MonadError;
  exports["try"] = $$try;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Rec.Class"] = $PS["Control.Monad.Rec.Class"] || {};
  var exports = $PS["Control.Monad.Rec.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];                
  var Loop = (function () {
      function Loop(value0) {
          this.value0 = value0;
      };
      Loop.create = function (value0) {
          return new Loop(value0);
      };
      return Loop;
  })();
  var Done = (function () {
      function Done(value0) {
          this.value0 = value0;
      };
      Done.create = function (value0) {
          return new Done(value0);
      };
      return Done;
  })();
  var MonadRec = function (Monad0, tailRecM) {
      this.Monad0 = Monad0;
      this.tailRecM = tailRecM;
  };
  var tailRecM = function (dict) {
      return dict.tailRecM;
  }; 
  var monadRecEffect = new MonadRec(function () {
      return Effect.monadEffect;
  }, function (f) {
      return function (a) {
          var fromDone = function (v) {
              if (v instanceof Done) {
                  return v.value0;
              };
              throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 111, column 30 - line 111, column 44): " + [ v.constructor.name ]);
          };
          return function __do() {
              var v = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(f(a))();
              (function () {
                  while (!(function __do() {
                      var v1 = Effect_Ref.read(v)();
                      if (v1 instanceof Loop) {
                          var v2 = f(v1.value0)();
                          var v3 = Effect_Ref.write(v2)(v)();
                          return false;
                      };
                      if (v1 instanceof Done) {
                          return true;
                      };
                      throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 102, column 22 - line 107, column 28): " + [ v1.constructor.name ]);
                  })()) {

                  };
                  return {};
              })();
              return Data_Functor.map(Effect.functorEffect)(fromDone)(Effect_Ref.read(v))();
          };
      };
  });
  exports["Loop"] = Loop;
  exports["Done"] = Done;
  exports["MonadRec"] = MonadRec;
  exports["tailRecM"] = tailRecM;
  exports["monadRecEffect"] = monadRecEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Parallel.Class"] = $PS["Control.Parallel.Class"] || {};
  var exports = $PS["Control.Parallel.Class"];
  var Parallel = function (Applicative1, Monad0, parallel, sequential) {
      this.Applicative1 = Applicative1;
      this.Monad0 = Monad0;
      this.parallel = parallel;
      this.sequential = sequential;
  };
  var sequential = function (dict) {
      return dict.sequential;
  };
  var parallel = function (dict) {
      return dict.parallel;
  };
  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
  exports["Parallel"] = Parallel;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];         
  var MonadEffect = function (Monad0, liftEffect) {
      this.Monad0 = Monad0;
      this.liftEffect = liftEffect;
  };                                                         
  var liftEffect = function (dict) {
      return dict.liftEffect;
  };
  exports["liftEffect"] = liftEffect;
  exports["MonadEffect"] = MonadEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);
(function(exports) {
  "use strict";

  // module Partial.Unsafe

  exports.unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
  "use strict";

  // module Partial

  exports.crashWith = function () {
    return function (msg) {
      throw new Error(msg);
    };
  };
})(PS["Partial"] = PS["Partial"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Partial"] = $PS["Partial"] || {};
  var exports = $PS["Partial"];
  var $foreign = $PS["Partial"];
  exports["crashWith"] = $foreign.crashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Partial.Unsafe"] = $PS["Partial.Unsafe"] || {};
  var exports = $PS["Partial.Unsafe"];
  var $foreign = $PS["Partial.Unsafe"];
  var Partial = $PS["Partial"];
  var unsafeCrashWith = function (msg) {
      return $foreign.unsafePartial(function (dictPartial) {
          return Partial.crashWith()(msg);
      });
  };
  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Aff"] = $PS["Effect.Aff"] || {};
  var exports = $PS["Effect.Aff"];
  var $foreign = $PS["Effect.Aff"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Canceler = function (x) {
      return x;
  };
  var suspendAff = $foreign["_fork"](false);
  var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
  var functorAff = new Data_Functor.Functor($foreign["_map"]);
  var forkAff = $foreign["_fork"](true);
  var ffiUtil = (function () {
      var unsafeFromRight = function (v) {
          if (v instanceof Data_Either.Right) {
              return v.value0;
          };
          if (v instanceof Data_Either.Left) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 400, column 21 - line 402, column 54): " + [ v.constructor.name ]);
      };
      var unsafeFromLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return v.value0;
          };
          if (v instanceof Data_Either.Right) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 395, column 20 - line 397, column 54): " + [ v.constructor.name ]);
      };
      var isLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return true;
          };
          if (v instanceof Data_Either.Right) {
              return false;
          };
          throw new Error("Failed pattern match at Effect.Aff (line 390, column 12 - line 392, column 20): " + [ v.constructor.name ]);
      };
      return {
          isLeft: isLeft,
          fromLeft: unsafeFromLeft,
          fromRight: unsafeFromRight,
          left: Data_Either.Left.create,
          right: Data_Either.Right.create
      };
  })();
  var makeFiber = function (aff) {
      return $foreign["_makeFiber"](ffiUtil, aff);
  };
  var launchAff = function (aff) {
      return function __do() {
          var v = makeFiber(aff)();
          v.run();
          return v;
      };
  };
  var launchAff_ = (function () {
      var $49 = Data_Functor["void"](Effect.functorEffect);
      return function ($50) {
          return $49(launchAff($50));
      };
  })();
  var bracket = function (acquire) {
      return function (completed) {
          return $foreign.generalBracket(acquire)({
              killed: Data_Function["const"](completed),
              failed: Data_Function["const"](completed),
              completed: Data_Function["const"](completed)
          });
      };
  };
  var applyParAff = new Control_Apply.Apply(function () {
      return functorParAff;
  }, $foreign["_parAffApply"]);
  var monadAff = new Control_Monad.Monad(function () {
      return applicativeAff;
  }, function () {
      return bindAff;
  });
  var bindAff = new Control_Bind.Bind(function () {
      return applyAff;
  }, $foreign["_bind"]);
  var applyAff = new Control_Apply.Apply(function () {
      return functorAff;
  }, Control_Monad.ap(monadAff));
  var applicativeAff = new Control_Applicative.Applicative(function () {
      return applyAff;
  }, $foreign["_pure"]);
  var $$finally = function (fin) {
      return function (a) {
          return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
      };
  };
  var monadEffectAff = new Effect_Class.MonadEffect(function () {
      return monadAff;
  }, $foreign["_liftEffect"]);
  var effectCanceler = (function () {
      var $51 = Effect_Class.liftEffect(monadEffectAff);
      return function ($52) {
          return Canceler(Data_Function["const"]($51($52)));
      };
  })();
  var joinFiber = function (v) {
      return $foreign.makeAff(function (k) {
          return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
      });
  };
  var functorFiber = new Data_Functor.Functor(function (f) {
      return function (t) {
          return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
      };
  });
  var killFiber = function (e) {
      return function (v) {
          return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
              if (v1) {
                  return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
              };
              return $foreign.makeAff(function (k) {
                  return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
              });
          });
      };
  };   
  var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
      return monadAff;
  }, $foreign["_throwError"]);
  var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
      return monadThrowAff;
  }, $foreign["_catchError"]);
  var attempt = Control_Monad_Error_Class["try"](monadErrorAff);
  var runAff = function (k) {
      return function (aff) {
          return launchAff(Control_Bind.bindFlipped(bindAff)((function () {
              var $55 = Effect_Class.liftEffect(monadEffectAff);
              return function ($56) {
                  return $55(k($56));
              };
          })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
      };
  };
  var runAff_ = function (k) {
      return function (aff) {
          return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
      };
  };
  var parallelAff = new Control_Parallel_Class.Parallel(function () {
      return applicativeParAff;
  }, function () {
      return monadAff;
  }, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
  var applicativeParAff = new Control_Applicative.Applicative(function () {
      return applyParAff;
  }, (function () {
      var $59 = Control_Parallel_Class.parallel(parallelAff);
      var $60 = Control_Applicative.pure(applicativeAff);
      return function ($61) {
          return $59($60($61));
      };
  })());
  var monadRecAff = new Control_Monad_Rec_Class.MonadRec(function () {
      return monadAff;
  }, function (k) {
      var go = function (a) {
          return Control_Bind.bind(bindAff)(k(a))(function (v) {
              if (v instanceof Control_Monad_Rec_Class.Done) {
                  return Control_Applicative.pure(applicativeAff)(v.value0);
              };
              if (v instanceof Control_Monad_Rec_Class.Loop) {
                  return go(v.value0);
              };
              throw new Error("Failed pattern match at Effect.Aff (line 100, column 7 - line 102, column 22): " + [ v.constructor.name ]);
          });
      };
      return go;
  });
  exports["launchAff_"] = launchAff_;
  exports["runAff_"] = runAff_;
  exports["forkAff"] = forkAff;
  exports["suspendAff"] = suspendAff;
  exports["attempt"] = attempt;
  exports["finally"] = $$finally;
  exports["killFiber"] = killFiber;
  exports["joinFiber"] = joinFiber;
  exports["effectCanceler"] = effectCanceler;
  exports["functorAff"] = functorAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["monadRecAff"] = monadRecAff;
  exports["monadErrorAff"] = monadErrorAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["functorFiber"] = functorFiber;
  exports["makeAff"] = $foreign.makeAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Aff.Class"] = $PS["Effect.Aff.Class"] || {};
  var exports = $PS["Effect.Aff.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var MonadAff = function (MonadEffect0, liftAff) {
      this.MonadEffect0 = MonadEffect0;
      this.liftAff = liftAff;
  };
  var monadAffAff = new MonadAff(function () {
      return Effect_Aff.monadEffectAff;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftAff = function (dict) {
      return dict.liftAff;
  };
  exports["liftAff"] = liftAff;
  exports["monadAffAff"] = monadAffAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Exists"] = $PS["Data.Exists"] || {};
  var exports = $PS["Data.Exists"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var runExists = Unsafe_Coerce.unsafeCoerce;
  var mkExists = Unsafe_Coerce.unsafeCoerce;
  exports["mkExists"] = mkExists;
  exports["runExists"] = runExists;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Coyoneda"] = $PS["Data.Coyoneda"] || {};
  var exports = $PS["Data.Coyoneda"];
  var Control_Category = $PS["Control.Category"];
  var Data_Exists = $PS["Data.Exists"];
  var Data_Functor = $PS["Data.Functor"];                        
  var CoyonedaF = (function () {
      function CoyonedaF(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CoyonedaF.create = function (value0) {
          return function (value1) {
              return new CoyonedaF(value0, value1);
          };
      };
      return CoyonedaF;
  })();
  var Coyoneda = function (x) {
      return x;
  };
  var unCoyoneda = function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return f(v1.value0)(v1.value1);
          })(v);
      };
  };
  var coyoneda = function (k) {
      return function (fi) {
          return Coyoneda(Data_Exists.mkExists(new CoyonedaF(k, fi)));
      };
  };
  var functorCoyoneda = new Data_Functor.Functor(function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return coyoneda(function ($85) {
                  return f(v1.value0($85));
              })(v1.value1);
          })(v);
      };
  });
  var liftCoyoneda = coyoneda(Control_Category.identity(Control_Category.categoryFn));
  exports["unCoyoneda"] = unCoyoneda;
  exports["liftCoyoneda"] = liftCoyoneda;
  exports["functorCoyoneda"] = functorCoyoneda;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.FoldableWithIndex"] = $PS["Data.FoldableWithIndex"] || {};
  var exports = $PS["Data.FoldableWithIndex"];
  var FoldableWithIndex = function (Foldable0, foldMapWithIndex, foldlWithIndex, foldrWithIndex) {
      this.Foldable0 = Foldable0;
      this.foldMapWithIndex = foldMapWithIndex;
      this.foldlWithIndex = foldlWithIndex;
      this.foldrWithIndex = foldrWithIndex;
  };
  var foldrWithIndex = function (dict) {
      return dict.foldrWithIndex;
  };
  exports["FoldableWithIndex"] = FoldableWithIndex;
  exports["foldrWithIndex"] = foldrWithIndex;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];                   
  var Plus = function (Alt0, empty) {
      this.Alt0 = Alt0;
      this.empty = empty;
  };       
  var empty = function (dict) {
      return dict.empty;
  };
  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];                
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var listMap = function (f) {
      var chunkedRevMap = function ($copy_chunksAcc) {
          return function ($copy_v) {
              var $tco_var_chunksAcc = $copy_chunksAcc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(chunksAcc, v) {
                  if (v instanceof Cons && (v.value1 instanceof Cons && v.value1.value1 instanceof Cons)) {
                      $tco_var_chunksAcc = new Cons(v, chunksAcc);
                      $copy_v = v.value1.value1.value1;
                      return;
                  };
                  var unrolledMap = function (v1) {
                      if (v1 instanceof Cons && (v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil)) {
                          return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
                      };
                      if (v1 instanceof Cons && v1.value1 instanceof Nil) {
                          return new Cons(f(v1.value0), Nil.value);
                      };
                      return Nil.value;
                  };
                  var reverseUnrolledMap = function ($copy_v1) {
                      return function ($copy_acc) {
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v1, acc) {
                              if (v1 instanceof Cons && (v1.value0 instanceof Cons && (v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons))) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                                  return;
                              };
                              $tco_done = true;
                              return acc;
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v1, $copy_acc);
                          };
                          return $tco_result;
                      };
                  };
                  $tco_done = true;
                  return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
              };
              return $tco_result;
          };
      };
      return chunkedRevMap(Nil.value);
  };
  var functorList = new Data_Functor.Functor(listMap);                 
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return Data_Foldable.foldl(foldableList)(function (acc) {
              var $202 = Data_Semigroup.append(dictMonoid.Semigroup0())(acc);
              return function ($203) {
                  return $202(f($203));
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  }, function (f) {
      var go = function ($copy_b) {
          return function ($copy_v) {
              var $tco_var_b = $copy_b;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(b, v) {
                  if (v instanceof Nil) {
                      $tco_done = true;
                      return b;
                  };
                  if (v instanceof Cons) {
                      $tco_var_b = f(b)(v.value0);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List.Types (line 109, column 12 - line 111, column 30): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_b, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  }, function (f) {
      return function (b) {
          var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value);
          var $204 = Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b);
          return function ($205) {
              return $204(rev($205));
          };
      };
  });
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
      return function (ys) {
          return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
      };
  });
  var unfoldable1List = new Data_Unfoldable1.Unfoldable1(function (f) {
      return function (b) {
          var go = function ($copy_source) {
              return function ($copy_memo) {
                  var $tco_var_source = $copy_source;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(source, memo) {
                      var v = f(source);
                      if (v.value1 instanceof Data_Maybe.Just) {
                          $tco_var_source = v.value1.value0;
                          $copy_memo = new Cons(v.value0, memo);
                          return;
                      };
                      if (v.value1 instanceof Data_Maybe.Nothing) {
                          $tco_done = true;
                          return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(new Cons(v.value0, memo));
                      };
                      throw new Error("Failed pattern match at Data.List.Types (line 133, column 22 - line 135, column 61): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_source, $copy_memo);
                  };
                  return $tco_result;
              };
          };
          return go(b)(Nil.value);
      };
  });
  var unfoldableList = new Data_Unfoldable.Unfoldable(function () {
      return unfoldable1List;
  }, function (f) {
      return function (b) {
          var go = function ($copy_source) {
              return function ($copy_memo) {
                  var $tco_var_source = $copy_source;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(source, memo) {
                      var v = f(source);
                      if (v instanceof Data_Maybe.Nothing) {
                          $tco_done = true;
                          return Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value)(memo);
                      };
                      if (v instanceof Data_Maybe.Just) {
                          $tco_var_source = v.value0.value1;
                          $copy_memo = new Cons(v.value0.value0, memo);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.List.Types (line 140, column 22 - line 142, column 52): " + [ v.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_source, $copy_memo);
                  };
                  return $tco_result;
              };
          };
          return go(b)(Nil.value);
      };
  });
  var applyList = new Control_Apply.Apply(function () {
      return functorList;
  }, function (v) {
      return function (v1) {
          if (v instanceof Nil) {
              return Nil.value;
          };
          if (v instanceof Cons) {
              return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.List.Types (line 155, column 1 - line 157, column 48): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeList = new Control_Applicative.Applicative(function () {
      return applyList;
  }, function (a) {
      return new Cons(a, Nil.value);
  });                                              
  var altList = new Control_Alt.Alt(function () {
      return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
      return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["semigroupList"] = semigroupList;
  exports["foldableList"] = foldableList;
  exports["unfoldableList"] = unfoldableList;
  exports["applicativeList"] = applicativeList;
  exports["plusList"] = plusList;
})(PS);
(function(exports) {
  "use strict";

  // jshint maxparams: 3

  exports.traverseArrayImpl = function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                case 0: return pure([]);
                case 1: return map(array1)(f(array[bot]));
                case 2: return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));
                case 3: return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));
                default:
                  // This slightly tricky pivot selection aims to produce two
                  // even-length partitions where possible.
                  var pivot = bot + Math.floor((top - bot) / 4) * 2;
                  return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }
              return go(0, array.length);
            };
          };
        };
      };
    };
  }();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Traversable"] = $PS["Data.Traversable"] || {};
  var exports = $PS["Data.Traversable"];
  var $foreign = $PS["Data.Traversable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];                                                      
  var Traversable = function (Foldable1, Functor0, sequence, traverse) {
      this.Foldable1 = Foldable1;
      this.Functor0 = Functor0;
      this.sequence = sequence;
      this.traverse = traverse;
  };
  var traverse = function (dict) {
      return dict.traverse;
  }; 
  var sequenceDefault = function (dictTraversable) {
      return function (dictApplicative) {
          return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var traversableArray = new Traversable(function () {
      return Data_Foldable.foldableArray;
  }, function () {
      return Data_Functor.functorArray;
  }, function (dictApplicative) {
      return sequenceDefault(traversableArray)(dictApplicative);
  }, function (dictApplicative) {
      return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
  });
  exports["Traversable"] = Traversable;
  exports["traverse"] = traverse;
  exports["traversableArray"] = traversableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Map.Internal"] = $PS["Data.Map.Internal"] || {};
  var exports = $PS["Data.Map.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_FoldableWithIndex = $PS["Data.FoldableWithIndex"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                
  var Leaf = (function () {
      function Leaf() {

      };
      Leaf.value = new Leaf();
      return Leaf;
  })();
  var Two = (function () {
      function Two(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Two.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Two(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Two;
  })();
  var Three = (function () {
      function Three(value0, value1, value2, value3, value4, value5, value6) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
          this.value6 = value6;
      };
      Three.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return function (value6) {
                                  return new Three(value0, value1, value2, value3, value4, value5, value6);
                              };
                          };
                      };
                  };
              };
          };
      };
      return Three;
  })();
  var TwoLeft = (function () {
      function TwoLeft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoLeft(value0, value1, value2);
              };
          };
      };
      return TwoLeft;
  })();
  var TwoRight = (function () {
      function TwoRight(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoRight(value0, value1, value2);
              };
          };
      };
      return TwoRight;
  })();
  var ThreeLeft = (function () {
      function ThreeLeft(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeLeft(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeLeft;
  })();
  var ThreeMiddle = (function () {
      function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeMiddle.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeMiddle;
  })();
  var ThreeRight = (function () {
      function ThreeRight(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeRight(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeRight;
  })();
  var KickUp = (function () {
      function KickUp(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      KickUp.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new KickUp(value0, value1, value2, value3);
                  };
              };
          };
      };
      return KickUp;
  })();
  var values = function (v) {
      if (v instanceof Leaf) {
          return Data_List_Types.Nil.value;
      };
      if (v instanceof Two) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(values(v.value3)));
      };
      if (v instanceof Three) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value5))(values(v.value6)))));
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 612, column 1 - line 612, column 40): " + [ v.constructor.name ]);
  };
  var singleton = function (k) {
      return function (v) {
          return new Two(Leaf.value, k, v, Leaf.value);
      };
  };
  var toUnfoldable = function (dictUnfoldable) {
      return function (m) {
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof Leaf) {
                          $copy_v = v.value1;
                          return;
                      };
                      if (v.value0 instanceof Two && (v.value0.value0 instanceof Leaf && v.value0.value3 instanceof Leaf)) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), v.value1));
                      };
                      if (v.value0 instanceof Two && v.value0.value0 instanceof Leaf) {
                          $tco_done = true;
                          return Data_Maybe.Just.create(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v.value0.value1, v.value0.value2), new Data_List_Types.Cons(v.value0.value3, v.value1)));
                      };
                      if (v.value0 instanceof Two) {
                          $copy_v = new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(singleton(v.value0.value1)(v.value0.value2), new Data_List_Types.Cons(v.value0.value3, v.value1)));
                          return;
                      };
                      if (v.value0 instanceof Three) {
                          $copy_v = new Data_List_Types.Cons(v.value0.value0, new Data_List_Types.Cons(singleton(v.value0.value1)(v.value0.value2), new Data_List_Types.Cons(v.value0.value3, new Data_List_Types.Cons(singleton(v.value0.value4)(v.value0.value5), new Data_List_Types.Cons(v.value0.value6, v.value1)))));
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 577, column 18 - line 586, column 71): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 576, column 3 - line 576, column 19): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return Data_Unfoldable.unfoldr(dictUnfoldable)(go)(new Data_List_Types.Cons(m, Data_List_Types.Nil.value));
      };
  };
  var lookup = function (dictOrd) {
      return function (k) {
          var comp = Data_Ord.compare(dictOrd);
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Leaf) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Two) {
                      var v2 = comp(k)(v.value1);
                      if (v2 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      if (v2 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  if (v instanceof Three) {
                      var v3 = comp(k)(v.value1);
                      if (v3 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      var v4 = comp(k)(v.value4);
                      if (v4 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value5);
                      };
                      if (v3 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      if (v4 instanceof Data_Ordering.GT) {
                          $copy_v = v.value6;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 200, column 5 - line 200, column 22): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return go;
      };
  };
  var member = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.isJust(lookup(dictOrd)(k)(m));
          };
      };
  };
  var functorMap = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Leaf) {
              return Leaf.value;
          };
          if (v1 instanceof Two) {
              return new Two(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3));
          };
          if (v1 instanceof Three) {
              return new Three(Data_Functor.map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), Data_Functor.map(functorMap)(v)(v1.value3), v1.value4, v(v1.value5), Data_Functor.map(functorMap)(v)(v1.value6));
          };
          throw new Error("Failed pattern match at Data.Map.Internal (line 96, column 1 - line 99, column 110): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var fromZipper = function ($copy_dictOrd) {
      return function ($copy_v) {
          return function ($copy_tree) {
              var $tco_var_dictOrd = $copy_dictOrd;
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(dictOrd, v, tree) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return tree;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof TwoLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
                          return;
                      };
                      if (v.value0 instanceof TwoRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
                          return;
                      };
                      if (v.value0 instanceof ThreeLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeMiddle) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 418, column 3 - line 423, column 88): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 415, column 1 - line 415, column 80): " + [ v.constructor.name, tree.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
              };
              return $tco_result;
          };
      };
  };
  var insert = function (dictOrd) {
      return function (k) {
          return function (v) {
              var up = function ($copy_v1) {
                  return function ($copy_v2) {
                      var $tco_var_v1 = $copy_v1;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(v1, v2) {
                          if (v1 instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
                          };
                          if (v1 instanceof Data_List_Types.Cons) {
                              if (v1.value0 instanceof TwoLeft) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v2.value0, v2.value1, v2.value2, v2.value3, v1.value0.value0, v1.value0.value1, v1.value0.value2));
                              };
                              if (v1.value0 instanceof TwoRight) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0, v2.value1, v2.value2, v2.value3));
                              };
                              if (v1.value0 instanceof ThreeLeft) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v2.value0, v2.value1, v2.value2, v2.value3), v1.value0.value0, v1.value0.value1, new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeMiddle) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0), v2.value1, v2.value2, new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeRight) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3), v1.value0.value4, v1.value0.value5, new Two(v2.value0, v2.value1, v2.value2, v2.value3));
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.Map.Internal (line 454, column 5 - line 459, column 108): " + [ v1.value0.constructor.name, v2.constructor.name ]);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 451, column 3 - line 451, column 56): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                      };
                      return $tco_result;
                  };
              };
              var comp = Data_Ord.compare(dictOrd);
              var down = function ($copy_ctx) {
                  return function ($copy_v1) {
                      var $tco_var_ctx = $copy_ctx;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(ctx, v1) {
                          if (v1 instanceof Leaf) {
                              $tco_done = true;
                              return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
                          };
                          if (v1 instanceof Two) {
                              var v2 = comp(k)(v1.value1);
                              if (v2 instanceof Data_Ordering.EQ) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                              };
                              if (v2 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                              $copy_v1 = v1.value3;
                              return;
                          };
                          if (v1 instanceof Three) {
                              var v3 = comp(k)(v1.value1);
                              if (v3 instanceof Data_Ordering.EQ) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                              };
                              var v4 = comp(k)(v1.value4);
                              if (v4 instanceof Data_Ordering.EQ) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                              };
                              if (v3 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              if (v3 instanceof Data_Ordering.GT && v4 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value3;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                              $copy_v1 = v1.value6;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 434, column 3 - line 434, column 55): " + [ ctx.constructor.name, v1.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
                      };
                      return $tco_result;
                  };
              };
              return down(Data_List_Types.Nil.value);
          };
      };
  };
  var pop = function (dictOrd) {
      return function (k) {
          var up = function ($copy_ctxs) {
              return function ($copy_tree) {
                  var $tco_var_ctxs = $copy_ctxs;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctxs, tree) {
                      if (ctxs instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return tree;
                      };
                      if (ctxs instanceof Data_List_Types.Cons) {
                          if (ctxs.value0 instanceof TwoLeft && (ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoRight && (ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && (ctxs.value0.value2 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeRight && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 511, column 9 - line 528, column 136): " + [ ctxs.value0.constructor.name, tree.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 508, column 5 - line 528, column 136): " + [ ctxs.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
                  };
                  return $tco_result;
              };
          };
          var removeMaxNode = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Two && (m.value0 instanceof Leaf && m.value3 instanceof Leaf)) {
                          $tco_done = true;
                          return up(ctx)(Leaf.value);
                      };
                      if (m instanceof Two) {
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three && (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf))) {
                          $tco_done = true;
                          return up(new Data_List_Types.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
                      };
                      if (m instanceof Three) {
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 540, column 5 - line 544, column 107): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          var maxNode = function ($copy_m) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(m) {
                  if (m instanceof Two && m.value3 instanceof Leaf) {
                      $tco_done = true;
                      return {
                          key: m.value1,
                          value: m.value2
                      };
                  };
                  if (m instanceof Two) {
                      $copy_m = m.value3;
                      return;
                  };
                  if (m instanceof Three && m.value6 instanceof Leaf) {
                      $tco_done = true;
                      return {
                          key: m.value4,
                          value: m.value5
                      };
                  };
                  if (m instanceof Three) {
                      $copy_m = m.value6;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 531, column 33 - line 535, column 45): " + [ m.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_m);
              };
              return $tco_result;
          };
          var comp = Data_Ord.compare(dictOrd);
          var down = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Leaf) {
                          $tco_done = true;
                          return Data_Maybe.Nothing.value;
                      };
                      if (m instanceof Two) {
                          var v = comp(k)(m.value1);
                          if (m.value3 instanceof Leaf && v instanceof Data_Ordering.EQ) {
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three) {
                          var leaves = (function () {
                              if (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf)) {
                                  return true;
                              };
                              return false;
                          })();
                          var v = comp(k)(m.value4);
                          var v3 = comp(k)(m.value1);
                          if (leaves && v3 instanceof Data_Ordering.EQ) {
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
                          };
                          if (leaves && v instanceof Data_Ordering.EQ) {
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
                          };
                          if (v3 instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value3);
                              $tco_done = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
                          };
                          if (v3 instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          if (v3 instanceof Data_Ordering.GT && v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value3;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 481, column 34 - line 504, column 80): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          return down(Data_List_Types.Nil.value);
      };
  };
  var foldableMap = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return function (m) {
              return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  });
  var traversableMap = new Data_Traversable.Traversable(function () {
      return foldableMap;
  }, function () {
      return functorMap;
  }, function (dictApplicative) {
      return Data_Traversable.traverse(traversableMap)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  }, function (dictApplicative) {
      return function (f) {
          return function (v) {
              if (v instanceof Leaf) {
                  return Control_Applicative.pure(dictApplicative)(Leaf.value);
              };
              if (v instanceof Two) {
                  return Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(Two.create)(Data_Traversable.traverse(traversableMap)(dictApplicative)(f)(v.value0)))(Control_Applicative.pure(dictApplicative)(v.value1)))(f(v.value2)))(Data_Traversable.traverse(traversableMap)(dictApplicative)(f)(v.value3));
              };
              if (v instanceof Three) {
                  return Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(Three.create)(Data_Traversable.traverse(traversableMap)(dictApplicative)(f)(v.value0)))(Control_Applicative.pure(dictApplicative)(v.value1)))(f(v.value2)))(Data_Traversable.traverse(traversableMap)(dictApplicative)(f)(v.value3)))(Control_Applicative.pure(dictApplicative)(v.value4)))(f(v.value5)))(Data_Traversable.traverse(traversableMap)(dictApplicative)(f)(v.value6));
              };
              throw new Error("Failed pattern match at Data.Map.Internal (line 119, column 1 - line 134, column 31): " + [ f.constructor.name, v.constructor.name ]);
          };
      };
  });
  var foldSubmap = function (dictOrd) {
      return function (dictMonoid) {
          return function (kmin) {
              return function (kmax) {
                  return function (f) {
                      var tooSmall = (function () {
                          if (kmin instanceof Data_Maybe.Just) {
                              return function (k) {
                                  return Data_Ord.lessThan(dictOrd)(k)(kmin.value0);
                              };
                          };
                          if (kmin instanceof Data_Maybe.Nothing) {
                              return Data_Function["const"](false);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 319, column 7 - line 323, column 22): " + [ kmin.constructor.name ]);
                      })();
                      var tooLarge = (function () {
                          if (kmax instanceof Data_Maybe.Just) {
                              return function (k) {
                                  return Data_Ord.greaterThan(dictOrd)(k)(kmax.value0);
                              };
                          };
                          if (kmax instanceof Data_Maybe.Nothing) {
                              return Data_Function["const"](false);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 326, column 7 - line 330, column 22): " + [ kmax.constructor.name ]);
                      })();
                      var inBounds = (function () {
                          if (kmin instanceof Data_Maybe.Just && kmax instanceof Data_Maybe.Just) {
                              return function (k) {
                                  return Data_Ord.lessThanOrEq(dictOrd)(kmin.value0)(k) && Data_Ord.lessThanOrEq(dictOrd)(k)(kmax.value0);
                              };
                          };
                          if (kmin instanceof Data_Maybe.Just && kmax instanceof Data_Maybe.Nothing) {
                              return function (k) {
                                  return Data_Ord.lessThanOrEq(dictOrd)(kmin.value0)(k);
                              };
                          };
                          if (kmin instanceof Data_Maybe.Nothing && kmax instanceof Data_Maybe.Just) {
                              return function (k) {
                                  return Data_Ord.lessThanOrEq(dictOrd)(k)(kmax.value0);
                              };
                          };
                          if (kmin instanceof Data_Maybe.Nothing && kmax instanceof Data_Maybe.Nothing) {
                              return Data_Function["const"](true);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 333, column 7 - line 341, column 21): " + [ kmin.constructor.name, kmax.constructor.name ]);
                      })();
                      var go = function (v) {
                          if (v instanceof Leaf) {
                              return Data_Monoid.mempty(dictMonoid);
                          };
                          if (v instanceof Two) {
                              return Data_Semigroup.append(dictMonoid.Semigroup0())((function () {
                                  var $633 = tooSmall(v.value1);
                                  if ($633) {
                                      return Data_Monoid.mempty(dictMonoid);
                                  };
                                  return go(v.value0);
                              })())(Data_Semigroup.append(dictMonoid.Semigroup0())((function () {
                                  var $634 = inBounds(v.value1);
                                  if ($634) {
                                      return f(v.value1)(v.value2);
                                  };
                                  return Data_Monoid.mempty(dictMonoid);
                              })())((function () {
                                  var $635 = tooLarge(v.value1);
                                  if ($635) {
                                      return Data_Monoid.mempty(dictMonoid);
                                  };
                                  return go(v.value3);
                              })()));
                          };
                          if (v instanceof Three) {
                              return Data_Semigroup.append(dictMonoid.Semigroup0())((function () {
                                  var $640 = tooSmall(v.value1);
                                  if ($640) {
                                      return Data_Monoid.mempty(dictMonoid);
                                  };
                                  return go(v.value0);
                              })())(Data_Semigroup.append(dictMonoid.Semigroup0())((function () {
                                  var $641 = inBounds(v.value1);
                                  if ($641) {
                                      return f(v.value1)(v.value2);
                                  };
                                  return Data_Monoid.mempty(dictMonoid);
                              })())(Data_Semigroup.append(dictMonoid.Semigroup0())((function () {
                                  var $642 = tooSmall(v.value4) || tooLarge(v.value1);
                                  if ($642) {
                                      return Data_Monoid.mempty(dictMonoid);
                                  };
                                  return go(v.value3);
                              })())(Data_Semigroup.append(dictMonoid.Semigroup0())((function () {
                                  var $643 = inBounds(v.value4);
                                  if ($643) {
                                      return f(v.value4)(v.value5);
                                  };
                                  return Data_Monoid.mempty(dictMonoid);
                              })())((function () {
                                  var $644 = tooLarge(v.value4);
                                  if ($644) {
                                      return Data_Monoid.mempty(dictMonoid);
                                  };
                                  return go(v.value6);
                              })()))));
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 359, column 10 - line 371, column 54): " + [ v.constructor.name ]);
                      };
                      return go;
                  };
              };
          };
      };
  };
  var empty = Leaf.value;
  var $$delete = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
          };
      };
  };
  var asList = Control_Category.identity(Control_Category.categoryFn);
  var foldableWithIndexMap = new Data_FoldableWithIndex.FoldableWithIndex(function () {
      return foldableMap;
  }, function (dictMonoid) {
      return function (f) {
          return function (m) {
              return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(Data_Tuple.uncurry(f))(asList(toUnfoldable(Data_List_Types.unfoldableList)(m)));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldl(Data_List_Types.foldableList)((function () {
                  var $763 = Data_Function.flip(f);
                  return function ($764) {
                      return Data_Tuple.uncurry($763($764));
                  };
              })())(z)(asList(toUnfoldable(Data_List_Types.unfoldableList)(m)));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldr(Data_List_Types.foldableList)(Data_Tuple.uncurry(f))(z)(asList(toUnfoldable(Data_List_Types.unfoldableList)(m)));
          };
      };
  });
  var alter = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (m) {
                  var v = f(lookup(dictOrd)(k)(m));
                  if (v instanceof Data_Maybe.Nothing) {
                      return $$delete(dictOrd)(k)(m);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return insert(dictOrd)(k)(v.value0)(m);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 549, column 15 - line 551, column 25): " + [ v.constructor.name ]);
              };
          };
      };
  };
  var unionWith = function (dictOrd) {
      return function (f) {
          return function (m1) {
              return function (m2) {
                  var go = function (m) {
                      return function (v) {
                          return alter(dictOrd)((function () {
                              var $769 = Data_Maybe.maybe(v.value1)(f(v.value1));
                              return function ($770) {
                                  return Data_Maybe.Just.create($769($770));
                              };
                          })())(v.value0)(m);
                      };
                  };
                  return Data_Foldable.foldl(Data_List_Types.foldableList)(go)(m2)(toUnfoldable(Data_List_Types.unfoldableList)(m1));
              };
          };
      };
  };
  var union = function (dictOrd) {
      return unionWith(dictOrd)(Data_Function["const"]);
  };
  var semigroupMap = function (dictOrd) {
      return new Data_Semigroup.Semigroup(union(dictOrd));
  };
  var monoidMap = function (dictOrd) {
      return new Data_Monoid.Monoid(function () {
          return semigroupMap(dictOrd);
      }, empty);
  };
  exports["empty"] = empty;
  exports["singleton"] = singleton;
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["foldSubmap"] = foldSubmap;
  exports["delete"] = $$delete;
  exports["pop"] = pop;
  exports["member"] = member;
  exports["alter"] = alter;
  exports["monoidMap"] = monoidMap;
  exports["functorMap"] = functorMap;
  exports["foldableMap"] = foldableMap;
  exports["foldableWithIndexMap"] = foldableWithIndexMap;
  exports["traversableMap"] = traversableMap;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];      
  var SProxy = (function () {
      function SProxy() {

      };
      SProxy.value = new SProxy();
      return SProxy;
  })();
  var IsSymbol = function (reflectSymbol) {
      this.reflectSymbol = reflectSymbol;
  };
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Data.OrdBox"] = $PS["Halogen.Data.OrdBox"] || {};
  var exports = $PS["Halogen.Data.OrdBox"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ord = $PS["Data.Ord"];                
  var OrdBox = (function () {
      function OrdBox(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      OrdBox.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new OrdBox(value0, value1, value2);
              };
          };
      };
      return OrdBox;
  })();
  var unOrdBox = function (v) {
      return v.value2;
  };
  var mkOrdBox = function (dictOrd) {
      return OrdBox.create(Data_Eq.eq(dictOrd.Eq0()))(Data_Ord.compare(dictOrd));
  };
  var eqOrdBox = new Data_Eq.Eq(function (v) {
      return function (v1) {
          return v.value0(v.value2)(v1.value2);
      };
  });
  var ordOrdBox = new Data_Ord.Ord(function () {
      return eqOrdBox;
  }, function (v) {
      return function (v1) {
          return v.value1(v.value2)(v1.value2);
      };
  });
  exports["mkOrdBox"] = mkOrdBox;
  exports["unOrdBox"] = unOrdBox;
  exports["ordOrdBox"] = ordOrdBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Data.Slot"] = $PS["Halogen.Data.Slot"] || {};
  var exports = $PS["Halogen.Data.Slot"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_Data_OrdBox = $PS["Halogen.Data.OrdBox"];
  var slots = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (v) {
                      var key = Data_Symbol.reflectSymbol(dictIsSymbol)(sym);
                      var go = function (v1) {
                          return function (val) {
                              if (key === v1.value0) {
                                  return Data_Map_Internal.singleton(Halogen_Data_OrdBox.unOrdBox(v1.value1))(val);
                              };
                              if (Data_Boolean.otherwise) {
                                  return Data_Monoid.mempty(Data_Map_Internal.monoidMap(dictOrd));
                              };
                              throw new Error("Failed pattern match at Halogen.Data.Slot (line 101, column 3 - line 103, column 25): " + [ v1.constructor.name, val.constructor.name ]);
                          };
                      };
                      return Data_Map_Internal.foldSubmap(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(Data_Map_Internal.monoidMap(dictOrd))(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value)(go)(v);
                  };
              };
          };
      };
  };
  var pop = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.pop(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var lookup = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (val) {
                          return function (v) {
                              return Data_Map_Internal.insert(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(val)(v);
                          };
                      };
                  };
              };
          };
      };
  };
  var foreachSlot = function (dictApplicative) {
      return function (v) {
          return function (k) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Map_Internal.foldableMap)(function ($37) {
                  return k($37);
              })(v);
          };
      };
  };
  var empty = Data_Map_Internal.empty;
  exports["empty"] = empty;
  exports["lookup"] = lookup;
  exports["insert"] = insert;
  exports["pop"] = pop;
  exports["slots"] = slots;
  exports["foreachSlot"] = foreachSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.List"] = $PS["Data.List"] || {};
  var exports = $PS["Data.List"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                                  
  var uncons = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just({
              head: v.value0,
              tail: v.value1
          });
      };
      throw new Error("Failed pattern match at Data.List (line 259, column 1 - line 259, column 66): " + [ v.constructor.name ]);
  };
  var toUnfoldable = function (dictUnfoldable) {
      return Data_Unfoldable.unfoldr(dictUnfoldable)(function (xs) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (rec) {
              return new Data_Tuple.Tuple(rec.head, rec.tail);
          })(uncons(xs));
      });
  };
  var tail = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just(v.value1);
      };
      throw new Error("Failed pattern match at Data.List (line 245, column 1 - line 245, column 43): " + [ v.constructor.name ]);
  };
  var reverse = (function () {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return acc;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List (line 368, column 3 - line 368, column 19): " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  })();
  var $$null = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };
  var some = function (dictAlternative) {
      return function (dictLazy) {
          return function (v) {
              return Control_Apply.apply((dictAlternative.Applicative0()).Apply0())(Data_Functor.map(((dictAlternative.Plus1()).Alt0()).Functor0())(Data_List_Types.Cons.create)(v))(Control_Lazy.defer(dictLazy)(function (v1) {
                  return many(dictAlternative)(dictLazy)(v);
              }));
          };
      };
  };
  var many = function (dictAlternative) {
      return function (dictLazy) {
          return function (v) {
              return Control_Alt.alt((dictAlternative.Plus1()).Alt0())(some(dictAlternative)(dictLazy)(v))(Control_Applicative.pure(dictAlternative.Applicative0())(Data_List_Types.Nil.value));
          };
      };
  };
  var head = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just(v.value0);
      };
      throw new Error("Failed pattern match at Data.List (line 230, column 1 - line 230, column 22): " + [ v.constructor.name ]);
  };
  exports["toUnfoldable"] = toUnfoldable;
  exports["many"] = many;
  exports["null"] = $$null;
  exports["reverse"] = reverse;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.CatQueue"] = $PS["Data.CatQueue"] || {};
  var exports = $PS["Data.CatQueue"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatQueue = (function () {
      function CatQueue(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatQueue.create = function (value0) {
          return function (value1) {
              return new CatQueue(value0, value1);
          };
      };
      return CatQueue;
  })();
  var uncons = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          if (v.value0 instanceof Data_List_Types.Nil) {
              $copy_v = new CatQueue(Data_List.reverse(v.value1), Data_List_Types.Nil.value);
              return;
          };
          if (v.value0 instanceof Data_List_Types.Cons) {
              $tco_done = true;
              return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0, new CatQueue(v.value0.value1, v.value1)));
          };
          throw new Error("Failed pattern match at Data.CatQueue (line 83, column 1 - line 83, column 63): " + [ v.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var snoc = function (v) {
      return function (a) {
          return new CatQueue(v.value0, new Data_List_Types.Cons(a, v.value1));
      };
  };
  var $$null = function (v) {
      if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };                                                                                                
  var empty = new CatQueue(Data_List_Types.Nil.value, Data_List_Types.Nil.value);
  exports["empty"] = empty;
  exports["null"] = $$null;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.CatList"] = $PS["Data.CatList"] || {};
  var exports = $PS["Data.CatList"];
  var Data_CatQueue = $PS["Data.CatQueue"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatNil = (function () {
      function CatNil() {

      };
      CatNil.value = new CatNil();
      return CatNil;
  })();
  var CatCons = (function () {
      function CatCons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatCons.create = function (value0) {
          return function (value1) {
              return new CatCons(value0, value1);
          };
      };
      return CatCons;
  })();
  var link = function (v) {
      return function (v1) {
          if (v instanceof CatNil) {
              return v1;
          };
          if (v1 instanceof CatNil) {
              return v;
          };
          if (v instanceof CatCons) {
              return new CatCons(v.value0, Data_CatQueue.snoc(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.CatList (line 109, column 1 - line 109, column 54): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var foldr = function (k) {
      return function (b) {
          return function (q) {
              var foldl = function ($copy_v) {
                  return function ($copy_c) {
                      return function ($copy_v1) {
                          var $tco_var_v = $copy_v;
                          var $tco_var_c = $copy_c;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v, c, v1) {
                              if (v1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return c;
                              };
                              if (v1 instanceof Data_List_Types.Cons) {
                                  $tco_var_v = v;
                                  $tco_var_c = v(c)(v1.value0);
                                  $copy_v1 = v1.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.CatList (line 125, column 3 - line 125, column 59): " + [ v.constructor.name, c.constructor.name, v1.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v, $tco_var_c, $copy_v1);
                          };
                          return $tco_result;
                      };
                  };
              };
              var go = function ($copy_xs) {
                  return function ($copy_ys) {
                      var $tco_var_xs = $copy_xs;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(xs, ys) {
                          var v = Data_CatQueue.uncons(xs);
                          if (v instanceof Data_Maybe.Nothing) {
                              $tco_done = true;
                              return foldl(function (x) {
                                  return function (i) {
                                      return i(x);
                                  };
                              })(b)(ys);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              $tco_var_xs = v.value0.value1;
                              $copy_ys = new Data_List_Types.Cons(k(v.value0.value0), ys);
                              return;
                          };
                          throw new Error("Failed pattern match at Data.CatList (line 121, column 14 - line 123, column 67): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_xs, $copy_ys);
                      };
                      return $tco_result;
                  };
              };
              return go(q)(Data_List_Types.Nil.value);
          };
      };
  };
  var uncons = function (v) {
      if (v instanceof CatNil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof CatCons) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, (function () {
              var $44 = Data_CatQueue["null"](v.value1);
              if ($44) {
                  return CatNil.value;
              };
              return foldr(link)(CatNil.value)(v.value1);
          })()));
      };
      throw new Error("Failed pattern match at Data.CatList (line 100, column 1 - line 100, column 61): " + [ v.constructor.name ]);
  };
  var empty = CatNil.value;
  var append = link;
  var semigroupCatList = new Data_Semigroup.Semigroup(append);
  var snoc = function (cat) {
      return function (a) {
          return append(cat)(new CatCons(a, Data_CatQueue.empty));
      };
  };
  exports["empty"] = empty;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
  exports["semigroupCatList"] = semigroupCatList;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Free"] = $PS["Control.Monad.Free"] || {};
  var exports = $PS["Control.Monad.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Data_CatList = $PS["Data.CatList"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Free = (function () {
      function Free(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Free.create = function (value0) {
          return function (value1) {
              return new Free(value0, value1);
          };
      };
      return Free;
  })();
  var Return = (function () {
      function Return(value0) {
          this.value0 = value0;
      };
      Return.create = function (value0) {
          return new Return(value0);
      };
      return Return;
  })();
  var Bind = (function () {
      function Bind(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bind.create = function (value0) {
          return function (value1) {
              return new Bind(value0, value1);
          };
      };
      return Bind;
  })();
  var toView = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          var runExpF = function (v2) {
              return v2;
          };
          var concatF = function (v2) {
              return function (r) {
                  return new Free(v2.value0, Data_Semigroup.append(Data_CatList.semigroupCatList)(v2.value1)(r));
              };
          };
          if (v.value0 instanceof Return) {
              var v2 = Data_CatList.uncons(v.value1);
              if (v2 instanceof Data_Maybe.Nothing) {
                  $tco_done = true;
                  return new Return(v.value0.value0);
              };
              if (v2 instanceof Data_Maybe.Just) {
                  $copy_v = concatF(runExpF(v2.value0.value0)(v.value0.value0))(v2.value0.value1);
                  return;
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 227, column 7 - line 231, column 64): " + [ v2.constructor.name ]);
          };
          if (v.value0 instanceof Bind) {
              $tco_done = true;
              return new Bind(v.value0.value0, function (a) {
                  return concatF(v.value0.value1(a))(v.value1);
              });
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 225, column 3 - line 233, column 56): " + [ v.value0.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var fromView = function (f) {
      return new Free(f, Data_CatList.empty);
  };
  var freeMonad = new Control_Monad.Monad(function () {
      return freeApplicative;
  }, function () {
      return freeBind;
  });
  var freeFunctor = new Data_Functor.Functor(function (k) {
      return function (f) {
          return Control_Bind.bindFlipped(freeBind)((function () {
              var $120 = Control_Applicative.pure(freeApplicative);
              return function ($121) {
                  return $120(k($121));
              };
          })())(f);
      };
  });
  var freeBind = new Control_Bind.Bind(function () {
      return freeApply;
  }, function (v) {
      return function (k) {
          return new Free(v.value0, Data_CatList.snoc(v.value1)(k));
      };
  });
  var freeApply = new Control_Apply.Apply(function () {
      return freeFunctor;
  }, Control_Monad.ap(freeMonad));
  var freeApplicative = new Control_Applicative.Applicative(function () {
      return freeApply;
  }, function ($122) {
      return fromView(Return.create($122));
  });
  var liftF = function (f) {
      return fromView(new Bind(f, (function () {
          var $123 = Control_Applicative.pure(freeApplicative);
          return function ($124) {
              return $123($124);
          };
      })()));
  };
  var foldFree = function (dictMonadRec) {
      return function (k) {
          var go = function (f) {
              var v = toView(f);
              if (v instanceof Return) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(v.value0));
              };
              if (v instanceof Bind) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(function ($136) {
                      return Control_Monad_Rec_Class.Loop.create(v.value1($136));
                  })(k(v.value0));
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 158, column 10 - line 160, column 37): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  exports["liftF"] = liftF;
  exports["foldFree"] = foldFree;
  exports["freeFunctor"] = freeFunctor;
  exports["freeBind"] = freeBind;
  exports["freeApplicative"] = freeApplicative;
  exports["freeMonad"] = freeMonad;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Query.ChildQuery"] = $PS["Halogen.Query.ChildQuery"] || {};
  var exports = $PS["Halogen.Query.ChildQuery"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ChildQuery = (function () {
      function ChildQuery(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      ChildQuery.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new ChildQuery(value0, value1, value2);
              };
          };
      };
      return ChildQuery;
  })();
  var unChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  var mkChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  exports["ChildQuery"] = ChildQuery;
  exports["mkChildQueryBox"] = mkChildQueryBox;
  exports["unChildQueryBox"] = unChildQueryBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Query.HalogenM"] = $PS["Halogen.Query.HalogenM"] || {};
  var exports = $PS["Halogen.Query.HalogenM"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_FoldableWithIndex = $PS["Data.FoldableWithIndex"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];                  
  var SubscriptionId = function (x) {
      return x;
  };
  var ForkId = function (x) {
      return x;
  };
  var State = (function () {
      function State(value0) {
          this.value0 = value0;
      };
      State.create = function (value0) {
          return new State(value0);
      };
      return State;
  })();
  var Subscribe = (function () {
      function Subscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Subscribe.create = function (value0) {
          return function (value1) {
              return new Subscribe(value0, value1);
          };
      };
      return Subscribe;
  })();
  var Unsubscribe = (function () {
      function Unsubscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Unsubscribe.create = function (value0) {
          return function (value1) {
              return new Unsubscribe(value0, value1);
          };
      };
      return Unsubscribe;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var ChildQuery = (function () {
      function ChildQuery(value0) {
          this.value0 = value0;
      };
      ChildQuery.create = function (value0) {
          return new ChildQuery(value0);
      };
      return ChildQuery;
  })();
  var Raise = (function () {
      function Raise(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Raise.create = function (value0) {
          return function (value1) {
              return new Raise(value0, value1);
          };
      };
      return Raise;
  })();
  var Par = (function () {
      function Par(value0) {
          this.value0 = value0;
      };
      Par.create = function (value0) {
          return new Par(value0);
      };
      return Par;
  })();
  var Fork = (function () {
      function Fork(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Fork.create = function (value0) {
          return function (value1) {
              return new Fork(value0, value1);
          };
      };
      return Fork;
  })();
  var Kill = (function () {
      function Kill(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Kill.create = function (value0) {
          return function (value1) {
              return new Kill(value0, value1);
          };
      };
      return Kill;
  })();
  var GetRef = (function () {
      function GetRef(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      GetRef.create = function (value0) {
          return function (value1) {
              return new GetRef(value0, value1);
          };
      };
      return GetRef;
  })();
  var HalogenM = function (x) {
      return x;
  };
  var subscribe = function (es) {
      return HalogenM(Control_Monad_Free.liftF(new Subscribe(function (v) {
          return es;
      }, Control_Category.identity(Control_Category.categoryFn))));
  };
  var raise = function (o) {
      return HalogenM(Control_Monad_Free.liftF(new Raise(o, Data_Unit.unit)));
  };
  var queryAll = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (q) {
                      var catMapMaybes = function (dictOrd1) {
                          return Data_FoldableWithIndex.foldrWithIndex(Data_Map_Internal.foldableWithIndexMap)(function (k) {
                              return function (v) {
                                  return function (acc) {
                                      return Data_Maybe.maybe(acc)(Data_Function.flip(Data_Map_Internal.insert(dictOrd1)(k))(acc))(v);
                                  };
                              };
                          })(Data_Map_Internal.empty);
                      };
                      return HalogenM(Control_Monad_Free.liftF(ChildQuery.create(Halogen_Query_ChildQuery.mkChildQueryBox(new Halogen_Query_ChildQuery.ChildQuery(function (dictApplicative) {
                          return function (k) {
                              var $129 = Data_Functor.map((dictApplicative.Apply0()).Functor0())(catMapMaybes(dictOrd));
                              var $130 = Data_Traversable.traverse(Data_Map_Internal.traversableMap)(dictApplicative)(k);
                              var $131 = Halogen_Data_Slot.slots()(dictIsSymbol)(dictOrd)(label);
                              return function ($132) {
                                  return $129($130($131($132)));
                              };
                          };
                      }, q, Control_Category.identity(Control_Category.categoryFn))))));
                  };
              };
          };
      };
  };
  var ordSubscriptionId = Data_Ord.ordInt;
  var ordForkId = Data_Ord.ordInt;
  var monadHalogenM = Control_Monad_Free.freeMonad;
  var monadStateHalogenM = new Control_Monad_State_Class.MonadState(function () {
      return monadHalogenM;
  }, function ($137) {
      return HalogenM(Control_Monad_Free.liftF(State.create($137)));
  });
  var monadEffectHalogenM = function (dictMonadEffect) {
      return new Effect_Class.MonadEffect(function () {
          return monadHalogenM;
      }, (function () {
          var $142 = Effect_Class.liftEffect(dictMonadEffect);
          return function ($143) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($142($143))));
          };
      })());
  };
  var getRef = function (p) {
      return HalogenM(Control_Monad_Free.liftF(new GetRef(p, Control_Category.identity(Control_Category.categoryFn))));
  };
  var functorHalogenM = Control_Monad_Free.freeFunctor;
  var bindHalogenM = Control_Monad_Free.freeBind;           
  var applicativeHalogenM = Control_Monad_Free.freeApplicative;
  exports["State"] = State;
  exports["Subscribe"] = Subscribe;
  exports["Unsubscribe"] = Unsubscribe;
  exports["Lift"] = Lift;
  exports["ChildQuery"] = ChildQuery;
  exports["Raise"] = Raise;
  exports["Par"] = Par;
  exports["Fork"] = Fork;
  exports["Kill"] = Kill;
  exports["GetRef"] = GetRef;
  exports["raise"] = raise;
  exports["queryAll"] = queryAll;
  exports["SubscriptionId"] = SubscriptionId;
  exports["subscribe"] = subscribe;
  exports["ForkId"] = ForkId;
  exports["getRef"] = getRef;
  exports["functorHalogenM"] = functorHalogenM;
  exports["applicativeHalogenM"] = applicativeHalogenM;
  exports["bindHalogenM"] = bindHalogenM;
  exports["monadEffectHalogenM"] = monadEffectHalogenM;
  exports["monadStateHalogenM"] = monadStateHalogenM;
  exports["ordSubscriptionId"] = ordSubscriptionId;
  exports["ordForkId"] = ordForkId;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Query.HalogenQ"] = $PS["Halogen.Query.HalogenQ"] || {};
  var exports = $PS["Halogen.Query.HalogenQ"];           
  var Initialize = (function () {
      function Initialize(value0) {
          this.value0 = value0;
      };
      Initialize.create = function (value0) {
          return new Initialize(value0);
      };
      return Initialize;
  })();
  var Finalize = (function () {
      function Finalize(value0) {
          this.value0 = value0;
      };
      Finalize.create = function (value0) {
          return new Finalize(value0);
      };
      return Finalize;
  })();
  var Receive = (function () {
      function Receive(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Receive.create = function (value0) {
          return function (value1) {
              return new Receive(value0, value1);
          };
      };
      return Receive;
  })();
  var Action = (function () {
      function Action(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Action.create = function (value0) {
          return function (value1) {
              return new Action(value0, value1);
          };
      };
      return Action;
  })();
  var Query = (function () {
      function Query(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Query.create = function (value0) {
          return function (value1) {
              return new Query(value0, value1);
          };
      };
      return Query;
  })();
  exports["Initialize"] = Initialize;
  exports["Finalize"] = Finalize;
  exports["Receive"] = Receive;
  exports["Action"] = Action;
  exports["Query"] = Query;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Component"] = $PS["Halogen.Component"] || {};
  var exports = $PS["Halogen.Component"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ComponentSlot = (function () {
      function ComponentSlot(value0) {
          this.value0 = value0;
      };
      ComponentSlot.create = function (value0) {
          return new ComponentSlot(value0);
      };
      return ComponentSlot;
  })();
  var ThunkSlot = (function () {
      function ThunkSlot(value0) {
          this.value0 = value0;
      };
      ThunkSlot.create = function (value0) {
          return new ThunkSlot(value0);
      };
      return ThunkSlot;
  })();
  var unComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var unComponent = Unsafe_Coerce.unsafeCoerce;
  var mkEval = function (args) {
      return function (v) {
          if (v instanceof Halogen_Query_HalogenQ.Initialize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.initialize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Finalize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.finalize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Receive) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.receive(v.value0)))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Action) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(args.handleAction(v.value0))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Query) {
              return Data_Coyoneda.unCoyoneda(function (g) {
                  var $28 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(Data_Maybe.maybe(v.value1(Data_Unit.unit))(g));
                  return function ($29) {
                      return $28(args.handleQuery($29));
                  };
              })(v.value0);
          };
          throw new Error("Failed pattern match at Halogen.Component (line 172, column 15 - line 182, column 70): " + [ v.constructor.name ]);
      };
  };
  var mkComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var mkComponent = Unsafe_Coerce.unsafeCoerce;
  var defaultEval = {
      handleAction: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      handleQuery: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value)),
      receive: Data_Function["const"](Data_Maybe.Nothing.value),
      initialize: Data_Maybe.Nothing.value,
      finalize: Data_Maybe.Nothing.value
  };
  var componentSlot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (comp) {
                          return function (input) {
                              return function (output) {
                                  return mkComponentSlot({
                                      get: Halogen_Data_Slot.lookup()(dictIsSymbol)(dictOrd)(label)(p),
                                      pop: Halogen_Data_Slot.pop()(dictIsSymbol)(dictOrd)(label)(p),
                                      set: Halogen_Data_Slot.insert()(dictIsSymbol)(dictOrd)(label)(p),
                                      component: comp,
                                      input: new Halogen_Query_HalogenQ.Receive(input, Data_Unit.unit),
                                      output: output
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["mkComponent"] = mkComponent;
  exports["unComponent"] = unComponent;
  exports["mkEval"] = mkEval;
  exports["defaultEval"] = defaultEval;
  exports["ComponentSlot"] = ComponentSlot;
  exports["ThunkSlot"] = ThunkSlot;
  exports["componentSlot"] = componentSlot;
  exports["unComponentSlot"] = unComponentSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.HTML.Elements"] = $PS["Halogen.HTML.Elements"] || {};
  var exports = $PS["Halogen.HTML.Elements"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var element = Halogen_HTML_Core.element(Data_Maybe.Nothing.value);
  var p = element("p");
  var span = element("span");
  var div = element("div");
  var button = element("button");
  exports["button"] = button;
  exports["div"] = div;
  exports["p"] = p;
  exports["span"] = span;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.UIEvent.KeyboardEvent.EventTypes"] = $PS["Web.UIEvent.KeyboardEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];
  var keydown = "keydown";
  exports["keydown"] = keydown;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.UIEvent.MouseEvent.EventTypes"] = $PS["Web.UIEvent.MouseEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseover = "mouseover";
  var mouseout = "mouseout";
  var click = "click";
  exports["click"] = click;
  exports["mouseout"] = mouseout;
  exports["mouseover"] = mouseover;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.HTML.Events"] = $PS["Halogen.HTML.Events"] || {};
  var exports = $PS["Halogen.HTML.Events"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_UIEvent_KeyboardEvent_EventTypes = $PS["Web.UIEvent.KeyboardEvent.EventTypes"];
  var Web_UIEvent_MouseEvent_EventTypes = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseHandler = Unsafe_Coerce.unsafeCoerce;
  var keyHandler = Unsafe_Coerce.unsafeCoerce;
  var handler = function (et) {
      var $0 = Halogen_HTML_Core.handler(et);
      var $1 = Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Halogen_Query_Input.Action.create));
      return function ($2) {
          return $0($1($2));
      };
  };                                                       
  var onClick = (function () {
      var $3 = handler(Web_UIEvent_MouseEvent_EventTypes.click);
      return function ($4) {
          return $3(mouseHandler($4));
      };
  })();                                                      
  var onKeyDown = (function () {
      var $7 = handler(Web_UIEvent_KeyboardEvent_EventTypes.keydown);
      return function ($8) {
          return $7(keyHandler($8));
      };
  })();
  var onMouseOut = (function () {
      var $19 = handler(Web_UIEvent_MouseEvent_EventTypes.mouseout);
      return function ($20) {
          return $19(mouseHandler($20));
      };
  })();
  var onMouseOver = (function () {
      var $21 = handler(Web_UIEvent_MouseEvent_EventTypes.mouseover);
      return function ($22) {
          return $21(mouseHandler($22));
      };
  })();
  exports["onClick"] = onClick;
  exports["onMouseOver"] = onMouseOver;
  exports["onMouseOut"] = onMouseOut;
  exports["onKeyDown"] = onKeyDown;
})(PS);
(function(exports) {
  "use strict";

  exports._read = function (nothing, just, value) {
    var tag = Object.prototype.toString.call(value);
    if (tag.indexOf("[object HTML") === 0 && tag.indexOf("Element]") === tag.length - 8) {
      return just(value);
    } else {
      return nothing;
    }
  };
})(PS["Web.HTML.HTMLElement"] = PS["Web.HTML.HTMLElement"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.HTML.HTMLElement"] = $PS["Web.HTML.HTMLElement"] || {};
  var exports = $PS["Web.HTML.HTMLElement"];
  var $foreign = $PS["Web.HTML.HTMLElement"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  var fromNode = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  var fromElement = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  exports["fromElement"] = fromElement;
  exports["fromNode"] = fromNode;
  exports["toNode"] = toNode;
  exports["toEventTarget"] = toEventTarget;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Query"] = $PS["Halogen.Query"] || {};
  var exports = $PS["Halogen.Query"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];                
  var tell = function (act) {
      return act(Data_Unit.unit);
  };
  var getHTMLElementRef = (function () {
      var $1 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(function (v) {
          return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(v);
      });
      return function ($2) {
          return $1(Halogen_Query_HalogenM.getRef($2));
      };
  })();
  exports["tell"] = tell;
  exports["getHTMLElementRef"] = getHTMLElementRef;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Trans.Class"] = $PS["Control.Monad.Trans.Class"] || {};
  var exports = $PS["Control.Monad.Trans.Class"];
  var MonadTrans = function (lift) {
      this.lift = lift;
  };
  var lift = function (dict) {
      return dict.lift;
  };
  exports["lift"] = lift;
  exports["MonadTrans"] = MonadTrans;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Except.Trans"] = $PS["Control.Monad.Except.Trans"] || {};
  var exports = $PS["Control.Monad.Except.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                
  var ExceptT = function (x) {
      return x;
  };
  var runExceptT = function (v) {
      return v;
  };          
  var monadTransExceptT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      return function (m) {
          return Control_Bind.bind(dictMonad.Bind1())(m)(function (v) {
              return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(v));
          });
      };
  });
  var mapExceptT = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var functorExceptT = function (dictFunctor) {
      return new Data_Functor.Functor(function (f) {
          return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
      });
  };
  var monadExceptT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeExceptT(dictMonad);
      }, function () {
          return bindExceptT(dictMonad);
      });
  };
  var bindExceptT = function (dictMonad) {
      return new Control_Bind.Bind(function () {
          return applyExceptT(dictMonad);
      }, function (v) {
          return function (k) {
              return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either((function () {
                  var $98 = Control_Applicative.pure(dictMonad.Applicative0());
                  return function ($99) {
                      return $98(Data_Either.Left.create($99));
                  };
              })())(function (a) {
                  var v1 = k(a);
                  return v1;
              }));
          };
      });
  };
  var applyExceptT = function (dictMonad) {
      return new Control_Apply.Apply(function () {
          return functorExceptT(((dictMonad.Bind1()).Apply0()).Functor0());
      }, Control_Monad.ap(monadExceptT(dictMonad)));
  };
  var applicativeExceptT = function (dictMonad) {
      return new Control_Applicative.Applicative(function () {
          return applyExceptT(dictMonad);
      }, (function () {
          var $100 = Control_Applicative.pure(dictMonad.Applicative0());
          return function ($101) {
              return ExceptT($100(Data_Either.Right.create($101)));
          };
      })());
  };
  var monadStateExceptT = function (dictMonadState) {
      return new Control_Monad_State_Class.MonadState(function () {
          return monadExceptT(dictMonadState.Monad0());
      }, function (f) {
          return Control_Monad_Trans_Class.lift(monadTransExceptT)(dictMonadState.Monad0())(Control_Monad_State_Class.state(dictMonadState)(f));
      });
  };
  var monadThrowExceptT = function (dictMonad) {
      return new Control_Monad_Error_Class.MonadThrow(function () {
          return monadExceptT(dictMonad);
      }, (function () {
          var $110 = Control_Applicative.pure(dictMonad.Applicative0());
          return function ($111) {
              return ExceptT($110(Data_Either.Left.create($111)));
          };
      })());
  };
  exports["ExceptT"] = ExceptT;
  exports["runExceptT"] = runExceptT;
  exports["functorExceptT"] = functorExceptT;
  exports["applyExceptT"] = applyExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["monadTransExceptT"] = monadTransExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
  exports["monadStateExceptT"] = monadStateExceptT;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Free.Trans"] = $PS["Control.Monad.Free.Trans"] || {};
  var exports = $PS["Control.Monad.Free.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Exists = $PS["Data.Exists"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];                      
  var Bound = (function () {
      function Bound(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bound.create = function (value0) {
          return function (value1) {
              return new Bound(value0, value1);
          };
      };
      return Bound;
  })();
  var FreeT = (function () {
      function FreeT(value0) {
          this.value0 = value0;
      };
      FreeT.create = function (value0) {
          return new FreeT(value0);
      };
      return FreeT;
  })();
  var Bind = (function () {
      function Bind(value0) {
          this.value0 = value0;
      };
      Bind.create = function (value0) {
          return new Bind(value0);
      };
      return Bind;
  })();
  var monadTransFreeT = function (dictFunctor) {
      return new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
          return function (ma) {
              return new FreeT(function (v) {
                  return Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Data_Either.Left.create)(ma);
              });
          };
      });
  };
  var freeT = FreeT.create;
  var bound = function (m) {
      return function (f) {
          return new Bind(Data_Exists.mkExists(new Bound(m, f)));
      };
  };
  var functorFreeT = function (dictFunctor) {
      return function (dictFunctor1) {
          return new Data_Functor.Functor(function (f) {
              return function (v) {
                  if (v instanceof FreeT) {
                      return new FreeT(function (v1) {
                          return Data_Functor.map(dictFunctor1)(Data_Bifunctor.bimap(Data_Either.bifunctorEither)(f)(Data_Functor.map(dictFunctor)(Data_Functor.map(functorFreeT(dictFunctor)(dictFunctor1))(f))))(v.value0(Data_Unit.unit));
                      });
                  };
                  if (v instanceof Bind) {
                      return Data_Exists.runExists(function (v1) {
                          return bound(v1.value0)((function () {
                              var $123 = Data_Functor.map(functorFreeT(dictFunctor)(dictFunctor1))(f);
                              return function ($124) {
                                  return $123(v1.value1($124));
                              };
                          })());
                      })(v.value0);
                  };
                  throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 59, column 1 - line 61, column 71): " + [ f.constructor.name, v.constructor.name ]);
              };
          });
      };
  };
  var monadFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Monad.Monad(function () {
              return applicativeFreeT(dictFunctor)(dictMonad);
          }, function () {
              return bindFreeT(dictFunctor)(dictMonad);
          });
      };
  };
  var bindFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Bind.Bind(function () {
              return applyFreeT(dictFunctor)(dictMonad);
          }, function (v) {
              return function (f) {
                  if (v instanceof Bind) {
                      return Data_Exists.runExists(function (v1) {
                          return bound(v1.value0)(function (x) {
                              return bound(function (v2) {
                                  return v1.value1(x);
                              })(f);
                          });
                      })(v.value0);
                  };
                  return bound(function (v1) {
                      return v;
                  })(f);
              };
          });
      };
  };
  var applyFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Apply.Apply(function () {
              return functorFreeT(dictFunctor)(((dictMonad.Bind1()).Apply0()).Functor0());
          }, Control_Monad.ap(monadFreeT(dictFunctor)(dictMonad)));
      };
  };
  var applicativeFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Applicative.Applicative(function () {
              return applyFreeT(dictFunctor)(dictMonad);
          }, function (a) {
              return new FreeT(function (v) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(a));
              });
          });
      };
  };
  var liftFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return function (fa) {
              return new FreeT(function (v) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(Data_Functor.map(dictFunctor)(Control_Applicative.pure(applicativeFreeT(dictFunctor)(dictMonad)))(fa)));
              });
          };
      };
  };
  var resume = function (dictFunctor) {
      return function (dictMonadRec) {
          var go = function (v) {
              if (v instanceof FreeT) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(v.value0(Data_Unit.unit));
              };
              if (v instanceof Bind) {
                  return Data_Exists.runExists(function (v1) {
                      var v2 = v1.value0(Data_Unit.unit);
                      if (v2 instanceof FreeT) {
                          return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(v2.value0(Data_Unit.unit))(function (v3) {
                              if (v3 instanceof Data_Either.Left) {
                                  return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(v1.value1(v3.value0)));
                              };
                              if (v3 instanceof Data_Either.Right) {
                                  return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(new Data_Either.Right(Data_Functor.map(dictFunctor)(function (h) {
                                      return Control_Bind.bind(bindFreeT(dictFunctor)(dictMonadRec.Monad0()))(h)(v1.value1);
                                  })(v3.value0))));
                              };
                              throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 54, column 20 - line 56, column 67): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v2 instanceof Bind) {
                          return Data_Exists.runExists(function (v3) {
                              return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Loop(Control_Bind.bind(bindFreeT(dictFunctor)(dictMonadRec.Monad0()))(v3.value0(Data_Unit.unit))(function (z) {
                                  return Control_Bind.bind(bindFreeT(dictFunctor)(dictMonadRec.Monad0()))(v3.value1(z))(v1.value1);
                              })));
                          })(v2.value0);
                      };
                      throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 52, column 5 - line 57, column 98): " + [ v2.constructor.name ]);
                  })(v.value0);
              };
              throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 49, column 3 - line 49, column 75): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  var runFreeT = function (dictFunctor) {
      return function (dictMonadRec) {
          return function (interp) {
              var go = function (v) {
                  if (v instanceof Data_Either.Left) {
                      return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Control_Monad_Rec_Class.Done(v.value0));
                  };
                  if (v instanceof Data_Either.Right) {
                      return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Loop.create)(interp(v.value0));
                  };
                  throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 141, column 3 - line 141, column 63): " + [ v.constructor.name ]);
              };
              return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(Control_Bind.composeKleisliFlipped((dictMonadRec.Monad0()).Bind1())(go)(resume(dictFunctor)(dictMonadRec)));
          };
      };
  };
  var monadRecFreeT = function (dictFunctor) {
      return function (dictMonad) {
          return new Control_Monad_Rec_Class.MonadRec(function () {
              return monadFreeT(dictFunctor)(dictMonad);
          }, function (f) {
              var go = function (s) {
                  return Control_Bind.bind(bindFreeT(dictFunctor)(dictMonad))(f(s))(function (v) {
                      if (v instanceof Control_Monad_Rec_Class.Loop) {
                          return go(v.value0);
                      };
                      if (v instanceof Control_Monad_Rec_Class.Done) {
                          return Control_Applicative.pure(applicativeFreeT(dictFunctor)(dictMonad))(v.value0);
                      };
                      throw new Error("Failed pattern match at Control.Monad.Free.Trans (line 82, column 15 - line 84, column 25): " + [ v.constructor.name ]);
                  });
              };
              return go;
          });
      };
  };
  exports["freeT"] = freeT;
  exports["liftFreeT"] = liftFreeT;
  exports["resume"] = resume;
  exports["runFreeT"] = runFreeT;
  exports["functorFreeT"] = functorFreeT;
  exports["applicativeFreeT"] = applicativeFreeT;
  exports["bindFreeT"] = bindFreeT;
  exports["monadTransFreeT"] = monadTransFreeT;
  exports["monadRecFreeT"] = monadRecFreeT;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Identity"] = $PS["Data.Identity"] || {};
  var exports = $PS["Data.Identity"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Newtype = $PS["Data.Newtype"];          
  var Identity = function (x) {
      return x;
  };
  var newtypeIdentity = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Identity);
  var functorIdentity = new Data_Functor.Functor(function (f) {
      return function (m) {
          return f(m);
      };
  });
  var applyIdentity = new Control_Apply.Apply(function () {
      return functorIdentity;
  }, function (v) {
      return function (v1) {
          return v(v1);
      };
  });
  var bindIdentity = new Control_Bind.Bind(function () {
      return applyIdentity;
  }, function (v) {
      return function (f) {
          return f(v);
      };
  });
  var applicativeIdentity = new Control_Applicative.Applicative(function () {
      return applyIdentity;
  }, Identity);
  var monadIdentity = new Control_Monad.Monad(function () {
      return applicativeIdentity;
  }, function () {
      return bindIdentity;
  });
  exports["newtypeIdentity"] = newtypeIdentity;
  exports["functorIdentity"] = functorIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Profunctor"] = $PS["Data.Profunctor"] || {};
  var exports = $PS["Data.Profunctor"];
  var Control_Category = $PS["Control.Category"];        
  var Profunctor = function (dimap) {
      this.dimap = dimap;
  };
  var profunctorFn = new Profunctor(function (a2b) {
      return function (c2d) {
          return function (b2c) {
              return function ($9) {
                  return c2d(b2c(a2b($9)));
              };
          };
      };
  });
  var dimap = function (dict) {
      return dict.dimap;
  };
  var rmap = function (dictProfunctor) {
      return function (b2c) {
          return dimap(dictProfunctor)(Control_Category.identity(Control_Category.categoryFn))(b2c);
      };
  };
  exports["dimap"] = dimap;
  exports["Profunctor"] = Profunctor;
  exports["rmap"] = rmap;
  exports["profunctorFn"] = profunctorFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Coroutine"] = $PS["Control.Coroutine"] || {};
  var exports = $PS["Control.Coroutine"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Control_Monad_Free_Trans = $PS["Control.Monad.Free.Trans"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Emit = (function () {
      function Emit(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Emit.create = function (value0) {
          return function (value1) {
              return new Emit(value0, value1);
          };
      };
      return Emit;
  })();
  var runProcess = function (dictMonadRec) {
      return Control_Monad_Free_Trans.runFreeT(Data_Identity.functorIdentity)(dictMonadRec)((function () {
          var $186 = Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0());
          var $187 = Data_Newtype.unwrap(Data_Identity.newtypeIdentity);
          return function ($188) {
              return $186($187($188));
          };
      })());
  };
  var profunctorAwait = new Data_Profunctor.Profunctor(function (f) {
      return function (g) {
          return function (v) {
              return Data_Profunctor.dimap(Data_Profunctor.profunctorFn)(f)(g)(v);
          };
      };
  });
  var loop = function (dictFunctor) {
      return function (dictMonad) {
          return function (me) {
              return Control_Monad_Rec_Class.tailRecM(Control_Monad_Free_Trans.monadRecFreeT(dictFunctor)(dictMonad))(function (v) {
                  return Data_Functor.map(Control_Monad_Free_Trans.functorFreeT(dictFunctor)(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Maybe.maybe(new Control_Monad_Rec_Class.Loop(Data_Unit.unit))(Control_Monad_Rec_Class.Done.create))(me);
              })(Data_Unit.unit);
          };
      };
  };
  var fuseWithL = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictFunctor2) {
              return function (dictMonadRec) {
                  return function (zap) {
                      return function (fs) {
                          return function (gs) {
                              var go = function (v) {
                                  return Control_Monad_Except_Trans.runExceptT(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(dictMonadRec.Monad0()))(Control_Monad_Except_Trans.ExceptT(Control_Monad_Free_Trans.resume(dictFunctor)(dictMonadRec)(v.value0)))(function (v1) {
                                      return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(dictMonadRec.Monad0()))(Control_Monad_Except_Trans.ExceptT(Control_Monad_Free_Trans.resume(dictFunctor1)(dictMonadRec)(v.value1)))(function (v2) {
                                          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonadRec.Monad0()))(Data_Functor.map(dictFunctor2)(function (t) {
                                              return Control_Monad_Free_Trans.freeT(function (v3) {
                                                  return go(t);
                                              });
                                          })(zap(Data_Tuple.Tuple.create)(v1)(v2)));
                                      });
                                  }));
                              };
                              return Control_Monad_Free_Trans.freeT(function (v) {
                                  return go(new Data_Tuple.Tuple(fs, gs));
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var fuseWith = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictFunctor2) {
              return function (dictMonadRec) {
                  return function (dictParallel) {
                      return function (zap) {
                          return function (fs) {
                              return function (gs) {
                                  var go = function (v) {
                                      return Control_Bind.bind((dictMonadRec.Monad0()).Bind1())(Control_Parallel_Class.sequential(dictParallel)(Control_Apply.apply((dictParallel.Applicative1()).Apply0())(Data_Functor.map(((dictParallel.Applicative1()).Apply0()).Functor0())(Control_Apply.lift2(Data_Either.applyEither)(zap(Data_Tuple.Tuple.create)))(Control_Parallel_Class.parallel(dictParallel)(Control_Monad_Free_Trans.resume(dictFunctor)(dictMonadRec)(v.value0))))(Control_Parallel_Class.parallel(dictParallel)(Control_Monad_Free_Trans.resume(dictFunctor1)(dictMonadRec)(v.value1)))))(function (v1) {
                                          if (v1 instanceof Data_Either.Left) {
                                              return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Data_Either.Left(v1.value0));
                                          };
                                          if (v1 instanceof Data_Either.Right) {
                                              return Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(new Data_Either.Right(Data_Functor.map(dictFunctor2)(function (t) {
                                                  return Control_Monad_Free_Trans.freeT(function (v2) {
                                                      return go(t);
                                                  });
                                              })(v1.value0)));
                                          };
                                          throw new Error("Failed pattern match at Control.Coroutine (line 79, column 5 - line 81, column 63): " + [ v1.constructor.name ]);
                                      });
                                  };
                                  return Control_Monad_Free_Trans.freeT(function (v) {
                                      return go(new Data_Tuple.Tuple(fs, gs));
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  var functorAwait = new Data_Functor.Functor(Data_Profunctor.rmap(profunctorAwait));
  var bifunctorEmit = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              return new Emit(f(v.value0), g(v.value1));
          };
      };
  });
  var functorEmit = new Data_Functor.Functor(Data_Bifunctor.rmap(bifunctorEmit));
  var connect = function (dictMonadRec) {
      return function (dictParallel) {
          return fuseWith(functorEmit)(functorAwait)(Data_Identity.functorIdentity)(dictMonadRec)(dictParallel)(function (f) {
              return function (v) {
                  return function (v1) {
                      return f(v.value1)(v1(v.value0));
                  };
              };
          });
      };
  };
  var emit = function (dictMonad) {
      return function (o) {
          return Control_Monad_Free_Trans.liftFreeT(functorEmit)(dictMonad)(new Emit(o, Data_Unit.unit));
      };
  };
  var producer = function (dictMonad) {
      return function (recv) {
          return loop(functorEmit)(dictMonad)(Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(functorEmit)(dictMonad))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(functorEmit))(dictMonad)(recv))(function (v) {
              if (v instanceof Data_Either.Left) {
                  return Data_Functor.voidLeft(Control_Monad_Free_Trans.functorFreeT(functorEmit)(((dictMonad.Bind1()).Apply0()).Functor0()))(emit(dictMonad)(v.value0))(Data_Maybe.Nothing.value);
              };
              if (v instanceof Data_Either.Right) {
                  return Control_Applicative.pure(Control_Monad_Free_Trans.applicativeFreeT(functorEmit)(dictMonad))(new Data_Maybe.Just(v.value0));
              };
              throw new Error("Failed pattern match at Control.Coroutine (line 125, column 3 - line 127, column 29): " + [ v.constructor.name ]);
          }));
      };
  };
  var pullFrom = function (dictMonadRec) {
      return fuseWithL(functorAwait)(functorEmit)(Data_Identity.functorIdentity)(dictMonadRec)(function (f) {
          return function (v) {
              return function (v1) {
                  return Control_Applicative.pure(Data_Identity.applicativeIdentity)(f(v(v1.value0))(v1.value1));
              };
          };
      });
  };
  var $$await = function (dictMonad) {
      return Control_Monad_Free_Trans.liftFreeT(functorAwait)(dictMonad)(Control_Category.identity(Control_Category.categoryFn));
  };
  exports["runProcess"] = runProcess;
  exports["producer"] = producer;
  exports["await"] = $$await;
  exports["connect"] = connect;
  exports["pullFrom"] = pullFrom;
  exports["functorEmit"] = functorEmit;
  exports["functorAwait"] = functorAwait;
})(PS);
(function(exports) {
  /* globals exports, setTimeout */
  "use strict";

  var AVar = function () {

    function MutableQueue () {
      this.head = null;
      this.last = null;
      this.size = 0;
    }

    function MutableCell (queue, value) {
      this.queue = queue;
      this.value = value;
      this.next  = null;
      this.prev  = null;
    }

    function AVar (value) {
      this.draining = false;
      this.error    = null;
      this.value    = value;
      this.takes    = new MutableQueue();
      this.reads    = new MutableQueue();
      this.puts     = new MutableQueue();
    }

    var EMPTY = {};

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function putLast (queue, value) {
      var cell = new MutableCell(queue, value);
      switch (queue.size) {
      case 0:
        queue.head = cell;
        break;
      case 1:
        cell.prev = queue.head;
        queue.head.next = cell;
        queue.last = cell;
        break;
      default:
        cell.prev = queue.last;
        queue.last.next = cell;
        queue.last = cell;
      }
      queue.size++;
      return cell;
    }

    function takeLast (queue) {
      var cell;
      switch (queue.size) {
      case 0:
        return null;
      case 1:
        cell = queue.head;
        queue.head = null;
        break;
      case 2:
        cell = queue.last;
        queue.head.next = null;
        queue.last = null;
        break;
      default:
        cell = queue.last;
        queue.last = cell.prev;
        queue.last.next = null;
      }
      cell.prev = null;
      cell.queue = null;
      queue.size--;
      return cell.value;
    }

    function takeHead (queue) {
      var cell;
      switch (queue.size) {
      case 0:
        return null;
      case 1:
        cell = queue.head;
        queue.head = null;
        break;
      case 2:
        cell = queue.head;
        queue.last.prev = null;
        queue.head = queue.last;
        queue.last = null;
        break;
      default:
        cell = queue.head;
        queue.head = cell.next;
        queue.head.prev = null;
      }
      cell.next = null;
      cell.queue = null;
      queue.size--;
      return cell.value;
    }

    function deleteCell (cell) {
      if (cell.queue === null) {
        return;
      }
      if (cell.queue.last === cell) {
        takeLast(cell.queue);
        return;
      }
      if (cell.queue.head === cell) {
        takeHead(cell.queue);
        return;
      }
      if (cell.prev) {
        cell.prev.next = cell.next;
      }
      if (cell.next) {
        cell.next.prev = cell.prev;
      }
      cell.queue.size--;
      cell.queue = null;
      cell.value = null;
      cell.next  = null;
      cell.prev  = null;
    }

    function drainVar (util, avar) {
      if (avar.draining) {
        return;
      }

      var ps = avar.puts;
      var ts = avar.takes;
      var rs = avar.reads;
      var p, r, t, value, rsize;

      avar.draining = true;

      while (1) { // eslint-disable-line no-constant-condition
        p = null;
        r = null;
        t = null;
        value = avar.value;
        rsize = rs.size;

        if (avar.error !== null) {
          value = util.left(avar.error);
          while (p = takeHead(ps)) { // eslint-disable-line no-cond-assign
            runEff(p.cb(value));
          }
          while (r = takeHead(rs)) { // eslint-disable-line no-cond-assign
            runEff(r(value));
          }
          while (t = takeHead(ts)) { // eslint-disable-line no-cond-assign
            runEff(t(value));
          }
          break;
        }

        // Process the next put. We do not immediately invoke the callback
        // because we want to preserve ordering. If there are takes/reads
        // we want to run those first.
        if (value === EMPTY && (p = takeHead(ps))) {
          avar.value = value = p.value;
        }

        if (value !== EMPTY) {
          // We go ahead and queue up the next take for the same reasons as
          // above. Invoking the read callbacks can affect the mutable queue.
          t = takeHead(ts);
          // We only want to process the reads queued up before running these
          // callbacks so we guard on rsize.
          while (rsize-- && (r = takeHead(rs))) {
            runEff(r(util.right(value)));
          }
          if (t !== null) {
            avar.value = EMPTY;
            runEff(t(util.right(value)));
          }
        }

        if (p !== null) {
          runEff(p.cb(util.right(void 0)));
        }

        // Callbacks could have queued up more items so we need to guard on the
        // actual mutable properties.
        if (avar.value === EMPTY && ps.size === 0 || avar.value !== EMPTY && ts.size === 0) {
          break;
        }
      }
      avar.draining = false;
    }

    AVar.EMPTY      = EMPTY;
    AVar.putLast    = putLast;
    AVar.takeLast   = takeLast;
    AVar.takeHead   = takeHead;
    AVar.deleteCell = deleteCell;
    AVar.drainVar   = drainVar;

    return AVar;
  }();

  exports.empty = function () {
    return new AVar(AVar.EMPTY);
  };

  exports._killVar = function (util, error, avar) {
    return function () {
      if (avar.error === null) {
        avar.error = error;
        avar.value = AVar.EMPTY;
        AVar.drainVar(util, avar);
      }
    };
  };

  exports._putVar = function (util, value, avar, cb) {
    return function () {
      var cell = AVar.putLast(avar.puts, { cb: cb, value: value });
      AVar.drainVar(util, avar);
      return function () {
        AVar.deleteCell(cell);
      };
    };
  };

  exports._takeVar = function (util, avar, cb) {
    return function () {
      var cell = AVar.putLast(avar.takes, cb);
      AVar.drainVar(util, avar);
      return function () {
        AVar.deleteCell(cell);
      };
    };
  };
})(PS["Effect.AVar"] = PS["Effect.AVar"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.AVar"] = $PS["Effect.AVar"] || {};
  var exports = $PS["Effect.AVar"];
  var $foreign = $PS["Effect.AVar"];
  var Data_Either = $PS["Data.Either"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var Killed = (function () {
      function Killed(value0) {
          this.value0 = value0;
      };
      Killed.create = function (value0) {
          return new Killed(value0);
      };
      return Killed;
  })();
  var Filled = (function () {
      function Filled(value0) {
          this.value0 = value0;
      };
      Filled.create = function (value0) {
          return new Filled(value0);
      };
      return Filled;
  })();
  var Empty = (function () {
      function Empty() {

      };
      Empty.value = new Empty();
      return Empty;
  })();
  var ffiUtil = {
      left: Data_Either.Left.create,
      right: Data_Either.Right.create,
      nothing: Data_Maybe.Nothing.value,
      just: Data_Maybe.Just.create,
      killed: Killed.create,
      filled: Filled.create,
      empty: Empty.value
  };
  var kill = function (err) {
      return function (avar) {
          return $foreign["_killVar"](ffiUtil, err, avar);
      };
  };
  var put = function (value) {
      return function (avar) {
          return function (cb) {
              return $foreign["_putVar"](ffiUtil, value, avar, cb);
          };
      };
  };
  var take = function (avar) {
      return function (cb) {
          return $foreign["_takeVar"](ffiUtil, avar, cb);
      };
  };
  exports["take"] = take;
  exports["put"] = put;
  exports["kill"] = kill;
  exports["empty"] = $foreign.empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Aff.AVar"] = $PS["Effect.Aff.AVar"] || {};
  var exports = $PS["Effect.Aff.AVar"];
  var Effect_AVar = $PS["Effect.AVar"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var take = function (avar) {
      return Effect_Aff.makeAff(function (k) {
          return function __do() {
              var v = Effect_AVar.take(avar)(k)();
              return Effect_Aff.effectCanceler(v);
          };
      });
  };
  var put = function (value) {
      return function (avar) {
          return Effect_Aff.makeAff(function (k) {
              return function __do() {
                  var v = Effect_AVar.put(value)(avar)(k)();
                  return Effect_Aff.effectCanceler(v);
              };
          });
      };
  };   
  var kill = function (error) {
      var $17 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
      var $18 = Effect_AVar.kill(error);
      return function ($19) {
          return $17($18($19));
      };
  };
  var empty = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_AVar.empty);
  exports["empty"] = empty;
  exports["take"] = take;
  exports["put"] = put;
  exports["kill"] = kill;
})(PS);
(function(exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];
  var $$throw = function ($2) {
      return $foreign.throwException($foreign.error($2));
  };
  exports["throw"] = $$throw;
  exports["error"] = $foreign.error;
  exports["throwException"] = $foreign.throwException;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Query.EventSource"] = $PS["Halogen.Query.EventSource"] || {};
  var exports = $PS["Halogen.Query.EventSource"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Coroutine = $PS["Control.Coroutine"];
  var Control_Monad_Free_Trans = $PS["Control.Monad.Free.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_AVar = $PS["Effect.Aff.AVar"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var EventSource = function (x) {
      return x;
  };
  var hoistFinalizer = function (nat) {
      return function (v) {
          return nat(v);
      };
  };
  var hoistEmitter = function (nat) {
      return function (v) {
          return function ($43) {
              return nat(v($43));
          };
      };
  };
  var finalize = function (v) {
      return v;
  };
  var emit = function (v) {
      return function (a) {
          return v(new Data_Either.Left(a));
      };
  };
  var affEventSource = function (dictMonadAff) {
      return function (recv) {
          return EventSource(Effect_Aff_Class.liftAff(dictMonadAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff_AVar.empty)(function (v) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff_AVar.empty)(function (v1) {
                  var producer = Control_Bind.discard(Control_Bind.discardUnit)(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorEmit)((dictMonadAff.MonadEffect0()).Monad0()))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(Control_Coroutine.functorEmit))((dictMonadAff.MonadEffect0()).Monad0())(Effect_Aff_Class.liftAff(dictMonadAff)(Control_Bind.bindFlipped(Effect_Aff.bindAff)(Data_Function.flip(Effect_Aff_AVar.put)(v1))(recv(Data_Function.flip(Effect_Aff_AVar.put)(v))))))(function () {
                      return Control_Coroutine.producer((dictMonadAff.MonadEffect0()).Monad0())(Effect_Aff_Class.liftAff(dictMonadAff)(Effect_Aff_AVar.take(v)));
                  });
                  var finalizer = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1())(Effect_Aff_Class.liftAff(dictMonadAff)(Effect_Aff.attempt(Effect_Aff_AVar.take(v1))))(function (v2) {
                      if (v2 instanceof Data_Either.Left) {
                          return Control_Applicative.pure(((dictMonadAff.MonadEffect0()).Monad0()).Applicative0())(Data_Unit.unit);
                      };
                      if (v2 instanceof Data_Either.Right) {
                          return Effect_Aff_Class.liftAff(dictMonadAff)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff_AVar.kill(Effect_Exception.error("finalized"))(v1))(function () {
                              return finalize(v2.value0);
                          }));
                      };
                      throw new Error("Failed pattern match at Halogen.Query.EventSource (line 71, column 51 - line 75, column 21): " + [ v2.constructor.name ]);
                  });
                  return Control_Applicative.pure(Effect_Aff.applicativeAff)({
                      producer: producer,
                      finalizer: finalizer
                  });
              });
          })));
      };
  };
  var effectEventSource = function (dictMonadAff) {
      var $44 = affEventSource(dictMonadAff);
      var $45 = Data_Profunctor.dimap(Data_Profunctor.profunctorFn)(hoistEmitter(Effect_Aff.launchAff_))((function () {
          var $47 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
          var $48 = Data_Functor.map(Effect.functorEffect)(hoistFinalizer(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)));
          return function ($49) {
              return $47($48($49));
          };
      })());
      return function ($46) {
          return $44($45($46));
      };
  };
  var eventListenerEventSource = function (dictMonadAff) {
      return function (eventType) {
          return function (target) {
              return function (f) {
                  return effectEventSource(dictMonadAff)(function (emitter) {
                      return function __do() {
                          var v = Web_Event_EventTarget.eventListener((function () {
                              var $50 = Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(emit(emitter));
                              return function ($51) {
                                  return $50(f($51));
                              };
                          })())();
                          Web_Event_EventTarget.addEventListener(eventType)(v)(false)(target)();
                          return Web_Event_EventTarget.removeEventListener(eventType)(v)(false)(target);
                      };
                  });
              };
          };
      };
  };
  exports["eventListenerEventSource"] = eventListenerEventSource;
  exports["finalize"] = finalize;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.State"] = $PS["Control.Monad.State"] || {};
  var exports = $PS["Control.Monad.State"];
  var evalState = function (v) {
      return function (s) {
          var v1 = v(s);
          return v1.value0;
      };
  };
  exports["evalState"] = evalState;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.State.Trans"] = $PS["Control.Monad.State.Trans"] || {};
  var exports = $PS["Control.Monad.State.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                      
  var StateT = function (x) {
      return x;
  };
  var runStateT = function (v) {
      return v;
  };         
  var monadTransStateT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      return function (m) {
          return function (s) {
              return Control_Bind.bind(dictMonad.Bind1())(m)(function (v) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v, s));
              });
          };
      };
  });
  var lazyStateT = new Control_Lazy.Lazy(function (f) {
      return function (s) {
          var v = f(Data_Unit.unit);
          return v(s);
      };
  });
  var functorStateT = function (dictFunctor) {
      return new Data_Functor.Functor(function (f) {
          return function (v) {
              return function (s) {
                  return Data_Functor.map(dictFunctor)(function (v1) {
                      return new Data_Tuple.Tuple(f(v1.value0), v1.value1);
                  })(v(s));
              };
          };
      });
  };
  var evalStateT = function (dictFunctor) {
      return function (v) {
          return function (s) {
              return Data_Functor.map(dictFunctor)(Data_Tuple.fst)(v(s));
          };
      };
  };
  var monadStateT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeStateT(dictMonad);
      }, function () {
          return bindStateT(dictMonad);
      });
  };
  var bindStateT = function (dictMonad) {
      return new Control_Bind.Bind(function () {
          return applyStateT(dictMonad);
      }, function (v) {
          return function (f) {
              return function (s) {
                  return Control_Bind.bind(dictMonad.Bind1())(v(s))(function (v1) {
                      var v3 = f(v1.value0);
                      return v3(v1.value1);
                  });
              };
          };
      });
  };
  var applyStateT = function (dictMonad) {
      return new Control_Apply.Apply(function () {
          return functorStateT(((dictMonad.Bind1()).Apply0()).Functor0());
      }, Control_Monad.ap(monadStateT(dictMonad)));
  };
  var applicativeStateT = function (dictMonad) {
      return new Control_Applicative.Applicative(function () {
          return applyStateT(dictMonad);
      }, function (a) {
          return function (s) {
              return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(a, s));
          };
      });
  };
  var monadStateStateT = function (dictMonad) {
      return new Control_Monad_State_Class.MonadState(function () {
          return monadStateT(dictMonad);
      }, function (f) {
          return StateT((function () {
              var $114 = Control_Applicative.pure(dictMonad.Applicative0());
              return function ($115) {
                  return $114(f($115));
              };
          })());
      });
  };
  exports["StateT"] = StateT;
  exports["runStateT"] = runStateT;
  exports["evalStateT"] = evalStateT;
  exports["functorStateT"] = functorStateT;
  exports["monadStateT"] = monadStateT;
  exports["monadTransStateT"] = monadTransStateT;
  exports["lazyStateT"] = lazyStateT;
  exports["monadStateStateT"] = monadStateStateT;
})(PS);
(function(exports) {
  exports.withCharCode = function(f) {
      return function (c) {
          return String.fromCharCode(f(c.charCodeAt()));
      }
  }
})(PS["Data.Char.Unicode"] = PS["Data.Char.Unicode"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Char"] = $PS["Data.Char"] || {};
  var exports = $PS["Data.Char"];
  var Data_Enum = $PS["Data.Enum"];                
  var toCharCode = Data_Enum.fromEnum(Data_Enum.boundedEnumChar);
  var fromCharCode = Data_Enum.toEnum(Data_Enum.boundedEnumChar);
  exports["toCharCode"] = toCharCode;
  exports["fromCharCode"] = fromCharCode;
})(PS);
(function(exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});
(function(exports) {
  /* globals exports */
  "use strict";         

  exports.infinity = Infinity;
})(PS["Global"] = PS["Global"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Global"] = $PS["Global"] || {};
  var exports = $PS["Global"];
  var $foreign = $PS["Global"];
  exports["infinity"] = $foreign.infinity;
})(PS);
(function(exports) {
  "use strict";          

  exports.floor = Math.floor;

  exports.pow = function (n) {
    return function (p) {
      return Math.pow(n, p);
    };
  };
})(PS["Math"] = PS["Math"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Math"] = $PS["Math"] || {};
  var exports = $PS["Math"];
  var $foreign = $PS["Math"];
  exports["floor"] = $foreign.floor;
  exports["pow"] = $foreign.pow;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Global = $PS["Global"];
  var $$Math = $PS["Math"];         
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var unsafeClamp = function (x) {
      if (x === Global.infinity) {
          return 0;
      };
      if (x === -Global.infinity) {
          return 0;
      };
      if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
          return Data_Bounded.top(Data_Bounded.boundedInt);
      };
      if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
          return Data_Bounded.bottom(Data_Bounded.boundedInt);
      };
      if (Data_Boolean.otherwise) {
          return Data_Maybe.fromMaybe(0)(fromNumber(x));
      };
      throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [ x.constructor.name ]);
  };
  var floor = function ($24) {
      return unsafeClamp($$Math.floor($24));
  };
  exports["floor"] = floor;
  exports["toNumber"] = $foreign.toNumber;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Char.Unicode.Internal"] = $PS["Data.Char.Unicode.Internal"] || {};
  var exports = $PS["Data.Char.Unicode.Internal"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ordering = $PS["Data.Ordering"];        
  var NUMCAT_LU = (function () {
      function NUMCAT_LU() {

      };
      NUMCAT_LU.value = new NUMCAT_LU();
      return NUMCAT_LU;
  })();
  var NUMCAT_LL = (function () {
      function NUMCAT_LL() {

      };
      NUMCAT_LL.value = new NUMCAT_LL();
      return NUMCAT_LL;
  })();
  var NUMCAT_LT = (function () {
      function NUMCAT_LT() {

      };
      NUMCAT_LT.value = new NUMCAT_LT();
      return NUMCAT_LT;
  })();
  var NUMCAT_LM = (function () {
      function NUMCAT_LM() {

      };
      NUMCAT_LM.value = new NUMCAT_LM();
      return NUMCAT_LM;
  })();
  var NUMCAT_LO = (function () {
      function NUMCAT_LO() {

      };
      NUMCAT_LO.value = new NUMCAT_LO();
      return NUMCAT_LO;
  })();
  var NUMCAT_MN = (function () {
      function NUMCAT_MN() {

      };
      NUMCAT_MN.value = new NUMCAT_MN();
      return NUMCAT_MN;
  })();
  var NUMCAT_MC = (function () {
      function NUMCAT_MC() {

      };
      NUMCAT_MC.value = new NUMCAT_MC();
      return NUMCAT_MC;
  })();
  var NUMCAT_ME = (function () {
      function NUMCAT_ME() {

      };
      NUMCAT_ME.value = new NUMCAT_ME();
      return NUMCAT_ME;
  })();
  var NUMCAT_ND = (function () {
      function NUMCAT_ND() {

      };
      NUMCAT_ND.value = new NUMCAT_ND();
      return NUMCAT_ND;
  })();
  var NUMCAT_NL = (function () {
      function NUMCAT_NL() {

      };
      NUMCAT_NL.value = new NUMCAT_NL();
      return NUMCAT_NL;
  })();
  var NUMCAT_NO = (function () {
      function NUMCAT_NO() {

      };
      NUMCAT_NO.value = new NUMCAT_NO();
      return NUMCAT_NO;
  })();
  var NUMCAT_PC = (function () {
      function NUMCAT_PC() {

      };
      NUMCAT_PC.value = new NUMCAT_PC();
      return NUMCAT_PC;
  })();
  var NUMCAT_PD = (function () {
      function NUMCAT_PD() {

      };
      NUMCAT_PD.value = new NUMCAT_PD();
      return NUMCAT_PD;
  })();
  var NUMCAT_PS = (function () {
      function NUMCAT_PS() {

      };
      NUMCAT_PS.value = new NUMCAT_PS();
      return NUMCAT_PS;
  })();
  var NUMCAT_PE = (function () {
      function NUMCAT_PE() {

      };
      NUMCAT_PE.value = new NUMCAT_PE();
      return NUMCAT_PE;
  })();
  var NUMCAT_PI = (function () {
      function NUMCAT_PI() {

      };
      NUMCAT_PI.value = new NUMCAT_PI();
      return NUMCAT_PI;
  })();
  var NUMCAT_PF = (function () {
      function NUMCAT_PF() {

      };
      NUMCAT_PF.value = new NUMCAT_PF();
      return NUMCAT_PF;
  })();
  var NUMCAT_PO = (function () {
      function NUMCAT_PO() {

      };
      NUMCAT_PO.value = new NUMCAT_PO();
      return NUMCAT_PO;
  })();
  var NUMCAT_SM = (function () {
      function NUMCAT_SM() {

      };
      NUMCAT_SM.value = new NUMCAT_SM();
      return NUMCAT_SM;
  })();
  var NUMCAT_SC = (function () {
      function NUMCAT_SC() {

      };
      NUMCAT_SC.value = new NUMCAT_SC();
      return NUMCAT_SC;
  })();
  var NUMCAT_SK = (function () {
      function NUMCAT_SK() {

      };
      NUMCAT_SK.value = new NUMCAT_SK();
      return NUMCAT_SK;
  })();
  var NUMCAT_SO = (function () {
      function NUMCAT_SO() {

      };
      NUMCAT_SO.value = new NUMCAT_SO();
      return NUMCAT_SO;
  })();
  var NUMCAT_ZS = (function () {
      function NUMCAT_ZS() {

      };
      NUMCAT_ZS.value = new NUMCAT_ZS();
      return NUMCAT_ZS;
  })();
  var NUMCAT_ZL = (function () {
      function NUMCAT_ZL() {

      };
      NUMCAT_ZL.value = new NUMCAT_ZL();
      return NUMCAT_ZL;
  })();
  var NUMCAT_ZP = (function () {
      function NUMCAT_ZP() {

      };
      NUMCAT_ZP.value = new NUMCAT_ZP();
      return NUMCAT_ZP;
  })();
  var NUMCAT_CC = (function () {
      function NUMCAT_CC() {

      };
      NUMCAT_CC.value = new NUMCAT_CC();
      return NUMCAT_CC;
  })();
  var NUMCAT_CF = (function () {
      function NUMCAT_CF() {

      };
      NUMCAT_CF.value = new NUMCAT_CF();
      return NUMCAT_CF;
  })();
  var NUMCAT_CS = (function () {
      function NUMCAT_CS() {

      };
      NUMCAT_CS.value = new NUMCAT_CS();
      return NUMCAT_CS;
  })();
  var NUMCAT_CO = (function () {
      function NUMCAT_CO() {

      };
      NUMCAT_CO.value = new NUMCAT_CO();
      return NUMCAT_CO;
  })();
  var NUMCAT_CN = (function () {
      function NUMCAT_CN() {

      };
      NUMCAT_CN.value = new NUMCAT_CN();
      return NUMCAT_CN;
  })();
  var numSpaceBlocks = 8;
  var numLat1Blocks = 63;
  var numConvBlocks = 1230;
  var numBlocks = 2783;    
  var gencatZS = 2;
  var rule1 = {
      category: gencatZS,
      unicodeCat: NUMCAT_ZS.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var spacechars = [ {
      start: 32,
      length: 1,
      convRule: rule1
  }, {
      start: 160,
      length: 1,
      convRule: rule1
  }, {
      start: 5760,
      length: 1,
      convRule: rule1
  }, {
      start: 6158,
      length: 1,
      convRule: rule1
  }, {
      start: 8192,
      length: 11,
      convRule: rule1
  }, {
      start: 8239,
      length: 1,
      convRule: rule1
  }, {
      start: 8287,
      length: 1,
      convRule: rule1
  }, {
      start: 12288,
      length: 1,
      convRule: rule1
  } ];
  var gencatZP = 67108864;
  var rule140 = {
      category: gencatZP,
      unicodeCat: NUMCAT_ZP.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatZL = 33554432;
  var rule139 = {
      category: gencatZL,
      unicodeCat: NUMCAT_ZL.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatSO = 8192;
  var rule13 = {
      category: gencatSO,
      unicodeCat: NUMCAT_SO.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var rule148 = {
      category: gencatSO,
      unicodeCat: NUMCAT_SO.value,
      possible: 1,
      updist: 0,
      lowdist: 26,
      titledist: 0
  };
  var rule149 = {
      category: gencatSO,
      unicodeCat: NUMCAT_SO.value,
      possible: 1,
      updist: -26 | 0,
      lowdist: 0,
      titledist: -26 | 0
  };
  var gencatSM = 64;
  var rule6 = {
      category: gencatSM,
      unicodeCat: NUMCAT_SM.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatSK = 1024;
  var rule10 = {
      category: gencatSK,
      unicodeCat: NUMCAT_SK.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatSC = 8;
  var rule3 = {
      category: gencatSC,
      unicodeCat: NUMCAT_SC.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPS = 16;
  var rule4 = {
      category: gencatPS,
      unicodeCat: NUMCAT_PS.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPO = 4;
  var rule2 = {
      category: gencatPO,
      unicodeCat: NUMCAT_PO.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPI = 16384;
  var rule15 = {
      category: gencatPI,
      unicodeCat: NUMCAT_PI.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPF = 131072;
  var rule19 = {
      category: gencatPF,
      unicodeCat: NUMCAT_PF.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPE = 32;
  var rule5 = {
      category: gencatPE,
      unicodeCat: NUMCAT_PE.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPD = 128;
  var rule7 = {
      category: gencatPD,
      unicodeCat: NUMCAT_PD.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatPC = 2048;
  var rule11 = {
      category: gencatPC,
      unicodeCat: NUMCAT_PC.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatNO = 65536;
  var rule17 = {
      category: gencatNO,
      unicodeCat: NUMCAT_NO.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatNL = 16777216;
  var rule116 = {
      category: gencatNL,
      unicodeCat: NUMCAT_NL.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var rule146 = {
      category: gencatNL,
      unicodeCat: NUMCAT_NL.value,
      possible: 1,
      updist: 0,
      lowdist: 16,
      titledist: 0
  };
  var rule147 = {
      category: gencatNL,
      unicodeCat: NUMCAT_NL.value,
      possible: 1,
      updist: -16 | 0,
      lowdist: 0,
      titledist: -16 | 0
  };
  var gencatND = 256;
  var rule8 = {
      category: gencatND,
      unicodeCat: NUMCAT_ND.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatMN = 2097152;
  var rule84 = {
      category: gencatMN,
      unicodeCat: NUMCAT_MN.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var rule85 = {
      category: gencatMN,
      unicodeCat: NUMCAT_MN.value,
      possible: 1,
      updist: 84,
      lowdist: 0,
      titledist: 84
  };
  var gencatME = 4194304;
  var rule109 = {
      category: gencatME,
      unicodeCat: NUMCAT_ME.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatMC = 8388608;
  var rule114 = {
      category: gencatMC,
      unicodeCat: NUMCAT_MC.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatLU = 512;
  var nullrule = {
      category: gencatLU,
      unicodeCat: NUMCAT_CN.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var rule105 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -60 | 0,
      titledist: 0
  };
  var rule107 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -7 | 0,
      titledist: 0
  };
  var rule108 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 80,
      titledist: 0
  };
  var rule110 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 15,
      titledist: 0
  };
  var rule112 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 48,
      titledist: 0
  };
  var rule115 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 7264,
      titledist: 0
  };
  var rule120 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -7615 | 0,
      titledist: 0
  };
  var rule122 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -8 | 0,
      titledist: 0
  };
  var rule131 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -74 | 0,
      titledist: 0
  };
  var rule134 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -86 | 0,
      titledist: 0
  };
  var rule135 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -100 | 0,
      titledist: 0
  };
  var rule136 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -112 | 0,
      titledist: 0
  };
  var rule137 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -128 | 0,
      titledist: 0
  };
  var rule138 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -126 | 0,
      titledist: 0
  };
  var rule141 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -7517 | 0,
      titledist: 0
  };
  var rule142 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -8383 | 0,
      titledist: 0
  };
  var rule143 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -8262 | 0,
      titledist: 0
  };
  var rule144 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 28,
      titledist: 0
  };
  var rule150 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10743 | 0,
      titledist: 0
  };
  var rule151 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -3814 | 0,
      titledist: 0
  };
  var rule152 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10727 | 0,
      titledist: 0
  };
  var rule155 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10780 | 0,
      titledist: 0
  };
  var rule156 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10749 | 0,
      titledist: 0
  };
  var rule157 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10783 | 0,
      titledist: 0
  };
  var rule158 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10782 | 0,
      titledist: 0
  };
  var rule159 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -10815 | 0,
      titledist: 0
  };
  var rule161 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -35332 | 0,
      titledist: 0
  };
  var rule162 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -42280 | 0,
      titledist: 0
  };
  var rule165 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 40,
      titledist: 0
  };
  var rule21 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 1,
      titledist: 0
  };
  var rule23 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -199 | 0,
      titledist: 0
  };
  var rule25 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -121 | 0,
      titledist: 0
  };
  var rule28 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 210,
      titledist: 0
  };
  var rule29 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 206,
      titledist: 0
  };
  var rule30 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 205,
      titledist: 0
  };
  var rule31 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 79,
      titledist: 0
  };
  var rule32 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 202,
      titledist: 0
  };
  var rule33 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 203,
      titledist: 0
  };
  var rule34 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 207,
      titledist: 0
  };
  var rule36 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 211,
      titledist: 0
  };
  var rule37 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 209,
      titledist: 0
  };
  var rule39 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 213,
      titledist: 0
  };
  var rule41 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 214,
      titledist: 0
  };
  var rule42 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 218,
      titledist: 0
  };
  var rule43 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 217,
      titledist: 0
  };
  var rule44 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 219,
      titledist: 0
  };
  var rule47 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 2,
      titledist: 1
  };
  var rule51 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -97 | 0,
      titledist: 0
  };
  var rule52 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -56 | 0,
      titledist: 0
  };
  var rule53 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -130 | 0,
      titledist: 0
  };
  var rule54 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 10795,
      titledist: 0
  };
  var rule55 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -163 | 0,
      titledist: 0
  };
  var rule56 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 10792,
      titledist: 0
  };
  var rule58 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: -195 | 0,
      titledist: 0
  };
  var rule59 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 69,
      titledist: 0
  };
  var rule60 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 71,
      titledist: 0
  };
  var rule86 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 38,
      titledist: 0
  };
  var rule87 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 37,
      titledist: 0
  };
  var rule88 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 64,
      titledist: 0
  };
  var rule89 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 63,
      titledist: 0
  };
  var rule9 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 32,
      titledist: 0
  };
  var rule95 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 1,
      updist: 0,
      lowdist: 8,
      titledist: 0
  };
  var rule98 = {
      category: gencatLU,
      unicodeCat: NUMCAT_LU.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatLT = 524288;
  var rule129 = {
      category: gencatLT,
      unicodeCat: NUMCAT_LT.value,
      possible: 1,
      updist: 0,
      lowdist: -8 | 0,
      titledist: 0
  };
  var rule132 = {
      category: gencatLT,
      unicodeCat: NUMCAT_LT.value,
      possible: 1,
      updist: 0,
      lowdist: -9 | 0,
      titledist: 0
  };
  var rule48 = {
      category: gencatLT,
      unicodeCat: NUMCAT_LT.value,
      possible: 1,
      updist: -1 | 0,
      lowdist: 1,
      titledist: 0
  };
  var gencatLO = 262144;
  var rule45 = {
      category: gencatLO,
      unicodeCat: NUMCAT_LO.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatLM = 1048576;
  var rule83 = {
      category: gencatLM,
      unicodeCat: NUMCAT_LM.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatLL = 4096;
  var rule100 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -54 | 0,
      lowdist: 0,
      titledist: -54 | 0
  };
  var rule101 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -8 | 0,
      lowdist: 0,
      titledist: -8 | 0
  };
  var rule102 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -86 | 0,
      lowdist: 0,
      titledist: -86 | 0
  };
  var rule103 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -80 | 0,
      lowdist: 0,
      titledist: -80 | 0
  };
  var rule104 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 7,
      lowdist: 0,
      titledist: 7
  };
  var rule106 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -96 | 0,
      lowdist: 0,
      titledist: -96 | 0
  };
  var rule111 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -15 | 0,
      lowdist: 0,
      titledist: -15 | 0
  };
  var rule113 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -48 | 0,
      lowdist: 0,
      titledist: -48 | 0
  };
  var rule117 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 35332,
      lowdist: 0,
      titledist: 35332
  };
  var rule118 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 3814,
      lowdist: 0,
      titledist: 3814
  };
  var rule119 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -59 | 0,
      lowdist: 0,
      titledist: -59 | 0
  };
  var rule12 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -32 | 0,
      lowdist: 0,
      titledist: -32 | 0
  };
  var rule121 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 8,
      lowdist: 0,
      titledist: 8
  };
  var rule123 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 74,
      lowdist: 0,
      titledist: 74
  };
  var rule124 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 86,
      lowdist: 0,
      titledist: 86
  };
  var rule125 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 100,
      lowdist: 0,
      titledist: 100
  };
  var rule126 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 128,
      lowdist: 0,
      titledist: 128
  };
  var rule127 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 112,
      lowdist: 0,
      titledist: 112
  };
  var rule128 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 126,
      lowdist: 0,
      titledist: 126
  };
  var rule130 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 9,
      lowdist: 0,
      titledist: 9
  };
  var rule133 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -7205 | 0,
      lowdist: 0,
      titledist: -7205 | 0
  };
  var rule14 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var rule145 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -28 | 0,
      lowdist: 0,
      titledist: -28 | 0
  };
  var rule153 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -10795 | 0,
      lowdist: 0,
      titledist: -10795 | 0
  };
  var rule154 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -10792 | 0,
      lowdist: 0,
      titledist: -10792 | 0
  };
  var rule160 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -7264 | 0,
      lowdist: 0,
      titledist: -7264 | 0
  };
  var rule166 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -40 | 0,
      lowdist: 0,
      titledist: -40 | 0
  };
  var rule18 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 743,
      lowdist: 0,
      titledist: 743
  };
  var rule20 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 121,
      lowdist: 0,
      titledist: 121
  };
  var rule22 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -1 | 0,
      lowdist: 0,
      titledist: -1 | 0
  };
  var rule24 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -232 | 0,
      lowdist: 0,
      titledist: -232 | 0
  };
  var rule26 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -300 | 0,
      lowdist: 0,
      titledist: -300 | 0
  };
  var rule27 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 195,
      lowdist: 0,
      titledist: 195
  };
  var rule35 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 97,
      lowdist: 0,
      titledist: 97
  };
  var rule38 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 163,
      lowdist: 0,
      titledist: 163
  };
  var rule40 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 130,
      lowdist: 0,
      titledist: 130
  };
  var rule46 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 56,
      lowdist: 0,
      titledist: 56
  };
  var rule49 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -2 | 0,
      lowdist: 0,
      titledist: -1 | 0
  };
  var rule50 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -79 | 0,
      lowdist: 0,
      titledist: -79 | 0
  };
  var rule57 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10815,
      lowdist: 0,
      titledist: 10815
  };
  var rule61 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10783,
      lowdist: 0,
      titledist: 10783
  };
  var rule62 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10780,
      lowdist: 0,
      titledist: 10780
  };
  var rule63 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10782,
      lowdist: 0,
      titledist: 10782
  };
  var rule64 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -210 | 0,
      lowdist: 0,
      titledist: -210 | 0
  };
  var rule65 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -206 | 0,
      lowdist: 0,
      titledist: -206 | 0
  };
  var rule66 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -205 | 0,
      lowdist: 0,
      titledist: -205 | 0
  };
  var rule67 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -202 | 0,
      lowdist: 0,
      titledist: -202 | 0
  };
  var rule68 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -203 | 0,
      lowdist: 0,
      titledist: -203 | 0
  };
  var rule69 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -207 | 0,
      lowdist: 0,
      titledist: -207 | 0
  };
  var rule70 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 42280,
      lowdist: 0,
      titledist: 42280
  };
  var rule71 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -209 | 0,
      lowdist: 0,
      titledist: -209 | 0
  };
  var rule72 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -211 | 0,
      lowdist: 0,
      titledist: -211 | 0
  };
  var rule73 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10743,
      lowdist: 0,
      titledist: 10743
  };
  var rule74 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10749,
      lowdist: 0,
      titledist: 10749
  };
  var rule75 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -213 | 0,
      lowdist: 0,
      titledist: -213 | 0
  };
  var rule76 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -214 | 0,
      lowdist: 0,
      titledist: -214 | 0
  };
  var rule77 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: 10727,
      lowdist: 0,
      titledist: 10727
  };
  var rule78 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -218 | 0,
      lowdist: 0,
      titledist: -218 | 0
  };
  var rule79 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -69 | 0,
      lowdist: 0,
      titledist: -69 | 0
  };
  var rule80 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -217 | 0,
      lowdist: 0,
      titledist: -217 | 0
  };
  var rule81 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -71 | 0,
      lowdist: 0,
      titledist: -71 | 0
  };
  var rule82 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -219 | 0,
      lowdist: 0,
      titledist: -219 | 0
  };
  var rule90 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -38 | 0,
      lowdist: 0,
      titledist: -38 | 0
  };
  var rule91 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -37 | 0,
      lowdist: 0,
      titledist: -37 | 0
  };
  var rule92 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -31 | 0,
      lowdist: 0,
      titledist: -31 | 0
  };
  var rule93 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -64 | 0,
      lowdist: 0,
      titledist: -64 | 0
  };
  var rule94 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -63 | 0,
      lowdist: 0,
      titledist: -63 | 0
  };
  var rule96 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -62 | 0,
      lowdist: 0,
      titledist: -62 | 0
  };
  var rule97 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -57 | 0,
      lowdist: 0,
      titledist: -57 | 0
  };
  var rule99 = {
      category: gencatLL,
      unicodeCat: NUMCAT_LL.value,
      possible: 1,
      updist: -47 | 0,
      lowdist: 0,
      titledist: -47 | 0
  };
  var gencatCS = 134217728;
  var rule163 = {
      category: gencatCS,
      unicodeCat: NUMCAT_CS.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatCO = 268435456;
  var rule164 = {
      category: gencatCO,
      unicodeCat: NUMCAT_CO.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatCF = 32768;
  var rule16 = {
      category: gencatCF,
      unicodeCat: NUMCAT_CF.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var gencatCC = 1;
  var rule0 = {
      category: gencatCC,
      unicodeCat: NUMCAT_CC.value,
      possible: 0,
      updist: 0,
      lowdist: 0,
      titledist: 0
  };
  var convchars = [ {
      start: 65,
      length: 26,
      convRule: rule9
  }, {
      start: 97,
      length: 26,
      convRule: rule12
  }, {
      start: 181,
      length: 1,
      convRule: rule18
  }, {
      start: 192,
      length: 23,
      convRule: rule9
  }, {
      start: 216,
      length: 7,
      convRule: rule9
  }, {
      start: 224,
      length: 23,
      convRule: rule12
  }, {
      start: 248,
      length: 7,
      convRule: rule12
  }, {
      start: 255,
      length: 1,
      convRule: rule20
  }, {
      start: 256,
      length: 1,
      convRule: rule21
  }, {
      start: 257,
      length: 1,
      convRule: rule22
  }, {
      start: 258,
      length: 1,
      convRule: rule21
  }, {
      start: 259,
      length: 1,
      convRule: rule22
  }, {
      start: 260,
      length: 1,
      convRule: rule21
  }, {
      start: 261,
      length: 1,
      convRule: rule22
  }, {
      start: 262,
      length: 1,
      convRule: rule21
  }, {
      start: 263,
      length: 1,
      convRule: rule22
  }, {
      start: 264,
      length: 1,
      convRule: rule21
  }, {
      start: 265,
      length: 1,
      convRule: rule22
  }, {
      start: 266,
      length: 1,
      convRule: rule21
  }, {
      start: 267,
      length: 1,
      convRule: rule22
  }, {
      start: 268,
      length: 1,
      convRule: rule21
  }, {
      start: 269,
      length: 1,
      convRule: rule22
  }, {
      start: 270,
      length: 1,
      convRule: rule21
  }, {
      start: 271,
      length: 1,
      convRule: rule22
  }, {
      start: 272,
      length: 1,
      convRule: rule21
  }, {
      start: 273,
      length: 1,
      convRule: rule22
  }, {
      start: 274,
      length: 1,
      convRule: rule21
  }, {
      start: 275,
      length: 1,
      convRule: rule22
  }, {
      start: 276,
      length: 1,
      convRule: rule21
  }, {
      start: 277,
      length: 1,
      convRule: rule22
  }, {
      start: 278,
      length: 1,
      convRule: rule21
  }, {
      start: 279,
      length: 1,
      convRule: rule22
  }, {
      start: 280,
      length: 1,
      convRule: rule21
  }, {
      start: 281,
      length: 1,
      convRule: rule22
  }, {
      start: 282,
      length: 1,
      convRule: rule21
  }, {
      start: 283,
      length: 1,
      convRule: rule22
  }, {
      start: 284,
      length: 1,
      convRule: rule21
  }, {
      start: 285,
      length: 1,
      convRule: rule22
  }, {
      start: 286,
      length: 1,
      convRule: rule21
  }, {
      start: 287,
      length: 1,
      convRule: rule22
  }, {
      start: 288,
      length: 1,
      convRule: rule21
  }, {
      start: 289,
      length: 1,
      convRule: rule22
  }, {
      start: 290,
      length: 1,
      convRule: rule21
  }, {
      start: 291,
      length: 1,
      convRule: rule22
  }, {
      start: 292,
      length: 1,
      convRule: rule21
  }, {
      start: 293,
      length: 1,
      convRule: rule22
  }, {
      start: 294,
      length: 1,
      convRule: rule21
  }, {
      start: 295,
      length: 1,
      convRule: rule22
  }, {
      start: 296,
      length: 1,
      convRule: rule21
  }, {
      start: 297,
      length: 1,
      convRule: rule22
  }, {
      start: 298,
      length: 1,
      convRule: rule21
  }, {
      start: 299,
      length: 1,
      convRule: rule22
  }, {
      start: 300,
      length: 1,
      convRule: rule21
  }, {
      start: 301,
      length: 1,
      convRule: rule22
  }, {
      start: 302,
      length: 1,
      convRule: rule21
  }, {
      start: 303,
      length: 1,
      convRule: rule22
  }, {
      start: 304,
      length: 1,
      convRule: rule23
  }, {
      start: 305,
      length: 1,
      convRule: rule24
  }, {
      start: 306,
      length: 1,
      convRule: rule21
  }, {
      start: 307,
      length: 1,
      convRule: rule22
  }, {
      start: 308,
      length: 1,
      convRule: rule21
  }, {
      start: 309,
      length: 1,
      convRule: rule22
  }, {
      start: 310,
      length: 1,
      convRule: rule21
  }, {
      start: 311,
      length: 1,
      convRule: rule22
  }, {
      start: 313,
      length: 1,
      convRule: rule21
  }, {
      start: 314,
      length: 1,
      convRule: rule22
  }, {
      start: 315,
      length: 1,
      convRule: rule21
  }, {
      start: 316,
      length: 1,
      convRule: rule22
  }, {
      start: 317,
      length: 1,
      convRule: rule21
  }, {
      start: 318,
      length: 1,
      convRule: rule22
  }, {
      start: 319,
      length: 1,
      convRule: rule21
  }, {
      start: 320,
      length: 1,
      convRule: rule22
  }, {
      start: 321,
      length: 1,
      convRule: rule21
  }, {
      start: 322,
      length: 1,
      convRule: rule22
  }, {
      start: 323,
      length: 1,
      convRule: rule21
  }, {
      start: 324,
      length: 1,
      convRule: rule22
  }, {
      start: 325,
      length: 1,
      convRule: rule21
  }, {
      start: 326,
      length: 1,
      convRule: rule22
  }, {
      start: 327,
      length: 1,
      convRule: rule21
  }, {
      start: 328,
      length: 1,
      convRule: rule22
  }, {
      start: 330,
      length: 1,
      convRule: rule21
  }, {
      start: 331,
      length: 1,
      convRule: rule22
  }, {
      start: 332,
      length: 1,
      convRule: rule21
  }, {
      start: 333,
      length: 1,
      convRule: rule22
  }, {
      start: 334,
      length: 1,
      convRule: rule21
  }, {
      start: 335,
      length: 1,
      convRule: rule22
  }, {
      start: 336,
      length: 1,
      convRule: rule21
  }, {
      start: 337,
      length: 1,
      convRule: rule22
  }, {
      start: 338,
      length: 1,
      convRule: rule21
  }, {
      start: 339,
      length: 1,
      convRule: rule22
  }, {
      start: 340,
      length: 1,
      convRule: rule21
  }, {
      start: 341,
      length: 1,
      convRule: rule22
  }, {
      start: 342,
      length: 1,
      convRule: rule21
  }, {
      start: 343,
      length: 1,
      convRule: rule22
  }, {
      start: 344,
      length: 1,
      convRule: rule21
  }, {
      start: 345,
      length: 1,
      convRule: rule22
  }, {
      start: 346,
      length: 1,
      convRule: rule21
  }, {
      start: 347,
      length: 1,
      convRule: rule22
  }, {
      start: 348,
      length: 1,
      convRule: rule21
  }, {
      start: 349,
      length: 1,
      convRule: rule22
  }, {
      start: 350,
      length: 1,
      convRule: rule21
  }, {
      start: 351,
      length: 1,
      convRule: rule22
  }, {
      start: 352,
      length: 1,
      convRule: rule21
  }, {
      start: 353,
      length: 1,
      convRule: rule22
  }, {
      start: 354,
      length: 1,
      convRule: rule21
  }, {
      start: 355,
      length: 1,
      convRule: rule22
  }, {
      start: 356,
      length: 1,
      convRule: rule21
  }, {
      start: 357,
      length: 1,
      convRule: rule22
  }, {
      start: 358,
      length: 1,
      convRule: rule21
  }, {
      start: 359,
      length: 1,
      convRule: rule22
  }, {
      start: 360,
      length: 1,
      convRule: rule21
  }, {
      start: 361,
      length: 1,
      convRule: rule22
  }, {
      start: 362,
      length: 1,
      convRule: rule21
  }, {
      start: 363,
      length: 1,
      convRule: rule22
  }, {
      start: 364,
      length: 1,
      convRule: rule21
  }, {
      start: 365,
      length: 1,
      convRule: rule22
  }, {
      start: 366,
      length: 1,
      convRule: rule21
  }, {
      start: 367,
      length: 1,
      convRule: rule22
  }, {
      start: 368,
      length: 1,
      convRule: rule21
  }, {
      start: 369,
      length: 1,
      convRule: rule22
  }, {
      start: 370,
      length: 1,
      convRule: rule21
  }, {
      start: 371,
      length: 1,
      convRule: rule22
  }, {
      start: 372,
      length: 1,
      convRule: rule21
  }, {
      start: 373,
      length: 1,
      convRule: rule22
  }, {
      start: 374,
      length: 1,
      convRule: rule21
  }, {
      start: 375,
      length: 1,
      convRule: rule22
  }, {
      start: 376,
      length: 1,
      convRule: rule25
  }, {
      start: 377,
      length: 1,
      convRule: rule21
  }, {
      start: 378,
      length: 1,
      convRule: rule22
  }, {
      start: 379,
      length: 1,
      convRule: rule21
  }, {
      start: 380,
      length: 1,
      convRule: rule22
  }, {
      start: 381,
      length: 1,
      convRule: rule21
  }, {
      start: 382,
      length: 1,
      convRule: rule22
  }, {
      start: 383,
      length: 1,
      convRule: rule26
  }, {
      start: 384,
      length: 1,
      convRule: rule27
  }, {
      start: 385,
      length: 1,
      convRule: rule28
  }, {
      start: 386,
      length: 1,
      convRule: rule21
  }, {
      start: 387,
      length: 1,
      convRule: rule22
  }, {
      start: 388,
      length: 1,
      convRule: rule21
  }, {
      start: 389,
      length: 1,
      convRule: rule22
  }, {
      start: 390,
      length: 1,
      convRule: rule29
  }, {
      start: 391,
      length: 1,
      convRule: rule21
  }, {
      start: 392,
      length: 1,
      convRule: rule22
  }, {
      start: 393,
      length: 2,
      convRule: rule30
  }, {
      start: 395,
      length: 1,
      convRule: rule21
  }, {
      start: 396,
      length: 1,
      convRule: rule22
  }, {
      start: 398,
      length: 1,
      convRule: rule31
  }, {
      start: 399,
      length: 1,
      convRule: rule32
  }, {
      start: 400,
      length: 1,
      convRule: rule33
  }, {
      start: 401,
      length: 1,
      convRule: rule21
  }, {
      start: 402,
      length: 1,
      convRule: rule22
  }, {
      start: 403,
      length: 1,
      convRule: rule30
  }, {
      start: 404,
      length: 1,
      convRule: rule34
  }, {
      start: 405,
      length: 1,
      convRule: rule35
  }, {
      start: 406,
      length: 1,
      convRule: rule36
  }, {
      start: 407,
      length: 1,
      convRule: rule37
  }, {
      start: 408,
      length: 1,
      convRule: rule21
  }, {
      start: 409,
      length: 1,
      convRule: rule22
  }, {
      start: 410,
      length: 1,
      convRule: rule38
  }, {
      start: 412,
      length: 1,
      convRule: rule36
  }, {
      start: 413,
      length: 1,
      convRule: rule39
  }, {
      start: 414,
      length: 1,
      convRule: rule40
  }, {
      start: 415,
      length: 1,
      convRule: rule41
  }, {
      start: 416,
      length: 1,
      convRule: rule21
  }, {
      start: 417,
      length: 1,
      convRule: rule22
  }, {
      start: 418,
      length: 1,
      convRule: rule21
  }, {
      start: 419,
      length: 1,
      convRule: rule22
  }, {
      start: 420,
      length: 1,
      convRule: rule21
  }, {
      start: 421,
      length: 1,
      convRule: rule22
  }, {
      start: 422,
      length: 1,
      convRule: rule42
  }, {
      start: 423,
      length: 1,
      convRule: rule21
  }, {
      start: 424,
      length: 1,
      convRule: rule22
  }, {
      start: 425,
      length: 1,
      convRule: rule42
  }, {
      start: 428,
      length: 1,
      convRule: rule21
  }, {
      start: 429,
      length: 1,
      convRule: rule22
  }, {
      start: 430,
      length: 1,
      convRule: rule42
  }, {
      start: 431,
      length: 1,
      convRule: rule21
  }, {
      start: 432,
      length: 1,
      convRule: rule22
  }, {
      start: 433,
      length: 2,
      convRule: rule43
  }, {
      start: 435,
      length: 1,
      convRule: rule21
  }, {
      start: 436,
      length: 1,
      convRule: rule22
  }, {
      start: 437,
      length: 1,
      convRule: rule21
  }, {
      start: 438,
      length: 1,
      convRule: rule22
  }, {
      start: 439,
      length: 1,
      convRule: rule44
  }, {
      start: 440,
      length: 1,
      convRule: rule21
  }, {
      start: 441,
      length: 1,
      convRule: rule22
  }, {
      start: 444,
      length: 1,
      convRule: rule21
  }, {
      start: 445,
      length: 1,
      convRule: rule22
  }, {
      start: 447,
      length: 1,
      convRule: rule46
  }, {
      start: 452,
      length: 1,
      convRule: rule47
  }, {
      start: 453,
      length: 1,
      convRule: rule48
  }, {
      start: 454,
      length: 1,
      convRule: rule49
  }, {
      start: 455,
      length: 1,
      convRule: rule47
  }, {
      start: 456,
      length: 1,
      convRule: rule48
  }, {
      start: 457,
      length: 1,
      convRule: rule49
  }, {
      start: 458,
      length: 1,
      convRule: rule47
  }, {
      start: 459,
      length: 1,
      convRule: rule48
  }, {
      start: 460,
      length: 1,
      convRule: rule49
  }, {
      start: 461,
      length: 1,
      convRule: rule21
  }, {
      start: 462,
      length: 1,
      convRule: rule22
  }, {
      start: 463,
      length: 1,
      convRule: rule21
  }, {
      start: 464,
      length: 1,
      convRule: rule22
  }, {
      start: 465,
      length: 1,
      convRule: rule21
  }, {
      start: 466,
      length: 1,
      convRule: rule22
  }, {
      start: 467,
      length: 1,
      convRule: rule21
  }, {
      start: 468,
      length: 1,
      convRule: rule22
  }, {
      start: 469,
      length: 1,
      convRule: rule21
  }, {
      start: 470,
      length: 1,
      convRule: rule22
  }, {
      start: 471,
      length: 1,
      convRule: rule21
  }, {
      start: 472,
      length: 1,
      convRule: rule22
  }, {
      start: 473,
      length: 1,
      convRule: rule21
  }, {
      start: 474,
      length: 1,
      convRule: rule22
  }, {
      start: 475,
      length: 1,
      convRule: rule21
  }, {
      start: 476,
      length: 1,
      convRule: rule22
  }, {
      start: 477,
      length: 1,
      convRule: rule50
  }, {
      start: 478,
      length: 1,
      convRule: rule21
  }, {
      start: 479,
      length: 1,
      convRule: rule22
  }, {
      start: 480,
      length: 1,
      convRule: rule21
  }, {
      start: 481,
      length: 1,
      convRule: rule22
  }, {
      start: 482,
      length: 1,
      convRule: rule21
  }, {
      start: 483,
      length: 1,
      convRule: rule22
  }, {
      start: 484,
      length: 1,
      convRule: rule21
  }, {
      start: 485,
      length: 1,
      convRule: rule22
  }, {
      start: 486,
      length: 1,
      convRule: rule21
  }, {
      start: 487,
      length: 1,
      convRule: rule22
  }, {
      start: 488,
      length: 1,
      convRule: rule21
  }, {
      start: 489,
      length: 1,
      convRule: rule22
  }, {
      start: 490,
      length: 1,
      convRule: rule21
  }, {
      start: 491,
      length: 1,
      convRule: rule22
  }, {
      start: 492,
      length: 1,
      convRule: rule21
  }, {
      start: 493,
      length: 1,
      convRule: rule22
  }, {
      start: 494,
      length: 1,
      convRule: rule21
  }, {
      start: 495,
      length: 1,
      convRule: rule22
  }, {
      start: 497,
      length: 1,
      convRule: rule47
  }, {
      start: 498,
      length: 1,
      convRule: rule48
  }, {
      start: 499,
      length: 1,
      convRule: rule49
  }, {
      start: 500,
      length: 1,
      convRule: rule21
  }, {
      start: 501,
      length: 1,
      convRule: rule22
  }, {
      start: 502,
      length: 1,
      convRule: rule51
  }, {
      start: 503,
      length: 1,
      convRule: rule52
  }, {
      start: 504,
      length: 1,
      convRule: rule21
  }, {
      start: 505,
      length: 1,
      convRule: rule22
  }, {
      start: 506,
      length: 1,
      convRule: rule21
  }, {
      start: 507,
      length: 1,
      convRule: rule22
  }, {
      start: 508,
      length: 1,
      convRule: rule21
  }, {
      start: 509,
      length: 1,
      convRule: rule22
  }, {
      start: 510,
      length: 1,
      convRule: rule21
  }, {
      start: 511,
      length: 1,
      convRule: rule22
  }, {
      start: 512,
      length: 1,
      convRule: rule21
  }, {
      start: 513,
      length: 1,
      convRule: rule22
  }, {
      start: 514,
      length: 1,
      convRule: rule21
  }, {
      start: 515,
      length: 1,
      convRule: rule22
  }, {
      start: 516,
      length: 1,
      convRule: rule21
  }, {
      start: 517,
      length: 1,
      convRule: rule22
  }, {
      start: 518,
      length: 1,
      convRule: rule21
  }, {
      start: 519,
      length: 1,
      convRule: rule22
  }, {
      start: 520,
      length: 1,
      convRule: rule21
  }, {
      start: 521,
      length: 1,
      convRule: rule22
  }, {
      start: 522,
      length: 1,
      convRule: rule21
  }, {
      start: 523,
      length: 1,
      convRule: rule22
  }, {
      start: 524,
      length: 1,
      convRule: rule21
  }, {
      start: 525,
      length: 1,
      convRule: rule22
  }, {
      start: 526,
      length: 1,
      convRule: rule21
  }, {
      start: 527,
      length: 1,
      convRule: rule22
  }, {
      start: 528,
      length: 1,
      convRule: rule21
  }, {
      start: 529,
      length: 1,
      convRule: rule22
  }, {
      start: 530,
      length: 1,
      convRule: rule21
  }, {
      start: 531,
      length: 1,
      convRule: rule22
  }, {
      start: 532,
      length: 1,
      convRule: rule21
  }, {
      start: 533,
      length: 1,
      convRule: rule22
  }, {
      start: 534,
      length: 1,
      convRule: rule21
  }, {
      start: 535,
      length: 1,
      convRule: rule22
  }, {
      start: 536,
      length: 1,
      convRule: rule21
  }, {
      start: 537,
      length: 1,
      convRule: rule22
  }, {
      start: 538,
      length: 1,
      convRule: rule21
  }, {
      start: 539,
      length: 1,
      convRule: rule22
  }, {
      start: 540,
      length: 1,
      convRule: rule21
  }, {
      start: 541,
      length: 1,
      convRule: rule22
  }, {
      start: 542,
      length: 1,
      convRule: rule21
  }, {
      start: 543,
      length: 1,
      convRule: rule22
  }, {
      start: 544,
      length: 1,
      convRule: rule53
  }, {
      start: 546,
      length: 1,
      convRule: rule21
  }, {
      start: 547,
      length: 1,
      convRule: rule22
  }, {
      start: 548,
      length: 1,
      convRule: rule21
  }, {
      start: 549,
      length: 1,
      convRule: rule22
  }, {
      start: 550,
      length: 1,
      convRule: rule21
  }, {
      start: 551,
      length: 1,
      convRule: rule22
  }, {
      start: 552,
      length: 1,
      convRule: rule21
  }, {
      start: 553,
      length: 1,
      convRule: rule22
  }, {
      start: 554,
      length: 1,
      convRule: rule21
  }, {
      start: 555,
      length: 1,
      convRule: rule22
  }, {
      start: 556,
      length: 1,
      convRule: rule21
  }, {
      start: 557,
      length: 1,
      convRule: rule22
  }, {
      start: 558,
      length: 1,
      convRule: rule21
  }, {
      start: 559,
      length: 1,
      convRule: rule22
  }, {
      start: 560,
      length: 1,
      convRule: rule21
  }, {
      start: 561,
      length: 1,
      convRule: rule22
  }, {
      start: 562,
      length: 1,
      convRule: rule21
  }, {
      start: 563,
      length: 1,
      convRule: rule22
  }, {
      start: 570,
      length: 1,
      convRule: rule54
  }, {
      start: 571,
      length: 1,
      convRule: rule21
  }, {
      start: 572,
      length: 1,
      convRule: rule22
  }, {
      start: 573,
      length: 1,
      convRule: rule55
  }, {
      start: 574,
      length: 1,
      convRule: rule56
  }, {
      start: 575,
      length: 2,
      convRule: rule57
  }, {
      start: 577,
      length: 1,
      convRule: rule21
  }, {
      start: 578,
      length: 1,
      convRule: rule22
  }, {
      start: 579,
      length: 1,
      convRule: rule58
  }, {
      start: 580,
      length: 1,
      convRule: rule59
  }, {
      start: 581,
      length: 1,
      convRule: rule60
  }, {
      start: 582,
      length: 1,
      convRule: rule21
  }, {
      start: 583,
      length: 1,
      convRule: rule22
  }, {
      start: 584,
      length: 1,
      convRule: rule21
  }, {
      start: 585,
      length: 1,
      convRule: rule22
  }, {
      start: 586,
      length: 1,
      convRule: rule21
  }, {
      start: 587,
      length: 1,
      convRule: rule22
  }, {
      start: 588,
      length: 1,
      convRule: rule21
  }, {
      start: 589,
      length: 1,
      convRule: rule22
  }, {
      start: 590,
      length: 1,
      convRule: rule21
  }, {
      start: 591,
      length: 1,
      convRule: rule22
  }, {
      start: 592,
      length: 1,
      convRule: rule61
  }, {
      start: 593,
      length: 1,
      convRule: rule62
  }, {
      start: 594,
      length: 1,
      convRule: rule63
  }, {
      start: 595,
      length: 1,
      convRule: rule64
  }, {
      start: 596,
      length: 1,
      convRule: rule65
  }, {
      start: 598,
      length: 2,
      convRule: rule66
  }, {
      start: 601,
      length: 1,
      convRule: rule67
  }, {
      start: 603,
      length: 1,
      convRule: rule68
  }, {
      start: 608,
      length: 1,
      convRule: rule66
  }, {
      start: 611,
      length: 1,
      convRule: rule69
  }, {
      start: 613,
      length: 1,
      convRule: rule70
  }, {
      start: 616,
      length: 1,
      convRule: rule71
  }, {
      start: 617,
      length: 1,
      convRule: rule72
  }, {
      start: 619,
      length: 1,
      convRule: rule73
  }, {
      start: 623,
      length: 1,
      convRule: rule72
  }, {
      start: 625,
      length: 1,
      convRule: rule74
  }, {
      start: 626,
      length: 1,
      convRule: rule75
  }, {
      start: 629,
      length: 1,
      convRule: rule76
  }, {
      start: 637,
      length: 1,
      convRule: rule77
  }, {
      start: 640,
      length: 1,
      convRule: rule78
  }, {
      start: 643,
      length: 1,
      convRule: rule78
  }, {
      start: 648,
      length: 1,
      convRule: rule78
  }, {
      start: 649,
      length: 1,
      convRule: rule79
  }, {
      start: 650,
      length: 2,
      convRule: rule80
  }, {
      start: 652,
      length: 1,
      convRule: rule81
  }, {
      start: 658,
      length: 1,
      convRule: rule82
  }, {
      start: 837,
      length: 1,
      convRule: rule85
  }, {
      start: 880,
      length: 1,
      convRule: rule21
  }, {
      start: 881,
      length: 1,
      convRule: rule22
  }, {
      start: 882,
      length: 1,
      convRule: rule21
  }, {
      start: 883,
      length: 1,
      convRule: rule22
  }, {
      start: 886,
      length: 1,
      convRule: rule21
  }, {
      start: 887,
      length: 1,
      convRule: rule22
  }, {
      start: 891,
      length: 3,
      convRule: rule40
  }, {
      start: 902,
      length: 1,
      convRule: rule86
  }, {
      start: 904,
      length: 3,
      convRule: rule87
  }, {
      start: 908,
      length: 1,
      convRule: rule88
  }, {
      start: 910,
      length: 2,
      convRule: rule89
  }, {
      start: 913,
      length: 17,
      convRule: rule9
  }, {
      start: 931,
      length: 9,
      convRule: rule9
  }, {
      start: 940,
      length: 1,
      convRule: rule90
  }, {
      start: 941,
      length: 3,
      convRule: rule91
  }, {
      start: 945,
      length: 17,
      convRule: rule12
  }, {
      start: 962,
      length: 1,
      convRule: rule92
  }, {
      start: 963,
      length: 9,
      convRule: rule12
  }, {
      start: 972,
      length: 1,
      convRule: rule93
  }, {
      start: 973,
      length: 2,
      convRule: rule94
  }, {
      start: 975,
      length: 1,
      convRule: rule95
  }, {
      start: 976,
      length: 1,
      convRule: rule96
  }, {
      start: 977,
      length: 1,
      convRule: rule97
  }, {
      start: 981,
      length: 1,
      convRule: rule99
  }, {
      start: 982,
      length: 1,
      convRule: rule100
  }, {
      start: 983,
      length: 1,
      convRule: rule101
  }, {
      start: 984,
      length: 1,
      convRule: rule21
  }, {
      start: 985,
      length: 1,
      convRule: rule22
  }, {
      start: 986,
      length: 1,
      convRule: rule21
  }, {
      start: 987,
      length: 1,
      convRule: rule22
  }, {
      start: 988,
      length: 1,
      convRule: rule21
  }, {
      start: 989,
      length: 1,
      convRule: rule22
  }, {
      start: 990,
      length: 1,
      convRule: rule21
  }, {
      start: 991,
      length: 1,
      convRule: rule22
  }, {
      start: 992,
      length: 1,
      convRule: rule21
  }, {
      start: 993,
      length: 1,
      convRule: rule22
  }, {
      start: 994,
      length: 1,
      convRule: rule21
  }, {
      start: 995,
      length: 1,
      convRule: rule22
  }, {
      start: 996,
      length: 1,
      convRule: rule21
  }, {
      start: 997,
      length: 1,
      convRule: rule22
  }, {
      start: 998,
      length: 1,
      convRule: rule21
  }, {
      start: 999,
      length: 1,
      convRule: rule22
  }, {
      start: 1000,
      length: 1,
      convRule: rule21
  }, {
      start: 1001,
      length: 1,
      convRule: rule22
  }, {
      start: 1002,
      length: 1,
      convRule: rule21
  }, {
      start: 1003,
      length: 1,
      convRule: rule22
  }, {
      start: 1004,
      length: 1,
      convRule: rule21
  }, {
      start: 1005,
      length: 1,
      convRule: rule22
  }, {
      start: 1006,
      length: 1,
      convRule: rule21
  }, {
      start: 1007,
      length: 1,
      convRule: rule22
  }, {
      start: 1008,
      length: 1,
      convRule: rule102
  }, {
      start: 1009,
      length: 1,
      convRule: rule103
  }, {
      start: 1010,
      length: 1,
      convRule: rule104
  }, {
      start: 1012,
      length: 1,
      convRule: rule105
  }, {
      start: 1013,
      length: 1,
      convRule: rule106
  }, {
      start: 1015,
      length: 1,
      convRule: rule21
  }, {
      start: 1016,
      length: 1,
      convRule: rule22
  }, {
      start: 1017,
      length: 1,
      convRule: rule107
  }, {
      start: 1018,
      length: 1,
      convRule: rule21
  }, {
      start: 1019,
      length: 1,
      convRule: rule22
  }, {
      start: 1021,
      length: 3,
      convRule: rule53
  }, {
      start: 1024,
      length: 16,
      convRule: rule108
  }, {
      start: 1040,
      length: 32,
      convRule: rule9
  }, {
      start: 1072,
      length: 32,
      convRule: rule12
  }, {
      start: 1104,
      length: 16,
      convRule: rule103
  }, {
      start: 1120,
      length: 1,
      convRule: rule21
  }, {
      start: 1121,
      length: 1,
      convRule: rule22
  }, {
      start: 1122,
      length: 1,
      convRule: rule21
  }, {
      start: 1123,
      length: 1,
      convRule: rule22
  }, {
      start: 1124,
      length: 1,
      convRule: rule21
  }, {
      start: 1125,
      length: 1,
      convRule: rule22
  }, {
      start: 1126,
      length: 1,
      convRule: rule21
  }, {
      start: 1127,
      length: 1,
      convRule: rule22
  }, {
      start: 1128,
      length: 1,
      convRule: rule21
  }, {
      start: 1129,
      length: 1,
      convRule: rule22
  }, {
      start: 1130,
      length: 1,
      convRule: rule21
  }, {
      start: 1131,
      length: 1,
      convRule: rule22
  }, {
      start: 1132,
      length: 1,
      convRule: rule21
  }, {
      start: 1133,
      length: 1,
      convRule: rule22
  }, {
      start: 1134,
      length: 1,
      convRule: rule21
  }, {
      start: 1135,
      length: 1,
      convRule: rule22
  }, {
      start: 1136,
      length: 1,
      convRule: rule21
  }, {
      start: 1137,
      length: 1,
      convRule: rule22
  }, {
      start: 1138,
      length: 1,
      convRule: rule21
  }, {
      start: 1139,
      length: 1,
      convRule: rule22
  }, {
      start: 1140,
      length: 1,
      convRule: rule21
  }, {
      start: 1141,
      length: 1,
      convRule: rule22
  }, {
      start: 1142,
      length: 1,
      convRule: rule21
  }, {
      start: 1143,
      length: 1,
      convRule: rule22
  }, {
      start: 1144,
      length: 1,
      convRule: rule21
  }, {
      start: 1145,
      length: 1,
      convRule: rule22
  }, {
      start: 1146,
      length: 1,
      convRule: rule21
  }, {
      start: 1147,
      length: 1,
      convRule: rule22
  }, {
      start: 1148,
      length: 1,
      convRule: rule21
  }, {
      start: 1149,
      length: 1,
      convRule: rule22
  }, {
      start: 1150,
      length: 1,
      convRule: rule21
  }, {
      start: 1151,
      length: 1,
      convRule: rule22
  }, {
      start: 1152,
      length: 1,
      convRule: rule21
  }, {
      start: 1153,
      length: 1,
      convRule: rule22
  }, {
      start: 1162,
      length: 1,
      convRule: rule21
  }, {
      start: 1163,
      length: 1,
      convRule: rule22
  }, {
      start: 1164,
      length: 1,
      convRule: rule21
  }, {
      start: 1165,
      length: 1,
      convRule: rule22
  }, {
      start: 1166,
      length: 1,
      convRule: rule21
  }, {
      start: 1167,
      length: 1,
      convRule: rule22
  }, {
      start: 1168,
      length: 1,
      convRule: rule21
  }, {
      start: 1169,
      length: 1,
      convRule: rule22
  }, {
      start: 1170,
      length: 1,
      convRule: rule21
  }, {
      start: 1171,
      length: 1,
      convRule: rule22
  }, {
      start: 1172,
      length: 1,
      convRule: rule21
  }, {
      start: 1173,
      length: 1,
      convRule: rule22
  }, {
      start: 1174,
      length: 1,
      convRule: rule21
  }, {
      start: 1175,
      length: 1,
      convRule: rule22
  }, {
      start: 1176,
      length: 1,
      convRule: rule21
  }, {
      start: 1177,
      length: 1,
      convRule: rule22
  }, {
      start: 1178,
      length: 1,
      convRule: rule21
  }, {
      start: 1179,
      length: 1,
      convRule: rule22
  }, {
      start: 1180,
      length: 1,
      convRule: rule21
  }, {
      start: 1181,
      length: 1,
      convRule: rule22
  }, {
      start: 1182,
      length: 1,
      convRule: rule21
  }, {
      start: 1183,
      length: 1,
      convRule: rule22
  }, {
      start: 1184,
      length: 1,
      convRule: rule21
  }, {
      start: 1185,
      length: 1,
      convRule: rule22
  }, {
      start: 1186,
      length: 1,
      convRule: rule21
  }, {
      start: 1187,
      length: 1,
      convRule: rule22
  }, {
      start: 1188,
      length: 1,
      convRule: rule21
  }, {
      start: 1189,
      length: 1,
      convRule: rule22
  }, {
      start: 1190,
      length: 1,
      convRule: rule21
  }, {
      start: 1191,
      length: 1,
      convRule: rule22
  }, {
      start: 1192,
      length: 1,
      convRule: rule21
  }, {
      start: 1193,
      length: 1,
      convRule: rule22
  }, {
      start: 1194,
      length: 1,
      convRule: rule21
  }, {
      start: 1195,
      length: 1,
      convRule: rule22
  }, {
      start: 1196,
      length: 1,
      convRule: rule21
  }, {
      start: 1197,
      length: 1,
      convRule: rule22
  }, {
      start: 1198,
      length: 1,
      convRule: rule21
  }, {
      start: 1199,
      length: 1,
      convRule: rule22
  }, {
      start: 1200,
      length: 1,
      convRule: rule21
  }, {
      start: 1201,
      length: 1,
      convRule: rule22
  }, {
      start: 1202,
      length: 1,
      convRule: rule21
  }, {
      start: 1203,
      length: 1,
      convRule: rule22
  }, {
      start: 1204,
      length: 1,
      convRule: rule21
  }, {
      start: 1205,
      length: 1,
      convRule: rule22
  }, {
      start: 1206,
      length: 1,
      convRule: rule21
  }, {
      start: 1207,
      length: 1,
      convRule: rule22
  }, {
      start: 1208,
      length: 1,
      convRule: rule21
  }, {
      start: 1209,
      length: 1,
      convRule: rule22
  }, {
      start: 1210,
      length: 1,
      convRule: rule21
  }, {
      start: 1211,
      length: 1,
      convRule: rule22
  }, {
      start: 1212,
      length: 1,
      convRule: rule21
  }, {
      start: 1213,
      length: 1,
      convRule: rule22
  }, {
      start: 1214,
      length: 1,
      convRule: rule21
  }, {
      start: 1215,
      length: 1,
      convRule: rule22
  }, {
      start: 1216,
      length: 1,
      convRule: rule110
  }, {
      start: 1217,
      length: 1,
      convRule: rule21
  }, {
      start: 1218,
      length: 1,
      convRule: rule22
  }, {
      start: 1219,
      length: 1,
      convRule: rule21
  }, {
      start: 1220,
      length: 1,
      convRule: rule22
  }, {
      start: 1221,
      length: 1,
      convRule: rule21
  }, {
      start: 1222,
      length: 1,
      convRule: rule22
  }, {
      start: 1223,
      length: 1,
      convRule: rule21
  }, {
      start: 1224,
      length: 1,
      convRule: rule22
  }, {
      start: 1225,
      length: 1,
      convRule: rule21
  }, {
      start: 1226,
      length: 1,
      convRule: rule22
  }, {
      start: 1227,
      length: 1,
      convRule: rule21
  }, {
      start: 1228,
      length: 1,
      convRule: rule22
  }, {
      start: 1229,
      length: 1,
      convRule: rule21
  }, {
      start: 1230,
      length: 1,
      convRule: rule22
  }, {
      start: 1231,
      length: 1,
      convRule: rule111
  }, {
      start: 1232,
      length: 1,
      convRule: rule21
  }, {
      start: 1233,
      length: 1,
      convRule: rule22
  }, {
      start: 1234,
      length: 1,
      convRule: rule21
  }, {
      start: 1235,
      length: 1,
      convRule: rule22
  }, {
      start: 1236,
      length: 1,
      convRule: rule21
  }, {
      start: 1237,
      length: 1,
      convRule: rule22
  }, {
      start: 1238,
      length: 1,
      convRule: rule21
  }, {
      start: 1239,
      length: 1,
      convRule: rule22
  }, {
      start: 1240,
      length: 1,
      convRule: rule21
  }, {
      start: 1241,
      length: 1,
      convRule: rule22
  }, {
      start: 1242,
      length: 1,
      convRule: rule21
  }, {
      start: 1243,
      length: 1,
      convRule: rule22
  }, {
      start: 1244,
      length: 1,
      convRule: rule21
  }, {
      start: 1245,
      length: 1,
      convRule: rule22
  }, {
      start: 1246,
      length: 1,
      convRule: rule21
  }, {
      start: 1247,
      length: 1,
      convRule: rule22
  }, {
      start: 1248,
      length: 1,
      convRule: rule21
  }, {
      start: 1249,
      length: 1,
      convRule: rule22
  }, {
      start: 1250,
      length: 1,
      convRule: rule21
  }, {
      start: 1251,
      length: 1,
      convRule: rule22
  }, {
      start: 1252,
      length: 1,
      convRule: rule21
  }, {
      start: 1253,
      length: 1,
      convRule: rule22
  }, {
      start: 1254,
      length: 1,
      convRule: rule21
  }, {
      start: 1255,
      length: 1,
      convRule: rule22
  }, {
      start: 1256,
      length: 1,
      convRule: rule21
  }, {
      start: 1257,
      length: 1,
      convRule: rule22
  }, {
      start: 1258,
      length: 1,
      convRule: rule21
  }, {
      start: 1259,
      length: 1,
      convRule: rule22
  }, {
      start: 1260,
      length: 1,
      convRule: rule21
  }, {
      start: 1261,
      length: 1,
      convRule: rule22
  }, {
      start: 1262,
      length: 1,
      convRule: rule21
  }, {
      start: 1263,
      length: 1,
      convRule: rule22
  }, {
      start: 1264,
      length: 1,
      convRule: rule21
  }, {
      start: 1265,
      length: 1,
      convRule: rule22
  }, {
      start: 1266,
      length: 1,
      convRule: rule21
  }, {
      start: 1267,
      length: 1,
      convRule: rule22
  }, {
      start: 1268,
      length: 1,
      convRule: rule21
  }, {
      start: 1269,
      length: 1,
      convRule: rule22
  }, {
      start: 1270,
      length: 1,
      convRule: rule21
  }, {
      start: 1271,
      length: 1,
      convRule: rule22
  }, {
      start: 1272,
      length: 1,
      convRule: rule21
  }, {
      start: 1273,
      length: 1,
      convRule: rule22
  }, {
      start: 1274,
      length: 1,
      convRule: rule21
  }, {
      start: 1275,
      length: 1,
      convRule: rule22
  }, {
      start: 1276,
      length: 1,
      convRule: rule21
  }, {
      start: 1277,
      length: 1,
      convRule: rule22
  }, {
      start: 1278,
      length: 1,
      convRule: rule21
  }, {
      start: 1279,
      length: 1,
      convRule: rule22
  }, {
      start: 1280,
      length: 1,
      convRule: rule21
  }, {
      start: 1281,
      length: 1,
      convRule: rule22
  }, {
      start: 1282,
      length: 1,
      convRule: rule21
  }, {
      start: 1283,
      length: 1,
      convRule: rule22
  }, {
      start: 1284,
      length: 1,
      convRule: rule21
  }, {
      start: 1285,
      length: 1,
      convRule: rule22
  }, {
      start: 1286,
      length: 1,
      convRule: rule21
  }, {
      start: 1287,
      length: 1,
      convRule: rule22
  }, {
      start: 1288,
      length: 1,
      convRule: rule21
  }, {
      start: 1289,
      length: 1,
      convRule: rule22
  }, {
      start: 1290,
      length: 1,
      convRule: rule21
  }, {
      start: 1291,
      length: 1,
      convRule: rule22
  }, {
      start: 1292,
      length: 1,
      convRule: rule21
  }, {
      start: 1293,
      length: 1,
      convRule: rule22
  }, {
      start: 1294,
      length: 1,
      convRule: rule21
  }, {
      start: 1295,
      length: 1,
      convRule: rule22
  }, {
      start: 1296,
      length: 1,
      convRule: rule21
  }, {
      start: 1297,
      length: 1,
      convRule: rule22
  }, {
      start: 1298,
      length: 1,
      convRule: rule21
  }, {
      start: 1299,
      length: 1,
      convRule: rule22
  }, {
      start: 1300,
      length: 1,
      convRule: rule21
  }, {
      start: 1301,
      length: 1,
      convRule: rule22
  }, {
      start: 1302,
      length: 1,
      convRule: rule21
  }, {
      start: 1303,
      length: 1,
      convRule: rule22
  }, {
      start: 1304,
      length: 1,
      convRule: rule21
  }, {
      start: 1305,
      length: 1,
      convRule: rule22
  }, {
      start: 1306,
      length: 1,
      convRule: rule21
  }, {
      start: 1307,
      length: 1,
      convRule: rule22
  }, {
      start: 1308,
      length: 1,
      convRule: rule21
  }, {
      start: 1309,
      length: 1,
      convRule: rule22
  }, {
      start: 1310,
      length: 1,
      convRule: rule21
  }, {
      start: 1311,
      length: 1,
      convRule: rule22
  }, {
      start: 1312,
      length: 1,
      convRule: rule21
  }, {
      start: 1313,
      length: 1,
      convRule: rule22
  }, {
      start: 1314,
      length: 1,
      convRule: rule21
  }, {
      start: 1315,
      length: 1,
      convRule: rule22
  }, {
      start: 1316,
      length: 1,
      convRule: rule21
  }, {
      start: 1317,
      length: 1,
      convRule: rule22
  }, {
      start: 1318,
      length: 1,
      convRule: rule21
  }, {
      start: 1319,
      length: 1,
      convRule: rule22
  }, {
      start: 1329,
      length: 38,
      convRule: rule112
  }, {
      start: 1377,
      length: 38,
      convRule: rule113
  }, {
      start: 4256,
      length: 38,
      convRule: rule115
  }, {
      start: 7545,
      length: 1,
      convRule: rule117
  }, {
      start: 7549,
      length: 1,
      convRule: rule118
  }, {
      start: 7680,
      length: 1,
      convRule: rule21
  }, {
      start: 7681,
      length: 1,
      convRule: rule22
  }, {
      start: 7682,
      length: 1,
      convRule: rule21
  }, {
      start: 7683,
      length: 1,
      convRule: rule22
  }, {
      start: 7684,
      length: 1,
      convRule: rule21
  }, {
      start: 7685,
      length: 1,
      convRule: rule22
  }, {
      start: 7686,
      length: 1,
      convRule: rule21
  }, {
      start: 7687,
      length: 1,
      convRule: rule22
  }, {
      start: 7688,
      length: 1,
      convRule: rule21
  }, {
      start: 7689,
      length: 1,
      convRule: rule22
  }, {
      start: 7690,
      length: 1,
      convRule: rule21
  }, {
      start: 7691,
      length: 1,
      convRule: rule22
  }, {
      start: 7692,
      length: 1,
      convRule: rule21
  }, {
      start: 7693,
      length: 1,
      convRule: rule22
  }, {
      start: 7694,
      length: 1,
      convRule: rule21
  }, {
      start: 7695,
      length: 1,
      convRule: rule22
  }, {
      start: 7696,
      length: 1,
      convRule: rule21
  }, {
      start: 7697,
      length: 1,
      convRule: rule22
  }, {
      start: 7698,
      length: 1,
      convRule: rule21
  }, {
      start: 7699,
      length: 1,
      convRule: rule22
  }, {
      start: 7700,
      length: 1,
      convRule: rule21
  }, {
      start: 7701,
      length: 1,
      convRule: rule22
  }, {
      start: 7702,
      length: 1,
      convRule: rule21
  }, {
      start: 7703,
      length: 1,
      convRule: rule22
  }, {
      start: 7704,
      length: 1,
      convRule: rule21
  }, {
      start: 7705,
      length: 1,
      convRule: rule22
  }, {
      start: 7706,
      length: 1,
      convRule: rule21
  }, {
      start: 7707,
      length: 1,
      convRule: rule22
  }, {
      start: 7708,
      length: 1,
      convRule: rule21
  }, {
      start: 7709,
      length: 1,
      convRule: rule22
  }, {
      start: 7710,
      length: 1,
      convRule: rule21
  }, {
      start: 7711,
      length: 1,
      convRule: rule22
  }, {
      start: 7712,
      length: 1,
      convRule: rule21
  }, {
      start: 7713,
      length: 1,
      convRule: rule22
  }, {
      start: 7714,
      length: 1,
      convRule: rule21
  }, {
      start: 7715,
      length: 1,
      convRule: rule22
  }, {
      start: 7716,
      length: 1,
      convRule: rule21
  }, {
      start: 7717,
      length: 1,
      convRule: rule22
  }, {
      start: 7718,
      length: 1,
      convRule: rule21
  }, {
      start: 7719,
      length: 1,
      convRule: rule22
  }, {
      start: 7720,
      length: 1,
      convRule: rule21
  }, {
      start: 7721,
      length: 1,
      convRule: rule22
  }, {
      start: 7722,
      length: 1,
      convRule: rule21
  }, {
      start: 7723,
      length: 1,
      convRule: rule22
  }, {
      start: 7724,
      length: 1,
      convRule: rule21
  }, {
      start: 7725,
      length: 1,
      convRule: rule22
  }, {
      start: 7726,
      length: 1,
      convRule: rule21
  }, {
      start: 7727,
      length: 1,
      convRule: rule22
  }, {
      start: 7728,
      length: 1,
      convRule: rule21
  }, {
      start: 7729,
      length: 1,
      convRule: rule22
  }, {
      start: 7730,
      length: 1,
      convRule: rule21
  }, {
      start: 7731,
      length: 1,
      convRule: rule22
  }, {
      start: 7732,
      length: 1,
      convRule: rule21
  }, {
      start: 7733,
      length: 1,
      convRule: rule22
  }, {
      start: 7734,
      length: 1,
      convRule: rule21
  }, {
      start: 7735,
      length: 1,
      convRule: rule22
  }, {
      start: 7736,
      length: 1,
      convRule: rule21
  }, {
      start: 7737,
      length: 1,
      convRule: rule22
  }, {
      start: 7738,
      length: 1,
      convRule: rule21
  }, {
      start: 7739,
      length: 1,
      convRule: rule22
  }, {
      start: 7740,
      length: 1,
      convRule: rule21
  }, {
      start: 7741,
      length: 1,
      convRule: rule22
  }, {
      start: 7742,
      length: 1,
      convRule: rule21
  }, {
      start: 7743,
      length: 1,
      convRule: rule22
  }, {
      start: 7744,
      length: 1,
      convRule: rule21
  }, {
      start: 7745,
      length: 1,
      convRule: rule22
  }, {
      start: 7746,
      length: 1,
      convRule: rule21
  }, {
      start: 7747,
      length: 1,
      convRule: rule22
  }, {
      start: 7748,
      length: 1,
      convRule: rule21
  }, {
      start: 7749,
      length: 1,
      convRule: rule22
  }, {
      start: 7750,
      length: 1,
      convRule: rule21
  }, {
      start: 7751,
      length: 1,
      convRule: rule22
  }, {
      start: 7752,
      length: 1,
      convRule: rule21
  }, {
      start: 7753,
      length: 1,
      convRule: rule22
  }, {
      start: 7754,
      length: 1,
      convRule: rule21
  }, {
      start: 7755,
      length: 1,
      convRule: rule22
  }, {
      start: 7756,
      length: 1,
      convRule: rule21
  }, {
      start: 7757,
      length: 1,
      convRule: rule22
  }, {
      start: 7758,
      length: 1,
      convRule: rule21
  }, {
      start: 7759,
      length: 1,
      convRule: rule22
  }, {
      start: 7760,
      length: 1,
      convRule: rule21
  }, {
      start: 7761,
      length: 1,
      convRule: rule22
  }, {
      start: 7762,
      length: 1,
      convRule: rule21
  }, {
      start: 7763,
      length: 1,
      convRule: rule22
  }, {
      start: 7764,
      length: 1,
      convRule: rule21
  }, {
      start: 7765,
      length: 1,
      convRule: rule22
  }, {
      start: 7766,
      length: 1,
      convRule: rule21
  }, {
      start: 7767,
      length: 1,
      convRule: rule22
  }, {
      start: 7768,
      length: 1,
      convRule: rule21
  }, {
      start: 7769,
      length: 1,
      convRule: rule22
  }, {
      start: 7770,
      length: 1,
      convRule: rule21
  }, {
      start: 7771,
      length: 1,
      convRule: rule22
  }, {
      start: 7772,
      length: 1,
      convRule: rule21
  }, {
      start: 7773,
      length: 1,
      convRule: rule22
  }, {
      start: 7774,
      length: 1,
      convRule: rule21
  }, {
      start: 7775,
      length: 1,
      convRule: rule22
  }, {
      start: 7776,
      length: 1,
      convRule: rule21
  }, {
      start: 7777,
      length: 1,
      convRule: rule22
  }, {
      start: 7778,
      length: 1,
      convRule: rule21
  }, {
      start: 7779,
      length: 1,
      convRule: rule22
  }, {
      start: 7780,
      length: 1,
      convRule: rule21
  }, {
      start: 7781,
      length: 1,
      convRule: rule22
  }, {
      start: 7782,
      length: 1,
      convRule: rule21
  }, {
      start: 7783,
      length: 1,
      convRule: rule22
  }, {
      start: 7784,
      length: 1,
      convRule: rule21
  }, {
      start: 7785,
      length: 1,
      convRule: rule22
  }, {
      start: 7786,
      length: 1,
      convRule: rule21
  }, {
      start: 7787,
      length: 1,
      convRule: rule22
  }, {
      start: 7788,
      length: 1,
      convRule: rule21
  }, {
      start: 7789,
      length: 1,
      convRule: rule22
  }, {
      start: 7790,
      length: 1,
      convRule: rule21
  }, {
      start: 7791,
      length: 1,
      convRule: rule22
  }, {
      start: 7792,
      length: 1,
      convRule: rule21
  }, {
      start: 7793,
      length: 1,
      convRule: rule22
  }, {
      start: 7794,
      length: 1,
      convRule: rule21
  }, {
      start: 7795,
      length: 1,
      convRule: rule22
  }, {
      start: 7796,
      length: 1,
      convRule: rule21
  }, {
      start: 7797,
      length: 1,
      convRule: rule22
  }, {
      start: 7798,
      length: 1,
      convRule: rule21
  }, {
      start: 7799,
      length: 1,
      convRule: rule22
  }, {
      start: 7800,
      length: 1,
      convRule: rule21
  }, {
      start: 7801,
      length: 1,
      convRule: rule22
  }, {
      start: 7802,
      length: 1,
      convRule: rule21
  }, {
      start: 7803,
      length: 1,
      convRule: rule22
  }, {
      start: 7804,
      length: 1,
      convRule: rule21
  }, {
      start: 7805,
      length: 1,
      convRule: rule22
  }, {
      start: 7806,
      length: 1,
      convRule: rule21
  }, {
      start: 7807,
      length: 1,
      convRule: rule22
  }, {
      start: 7808,
      length: 1,
      convRule: rule21
  }, {
      start: 7809,
      length: 1,
      convRule: rule22
  }, {
      start: 7810,
      length: 1,
      convRule: rule21
  }, {
      start: 7811,
      length: 1,
      convRule: rule22
  }, {
      start: 7812,
      length: 1,
      convRule: rule21
  }, {
      start: 7813,
      length: 1,
      convRule: rule22
  }, {
      start: 7814,
      length: 1,
      convRule: rule21
  }, {
      start: 7815,
      length: 1,
      convRule: rule22
  }, {
      start: 7816,
      length: 1,
      convRule: rule21
  }, {
      start: 7817,
      length: 1,
      convRule: rule22
  }, {
      start: 7818,
      length: 1,
      convRule: rule21
  }, {
      start: 7819,
      length: 1,
      convRule: rule22
  }, {
      start: 7820,
      length: 1,
      convRule: rule21
  }, {
      start: 7821,
      length: 1,
      convRule: rule22
  }, {
      start: 7822,
      length: 1,
      convRule: rule21
  }, {
      start: 7823,
      length: 1,
      convRule: rule22
  }, {
      start: 7824,
      length: 1,
      convRule: rule21
  }, {
      start: 7825,
      length: 1,
      convRule: rule22
  }, {
      start: 7826,
      length: 1,
      convRule: rule21
  }, {
      start: 7827,
      length: 1,
      convRule: rule22
  }, {
      start: 7828,
      length: 1,
      convRule: rule21
  }, {
      start: 7829,
      length: 1,
      convRule: rule22
  }, {
      start: 7835,
      length: 1,
      convRule: rule119
  }, {
      start: 7838,
      length: 1,
      convRule: rule120
  }, {
      start: 7840,
      length: 1,
      convRule: rule21
  }, {
      start: 7841,
      length: 1,
      convRule: rule22
  }, {
      start: 7842,
      length: 1,
      convRule: rule21
  }, {
      start: 7843,
      length: 1,
      convRule: rule22
  }, {
      start: 7844,
      length: 1,
      convRule: rule21
  }, {
      start: 7845,
      length: 1,
      convRule: rule22
  }, {
      start: 7846,
      length: 1,
      convRule: rule21
  }, {
      start: 7847,
      length: 1,
      convRule: rule22
  }, {
      start: 7848,
      length: 1,
      convRule: rule21
  }, {
      start: 7849,
      length: 1,
      convRule: rule22
  }, {
      start: 7850,
      length: 1,
      convRule: rule21
  }, {
      start: 7851,
      length: 1,
      convRule: rule22
  }, {
      start: 7852,
      length: 1,
      convRule: rule21
  }, {
      start: 7853,
      length: 1,
      convRule: rule22
  }, {
      start: 7854,
      length: 1,
      convRule: rule21
  }, {
      start: 7855,
      length: 1,
      convRule: rule22
  }, {
      start: 7856,
      length: 1,
      convRule: rule21
  }, {
      start: 7857,
      length: 1,
      convRule: rule22
  }, {
      start: 7858,
      length: 1,
      convRule: rule21
  }, {
      start: 7859,
      length: 1,
      convRule: rule22
  }, {
      start: 7860,
      length: 1,
      convRule: rule21
  }, {
      start: 7861,
      length: 1,
      convRule: rule22
  }, {
      start: 7862,
      length: 1,
      convRule: rule21
  }, {
      start: 7863,
      length: 1,
      convRule: rule22
  }, {
      start: 7864,
      length: 1,
      convRule: rule21
  }, {
      start: 7865,
      length: 1,
      convRule: rule22
  }, {
      start: 7866,
      length: 1,
      convRule: rule21
  }, {
      start: 7867,
      length: 1,
      convRule: rule22
  }, {
      start: 7868,
      length: 1,
      convRule: rule21
  }, {
      start: 7869,
      length: 1,
      convRule: rule22
  }, {
      start: 7870,
      length: 1,
      convRule: rule21
  }, {
      start: 7871,
      length: 1,
      convRule: rule22
  }, {
      start: 7872,
      length: 1,
      convRule: rule21
  }, {
      start: 7873,
      length: 1,
      convRule: rule22
  }, {
      start: 7874,
      length: 1,
      convRule: rule21
  }, {
      start: 7875,
      length: 1,
      convRule: rule22
  }, {
      start: 7876,
      length: 1,
      convRule: rule21
  }, {
      start: 7877,
      length: 1,
      convRule: rule22
  }, {
      start: 7878,
      length: 1,
      convRule: rule21
  }, {
      start: 7879,
      length: 1,
      convRule: rule22
  }, {
      start: 7880,
      length: 1,
      convRule: rule21
  }, {
      start: 7881,
      length: 1,
      convRule: rule22
  }, {
      start: 7882,
      length: 1,
      convRule: rule21
  }, {
      start: 7883,
      length: 1,
      convRule: rule22
  }, {
      start: 7884,
      length: 1,
      convRule: rule21
  }, {
      start: 7885,
      length: 1,
      convRule: rule22
  }, {
      start: 7886,
      length: 1,
      convRule: rule21
  }, {
      start: 7887,
      length: 1,
      convRule: rule22
  }, {
      start: 7888,
      length: 1,
      convRule: rule21
  }, {
      start: 7889,
      length: 1,
      convRule: rule22
  }, {
      start: 7890,
      length: 1,
      convRule: rule21
  }, {
      start: 7891,
      length: 1,
      convRule: rule22
  }, {
      start: 7892,
      length: 1,
      convRule: rule21
  }, {
      start: 7893,
      length: 1,
      convRule: rule22
  }, {
      start: 7894,
      length: 1,
      convRule: rule21
  }, {
      start: 7895,
      length: 1,
      convRule: rule22
  }, {
      start: 7896,
      length: 1,
      convRule: rule21
  }, {
      start: 7897,
      length: 1,
      convRule: rule22
  }, {
      start: 7898,
      length: 1,
      convRule: rule21
  }, {
      start: 7899,
      length: 1,
      convRule: rule22
  }, {
      start: 7900,
      length: 1,
      convRule: rule21
  }, {
      start: 7901,
      length: 1,
      convRule: rule22
  }, {
      start: 7902,
      length: 1,
      convRule: rule21
  }, {
      start: 7903,
      length: 1,
      convRule: rule22
  }, {
      start: 7904,
      length: 1,
      convRule: rule21
  }, {
      start: 7905,
      length: 1,
      convRule: rule22
  }, {
      start: 7906,
      length: 1,
      convRule: rule21
  }, {
      start: 7907,
      length: 1,
      convRule: rule22
  }, {
      start: 7908,
      length: 1,
      convRule: rule21
  }, {
      start: 7909,
      length: 1,
      convRule: rule22
  }, {
      start: 7910,
      length: 1,
      convRule: rule21
  }, {
      start: 7911,
      length: 1,
      convRule: rule22
  }, {
      start: 7912,
      length: 1,
      convRule: rule21
  }, {
      start: 7913,
      length: 1,
      convRule: rule22
  }, {
      start: 7914,
      length: 1,
      convRule: rule21
  }, {
      start: 7915,
      length: 1,
      convRule: rule22
  }, {
      start: 7916,
      length: 1,
      convRule: rule21
  }, {
      start: 7917,
      length: 1,
      convRule: rule22
  }, {
      start: 7918,
      length: 1,
      convRule: rule21
  }, {
      start: 7919,
      length: 1,
      convRule: rule22
  }, {
      start: 7920,
      length: 1,
      convRule: rule21
  }, {
      start: 7921,
      length: 1,
      convRule: rule22
  }, {
      start: 7922,
      length: 1,
      convRule: rule21
  }, {
      start: 7923,
      length: 1,
      convRule: rule22
  }, {
      start: 7924,
      length: 1,
      convRule: rule21
  }, {
      start: 7925,
      length: 1,
      convRule: rule22
  }, {
      start: 7926,
      length: 1,
      convRule: rule21
  }, {
      start: 7927,
      length: 1,
      convRule: rule22
  }, {
      start: 7928,
      length: 1,
      convRule: rule21
  }, {
      start: 7929,
      length: 1,
      convRule: rule22
  }, {
      start: 7930,
      length: 1,
      convRule: rule21
  }, {
      start: 7931,
      length: 1,
      convRule: rule22
  }, {
      start: 7932,
      length: 1,
      convRule: rule21
  }, {
      start: 7933,
      length: 1,
      convRule: rule22
  }, {
      start: 7934,
      length: 1,
      convRule: rule21
  }, {
      start: 7935,
      length: 1,
      convRule: rule22
  }, {
      start: 7936,
      length: 8,
      convRule: rule121
  }, {
      start: 7944,
      length: 8,
      convRule: rule122
  }, {
      start: 7952,
      length: 6,
      convRule: rule121
  }, {
      start: 7960,
      length: 6,
      convRule: rule122
  }, {
      start: 7968,
      length: 8,
      convRule: rule121
  }, {
      start: 7976,
      length: 8,
      convRule: rule122
  }, {
      start: 7984,
      length: 8,
      convRule: rule121
  }, {
      start: 7992,
      length: 8,
      convRule: rule122
  }, {
      start: 8000,
      length: 6,
      convRule: rule121
  }, {
      start: 8008,
      length: 6,
      convRule: rule122
  }, {
      start: 8017,
      length: 1,
      convRule: rule121
  }, {
      start: 8019,
      length: 1,
      convRule: rule121
  }, {
      start: 8021,
      length: 1,
      convRule: rule121
  }, {
      start: 8023,
      length: 1,
      convRule: rule121
  }, {
      start: 8025,
      length: 1,
      convRule: rule122
  }, {
      start: 8027,
      length: 1,
      convRule: rule122
  }, {
      start: 8029,
      length: 1,
      convRule: rule122
  }, {
      start: 8031,
      length: 1,
      convRule: rule122
  }, {
      start: 8032,
      length: 8,
      convRule: rule121
  }, {
      start: 8040,
      length: 8,
      convRule: rule122
  }, {
      start: 8048,
      length: 2,
      convRule: rule123
  }, {
      start: 8050,
      length: 4,
      convRule: rule124
  }, {
      start: 8054,
      length: 2,
      convRule: rule125
  }, {
      start: 8056,
      length: 2,
      convRule: rule126
  }, {
      start: 8058,
      length: 2,
      convRule: rule127
  }, {
      start: 8060,
      length: 2,
      convRule: rule128
  }, {
      start: 8064,
      length: 8,
      convRule: rule121
  }, {
      start: 8072,
      length: 8,
      convRule: rule129
  }, {
      start: 8080,
      length: 8,
      convRule: rule121
  }, {
      start: 8088,
      length: 8,
      convRule: rule129
  }, {
      start: 8096,
      length: 8,
      convRule: rule121
  }, {
      start: 8104,
      length: 8,
      convRule: rule129
  }, {
      start: 8112,
      length: 2,
      convRule: rule121
  }, {
      start: 8115,
      length: 1,
      convRule: rule130
  }, {
      start: 8120,
      length: 2,
      convRule: rule122
  }, {
      start: 8122,
      length: 2,
      convRule: rule131
  }, {
      start: 8124,
      length: 1,
      convRule: rule132
  }, {
      start: 8126,
      length: 1,
      convRule: rule133
  }, {
      start: 8131,
      length: 1,
      convRule: rule130
  }, {
      start: 8136,
      length: 4,
      convRule: rule134
  }, {
      start: 8140,
      length: 1,
      convRule: rule132
  }, {
      start: 8144,
      length: 2,
      convRule: rule121
  }, {
      start: 8152,
      length: 2,
      convRule: rule122
  }, {
      start: 8154,
      length: 2,
      convRule: rule135
  }, {
      start: 8160,
      length: 2,
      convRule: rule121
  }, {
      start: 8165,
      length: 1,
      convRule: rule104
  }, {
      start: 8168,
      length: 2,
      convRule: rule122
  }, {
      start: 8170,
      length: 2,
      convRule: rule136
  }, {
      start: 8172,
      length: 1,
      convRule: rule107
  }, {
      start: 8179,
      length: 1,
      convRule: rule130
  }, {
      start: 8184,
      length: 2,
      convRule: rule137
  }, {
      start: 8186,
      length: 2,
      convRule: rule138
  }, {
      start: 8188,
      length: 1,
      convRule: rule132
  }, {
      start: 8486,
      length: 1,
      convRule: rule141
  }, {
      start: 8490,
      length: 1,
      convRule: rule142
  }, {
      start: 8491,
      length: 1,
      convRule: rule143
  }, {
      start: 8498,
      length: 1,
      convRule: rule144
  }, {
      start: 8526,
      length: 1,
      convRule: rule145
  }, {
      start: 8544,
      length: 16,
      convRule: rule146
  }, {
      start: 8560,
      length: 16,
      convRule: rule147
  }, {
      start: 8579,
      length: 1,
      convRule: rule21
  }, {
      start: 8580,
      length: 1,
      convRule: rule22
  }, {
      start: 9398,
      length: 26,
      convRule: rule148
  }, {
      start: 9424,
      length: 26,
      convRule: rule149
  }, {
      start: 11264,
      length: 47,
      convRule: rule112
  }, {
      start: 11312,
      length: 47,
      convRule: rule113
  }, {
      start: 11360,
      length: 1,
      convRule: rule21
  }, {
      start: 11361,
      length: 1,
      convRule: rule22
  }, {
      start: 11362,
      length: 1,
      convRule: rule150
  }, {
      start: 11363,
      length: 1,
      convRule: rule151
  }, {
      start: 11364,
      length: 1,
      convRule: rule152
  }, {
      start: 11365,
      length: 1,
      convRule: rule153
  }, {
      start: 11366,
      length: 1,
      convRule: rule154
  }, {
      start: 11367,
      length: 1,
      convRule: rule21
  }, {
      start: 11368,
      length: 1,
      convRule: rule22
  }, {
      start: 11369,
      length: 1,
      convRule: rule21
  }, {
      start: 11370,
      length: 1,
      convRule: rule22
  }, {
      start: 11371,
      length: 1,
      convRule: rule21
  }, {
      start: 11372,
      length: 1,
      convRule: rule22
  }, {
      start: 11373,
      length: 1,
      convRule: rule155
  }, {
      start: 11374,
      length: 1,
      convRule: rule156
  }, {
      start: 11375,
      length: 1,
      convRule: rule157
  }, {
      start: 11376,
      length: 1,
      convRule: rule158
  }, {
      start: 11378,
      length: 1,
      convRule: rule21
  }, {
      start: 11379,
      length: 1,
      convRule: rule22
  }, {
      start: 11381,
      length: 1,
      convRule: rule21
  }, {
      start: 11382,
      length: 1,
      convRule: rule22
  }, {
      start: 11390,
      length: 2,
      convRule: rule159
  }, {
      start: 11392,
      length: 1,
      convRule: rule21
  }, {
      start: 11393,
      length: 1,
      convRule: rule22
  }, {
      start: 11394,
      length: 1,
      convRule: rule21
  }, {
      start: 11395,
      length: 1,
      convRule: rule22
  }, {
      start: 11396,
      length: 1,
      convRule: rule21
  }, {
      start: 11397,
      length: 1,
      convRule: rule22
  }, {
      start: 11398,
      length: 1,
      convRule: rule21
  }, {
      start: 11399,
      length: 1,
      convRule: rule22
  }, {
      start: 11400,
      length: 1,
      convRule: rule21
  }, {
      start: 11401,
      length: 1,
      convRule: rule22
  }, {
      start: 11402,
      length: 1,
      convRule: rule21
  }, {
      start: 11403,
      length: 1,
      convRule: rule22
  }, {
      start: 11404,
      length: 1,
      convRule: rule21
  }, {
      start: 11405,
      length: 1,
      convRule: rule22
  }, {
      start: 11406,
      length: 1,
      convRule: rule21
  }, {
      start: 11407,
      length: 1,
      convRule: rule22
  }, {
      start: 11408,
      length: 1,
      convRule: rule21
  }, {
      start: 11409,
      length: 1,
      convRule: rule22
  }, {
      start: 11410,
      length: 1,
      convRule: rule21
  }, {
      start: 11411,
      length: 1,
      convRule: rule22
  }, {
      start: 11412,
      length: 1,
      convRule: rule21
  }, {
      start: 11413,
      length: 1,
      convRule: rule22
  }, {
      start: 11414,
      length: 1,
      convRule: rule21
  }, {
      start: 11415,
      length: 1,
      convRule: rule22
  }, {
      start: 11416,
      length: 1,
      convRule: rule21
  }, {
      start: 11417,
      length: 1,
      convRule: rule22
  }, {
      start: 11418,
      length: 1,
      convRule: rule21
  }, {
      start: 11419,
      length: 1,
      convRule: rule22
  }, {
      start: 11420,
      length: 1,
      convRule: rule21
  }, {
      start: 11421,
      length: 1,
      convRule: rule22
  }, {
      start: 11422,
      length: 1,
      convRule: rule21
  }, {
      start: 11423,
      length: 1,
      convRule: rule22
  }, {
      start: 11424,
      length: 1,
      convRule: rule21
  }, {
      start: 11425,
      length: 1,
      convRule: rule22
  }, {
      start: 11426,
      length: 1,
      convRule: rule21
  }, {
      start: 11427,
      length: 1,
      convRule: rule22
  }, {
      start: 11428,
      length: 1,
      convRule: rule21
  }, {
      start: 11429,
      length: 1,
      convRule: rule22
  }, {
      start: 11430,
      length: 1,
      convRule: rule21
  }, {
      start: 11431,
      length: 1,
      convRule: rule22
  }, {
      start: 11432,
      length: 1,
      convRule: rule21
  }, {
      start: 11433,
      length: 1,
      convRule: rule22
  }, {
      start: 11434,
      length: 1,
      convRule: rule21
  }, {
      start: 11435,
      length: 1,
      convRule: rule22
  }, {
      start: 11436,
      length: 1,
      convRule: rule21
  }, {
      start: 11437,
      length: 1,
      convRule: rule22
  }, {
      start: 11438,
      length: 1,
      convRule: rule21
  }, {
      start: 11439,
      length: 1,
      convRule: rule22
  }, {
      start: 11440,
      length: 1,
      convRule: rule21
  }, {
      start: 11441,
      length: 1,
      convRule: rule22
  }, {
      start: 11442,
      length: 1,
      convRule: rule21
  }, {
      start: 11443,
      length: 1,
      convRule: rule22
  }, {
      start: 11444,
      length: 1,
      convRule: rule21
  }, {
      start: 11445,
      length: 1,
      convRule: rule22
  }, {
      start: 11446,
      length: 1,
      convRule: rule21
  }, {
      start: 11447,
      length: 1,
      convRule: rule22
  }, {
      start: 11448,
      length: 1,
      convRule: rule21
  }, {
      start: 11449,
      length: 1,
      convRule: rule22
  }, {
      start: 11450,
      length: 1,
      convRule: rule21
  }, {
      start: 11451,
      length: 1,
      convRule: rule22
  }, {
      start: 11452,
      length: 1,
      convRule: rule21
  }, {
      start: 11453,
      length: 1,
      convRule: rule22
  }, {
      start: 11454,
      length: 1,
      convRule: rule21
  }, {
      start: 11455,
      length: 1,
      convRule: rule22
  }, {
      start: 11456,
      length: 1,
      convRule: rule21
  }, {
      start: 11457,
      length: 1,
      convRule: rule22
  }, {
      start: 11458,
      length: 1,
      convRule: rule21
  }, {
      start: 11459,
      length: 1,
      convRule: rule22
  }, {
      start: 11460,
      length: 1,
      convRule: rule21
  }, {
      start: 11461,
      length: 1,
      convRule: rule22
  }, {
      start: 11462,
      length: 1,
      convRule: rule21
  }, {
      start: 11463,
      length: 1,
      convRule: rule22
  }, {
      start: 11464,
      length: 1,
      convRule: rule21
  }, {
      start: 11465,
      length: 1,
      convRule: rule22
  }, {
      start: 11466,
      length: 1,
      convRule: rule21
  }, {
      start: 11467,
      length: 1,
      convRule: rule22
  }, {
      start: 11468,
      length: 1,
      convRule: rule21
  }, {
      start: 11469,
      length: 1,
      convRule: rule22
  }, {
      start: 11470,
      length: 1,
      convRule: rule21
  }, {
      start: 11471,
      length: 1,
      convRule: rule22
  }, {
      start: 11472,
      length: 1,
      convRule: rule21
  }, {
      start: 11473,
      length: 1,
      convRule: rule22
  }, {
      start: 11474,
      length: 1,
      convRule: rule21
  }, {
      start: 11475,
      length: 1,
      convRule: rule22
  }, {
      start: 11476,
      length: 1,
      convRule: rule21
  }, {
      start: 11477,
      length: 1,
      convRule: rule22
  }, {
      start: 11478,
      length: 1,
      convRule: rule21
  }, {
      start: 11479,
      length: 1,
      convRule: rule22
  }, {
      start: 11480,
      length: 1,
      convRule: rule21
  }, {
      start: 11481,
      length: 1,
      convRule: rule22
  }, {
      start: 11482,
      length: 1,
      convRule: rule21
  }, {
      start: 11483,
      length: 1,
      convRule: rule22
  }, {
      start: 11484,
      length: 1,
      convRule: rule21
  }, {
      start: 11485,
      length: 1,
      convRule: rule22
  }, {
      start: 11486,
      length: 1,
      convRule: rule21
  }, {
      start: 11487,
      length: 1,
      convRule: rule22
  }, {
      start: 11488,
      length: 1,
      convRule: rule21
  }, {
      start: 11489,
      length: 1,
      convRule: rule22
  }, {
      start: 11490,
      length: 1,
      convRule: rule21
  }, {
      start: 11491,
      length: 1,
      convRule: rule22
  }, {
      start: 11499,
      length: 1,
      convRule: rule21
  }, {
      start: 11500,
      length: 1,
      convRule: rule22
  }, {
      start: 11501,
      length: 1,
      convRule: rule21
  }, {
      start: 11502,
      length: 1,
      convRule: rule22
  }, {
      start: 11520,
      length: 38,
      convRule: rule160
  }, {
      start: 42560,
      length: 1,
      convRule: rule21
  }, {
      start: 42561,
      length: 1,
      convRule: rule22
  }, {
      start: 42562,
      length: 1,
      convRule: rule21
  }, {
      start: 42563,
      length: 1,
      convRule: rule22
  }, {
      start: 42564,
      length: 1,
      convRule: rule21
  }, {
      start: 42565,
      length: 1,
      convRule: rule22
  }, {
      start: 42566,
      length: 1,
      convRule: rule21
  }, {
      start: 42567,
      length: 1,
      convRule: rule22
  }, {
      start: 42568,
      length: 1,
      convRule: rule21
  }, {
      start: 42569,
      length: 1,
      convRule: rule22
  }, {
      start: 42570,
      length: 1,
      convRule: rule21
  }, {
      start: 42571,
      length: 1,
      convRule: rule22
  }, {
      start: 42572,
      length: 1,
      convRule: rule21
  }, {
      start: 42573,
      length: 1,
      convRule: rule22
  }, {
      start: 42574,
      length: 1,
      convRule: rule21
  }, {
      start: 42575,
      length: 1,
      convRule: rule22
  }, {
      start: 42576,
      length: 1,
      convRule: rule21
  }, {
      start: 42577,
      length: 1,
      convRule: rule22
  }, {
      start: 42578,
      length: 1,
      convRule: rule21
  }, {
      start: 42579,
      length: 1,
      convRule: rule22
  }, {
      start: 42580,
      length: 1,
      convRule: rule21
  }, {
      start: 42581,
      length: 1,
      convRule: rule22
  }, {
      start: 42582,
      length: 1,
      convRule: rule21
  }, {
      start: 42583,
      length: 1,
      convRule: rule22
  }, {
      start: 42584,
      length: 1,
      convRule: rule21
  }, {
      start: 42585,
      length: 1,
      convRule: rule22
  }, {
      start: 42586,
      length: 1,
      convRule: rule21
  }, {
      start: 42587,
      length: 1,
      convRule: rule22
  }, {
      start: 42588,
      length: 1,
      convRule: rule21
  }, {
      start: 42589,
      length: 1,
      convRule: rule22
  }, {
      start: 42590,
      length: 1,
      convRule: rule21
  }, {
      start: 42591,
      length: 1,
      convRule: rule22
  }, {
      start: 42592,
      length: 1,
      convRule: rule21
  }, {
      start: 42593,
      length: 1,
      convRule: rule22
  }, {
      start: 42594,
      length: 1,
      convRule: rule21
  }, {
      start: 42595,
      length: 1,
      convRule: rule22
  }, {
      start: 42596,
      length: 1,
      convRule: rule21
  }, {
      start: 42597,
      length: 1,
      convRule: rule22
  }, {
      start: 42598,
      length: 1,
      convRule: rule21
  }, {
      start: 42599,
      length: 1,
      convRule: rule22
  }, {
      start: 42600,
      length: 1,
      convRule: rule21
  }, {
      start: 42601,
      length: 1,
      convRule: rule22
  }, {
      start: 42602,
      length: 1,
      convRule: rule21
  }, {
      start: 42603,
      length: 1,
      convRule: rule22
  }, {
      start: 42604,
      length: 1,
      convRule: rule21
  }, {
      start: 42605,
      length: 1,
      convRule: rule22
  }, {
      start: 42624,
      length: 1,
      convRule: rule21
  }, {
      start: 42625,
      length: 1,
      convRule: rule22
  }, {
      start: 42626,
      length: 1,
      convRule: rule21
  }, {
      start: 42627,
      length: 1,
      convRule: rule22
  }, {
      start: 42628,
      length: 1,
      convRule: rule21
  }, {
      start: 42629,
      length: 1,
      convRule: rule22
  }, {
      start: 42630,
      length: 1,
      convRule: rule21
  }, {
      start: 42631,
      length: 1,
      convRule: rule22
  }, {
      start: 42632,
      length: 1,
      convRule: rule21
  }, {
      start: 42633,
      length: 1,
      convRule: rule22
  }, {
      start: 42634,
      length: 1,
      convRule: rule21
  }, {
      start: 42635,
      length: 1,
      convRule: rule22
  }, {
      start: 42636,
      length: 1,
      convRule: rule21
  }, {
      start: 42637,
      length: 1,
      convRule: rule22
  }, {
      start: 42638,
      length: 1,
      convRule: rule21
  }, {
      start: 42639,
      length: 1,
      convRule: rule22
  }, {
      start: 42640,
      length: 1,
      convRule: rule21
  }, {
      start: 42641,
      length: 1,
      convRule: rule22
  }, {
      start: 42642,
      length: 1,
      convRule: rule21
  }, {
      start: 42643,
      length: 1,
      convRule: rule22
  }, {
      start: 42644,
      length: 1,
      convRule: rule21
  }, {
      start: 42645,
      length: 1,
      convRule: rule22
  }, {
      start: 42646,
      length: 1,
      convRule: rule21
  }, {
      start: 42647,
      length: 1,
      convRule: rule22
  }, {
      start: 42786,
      length: 1,
      convRule: rule21
  }, {
      start: 42787,
      length: 1,
      convRule: rule22
  }, {
      start: 42788,
      length: 1,
      convRule: rule21
  }, {
      start: 42789,
      length: 1,
      convRule: rule22
  }, {
      start: 42790,
      length: 1,
      convRule: rule21
  }, {
      start: 42791,
      length: 1,
      convRule: rule22
  }, {
      start: 42792,
      length: 1,
      convRule: rule21
  }, {
      start: 42793,
      length: 1,
      convRule: rule22
  }, {
      start: 42794,
      length: 1,
      convRule: rule21
  }, {
      start: 42795,
      length: 1,
      convRule: rule22
  }, {
      start: 42796,
      length: 1,
      convRule: rule21
  }, {
      start: 42797,
      length: 1,
      convRule: rule22
  }, {
      start: 42798,
      length: 1,
      convRule: rule21
  }, {
      start: 42799,
      length: 1,
      convRule: rule22
  }, {
      start: 42802,
      length: 1,
      convRule: rule21
  }, {
      start: 42803,
      length: 1,
      convRule: rule22
  }, {
      start: 42804,
      length: 1,
      convRule: rule21
  }, {
      start: 42805,
      length: 1,
      convRule: rule22
  }, {
      start: 42806,
      length: 1,
      convRule: rule21
  }, {
      start: 42807,
      length: 1,
      convRule: rule22
  }, {
      start: 42808,
      length: 1,
      convRule: rule21
  }, {
      start: 42809,
      length: 1,
      convRule: rule22
  }, {
      start: 42810,
      length: 1,
      convRule: rule21
  }, {
      start: 42811,
      length: 1,
      convRule: rule22
  }, {
      start: 42812,
      length: 1,
      convRule: rule21
  }, {
      start: 42813,
      length: 1,
      convRule: rule22
  }, {
      start: 42814,
      length: 1,
      convRule: rule21
  }, {
      start: 42815,
      length: 1,
      convRule: rule22
  }, {
      start: 42816,
      length: 1,
      convRule: rule21
  }, {
      start: 42817,
      length: 1,
      convRule: rule22
  }, {
      start: 42818,
      length: 1,
      convRule: rule21
  }, {
      start: 42819,
      length: 1,
      convRule: rule22
  }, {
      start: 42820,
      length: 1,
      convRule: rule21
  }, {
      start: 42821,
      length: 1,
      convRule: rule22
  }, {
      start: 42822,
      length: 1,
      convRule: rule21
  }, {
      start: 42823,
      length: 1,
      convRule: rule22
  }, {
      start: 42824,
      length: 1,
      convRule: rule21
  }, {
      start: 42825,
      length: 1,
      convRule: rule22
  }, {
      start: 42826,
      length: 1,
      convRule: rule21
  }, {
      start: 42827,
      length: 1,
      convRule: rule22
  }, {
      start: 42828,
      length: 1,
      convRule: rule21
  }, {
      start: 42829,
      length: 1,
      convRule: rule22
  }, {
      start: 42830,
      length: 1,
      convRule: rule21
  }, {
      start: 42831,
      length: 1,
      convRule: rule22
  }, {
      start: 42832,
      length: 1,
      convRule: rule21
  }, {
      start: 42833,
      length: 1,
      convRule: rule22
  }, {
      start: 42834,
      length: 1,
      convRule: rule21
  }, {
      start: 42835,
      length: 1,
      convRule: rule22
  }, {
      start: 42836,
      length: 1,
      convRule: rule21
  }, {
      start: 42837,
      length: 1,
      convRule: rule22
  }, {
      start: 42838,
      length: 1,
      convRule: rule21
  }, {
      start: 42839,
      length: 1,
      convRule: rule22
  }, {
      start: 42840,
      length: 1,
      convRule: rule21
  }, {
      start: 42841,
      length: 1,
      convRule: rule22
  }, {
      start: 42842,
      length: 1,
      convRule: rule21
  }, {
      start: 42843,
      length: 1,
      convRule: rule22
  }, {
      start: 42844,
      length: 1,
      convRule: rule21
  }, {
      start: 42845,
      length: 1,
      convRule: rule22
  }, {
      start: 42846,
      length: 1,
      convRule: rule21
  }, {
      start: 42847,
      length: 1,
      convRule: rule22
  }, {
      start: 42848,
      length: 1,
      convRule: rule21
  }, {
      start: 42849,
      length: 1,
      convRule: rule22
  }, {
      start: 42850,
      length: 1,
      convRule: rule21
  }, {
      start: 42851,
      length: 1,
      convRule: rule22
  }, {
      start: 42852,
      length: 1,
      convRule: rule21
  }, {
      start: 42853,
      length: 1,
      convRule: rule22
  }, {
      start: 42854,
      length: 1,
      convRule: rule21
  }, {
      start: 42855,
      length: 1,
      convRule: rule22
  }, {
      start: 42856,
      length: 1,
      convRule: rule21
  }, {
      start: 42857,
      length: 1,
      convRule: rule22
  }, {
      start: 42858,
      length: 1,
      convRule: rule21
  }, {
      start: 42859,
      length: 1,
      convRule: rule22
  }, {
      start: 42860,
      length: 1,
      convRule: rule21
  }, {
      start: 42861,
      length: 1,
      convRule: rule22
  }, {
      start: 42862,
      length: 1,
      convRule: rule21
  }, {
      start: 42863,
      length: 1,
      convRule: rule22
  }, {
      start: 42873,
      length: 1,
      convRule: rule21
  }, {
      start: 42874,
      length: 1,
      convRule: rule22
  }, {
      start: 42875,
      length: 1,
      convRule: rule21
  }, {
      start: 42876,
      length: 1,
      convRule: rule22
  }, {
      start: 42877,
      length: 1,
      convRule: rule161
  }, {
      start: 42878,
      length: 1,
      convRule: rule21
  }, {
      start: 42879,
      length: 1,
      convRule: rule22
  }, {
      start: 42880,
      length: 1,
      convRule: rule21
  }, {
      start: 42881,
      length: 1,
      convRule: rule22
  }, {
      start: 42882,
      length: 1,
      convRule: rule21
  }, {
      start: 42883,
      length: 1,
      convRule: rule22
  }, {
      start: 42884,
      length: 1,
      convRule: rule21
  }, {
      start: 42885,
      length: 1,
      convRule: rule22
  }, {
      start: 42886,
      length: 1,
      convRule: rule21
  }, {
      start: 42887,
      length: 1,
      convRule: rule22
  }, {
      start: 42891,
      length: 1,
      convRule: rule21
  }, {
      start: 42892,
      length: 1,
      convRule: rule22
  }, {
      start: 42893,
      length: 1,
      convRule: rule162
  }, {
      start: 42896,
      length: 1,
      convRule: rule21
  }, {
      start: 42897,
      length: 1,
      convRule: rule22
  }, {
      start: 42912,
      length: 1,
      convRule: rule21
  }, {
      start: 42913,
      length: 1,
      convRule: rule22
  }, {
      start: 42914,
      length: 1,
      convRule: rule21
  }, {
      start: 42915,
      length: 1,
      convRule: rule22
  }, {
      start: 42916,
      length: 1,
      convRule: rule21
  }, {
      start: 42917,
      length: 1,
      convRule: rule22
  }, {
      start: 42918,
      length: 1,
      convRule: rule21
  }, {
      start: 42919,
      length: 1,
      convRule: rule22
  }, {
      start: 42920,
      length: 1,
      convRule: rule21
  }, {
      start: 42921,
      length: 1,
      convRule: rule22
  }, {
      start: 65313,
      length: 26,
      convRule: rule9
  }, {
      start: 65345,
      length: 26,
      convRule: rule12
  }, {
      start: 66560,
      length: 40,
      convRule: rule165
  }, {
      start: 66600,
      length: 40,
      convRule: rule166
  } ];
  var bsearch = function (a) {
      return function (array) {
          return function (size) {
              return function (compare) {
                  var go = function ($copy_i) {
                      return function ($copy_k) {
                          var $tco_var_i = $copy_i;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(i, k) {
                              if (i > k) {
                                  $tco_done = true;
                                  return Data_Maybe.Nothing.value;
                              };
                              if (Data_Boolean.otherwise) {
                                  var j = Data_Int.floor(Data_Int.toNumber(i + k | 0) / 2.0);
                                  var v = compare(a)(array[j]);
                                  if (v instanceof Data_Ordering.EQ) {
                                      $tco_done = true;
                                      return new Data_Maybe.Just(array[j]);
                                  };
                                  if (v instanceof Data_Ordering.GT) {
                                      $tco_var_i = j + 1 | 0;
                                      $copy_k = k;
                                      return;
                                  };
                                  $tco_var_i = i;
                                  $copy_k = j - 1 | 0;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.Char.Unicode.Internal (line 4783, column 5 - line 4789, column 49): " + [ i.constructor.name, k.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_i, $copy_k);
                          };
                          return $tco_result;
                      };
                  };
                  return go(0)(size);
              };
          };
      };
  };
  var blkCmp = function (v) {
      return function (v1) {
          if (v.start >= v1.start && v.start < (v1.start + v1.length | 0)) {
              return Data_Ordering.EQ.value;
          };
          if (v.start > v1.start) {
              return Data_Ordering.GT.value;
          };
          if (Data_Boolean.otherwise) {
              return Data_Ordering.LT.value;
          };
          throw new Error("Failed pattern match at Data.Char.Unicode.Internal (line 4760, column 1 - line 4760, column 45): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var getRule = function (blocks) {
      return function (unichar) {
          return function (size) {
              var key = {
                  start: unichar,
                  length: 1,
                  convRule: nullrule
              };
              var maybeCharBlock = bsearch(key)(blocks)(size)(blkCmp);
              if (maybeCharBlock instanceof Data_Maybe.Nothing) {
                  return Data_Maybe.Nothing.value;
              };
              if (maybeCharBlock instanceof Data_Maybe.Just) {
                  return new Data_Maybe.Just(maybeCharBlock.value0.convRule);
              };
              throw new Error("Failed pattern match at Data.Char.Unicode.Internal (line 4773, column 8 - line 4775, column 62): " + [ maybeCharBlock.constructor.name ]);
          };
      };
  };
  var caseConv = function (f) {
      return function ($$char) {
          var maybeConversionRule = getRule(convchars)($$char)(numConvBlocks);
          if (maybeConversionRule instanceof Data_Maybe.Nothing) {
              return $$char;
          };
          if (maybeConversionRule instanceof Data_Maybe.Just) {
              return $$char + f(maybeConversionRule.value0) | 0;
          };
          throw new Error("Failed pattern match at Data.Char.Unicode.Internal (line 4850, column 8 - line 4852, column 55): " + [ maybeConversionRule.constructor.name ]);
      };
  };
  var uTowlower = caseConv(function (v) {
      return v.lowdist;
  });
  var uTowupper = caseConv(function (v) {
      return v.updist;
  });
  var checkAttrS = function (categories) {
      return function ($$char) {
          var maybeConversionRule = getRule(spacechars)($$char)(numSpaceBlocks);
          if (maybeConversionRule instanceof Data_Maybe.Nothing) {
              return false;
          };
          if (maybeConversionRule instanceof Data_Maybe.Just) {
              return Data_Maybe.isJust(Data_Array.elemIndex(Data_Eq.eqInt)(maybeConversionRule.value0.category)(categories));
          };
          throw new Error("Failed pattern match at Data.Char.Unicode.Internal (line 4807, column 8 - line 4809, column 92): " + [ maybeConversionRule.constructor.name ]);
      };
  };
  var uIswspace = checkAttrS([ gencatZS ]);
  var allchars = [ {
      start: 0,
      length: 32,
      convRule: rule0
  }, {
      start: 32,
      length: 1,
      convRule: rule1
  }, {
      start: 33,
      length: 3,
      convRule: rule2
  }, {
      start: 36,
      length: 1,
      convRule: rule3
  }, {
      start: 37,
      length: 3,
      convRule: rule2
  }, {
      start: 40,
      length: 1,
      convRule: rule4
  }, {
      start: 41,
      length: 1,
      convRule: rule5
  }, {
      start: 42,
      length: 1,
      convRule: rule2
  }, {
      start: 43,
      length: 1,
      convRule: rule6
  }, {
      start: 44,
      length: 1,
      convRule: rule2
  }, {
      start: 45,
      length: 1,
      convRule: rule7
  }, {
      start: 46,
      length: 2,
      convRule: rule2
  }, {
      start: 48,
      length: 10,
      convRule: rule8
  }, {
      start: 58,
      length: 2,
      convRule: rule2
  }, {
      start: 60,
      length: 3,
      convRule: rule6
  }, {
      start: 63,
      length: 2,
      convRule: rule2
  }, {
      start: 65,
      length: 26,
      convRule: rule9
  }, {
      start: 91,
      length: 1,
      convRule: rule4
  }, {
      start: 92,
      length: 1,
      convRule: rule2
  }, {
      start: 93,
      length: 1,
      convRule: rule5
  }, {
      start: 94,
      length: 1,
      convRule: rule10
  }, {
      start: 95,
      length: 1,
      convRule: rule11
  }, {
      start: 96,
      length: 1,
      convRule: rule10
  }, {
      start: 97,
      length: 26,
      convRule: rule12
  }, {
      start: 123,
      length: 1,
      convRule: rule4
  }, {
      start: 124,
      length: 1,
      convRule: rule6
  }, {
      start: 125,
      length: 1,
      convRule: rule5
  }, {
      start: 126,
      length: 1,
      convRule: rule6
  }, {
      start: 127,
      length: 33,
      convRule: rule0
  }, {
      start: 160,
      length: 1,
      convRule: rule1
  }, {
      start: 161,
      length: 1,
      convRule: rule2
  }, {
      start: 162,
      length: 4,
      convRule: rule3
  }, {
      start: 166,
      length: 2,
      convRule: rule13
  }, {
      start: 168,
      length: 1,
      convRule: rule10
  }, {
      start: 169,
      length: 1,
      convRule: rule13
  }, {
      start: 170,
      length: 1,
      convRule: rule14
  }, {
      start: 171,
      length: 1,
      convRule: rule15
  }, {
      start: 172,
      length: 1,
      convRule: rule6
  }, {
      start: 173,
      length: 1,
      convRule: rule16
  }, {
      start: 174,
      length: 1,
      convRule: rule13
  }, {
      start: 175,
      length: 1,
      convRule: rule10
  }, {
      start: 176,
      length: 1,
      convRule: rule13
  }, {
      start: 177,
      length: 1,
      convRule: rule6
  }, {
      start: 178,
      length: 2,
      convRule: rule17
  }, {
      start: 180,
      length: 1,
      convRule: rule10
  }, {
      start: 181,
      length: 1,
      convRule: rule18
  }, {
      start: 182,
      length: 1,
      convRule: rule13
  }, {
      start: 183,
      length: 1,
      convRule: rule2
  }, {
      start: 184,
      length: 1,
      convRule: rule10
  }, {
      start: 185,
      length: 1,
      convRule: rule17
  }, {
      start: 186,
      length: 1,
      convRule: rule14
  }, {
      start: 187,
      length: 1,
      convRule: rule19
  }, {
      start: 188,
      length: 3,
      convRule: rule17
  }, {
      start: 191,
      length: 1,
      convRule: rule2
  }, {
      start: 192,
      length: 23,
      convRule: rule9
  }, {
      start: 215,
      length: 1,
      convRule: rule6
  }, {
      start: 216,
      length: 7,
      convRule: rule9
  }, {
      start: 223,
      length: 1,
      convRule: rule14
  }, {
      start: 224,
      length: 23,
      convRule: rule12
  }, {
      start: 247,
      length: 1,
      convRule: rule6
  }, {
      start: 248,
      length: 7,
      convRule: rule12
  }, {
      start: 255,
      length: 1,
      convRule: rule20
  }, {
      start: 256,
      length: 1,
      convRule: rule21
  }, {
      start: 257,
      length: 1,
      convRule: rule22
  }, {
      start: 258,
      length: 1,
      convRule: rule21
  }, {
      start: 259,
      length: 1,
      convRule: rule22
  }, {
      start: 260,
      length: 1,
      convRule: rule21
  }, {
      start: 261,
      length: 1,
      convRule: rule22
  }, {
      start: 262,
      length: 1,
      convRule: rule21
  }, {
      start: 263,
      length: 1,
      convRule: rule22
  }, {
      start: 264,
      length: 1,
      convRule: rule21
  }, {
      start: 265,
      length: 1,
      convRule: rule22
  }, {
      start: 266,
      length: 1,
      convRule: rule21
  }, {
      start: 267,
      length: 1,
      convRule: rule22
  }, {
      start: 268,
      length: 1,
      convRule: rule21
  }, {
      start: 269,
      length: 1,
      convRule: rule22
  }, {
      start: 270,
      length: 1,
      convRule: rule21
  }, {
      start: 271,
      length: 1,
      convRule: rule22
  }, {
      start: 272,
      length: 1,
      convRule: rule21
  }, {
      start: 273,
      length: 1,
      convRule: rule22
  }, {
      start: 274,
      length: 1,
      convRule: rule21
  }, {
      start: 275,
      length: 1,
      convRule: rule22
  }, {
      start: 276,
      length: 1,
      convRule: rule21
  }, {
      start: 277,
      length: 1,
      convRule: rule22
  }, {
      start: 278,
      length: 1,
      convRule: rule21
  }, {
      start: 279,
      length: 1,
      convRule: rule22
  }, {
      start: 280,
      length: 1,
      convRule: rule21
  }, {
      start: 281,
      length: 1,
      convRule: rule22
  }, {
      start: 282,
      length: 1,
      convRule: rule21
  }, {
      start: 283,
      length: 1,
      convRule: rule22
  }, {
      start: 284,
      length: 1,
      convRule: rule21
  }, {
      start: 285,
      length: 1,
      convRule: rule22
  }, {
      start: 286,
      length: 1,
      convRule: rule21
  }, {
      start: 287,
      length: 1,
      convRule: rule22
  }, {
      start: 288,
      length: 1,
      convRule: rule21
  }, {
      start: 289,
      length: 1,
      convRule: rule22
  }, {
      start: 290,
      length: 1,
      convRule: rule21
  }, {
      start: 291,
      length: 1,
      convRule: rule22
  }, {
      start: 292,
      length: 1,
      convRule: rule21
  }, {
      start: 293,
      length: 1,
      convRule: rule22
  }, {
      start: 294,
      length: 1,
      convRule: rule21
  }, {
      start: 295,
      length: 1,
      convRule: rule22
  }, {
      start: 296,
      length: 1,
      convRule: rule21
  }, {
      start: 297,
      length: 1,
      convRule: rule22
  }, {
      start: 298,
      length: 1,
      convRule: rule21
  }, {
      start: 299,
      length: 1,
      convRule: rule22
  }, {
      start: 300,
      length: 1,
      convRule: rule21
  }, {
      start: 301,
      length: 1,
      convRule: rule22
  }, {
      start: 302,
      length: 1,
      convRule: rule21
  }, {
      start: 303,
      length: 1,
      convRule: rule22
  }, {
      start: 304,
      length: 1,
      convRule: rule23
  }, {
      start: 305,
      length: 1,
      convRule: rule24
  }, {
      start: 306,
      length: 1,
      convRule: rule21
  }, {
      start: 307,
      length: 1,
      convRule: rule22
  }, {
      start: 308,
      length: 1,
      convRule: rule21
  }, {
      start: 309,
      length: 1,
      convRule: rule22
  }, {
      start: 310,
      length: 1,
      convRule: rule21
  }, {
      start: 311,
      length: 1,
      convRule: rule22
  }, {
      start: 312,
      length: 1,
      convRule: rule14
  }, {
      start: 313,
      length: 1,
      convRule: rule21
  }, {
      start: 314,
      length: 1,
      convRule: rule22
  }, {
      start: 315,
      length: 1,
      convRule: rule21
  }, {
      start: 316,
      length: 1,
      convRule: rule22
  }, {
      start: 317,
      length: 1,
      convRule: rule21
  }, {
      start: 318,
      length: 1,
      convRule: rule22
  }, {
      start: 319,
      length: 1,
      convRule: rule21
  }, {
      start: 320,
      length: 1,
      convRule: rule22
  }, {
      start: 321,
      length: 1,
      convRule: rule21
  }, {
      start: 322,
      length: 1,
      convRule: rule22
  }, {
      start: 323,
      length: 1,
      convRule: rule21
  }, {
      start: 324,
      length: 1,
      convRule: rule22
  }, {
      start: 325,
      length: 1,
      convRule: rule21
  }, {
      start: 326,
      length: 1,
      convRule: rule22
  }, {
      start: 327,
      length: 1,
      convRule: rule21
  }, {
      start: 328,
      length: 1,
      convRule: rule22
  }, {
      start: 329,
      length: 1,
      convRule: rule14
  }, {
      start: 330,
      length: 1,
      convRule: rule21
  }, {
      start: 331,
      length: 1,
      convRule: rule22
  }, {
      start: 332,
      length: 1,
      convRule: rule21
  }, {
      start: 333,
      length: 1,
      convRule: rule22
  }, {
      start: 334,
      length: 1,
      convRule: rule21
  }, {
      start: 335,
      length: 1,
      convRule: rule22
  }, {
      start: 336,
      length: 1,
      convRule: rule21
  }, {
      start: 337,
      length: 1,
      convRule: rule22
  }, {
      start: 338,
      length: 1,
      convRule: rule21
  }, {
      start: 339,
      length: 1,
      convRule: rule22
  }, {
      start: 340,
      length: 1,
      convRule: rule21
  }, {
      start: 341,
      length: 1,
      convRule: rule22
  }, {
      start: 342,
      length: 1,
      convRule: rule21
  }, {
      start: 343,
      length: 1,
      convRule: rule22
  }, {
      start: 344,
      length: 1,
      convRule: rule21
  }, {
      start: 345,
      length: 1,
      convRule: rule22
  }, {
      start: 346,
      length: 1,
      convRule: rule21
  }, {
      start: 347,
      length: 1,
      convRule: rule22
  }, {
      start: 348,
      length: 1,
      convRule: rule21
  }, {
      start: 349,
      length: 1,
      convRule: rule22
  }, {
      start: 350,
      length: 1,
      convRule: rule21
  }, {
      start: 351,
      length: 1,
      convRule: rule22
  }, {
      start: 352,
      length: 1,
      convRule: rule21
  }, {
      start: 353,
      length: 1,
      convRule: rule22
  }, {
      start: 354,
      length: 1,
      convRule: rule21
  }, {
      start: 355,
      length: 1,
      convRule: rule22
  }, {
      start: 356,
      length: 1,
      convRule: rule21
  }, {
      start: 357,
      length: 1,
      convRule: rule22
  }, {
      start: 358,
      length: 1,
      convRule: rule21
  }, {
      start: 359,
      length: 1,
      convRule: rule22
  }, {
      start: 360,
      length: 1,
      convRule: rule21
  }, {
      start: 361,
      length: 1,
      convRule: rule22
  }, {
      start: 362,
      length: 1,
      convRule: rule21
  }, {
      start: 363,
      length: 1,
      convRule: rule22
  }, {
      start: 364,
      length: 1,
      convRule: rule21
  }, {
      start: 365,
      length: 1,
      convRule: rule22
  }, {
      start: 366,
      length: 1,
      convRule: rule21
  }, {
      start: 367,
      length: 1,
      convRule: rule22
  }, {
      start: 368,
      length: 1,
      convRule: rule21
  }, {
      start: 369,
      length: 1,
      convRule: rule22
  }, {
      start: 370,
      length: 1,
      convRule: rule21
  }, {
      start: 371,
      length: 1,
      convRule: rule22
  }, {
      start: 372,
      length: 1,
      convRule: rule21
  }, {
      start: 373,
      length: 1,
      convRule: rule22
  }, {
      start: 374,
      length: 1,
      convRule: rule21
  }, {
      start: 375,
      length: 1,
      convRule: rule22
  }, {
      start: 376,
      length: 1,
      convRule: rule25
  }, {
      start: 377,
      length: 1,
      convRule: rule21
  }, {
      start: 378,
      length: 1,
      convRule: rule22
  }, {
      start: 379,
      length: 1,
      convRule: rule21
  }, {
      start: 380,
      length: 1,
      convRule: rule22
  }, {
      start: 381,
      length: 1,
      convRule: rule21
  }, {
      start: 382,
      length: 1,
      convRule: rule22
  }, {
      start: 383,
      length: 1,
      convRule: rule26
  }, {
      start: 384,
      length: 1,
      convRule: rule27
  }, {
      start: 385,
      length: 1,
      convRule: rule28
  }, {
      start: 386,
      length: 1,
      convRule: rule21
  }, {
      start: 387,
      length: 1,
      convRule: rule22
  }, {
      start: 388,
      length: 1,
      convRule: rule21
  }, {
      start: 389,
      length: 1,
      convRule: rule22
  }, {
      start: 390,
      length: 1,
      convRule: rule29
  }, {
      start: 391,
      length: 1,
      convRule: rule21
  }, {
      start: 392,
      length: 1,
      convRule: rule22
  }, {
      start: 393,
      length: 2,
      convRule: rule30
  }, {
      start: 395,
      length: 1,
      convRule: rule21
  }, {
      start: 396,
      length: 1,
      convRule: rule22
  }, {
      start: 397,
      length: 1,
      convRule: rule14
  }, {
      start: 398,
      length: 1,
      convRule: rule31
  }, {
      start: 399,
      length: 1,
      convRule: rule32
  }, {
      start: 400,
      length: 1,
      convRule: rule33
  }, {
      start: 401,
      length: 1,
      convRule: rule21
  }, {
      start: 402,
      length: 1,
      convRule: rule22
  }, {
      start: 403,
      length: 1,
      convRule: rule30
  }, {
      start: 404,
      length: 1,
      convRule: rule34
  }, {
      start: 405,
      length: 1,
      convRule: rule35
  }, {
      start: 406,
      length: 1,
      convRule: rule36
  }, {
      start: 407,
      length: 1,
      convRule: rule37
  }, {
      start: 408,
      length: 1,
      convRule: rule21
  }, {
      start: 409,
      length: 1,
      convRule: rule22
  }, {
      start: 410,
      length: 1,
      convRule: rule38
  }, {
      start: 411,
      length: 1,
      convRule: rule14
  }, {
      start: 412,
      length: 1,
      convRule: rule36
  }, {
      start: 413,
      length: 1,
      convRule: rule39
  }, {
      start: 414,
      length: 1,
      convRule: rule40
  }, {
      start: 415,
      length: 1,
      convRule: rule41
  }, {
      start: 416,
      length: 1,
      convRule: rule21
  }, {
      start: 417,
      length: 1,
      convRule: rule22
  }, {
      start: 418,
      length: 1,
      convRule: rule21
  }, {
      start: 419,
      length: 1,
      convRule: rule22
  }, {
      start: 420,
      length: 1,
      convRule: rule21
  }, {
      start: 421,
      length: 1,
      convRule: rule22
  }, {
      start: 422,
      length: 1,
      convRule: rule42
  }, {
      start: 423,
      length: 1,
      convRule: rule21
  }, {
      start: 424,
      length: 1,
      convRule: rule22
  }, {
      start: 425,
      length: 1,
      convRule: rule42
  }, {
      start: 426,
      length: 2,
      convRule: rule14
  }, {
      start: 428,
      length: 1,
      convRule: rule21
  }, {
      start: 429,
      length: 1,
      convRule: rule22
  }, {
      start: 430,
      length: 1,
      convRule: rule42
  }, {
      start: 431,
      length: 1,
      convRule: rule21
  }, {
      start: 432,
      length: 1,
      convRule: rule22
  }, {
      start: 433,
      length: 2,
      convRule: rule43
  }, {
      start: 435,
      length: 1,
      convRule: rule21
  }, {
      start: 436,
      length: 1,
      convRule: rule22
  }, {
      start: 437,
      length: 1,
      convRule: rule21
  }, {
      start: 438,
      length: 1,
      convRule: rule22
  }, {
      start: 439,
      length: 1,
      convRule: rule44
  }, {
      start: 440,
      length: 1,
      convRule: rule21
  }, {
      start: 441,
      length: 1,
      convRule: rule22
  }, {
      start: 442,
      length: 1,
      convRule: rule14
  }, {
      start: 443,
      length: 1,
      convRule: rule45
  }, {
      start: 444,
      length: 1,
      convRule: rule21
  }, {
      start: 445,
      length: 1,
      convRule: rule22
  }, {
      start: 446,
      length: 1,
      convRule: rule14
  }, {
      start: 447,
      length: 1,
      convRule: rule46
  }, {
      start: 448,
      length: 4,
      convRule: rule45
  }, {
      start: 452,
      length: 1,
      convRule: rule47
  }, {
      start: 453,
      length: 1,
      convRule: rule48
  }, {
      start: 454,
      length: 1,
      convRule: rule49
  }, {
      start: 455,
      length: 1,
      convRule: rule47
  }, {
      start: 456,
      length: 1,
      convRule: rule48
  }, {
      start: 457,
      length: 1,
      convRule: rule49
  }, {
      start: 458,
      length: 1,
      convRule: rule47
  }, {
      start: 459,
      length: 1,
      convRule: rule48
  }, {
      start: 460,
      length: 1,
      convRule: rule49
  }, {
      start: 461,
      length: 1,
      convRule: rule21
  }, {
      start: 462,
      length: 1,
      convRule: rule22
  }, {
      start: 463,
      length: 1,
      convRule: rule21
  }, {
      start: 464,
      length: 1,
      convRule: rule22
  }, {
      start: 465,
      length: 1,
      convRule: rule21
  }, {
      start: 466,
      length: 1,
      convRule: rule22
  }, {
      start: 467,
      length: 1,
      convRule: rule21
  }, {
      start: 468,
      length: 1,
      convRule: rule22
  }, {
      start: 469,
      length: 1,
      convRule: rule21
  }, {
      start: 470,
      length: 1,
      convRule: rule22
  }, {
      start: 471,
      length: 1,
      convRule: rule21
  }, {
      start: 472,
      length: 1,
      convRule: rule22
  }, {
      start: 473,
      length: 1,
      convRule: rule21
  }, {
      start: 474,
      length: 1,
      convRule: rule22
  }, {
      start: 475,
      length: 1,
      convRule: rule21
  }, {
      start: 476,
      length: 1,
      convRule: rule22
  }, {
      start: 477,
      length: 1,
      convRule: rule50
  }, {
      start: 478,
      length: 1,
      convRule: rule21
  }, {
      start: 479,
      length: 1,
      convRule: rule22
  }, {
      start: 480,
      length: 1,
      convRule: rule21
  }, {
      start: 481,
      length: 1,
      convRule: rule22
  }, {
      start: 482,
      length: 1,
      convRule: rule21
  }, {
      start: 483,
      length: 1,
      convRule: rule22
  }, {
      start: 484,
      length: 1,
      convRule: rule21
  }, {
      start: 485,
      length: 1,
      convRule: rule22
  }, {
      start: 486,
      length: 1,
      convRule: rule21
  }, {
      start: 487,
      length: 1,
      convRule: rule22
  }, {
      start: 488,
      length: 1,
      convRule: rule21
  }, {
      start: 489,
      length: 1,
      convRule: rule22
  }, {
      start: 490,
      length: 1,
      convRule: rule21
  }, {
      start: 491,
      length: 1,
      convRule: rule22
  }, {
      start: 492,
      length: 1,
      convRule: rule21
  }, {
      start: 493,
      length: 1,
      convRule: rule22
  }, {
      start: 494,
      length: 1,
      convRule: rule21
  }, {
      start: 495,
      length: 1,
      convRule: rule22
  }, {
      start: 496,
      length: 1,
      convRule: rule14
  }, {
      start: 497,
      length: 1,
      convRule: rule47
  }, {
      start: 498,
      length: 1,
      convRule: rule48
  }, {
      start: 499,
      length: 1,
      convRule: rule49
  }, {
      start: 500,
      length: 1,
      convRule: rule21
  }, {
      start: 501,
      length: 1,
      convRule: rule22
  }, {
      start: 502,
      length: 1,
      convRule: rule51
  }, {
      start: 503,
      length: 1,
      convRule: rule52
  }, {
      start: 504,
      length: 1,
      convRule: rule21
  }, {
      start: 505,
      length: 1,
      convRule: rule22
  }, {
      start: 506,
      length: 1,
      convRule: rule21
  }, {
      start: 507,
      length: 1,
      convRule: rule22
  }, {
      start: 508,
      length: 1,
      convRule: rule21
  }, {
      start: 509,
      length: 1,
      convRule: rule22
  }, {
      start: 510,
      length: 1,
      convRule: rule21
  }, {
      start: 511,
      length: 1,
      convRule: rule22
  }, {
      start: 512,
      length: 1,
      convRule: rule21
  }, {
      start: 513,
      length: 1,
      convRule: rule22
  }, {
      start: 514,
      length: 1,
      convRule: rule21
  }, {
      start: 515,
      length: 1,
      convRule: rule22
  }, {
      start: 516,
      length: 1,
      convRule: rule21
  }, {
      start: 517,
      length: 1,
      convRule: rule22
  }, {
      start: 518,
      length: 1,
      convRule: rule21
  }, {
      start: 519,
      length: 1,
      convRule: rule22
  }, {
      start: 520,
      length: 1,
      convRule: rule21
  }, {
      start: 521,
      length: 1,
      convRule: rule22
  }, {
      start: 522,
      length: 1,
      convRule: rule21
  }, {
      start: 523,
      length: 1,
      convRule: rule22
  }, {
      start: 524,
      length: 1,
      convRule: rule21
  }, {
      start: 525,
      length: 1,
      convRule: rule22
  }, {
      start: 526,
      length: 1,
      convRule: rule21
  }, {
      start: 527,
      length: 1,
      convRule: rule22
  }, {
      start: 528,
      length: 1,
      convRule: rule21
  }, {
      start: 529,
      length: 1,
      convRule: rule22
  }, {
      start: 530,
      length: 1,
      convRule: rule21
  }, {
      start: 531,
      length: 1,
      convRule: rule22
  }, {
      start: 532,
      length: 1,
      convRule: rule21
  }, {
      start: 533,
      length: 1,
      convRule: rule22
  }, {
      start: 534,
      length: 1,
      convRule: rule21
  }, {
      start: 535,
      length: 1,
      convRule: rule22
  }, {
      start: 536,
      length: 1,
      convRule: rule21
  }, {
      start: 537,
      length: 1,
      convRule: rule22
  }, {
      start: 538,
      length: 1,
      convRule: rule21
  }, {
      start: 539,
      length: 1,
      convRule: rule22
  }, {
      start: 540,
      length: 1,
      convRule: rule21
  }, {
      start: 541,
      length: 1,
      convRule: rule22
  }, {
      start: 542,
      length: 1,
      convRule: rule21
  }, {
      start: 543,
      length: 1,
      convRule: rule22
  }, {
      start: 544,
      length: 1,
      convRule: rule53
  }, {
      start: 545,
      length: 1,
      convRule: rule14
  }, {
      start: 546,
      length: 1,
      convRule: rule21
  }, {
      start: 547,
      length: 1,
      convRule: rule22
  }, {
      start: 548,
      length: 1,
      convRule: rule21
  }, {
      start: 549,
      length: 1,
      convRule: rule22
  }, {
      start: 550,
      length: 1,
      convRule: rule21
  }, {
      start: 551,
      length: 1,
      convRule: rule22
  }, {
      start: 552,
      length: 1,
      convRule: rule21
  }, {
      start: 553,
      length: 1,
      convRule: rule22
  }, {
      start: 554,
      length: 1,
      convRule: rule21
  }, {
      start: 555,
      length: 1,
      convRule: rule22
  }, {
      start: 556,
      length: 1,
      convRule: rule21
  }, {
      start: 557,
      length: 1,
      convRule: rule22
  }, {
      start: 558,
      length: 1,
      convRule: rule21
  }, {
      start: 559,
      length: 1,
      convRule: rule22
  }, {
      start: 560,
      length: 1,
      convRule: rule21
  }, {
      start: 561,
      length: 1,
      convRule: rule22
  }, {
      start: 562,
      length: 1,
      convRule: rule21
  }, {
      start: 563,
      length: 1,
      convRule: rule22
  }, {
      start: 564,
      length: 6,
      convRule: rule14
  }, {
      start: 570,
      length: 1,
      convRule: rule54
  }, {
      start: 571,
      length: 1,
      convRule: rule21
  }, {
      start: 572,
      length: 1,
      convRule: rule22
  }, {
      start: 573,
      length: 1,
      convRule: rule55
  }, {
      start: 574,
      length: 1,
      convRule: rule56
  }, {
      start: 575,
      length: 2,
      convRule: rule57
  }, {
      start: 577,
      length: 1,
      convRule: rule21
  }, {
      start: 578,
      length: 1,
      convRule: rule22
  }, {
      start: 579,
      length: 1,
      convRule: rule58
  }, {
      start: 580,
      length: 1,
      convRule: rule59
  }, {
      start: 581,
      length: 1,
      convRule: rule60
  }, {
      start: 582,
      length: 1,
      convRule: rule21
  }, {
      start: 583,
      length: 1,
      convRule: rule22
  }, {
      start: 584,
      length: 1,
      convRule: rule21
  }, {
      start: 585,
      length: 1,
      convRule: rule22
  }, {
      start: 586,
      length: 1,
      convRule: rule21
  }, {
      start: 587,
      length: 1,
      convRule: rule22
  }, {
      start: 588,
      length: 1,
      convRule: rule21
  }, {
      start: 589,
      length: 1,
      convRule: rule22
  }, {
      start: 590,
      length: 1,
      convRule: rule21
  }, {
      start: 591,
      length: 1,
      convRule: rule22
  }, {
      start: 592,
      length: 1,
      convRule: rule61
  }, {
      start: 593,
      length: 1,
      convRule: rule62
  }, {
      start: 594,
      length: 1,
      convRule: rule63
  }, {
      start: 595,
      length: 1,
      convRule: rule64
  }, {
      start: 596,
      length: 1,
      convRule: rule65
  }, {
      start: 597,
      length: 1,
      convRule: rule14
  }, {
      start: 598,
      length: 2,
      convRule: rule66
  }, {
      start: 600,
      length: 1,
      convRule: rule14
  }, {
      start: 601,
      length: 1,
      convRule: rule67
  }, {
      start: 602,
      length: 1,
      convRule: rule14
  }, {
      start: 603,
      length: 1,
      convRule: rule68
  }, {
      start: 604,
      length: 4,
      convRule: rule14
  }, {
      start: 608,
      length: 1,
      convRule: rule66
  }, {
      start: 609,
      length: 2,
      convRule: rule14
  }, {
      start: 611,
      length: 1,
      convRule: rule69
  }, {
      start: 612,
      length: 1,
      convRule: rule14
  }, {
      start: 613,
      length: 1,
      convRule: rule70
  }, {
      start: 614,
      length: 2,
      convRule: rule14
  }, {
      start: 616,
      length: 1,
      convRule: rule71
  }, {
      start: 617,
      length: 1,
      convRule: rule72
  }, {
      start: 618,
      length: 1,
      convRule: rule14
  }, {
      start: 619,
      length: 1,
      convRule: rule73
  }, {
      start: 620,
      length: 3,
      convRule: rule14
  }, {
      start: 623,
      length: 1,
      convRule: rule72
  }, {
      start: 624,
      length: 1,
      convRule: rule14
  }, {
      start: 625,
      length: 1,
      convRule: rule74
  }, {
      start: 626,
      length: 1,
      convRule: rule75
  }, {
      start: 627,
      length: 2,
      convRule: rule14
  }, {
      start: 629,
      length: 1,
      convRule: rule76
  }, {
      start: 630,
      length: 7,
      convRule: rule14
  }, {
      start: 637,
      length: 1,
      convRule: rule77
  }, {
      start: 638,
      length: 2,
      convRule: rule14
  }, {
      start: 640,
      length: 1,
      convRule: rule78
  }, {
      start: 641,
      length: 2,
      convRule: rule14
  }, {
      start: 643,
      length: 1,
      convRule: rule78
  }, {
      start: 644,
      length: 4,
      convRule: rule14
  }, {
      start: 648,
      length: 1,
      convRule: rule78
  }, {
      start: 649,
      length: 1,
      convRule: rule79
  }, {
      start: 650,
      length: 2,
      convRule: rule80
  }, {
      start: 652,
      length: 1,
      convRule: rule81
  }, {
      start: 653,
      length: 5,
      convRule: rule14
  }, {
      start: 658,
      length: 1,
      convRule: rule82
  }, {
      start: 659,
      length: 1,
      convRule: rule14
  }, {
      start: 660,
      length: 1,
      convRule: rule45
  }, {
      start: 661,
      length: 27,
      convRule: rule14
  }, {
      start: 688,
      length: 18,
      convRule: rule83
  }, {
      start: 706,
      length: 4,
      convRule: rule10
  }, {
      start: 710,
      length: 12,
      convRule: rule83
  }, {
      start: 722,
      length: 14,
      convRule: rule10
  }, {
      start: 736,
      length: 5,
      convRule: rule83
  }, {
      start: 741,
      length: 7,
      convRule: rule10
  }, {
      start: 748,
      length: 1,
      convRule: rule83
  }, {
      start: 749,
      length: 1,
      convRule: rule10
  }, {
      start: 750,
      length: 1,
      convRule: rule83
  }, {
      start: 751,
      length: 17,
      convRule: rule10
  }, {
      start: 768,
      length: 69,
      convRule: rule84
  }, {
      start: 837,
      length: 1,
      convRule: rule85
  }, {
      start: 838,
      length: 42,
      convRule: rule84
  }, {
      start: 880,
      length: 1,
      convRule: rule21
  }, {
      start: 881,
      length: 1,
      convRule: rule22
  }, {
      start: 882,
      length: 1,
      convRule: rule21
  }, {
      start: 883,
      length: 1,
      convRule: rule22
  }, {
      start: 884,
      length: 1,
      convRule: rule83
  }, {
      start: 885,
      length: 1,
      convRule: rule10
  }, {
      start: 886,
      length: 1,
      convRule: rule21
  }, {
      start: 887,
      length: 1,
      convRule: rule22
  }, {
      start: 890,
      length: 1,
      convRule: rule83
  }, {
      start: 891,
      length: 3,
      convRule: rule40
  }, {
      start: 894,
      length: 1,
      convRule: rule2
  }, {
      start: 900,
      length: 2,
      convRule: rule10
  }, {
      start: 902,
      length: 1,
      convRule: rule86
  }, {
      start: 903,
      length: 1,
      convRule: rule2
  }, {
      start: 904,
      length: 3,
      convRule: rule87
  }, {
      start: 908,
      length: 1,
      convRule: rule88
  }, {
      start: 910,
      length: 2,
      convRule: rule89
  }, {
      start: 912,
      length: 1,
      convRule: rule14
  }, {
      start: 913,
      length: 17,
      convRule: rule9
  }, {
      start: 931,
      length: 9,
      convRule: rule9
  }, {
      start: 940,
      length: 1,
      convRule: rule90
  }, {
      start: 941,
      length: 3,
      convRule: rule91
  }, {
      start: 944,
      length: 1,
      convRule: rule14
  }, {
      start: 945,
      length: 17,
      convRule: rule12
  }, {
      start: 962,
      length: 1,
      convRule: rule92
  }, {
      start: 963,
      length: 9,
      convRule: rule12
  }, {
      start: 972,
      length: 1,
      convRule: rule93
  }, {
      start: 973,
      length: 2,
      convRule: rule94
  }, {
      start: 975,
      length: 1,
      convRule: rule95
  }, {
      start: 976,
      length: 1,
      convRule: rule96
  }, {
      start: 977,
      length: 1,
      convRule: rule97
  }, {
      start: 978,
      length: 3,
      convRule: rule98
  }, {
      start: 981,
      length: 1,
      convRule: rule99
  }, {
      start: 982,
      length: 1,
      convRule: rule100
  }, {
      start: 983,
      length: 1,
      convRule: rule101
  }, {
      start: 984,
      length: 1,
      convRule: rule21
  }, {
      start: 985,
      length: 1,
      convRule: rule22
  }, {
      start: 986,
      length: 1,
      convRule: rule21
  }, {
      start: 987,
      length: 1,
      convRule: rule22
  }, {
      start: 988,
      length: 1,
      convRule: rule21
  }, {
      start: 989,
      length: 1,
      convRule: rule22
  }, {
      start: 990,
      length: 1,
      convRule: rule21
  }, {
      start: 991,
      length: 1,
      convRule: rule22
  }, {
      start: 992,
      length: 1,
      convRule: rule21
  }, {
      start: 993,
      length: 1,
      convRule: rule22
  }, {
      start: 994,
      length: 1,
      convRule: rule21
  }, {
      start: 995,
      length: 1,
      convRule: rule22
  }, {
      start: 996,
      length: 1,
      convRule: rule21
  }, {
      start: 997,
      length: 1,
      convRule: rule22
  }, {
      start: 998,
      length: 1,
      convRule: rule21
  }, {
      start: 999,
      length: 1,
      convRule: rule22
  }, {
      start: 1000,
      length: 1,
      convRule: rule21
  }, {
      start: 1001,
      length: 1,
      convRule: rule22
  }, {
      start: 1002,
      length: 1,
      convRule: rule21
  }, {
      start: 1003,
      length: 1,
      convRule: rule22
  }, {
      start: 1004,
      length: 1,
      convRule: rule21
  }, {
      start: 1005,
      length: 1,
      convRule: rule22
  }, {
      start: 1006,
      length: 1,
      convRule: rule21
  }, {
      start: 1007,
      length: 1,
      convRule: rule22
  }, {
      start: 1008,
      length: 1,
      convRule: rule102
  }, {
      start: 1009,
      length: 1,
      convRule: rule103
  }, {
      start: 1010,
      length: 1,
      convRule: rule104
  }, {
      start: 1011,
      length: 1,
      convRule: rule14
  }, {
      start: 1012,
      length: 1,
      convRule: rule105
  }, {
      start: 1013,
      length: 1,
      convRule: rule106
  }, {
      start: 1014,
      length: 1,
      convRule: rule6
  }, {
      start: 1015,
      length: 1,
      convRule: rule21
  }, {
      start: 1016,
      length: 1,
      convRule: rule22
  }, {
      start: 1017,
      length: 1,
      convRule: rule107
  }, {
      start: 1018,
      length: 1,
      convRule: rule21
  }, {
      start: 1019,
      length: 1,
      convRule: rule22
  }, {
      start: 1020,
      length: 1,
      convRule: rule14
  }, {
      start: 1021,
      length: 3,
      convRule: rule53
  }, {
      start: 1024,
      length: 16,
      convRule: rule108
  }, {
      start: 1040,
      length: 32,
      convRule: rule9
  }, {
      start: 1072,
      length: 32,
      convRule: rule12
  }, {
      start: 1104,
      length: 16,
      convRule: rule103
  }, {
      start: 1120,
      length: 1,
      convRule: rule21
  }, {
      start: 1121,
      length: 1,
      convRule: rule22
  }, {
      start: 1122,
      length: 1,
      convRule: rule21
  }, {
      start: 1123,
      length: 1,
      convRule: rule22
  }, {
      start: 1124,
      length: 1,
      convRule: rule21
  }, {
      start: 1125,
      length: 1,
      convRule: rule22
  }, {
      start: 1126,
      length: 1,
      convRule: rule21
  }, {
      start: 1127,
      length: 1,
      convRule: rule22
  }, {
      start: 1128,
      length: 1,
      convRule: rule21
  }, {
      start: 1129,
      length: 1,
      convRule: rule22
  }, {
      start: 1130,
      length: 1,
      convRule: rule21
  }, {
      start: 1131,
      length: 1,
      convRule: rule22
  }, {
      start: 1132,
      length: 1,
      convRule: rule21
  }, {
      start: 1133,
      length: 1,
      convRule: rule22
  }, {
      start: 1134,
      length: 1,
      convRule: rule21
  }, {
      start: 1135,
      length: 1,
      convRule: rule22
  }, {
      start: 1136,
      length: 1,
      convRule: rule21
  }, {
      start: 1137,
      length: 1,
      convRule: rule22
  }, {
      start: 1138,
      length: 1,
      convRule: rule21
  }, {
      start: 1139,
      length: 1,
      convRule: rule22
  }, {
      start: 1140,
      length: 1,
      convRule: rule21
  }, {
      start: 1141,
      length: 1,
      convRule: rule22
  }, {
      start: 1142,
      length: 1,
      convRule: rule21
  }, {
      start: 1143,
      length: 1,
      convRule: rule22
  }, {
      start: 1144,
      length: 1,
      convRule: rule21
  }, {
      start: 1145,
      length: 1,
      convRule: rule22
  }, {
      start: 1146,
      length: 1,
      convRule: rule21
  }, {
      start: 1147,
      length: 1,
      convRule: rule22
  }, {
      start: 1148,
      length: 1,
      convRule: rule21
  }, {
      start: 1149,
      length: 1,
      convRule: rule22
  }, {
      start: 1150,
      length: 1,
      convRule: rule21
  }, {
      start: 1151,
      length: 1,
      convRule: rule22
  }, {
      start: 1152,
      length: 1,
      convRule: rule21
  }, {
      start: 1153,
      length: 1,
      convRule: rule22
  }, {
      start: 1154,
      length: 1,
      convRule: rule13
  }, {
      start: 1155,
      length: 5,
      convRule: rule84
  }, {
      start: 1160,
      length: 2,
      convRule: rule109
  }, {
      start: 1162,
      length: 1,
      convRule: rule21
  }, {
      start: 1163,
      length: 1,
      convRule: rule22
  }, {
      start: 1164,
      length: 1,
      convRule: rule21
  }, {
      start: 1165,
      length: 1,
      convRule: rule22
  }, {
      start: 1166,
      length: 1,
      convRule: rule21
  }, {
      start: 1167,
      length: 1,
      convRule: rule22
  }, {
      start: 1168,
      length: 1,
      convRule: rule21
  }, {
      start: 1169,
      length: 1,
      convRule: rule22
  }, {
      start: 1170,
      length: 1,
      convRule: rule21
  }, {
      start: 1171,
      length: 1,
      convRule: rule22
  }, {
      start: 1172,
      length: 1,
      convRule: rule21
  }, {
      start: 1173,
      length: 1,
      convRule: rule22
  }, {
      start: 1174,
      length: 1,
      convRule: rule21
  }, {
      start: 1175,
      length: 1,
      convRule: rule22
  }, {
      start: 1176,
      length: 1,
      convRule: rule21
  }, {
      start: 1177,
      length: 1,
      convRule: rule22
  }, {
      start: 1178,
      length: 1,
      convRule: rule21
  }, {
      start: 1179,
      length: 1,
      convRule: rule22
  }, {
      start: 1180,
      length: 1,
      convRule: rule21
  }, {
      start: 1181,
      length: 1,
      convRule: rule22
  }, {
      start: 1182,
      length: 1,
      convRule: rule21
  }, {
      start: 1183,
      length: 1,
      convRule: rule22
  }, {
      start: 1184,
      length: 1,
      convRule: rule21
  }, {
      start: 1185,
      length: 1,
      convRule: rule22
  }, {
      start: 1186,
      length: 1,
      convRule: rule21
  }, {
      start: 1187,
      length: 1,
      convRule: rule22
  }, {
      start: 1188,
      length: 1,
      convRule: rule21
  }, {
      start: 1189,
      length: 1,
      convRule: rule22
  }, {
      start: 1190,
      length: 1,
      convRule: rule21
  }, {
      start: 1191,
      length: 1,
      convRule: rule22
  }, {
      start: 1192,
      length: 1,
      convRule: rule21
  }, {
      start: 1193,
      length: 1,
      convRule: rule22
  }, {
      start: 1194,
      length: 1,
      convRule: rule21
  }, {
      start: 1195,
      length: 1,
      convRule: rule22
  }, {
      start: 1196,
      length: 1,
      convRule: rule21
  }, {
      start: 1197,
      length: 1,
      convRule: rule22
  }, {
      start: 1198,
      length: 1,
      convRule: rule21
  }, {
      start: 1199,
      length: 1,
      convRule: rule22
  }, {
      start: 1200,
      length: 1,
      convRule: rule21
  }, {
      start: 1201,
      length: 1,
      convRule: rule22
  }, {
      start: 1202,
      length: 1,
      convRule: rule21
  }, {
      start: 1203,
      length: 1,
      convRule: rule22
  }, {
      start: 1204,
      length: 1,
      convRule: rule21
  }, {
      start: 1205,
      length: 1,
      convRule: rule22
  }, {
      start: 1206,
      length: 1,
      convRule: rule21
  }, {
      start: 1207,
      length: 1,
      convRule: rule22
  }, {
      start: 1208,
      length: 1,
      convRule: rule21
  }, {
      start: 1209,
      length: 1,
      convRule: rule22
  }, {
      start: 1210,
      length: 1,
      convRule: rule21
  }, {
      start: 1211,
      length: 1,
      convRule: rule22
  }, {
      start: 1212,
      length: 1,
      convRule: rule21
  }, {
      start: 1213,
      length: 1,
      convRule: rule22
  }, {
      start: 1214,
      length: 1,
      convRule: rule21
  }, {
      start: 1215,
      length: 1,
      convRule: rule22
  }, {
      start: 1216,
      length: 1,
      convRule: rule110
  }, {
      start: 1217,
      length: 1,
      convRule: rule21
  }, {
      start: 1218,
      length: 1,
      convRule: rule22
  }, {
      start: 1219,
      length: 1,
      convRule: rule21
  }, {
      start: 1220,
      length: 1,
      convRule: rule22
  }, {
      start: 1221,
      length: 1,
      convRule: rule21
  }, {
      start: 1222,
      length: 1,
      convRule: rule22
  }, {
      start: 1223,
      length: 1,
      convRule: rule21
  }, {
      start: 1224,
      length: 1,
      convRule: rule22
  }, {
      start: 1225,
      length: 1,
      convRule: rule21
  }, {
      start: 1226,
      length: 1,
      convRule: rule22
  }, {
      start: 1227,
      length: 1,
      convRule: rule21
  }, {
      start: 1228,
      length: 1,
      convRule: rule22
  }, {
      start: 1229,
      length: 1,
      convRule: rule21
  }, {
      start: 1230,
      length: 1,
      convRule: rule22
  }, {
      start: 1231,
      length: 1,
      convRule: rule111
  }, {
      start: 1232,
      length: 1,
      convRule: rule21
  }, {
      start: 1233,
      length: 1,
      convRule: rule22
  }, {
      start: 1234,
      length: 1,
      convRule: rule21
  }, {
      start: 1235,
      length: 1,
      convRule: rule22
  }, {
      start: 1236,
      length: 1,
      convRule: rule21
  }, {
      start: 1237,
      length: 1,
      convRule: rule22
  }, {
      start: 1238,
      length: 1,
      convRule: rule21
  }, {
      start: 1239,
      length: 1,
      convRule: rule22
  }, {
      start: 1240,
      length: 1,
      convRule: rule21
  }, {
      start: 1241,
      length: 1,
      convRule: rule22
  }, {
      start: 1242,
      length: 1,
      convRule: rule21
  }, {
      start: 1243,
      length: 1,
      convRule: rule22
  }, {
      start: 1244,
      length: 1,
      convRule: rule21
  }, {
      start: 1245,
      length: 1,
      convRule: rule22
  }, {
      start: 1246,
      length: 1,
      convRule: rule21
  }, {
      start: 1247,
      length: 1,
      convRule: rule22
  }, {
      start: 1248,
      length: 1,
      convRule: rule21
  }, {
      start: 1249,
      length: 1,
      convRule: rule22
  }, {
      start: 1250,
      length: 1,
      convRule: rule21
  }, {
      start: 1251,
      length: 1,
      convRule: rule22
  }, {
      start: 1252,
      length: 1,
      convRule: rule21
  }, {
      start: 1253,
      length: 1,
      convRule: rule22
  }, {
      start: 1254,
      length: 1,
      convRule: rule21
  }, {
      start: 1255,
      length: 1,
      convRule: rule22
  }, {
      start: 1256,
      length: 1,
      convRule: rule21
  }, {
      start: 1257,
      length: 1,
      convRule: rule22
  }, {
      start: 1258,
      length: 1,
      convRule: rule21
  }, {
      start: 1259,
      length: 1,
      convRule: rule22
  }, {
      start: 1260,
      length: 1,
      convRule: rule21
  }, {
      start: 1261,
      length: 1,
      convRule: rule22
  }, {
      start: 1262,
      length: 1,
      convRule: rule21
  }, {
      start: 1263,
      length: 1,
      convRule: rule22
  }, {
      start: 1264,
      length: 1,
      convRule: rule21
  }, {
      start: 1265,
      length: 1,
      convRule: rule22
  }, {
      start: 1266,
      length: 1,
      convRule: rule21
  }, {
      start: 1267,
      length: 1,
      convRule: rule22
  }, {
      start: 1268,
      length: 1,
      convRule: rule21
  }, {
      start: 1269,
      length: 1,
      convRule: rule22
  }, {
      start: 1270,
      length: 1,
      convRule: rule21
  }, {
      start: 1271,
      length: 1,
      convRule: rule22
  }, {
      start: 1272,
      length: 1,
      convRule: rule21
  }, {
      start: 1273,
      length: 1,
      convRule: rule22
  }, {
      start: 1274,
      length: 1,
      convRule: rule21
  }, {
      start: 1275,
      length: 1,
      convRule: rule22
  }, {
      start: 1276,
      length: 1,
      convRule: rule21
  }, {
      start: 1277,
      length: 1,
      convRule: rule22
  }, {
      start: 1278,
      length: 1,
      convRule: rule21
  }, {
      start: 1279,
      length: 1,
      convRule: rule22
  }, {
      start: 1280,
      length: 1,
      convRule: rule21
  }, {
      start: 1281,
      length: 1,
      convRule: rule22
  }, {
      start: 1282,
      length: 1,
      convRule: rule21
  }, {
      start: 1283,
      length: 1,
      convRule: rule22
  }, {
      start: 1284,
      length: 1,
      convRule: rule21
  }, {
      start: 1285,
      length: 1,
      convRule: rule22
  }, {
      start: 1286,
      length: 1,
      convRule: rule21
  }, {
      start: 1287,
      length: 1,
      convRule: rule22
  }, {
      start: 1288,
      length: 1,
      convRule: rule21
  }, {
      start: 1289,
      length: 1,
      convRule: rule22
  }, {
      start: 1290,
      length: 1,
      convRule: rule21
  }, {
      start: 1291,
      length: 1,
      convRule: rule22
  }, {
      start: 1292,
      length: 1,
      convRule: rule21
  }, {
      start: 1293,
      length: 1,
      convRule: rule22
  }, {
      start: 1294,
      length: 1,
      convRule: rule21
  }, {
      start: 1295,
      length: 1,
      convRule: rule22
  }, {
      start: 1296,
      length: 1,
      convRule: rule21
  }, {
      start: 1297,
      length: 1,
      convRule: rule22
  }, {
      start: 1298,
      length: 1,
      convRule: rule21
  }, {
      start: 1299,
      length: 1,
      convRule: rule22
  }, {
      start: 1300,
      length: 1,
      convRule: rule21
  }, {
      start: 1301,
      length: 1,
      convRule: rule22
  }, {
      start: 1302,
      length: 1,
      convRule: rule21
  }, {
      start: 1303,
      length: 1,
      convRule: rule22
  }, {
      start: 1304,
      length: 1,
      convRule: rule21
  }, {
      start: 1305,
      length: 1,
      convRule: rule22
  }, {
      start: 1306,
      length: 1,
      convRule: rule21
  }, {
      start: 1307,
      length: 1,
      convRule: rule22
  }, {
      start: 1308,
      length: 1,
      convRule: rule21
  }, {
      start: 1309,
      length: 1,
      convRule: rule22
  }, {
      start: 1310,
      length: 1,
      convRule: rule21
  }, {
      start: 1311,
      length: 1,
      convRule: rule22
  }, {
      start: 1312,
      length: 1,
      convRule: rule21
  }, {
      start: 1313,
      length: 1,
      convRule: rule22
  }, {
      start: 1314,
      length: 1,
      convRule: rule21
  }, {
      start: 1315,
      length: 1,
      convRule: rule22
  }, {
      start: 1316,
      length: 1,
      convRule: rule21
  }, {
      start: 1317,
      length: 1,
      convRule: rule22
  }, {
      start: 1318,
      length: 1,
      convRule: rule21
  }, {
      start: 1319,
      length: 1,
      convRule: rule22
  }, {
      start: 1329,
      length: 38,
      convRule: rule112
  }, {
      start: 1369,
      length: 1,
      convRule: rule83
  }, {
      start: 1370,
      length: 6,
      convRule: rule2
  }, {
      start: 1377,
      length: 38,
      convRule: rule113
  }, {
      start: 1415,
      length: 1,
      convRule: rule14
  }, {
      start: 1417,
      length: 1,
      convRule: rule2
  }, {
      start: 1418,
      length: 1,
      convRule: rule7
  }, {
      start: 1425,
      length: 45,
      convRule: rule84
  }, {
      start: 1470,
      length: 1,
      convRule: rule7
  }, {
      start: 1471,
      length: 1,
      convRule: rule84
  }, {
      start: 1472,
      length: 1,
      convRule: rule2
  }, {
      start: 1473,
      length: 2,
      convRule: rule84
  }, {
      start: 1475,
      length: 1,
      convRule: rule2
  }, {
      start: 1476,
      length: 2,
      convRule: rule84
  }, {
      start: 1478,
      length: 1,
      convRule: rule2
  }, {
      start: 1479,
      length: 1,
      convRule: rule84
  }, {
      start: 1488,
      length: 27,
      convRule: rule45
  }, {
      start: 1520,
      length: 3,
      convRule: rule45
  }, {
      start: 1523,
      length: 2,
      convRule: rule2
  }, {
      start: 1536,
      length: 4,
      convRule: rule16
  }, {
      start: 1542,
      length: 3,
      convRule: rule6
  }, {
      start: 1545,
      length: 2,
      convRule: rule2
  }, {
      start: 1547,
      length: 1,
      convRule: rule3
  }, {
      start: 1548,
      length: 2,
      convRule: rule2
  }, {
      start: 1550,
      length: 2,
      convRule: rule13
  }, {
      start: 1552,
      length: 11,
      convRule: rule84
  }, {
      start: 1563,
      length: 1,
      convRule: rule2
  }, {
      start: 1566,
      length: 2,
      convRule: rule2
  }, {
      start: 1568,
      length: 32,
      convRule: rule45
  }, {
      start: 1600,
      length: 1,
      convRule: rule83
  }, {
      start: 1601,
      length: 10,
      convRule: rule45
  }, {
      start: 1611,
      length: 21,
      convRule: rule84
  }, {
      start: 1632,
      length: 10,
      convRule: rule8
  }, {
      start: 1642,
      length: 4,
      convRule: rule2
  }, {
      start: 1646,
      length: 2,
      convRule: rule45
  }, {
      start: 1648,
      length: 1,
      convRule: rule84
  }, {
      start: 1649,
      length: 99,
      convRule: rule45
  }, {
      start: 1748,
      length: 1,
      convRule: rule2
  }, {
      start: 1749,
      length: 1,
      convRule: rule45
  }, {
      start: 1750,
      length: 7,
      convRule: rule84
  }, {
      start: 1757,
      length: 1,
      convRule: rule16
  }, {
      start: 1758,
      length: 1,
      convRule: rule13
  }, {
      start: 1759,
      length: 6,
      convRule: rule84
  }, {
      start: 1765,
      length: 2,
      convRule: rule83
  }, {
      start: 1767,
      length: 2,
      convRule: rule84
  }, {
      start: 1769,
      length: 1,
      convRule: rule13
  }, {
      start: 1770,
      length: 4,
      convRule: rule84
  }, {
      start: 1774,
      length: 2,
      convRule: rule45
  }, {
      start: 1776,
      length: 10,
      convRule: rule8
  }, {
      start: 1786,
      length: 3,
      convRule: rule45
  }, {
      start: 1789,
      length: 2,
      convRule: rule13
  }, {
      start: 1791,
      length: 1,
      convRule: rule45
  }, {
      start: 1792,
      length: 14,
      convRule: rule2
  }, {
      start: 1807,
      length: 1,
      convRule: rule16
  }, {
      start: 1808,
      length: 1,
      convRule: rule45
  }, {
      start: 1809,
      length: 1,
      convRule: rule84
  }, {
      start: 1810,
      length: 30,
      convRule: rule45
  }, {
      start: 1840,
      length: 27,
      convRule: rule84
  }, {
      start: 1869,
      length: 89,
      convRule: rule45
  }, {
      start: 1958,
      length: 11,
      convRule: rule84
  }, {
      start: 1969,
      length: 1,
      convRule: rule45
  }, {
      start: 1984,
      length: 10,
      convRule: rule8
  }, {
      start: 1994,
      length: 33,
      convRule: rule45
  }, {
      start: 2027,
      length: 9,
      convRule: rule84
  }, {
      start: 2036,
      length: 2,
      convRule: rule83
  }, {
      start: 2038,
      length: 1,
      convRule: rule13
  }, {
      start: 2039,
      length: 3,
      convRule: rule2
  }, {
      start: 2042,
      length: 1,
      convRule: rule83
  }, {
      start: 2048,
      length: 22,
      convRule: rule45
  }, {
      start: 2070,
      length: 4,
      convRule: rule84
  }, {
      start: 2074,
      length: 1,
      convRule: rule83
  }, {
      start: 2075,
      length: 9,
      convRule: rule84
  }, {
      start: 2084,
      length: 1,
      convRule: rule83
  }, {
      start: 2085,
      length: 3,
      convRule: rule84
  }, {
      start: 2088,
      length: 1,
      convRule: rule83
  }, {
      start: 2089,
      length: 5,
      convRule: rule84
  }, {
      start: 2096,
      length: 15,
      convRule: rule2
  }, {
      start: 2112,
      length: 25,
      convRule: rule45
  }, {
      start: 2137,
      length: 3,
      convRule: rule84
  }, {
      start: 2142,
      length: 1,
      convRule: rule2
  }, {
      start: 2304,
      length: 3,
      convRule: rule84
  }, {
      start: 2307,
      length: 1,
      convRule: rule114
  }, {
      start: 2308,
      length: 54,
      convRule: rule45
  }, {
      start: 2362,
      length: 1,
      convRule: rule84
  }, {
      start: 2363,
      length: 1,
      convRule: rule114
  }, {
      start: 2364,
      length: 1,
      convRule: rule84
  }, {
      start: 2365,
      length: 1,
      convRule: rule45
  }, {
      start: 2366,
      length: 3,
      convRule: rule114
  }, {
      start: 2369,
      length: 8,
      convRule: rule84
  }, {
      start: 2377,
      length: 4,
      convRule: rule114
  }, {
      start: 2381,
      length: 1,
      convRule: rule84
  }, {
      start: 2382,
      length: 2,
      convRule: rule114
  }, {
      start: 2384,
      length: 1,
      convRule: rule45
  }, {
      start: 2385,
      length: 7,
      convRule: rule84
  }, {
      start: 2392,
      length: 10,
      convRule: rule45
  }, {
      start: 2402,
      length: 2,
      convRule: rule84
  }, {
      start: 2404,
      length: 2,
      convRule: rule2
  }, {
      start: 2406,
      length: 10,
      convRule: rule8
  }, {
      start: 2416,
      length: 1,
      convRule: rule2
  }, {
      start: 2417,
      length: 1,
      convRule: rule83
  }, {
      start: 2418,
      length: 6,
      convRule: rule45
  }, {
      start: 2425,
      length: 7,
      convRule: rule45
  }, {
      start: 2433,
      length: 1,
      convRule: rule84
  }, {
      start: 2434,
      length: 2,
      convRule: rule114
  }, {
      start: 2437,
      length: 8,
      convRule: rule45
  }, {
      start: 2447,
      length: 2,
      convRule: rule45
  }, {
      start: 2451,
      length: 22,
      convRule: rule45
  }, {
      start: 2474,
      length: 7,
      convRule: rule45
  }, {
      start: 2482,
      length: 1,
      convRule: rule45
  }, {
      start: 2486,
      length: 4,
      convRule: rule45
  }, {
      start: 2492,
      length: 1,
      convRule: rule84
  }, {
      start: 2493,
      length: 1,
      convRule: rule45
  }, {
      start: 2494,
      length: 3,
      convRule: rule114
  }, {
      start: 2497,
      length: 4,
      convRule: rule84
  }, {
      start: 2503,
      length: 2,
      convRule: rule114
  }, {
      start: 2507,
      length: 2,
      convRule: rule114
  }, {
      start: 2509,
      length: 1,
      convRule: rule84
  }, {
      start: 2510,
      length: 1,
      convRule: rule45
  }, {
      start: 2519,
      length: 1,
      convRule: rule114
  }, {
      start: 2524,
      length: 2,
      convRule: rule45
  }, {
      start: 2527,
      length: 3,
      convRule: rule45
  }, {
      start: 2530,
      length: 2,
      convRule: rule84
  }, {
      start: 2534,
      length: 10,
      convRule: rule8
  }, {
      start: 2544,
      length: 2,
      convRule: rule45
  }, {
      start: 2546,
      length: 2,
      convRule: rule3
  }, {
      start: 2548,
      length: 6,
      convRule: rule17
  }, {
      start: 2554,
      length: 1,
      convRule: rule13
  }, {
      start: 2555,
      length: 1,
      convRule: rule3
  }, {
      start: 2561,
      length: 2,
      convRule: rule84
  }, {
      start: 2563,
      length: 1,
      convRule: rule114
  }, {
      start: 2565,
      length: 6,
      convRule: rule45
  }, {
      start: 2575,
      length: 2,
      convRule: rule45
  }, {
      start: 2579,
      length: 22,
      convRule: rule45
  }, {
      start: 2602,
      length: 7,
      convRule: rule45
  }, {
      start: 2610,
      length: 2,
      convRule: rule45
  }, {
      start: 2613,
      length: 2,
      convRule: rule45
  }, {
      start: 2616,
      length: 2,
      convRule: rule45
  }, {
      start: 2620,
      length: 1,
      convRule: rule84
  }, {
      start: 2622,
      length: 3,
      convRule: rule114
  }, {
      start: 2625,
      length: 2,
      convRule: rule84
  }, {
      start: 2631,
      length: 2,
      convRule: rule84
  }, {
      start: 2635,
      length: 3,
      convRule: rule84
  }, {
      start: 2641,
      length: 1,
      convRule: rule84
  }, {
      start: 2649,
      length: 4,
      convRule: rule45
  }, {
      start: 2654,
      length: 1,
      convRule: rule45
  }, {
      start: 2662,
      length: 10,
      convRule: rule8
  }, {
      start: 2672,
      length: 2,
      convRule: rule84
  }, {
      start: 2674,
      length: 3,
      convRule: rule45
  }, {
      start: 2677,
      length: 1,
      convRule: rule84
  }, {
      start: 2689,
      length: 2,
      convRule: rule84
  }, {
      start: 2691,
      length: 1,
      convRule: rule114
  }, {
      start: 2693,
      length: 9,
      convRule: rule45
  }, {
      start: 2703,
      length: 3,
      convRule: rule45
  }, {
      start: 2707,
      length: 22,
      convRule: rule45
  }, {
      start: 2730,
      length: 7,
      convRule: rule45
  }, {
      start: 2738,
      length: 2,
      convRule: rule45
  }, {
      start: 2741,
      length: 5,
      convRule: rule45
  }, {
      start: 2748,
      length: 1,
      convRule: rule84
  }, {
      start: 2749,
      length: 1,
      convRule: rule45
  }, {
      start: 2750,
      length: 3,
      convRule: rule114
  }, {
      start: 2753,
      length: 5,
      convRule: rule84
  }, {
      start: 2759,
      length: 2,
      convRule: rule84
  }, {
      start: 2761,
      length: 1,
      convRule: rule114
  }, {
      start: 2763,
      length: 2,
      convRule: rule114
  }, {
      start: 2765,
      length: 1,
      convRule: rule84
  }, {
      start: 2768,
      length: 1,
      convRule: rule45
  }, {
      start: 2784,
      length: 2,
      convRule: rule45
  }, {
      start: 2786,
      length: 2,
      convRule: rule84
  }, {
      start: 2790,
      length: 10,
      convRule: rule8
  }, {
      start: 2801,
      length: 1,
      convRule: rule3
  }, {
      start: 2817,
      length: 1,
      convRule: rule84
  }, {
      start: 2818,
      length: 2,
      convRule: rule114
  }, {
      start: 2821,
      length: 8,
      convRule: rule45
  }, {
      start: 2831,
      length: 2,
      convRule: rule45
  }, {
      start: 2835,
      length: 22,
      convRule: rule45
  }, {
      start: 2858,
      length: 7,
      convRule: rule45
  }, {
      start: 2866,
      length: 2,
      convRule: rule45
  }, {
      start: 2869,
      length: 5,
      convRule: rule45
  }, {
      start: 2876,
      length: 1,
      convRule: rule84
  }, {
      start: 2877,
      length: 1,
      convRule: rule45
  }, {
      start: 2878,
      length: 1,
      convRule: rule114
  }, {
      start: 2879,
      length: 1,
      convRule: rule84
  }, {
      start: 2880,
      length: 1,
      convRule: rule114
  }, {
      start: 2881,
      length: 4,
      convRule: rule84
  }, {
      start: 2887,
      length: 2,
      convRule: rule114
  }, {
      start: 2891,
      length: 2,
      convRule: rule114
  }, {
      start: 2893,
      length: 1,
      convRule: rule84
  }, {
      start: 2902,
      length: 1,
      convRule: rule84
  }, {
      start: 2903,
      length: 1,
      convRule: rule114
  }, {
      start: 2908,
      length: 2,
      convRule: rule45
  }, {
      start: 2911,
      length: 3,
      convRule: rule45
  }, {
      start: 2914,
      length: 2,
      convRule: rule84
  }, {
      start: 2918,
      length: 10,
      convRule: rule8
  }, {
      start: 2928,
      length: 1,
      convRule: rule13
  }, {
      start: 2929,
      length: 1,
      convRule: rule45
  }, {
      start: 2930,
      length: 6,
      convRule: rule17
  }, {
      start: 2946,
      length: 1,
      convRule: rule84
  }, {
      start: 2947,
      length: 1,
      convRule: rule45
  }, {
      start: 2949,
      length: 6,
      convRule: rule45
  }, {
      start: 2958,
      length: 3,
      convRule: rule45
  }, {
      start: 2962,
      length: 4,
      convRule: rule45
  }, {
      start: 2969,
      length: 2,
      convRule: rule45
  }, {
      start: 2972,
      length: 1,
      convRule: rule45
  }, {
      start: 2974,
      length: 2,
      convRule: rule45
  }, {
      start: 2979,
      length: 2,
      convRule: rule45
  }, {
      start: 2984,
      length: 3,
      convRule: rule45
  }, {
      start: 2990,
      length: 12,
      convRule: rule45
  }, {
      start: 3006,
      length: 2,
      convRule: rule114
  }, {
      start: 3008,
      length: 1,
      convRule: rule84
  }, {
      start: 3009,
      length: 2,
      convRule: rule114
  }, {
      start: 3014,
      length: 3,
      convRule: rule114
  }, {
      start: 3018,
      length: 3,
      convRule: rule114
  }, {
      start: 3021,
      length: 1,
      convRule: rule84
  }, {
      start: 3024,
      length: 1,
      convRule: rule45
  }, {
      start: 3031,
      length: 1,
      convRule: rule114
  }, {
      start: 3046,
      length: 10,
      convRule: rule8
  }, {
      start: 3056,
      length: 3,
      convRule: rule17
  }, {
      start: 3059,
      length: 6,
      convRule: rule13
  }, {
      start: 3065,
      length: 1,
      convRule: rule3
  }, {
      start: 3066,
      length: 1,
      convRule: rule13
  }, {
      start: 3073,
      length: 3,
      convRule: rule114
  }, {
      start: 3077,
      length: 8,
      convRule: rule45
  }, {
      start: 3086,
      length: 3,
      convRule: rule45
  }, {
      start: 3090,
      length: 23,
      convRule: rule45
  }, {
      start: 3114,
      length: 10,
      convRule: rule45
  }, {
      start: 3125,
      length: 5,
      convRule: rule45
  }, {
      start: 3133,
      length: 1,
      convRule: rule45
  }, {
      start: 3134,
      length: 3,
      convRule: rule84
  }, {
      start: 3137,
      length: 4,
      convRule: rule114
  }, {
      start: 3142,
      length: 3,
      convRule: rule84
  }, {
      start: 3146,
      length: 4,
      convRule: rule84
  }, {
      start: 3157,
      length: 2,
      convRule: rule84
  }, {
      start: 3160,
      length: 2,
      convRule: rule45
  }, {
      start: 3168,
      length: 2,
      convRule: rule45
  }, {
      start: 3170,
      length: 2,
      convRule: rule84
  }, {
      start: 3174,
      length: 10,
      convRule: rule8
  }, {
      start: 3192,
      length: 7,
      convRule: rule17
  }, {
      start: 3199,
      length: 1,
      convRule: rule13
  }, {
      start: 3202,
      length: 2,
      convRule: rule114
  }, {
      start: 3205,
      length: 8,
      convRule: rule45
  }, {
      start: 3214,
      length: 3,
      convRule: rule45
  }, {
      start: 3218,
      length: 23,
      convRule: rule45
  }, {
      start: 3242,
      length: 10,
      convRule: rule45
  }, {
      start: 3253,
      length: 5,
      convRule: rule45
  }, {
      start: 3260,
      length: 1,
      convRule: rule84
  }, {
      start: 3261,
      length: 1,
      convRule: rule45
  }, {
      start: 3262,
      length: 1,
      convRule: rule114
  }, {
      start: 3263,
      length: 1,
      convRule: rule84
  }, {
      start: 3264,
      length: 5,
      convRule: rule114
  }, {
      start: 3270,
      length: 1,
      convRule: rule84
  }, {
      start: 3271,
      length: 2,
      convRule: rule114
  }, {
      start: 3274,
      length: 2,
      convRule: rule114
  }, {
      start: 3276,
      length: 2,
      convRule: rule84
  }, {
      start: 3285,
      length: 2,
      convRule: rule114
  }, {
      start: 3294,
      length: 1,
      convRule: rule45
  }, {
      start: 3296,
      length: 2,
      convRule: rule45
  }, {
      start: 3298,
      length: 2,
      convRule: rule84
  }, {
      start: 3302,
      length: 10,
      convRule: rule8
  }, {
      start: 3313,
      length: 2,
      convRule: rule45
  }, {
      start: 3330,
      length: 2,
      convRule: rule114
  }, {
      start: 3333,
      length: 8,
      convRule: rule45
  }, {
      start: 3342,
      length: 3,
      convRule: rule45
  }, {
      start: 3346,
      length: 41,
      convRule: rule45
  }, {
      start: 3389,
      length: 1,
      convRule: rule45
  }, {
      start: 3390,
      length: 3,
      convRule: rule114
  }, {
      start: 3393,
      length: 4,
      convRule: rule84
  }, {
      start: 3398,
      length: 3,
      convRule: rule114
  }, {
      start: 3402,
      length: 3,
      convRule: rule114
  }, {
      start: 3405,
      length: 1,
      convRule: rule84
  }, {
      start: 3406,
      length: 1,
      convRule: rule45
  }, {
      start: 3415,
      length: 1,
      convRule: rule114
  }, {
      start: 3424,
      length: 2,
      convRule: rule45
  }, {
      start: 3426,
      length: 2,
      convRule: rule84
  }, {
      start: 3430,
      length: 10,
      convRule: rule8
  }, {
      start: 3440,
      length: 6,
      convRule: rule17
  }, {
      start: 3449,
      length: 1,
      convRule: rule13
  }, {
      start: 3450,
      length: 6,
      convRule: rule45
  }, {
      start: 3458,
      length: 2,
      convRule: rule114
  }, {
      start: 3461,
      length: 18,
      convRule: rule45
  }, {
      start: 3482,
      length: 24,
      convRule: rule45
  }, {
      start: 3507,
      length: 9,
      convRule: rule45
  }, {
      start: 3517,
      length: 1,
      convRule: rule45
  }, {
      start: 3520,
      length: 7,
      convRule: rule45
  }, {
      start: 3530,
      length: 1,
      convRule: rule84
  }, {
      start: 3535,
      length: 3,
      convRule: rule114
  }, {
      start: 3538,
      length: 3,
      convRule: rule84
  }, {
      start: 3542,
      length: 1,
      convRule: rule84
  }, {
      start: 3544,
      length: 8,
      convRule: rule114
  }, {
      start: 3570,
      length: 2,
      convRule: rule114
  }, {
      start: 3572,
      length: 1,
      convRule: rule2
  }, {
      start: 3585,
      length: 48,
      convRule: rule45
  }, {
      start: 3633,
      length: 1,
      convRule: rule84
  }, {
      start: 3634,
      length: 2,
      convRule: rule45
  }, {
      start: 3636,
      length: 7,
      convRule: rule84
  }, {
      start: 3647,
      length: 1,
      convRule: rule3
  }, {
      start: 3648,
      length: 6,
      convRule: rule45
  }, {
      start: 3654,
      length: 1,
      convRule: rule83
  }, {
      start: 3655,
      length: 8,
      convRule: rule84
  }, {
      start: 3663,
      length: 1,
      convRule: rule2
  }, {
      start: 3664,
      length: 10,
      convRule: rule8
  }, {
      start: 3674,
      length: 2,
      convRule: rule2
  }, {
      start: 3713,
      length: 2,
      convRule: rule45
  }, {
      start: 3716,
      length: 1,
      convRule: rule45
  }, {
      start: 3719,
      length: 2,
      convRule: rule45
  }, {
      start: 3722,
      length: 1,
      convRule: rule45
  }, {
      start: 3725,
      length: 1,
      convRule: rule45
  }, {
      start: 3732,
      length: 4,
      convRule: rule45
  }, {
      start: 3737,
      length: 7,
      convRule: rule45
  }, {
      start: 3745,
      length: 3,
      convRule: rule45
  }, {
      start: 3749,
      length: 1,
      convRule: rule45
  }, {
      start: 3751,
      length: 1,
      convRule: rule45
  }, {
      start: 3754,
      length: 2,
      convRule: rule45
  }, {
      start: 3757,
      length: 4,
      convRule: rule45
  }, {
      start: 3761,
      length: 1,
      convRule: rule84
  }, {
      start: 3762,
      length: 2,
      convRule: rule45
  }, {
      start: 3764,
      length: 6,
      convRule: rule84
  }, {
      start: 3771,
      length: 2,
      convRule: rule84
  }, {
      start: 3773,
      length: 1,
      convRule: rule45
  }, {
      start: 3776,
      length: 5,
      convRule: rule45
  }, {
      start: 3782,
      length: 1,
      convRule: rule83
  }, {
      start: 3784,
      length: 6,
      convRule: rule84
  }, {
      start: 3792,
      length: 10,
      convRule: rule8
  }, {
      start: 3804,
      length: 2,
      convRule: rule45
  }, {
      start: 3840,
      length: 1,
      convRule: rule45
  }, {
      start: 3841,
      length: 3,
      convRule: rule13
  }, {
      start: 3844,
      length: 15,
      convRule: rule2
  }, {
      start: 3859,
      length: 5,
      convRule: rule13
  }, {
      start: 3864,
      length: 2,
      convRule: rule84
  }, {
      start: 3866,
      length: 6,
      convRule: rule13
  }, {
      start: 3872,
      length: 10,
      convRule: rule8
  }, {
      start: 3882,
      length: 10,
      convRule: rule17
  }, {
      start: 3892,
      length: 1,
      convRule: rule13
  }, {
      start: 3893,
      length: 1,
      convRule: rule84
  }, {
      start: 3894,
      length: 1,
      convRule: rule13
  }, {
      start: 3895,
      length: 1,
      convRule: rule84
  }, {
      start: 3896,
      length: 1,
      convRule: rule13
  }, {
      start: 3897,
      length: 1,
      convRule: rule84
  }, {
      start: 3898,
      length: 1,
      convRule: rule4
  }, {
      start: 3899,
      length: 1,
      convRule: rule5
  }, {
      start: 3900,
      length: 1,
      convRule: rule4
  }, {
      start: 3901,
      length: 1,
      convRule: rule5
  }, {
      start: 3902,
      length: 2,
      convRule: rule114
  }, {
      start: 3904,
      length: 8,
      convRule: rule45
  }, {
      start: 3913,
      length: 36,
      convRule: rule45
  }, {
      start: 3953,
      length: 14,
      convRule: rule84
  }, {
      start: 3967,
      length: 1,
      convRule: rule114
  }, {
      start: 3968,
      length: 5,
      convRule: rule84
  }, {
      start: 3973,
      length: 1,
      convRule: rule2
  }, {
      start: 3974,
      length: 2,
      convRule: rule84
  }, {
      start: 3976,
      length: 5,
      convRule: rule45
  }, {
      start: 3981,
      length: 11,
      convRule: rule84
  }, {
      start: 3993,
      length: 36,
      convRule: rule84
  }, {
      start: 4030,
      length: 8,
      convRule: rule13
  }, {
      start: 4038,
      length: 1,
      convRule: rule84
  }, {
      start: 4039,
      length: 6,
      convRule: rule13
  }, {
      start: 4046,
      length: 2,
      convRule: rule13
  }, {
      start: 4048,
      length: 5,
      convRule: rule2
  }, {
      start: 4053,
      length: 4,
      convRule: rule13
  }, {
      start: 4057,
      length: 2,
      convRule: rule2
  }, {
      start: 4096,
      length: 43,
      convRule: rule45
  }, {
      start: 4139,
      length: 2,
      convRule: rule114
  }, {
      start: 4141,
      length: 4,
      convRule: rule84
  }, {
      start: 4145,
      length: 1,
      convRule: rule114
  }, {
      start: 4146,
      length: 6,
      convRule: rule84
  }, {
      start: 4152,
      length: 1,
      convRule: rule114
  }, {
      start: 4153,
      length: 2,
      convRule: rule84
  }, {
      start: 4155,
      length: 2,
      convRule: rule114
  }, {
      start: 4157,
      length: 2,
      convRule: rule84
  }, {
      start: 4159,
      length: 1,
      convRule: rule45
  }, {
      start: 4160,
      length: 10,
      convRule: rule8
  }, {
      start: 4170,
      length: 6,
      convRule: rule2
  }, {
      start: 4176,
      length: 6,
      convRule: rule45
  }, {
      start: 4182,
      length: 2,
      convRule: rule114
  }, {
      start: 4184,
      length: 2,
      convRule: rule84
  }, {
      start: 4186,
      length: 4,
      convRule: rule45
  }, {
      start: 4190,
      length: 3,
      convRule: rule84
  }, {
      start: 4193,
      length: 1,
      convRule: rule45
  }, {
      start: 4194,
      length: 3,
      convRule: rule114
  }, {
      start: 4197,
      length: 2,
      convRule: rule45
  }, {
      start: 4199,
      length: 7,
      convRule: rule114
  }, {
      start: 4206,
      length: 3,
      convRule: rule45
  }, {
      start: 4209,
      length: 4,
      convRule: rule84
  }, {
      start: 4213,
      length: 13,
      convRule: rule45
  }, {
      start: 4226,
      length: 1,
      convRule: rule84
  }, {
      start: 4227,
      length: 2,
      convRule: rule114
  }, {
      start: 4229,
      length: 2,
      convRule: rule84
  }, {
      start: 4231,
      length: 6,
      convRule: rule114
  }, {
      start: 4237,
      length: 1,
      convRule: rule84
  }, {
      start: 4238,
      length: 1,
      convRule: rule45
  }, {
      start: 4239,
      length: 1,
      convRule: rule114
  }, {
      start: 4240,
      length: 10,
      convRule: rule8
  }, {
      start: 4250,
      length: 3,
      convRule: rule114
  }, {
      start: 4253,
      length: 1,
      convRule: rule84
  }, {
      start: 4254,
      length: 2,
      convRule: rule13
  }, {
      start: 4256,
      length: 38,
      convRule: rule115
  }, {
      start: 4304,
      length: 43,
      convRule: rule45
  }, {
      start: 4347,
      length: 1,
      convRule: rule2
  }, {
      start: 4348,
      length: 1,
      convRule: rule83
  }, {
      start: 4352,
      length: 329,
      convRule: rule45
  }, {
      start: 4682,
      length: 4,
      convRule: rule45
  }, {
      start: 4688,
      length: 7,
      convRule: rule45
  }, {
      start: 4696,
      length: 1,
      convRule: rule45
  }, {
      start: 4698,
      length: 4,
      convRule: rule45
  }, {
      start: 4704,
      length: 41,
      convRule: rule45
  }, {
      start: 4746,
      length: 4,
      convRule: rule45
  }, {
      start: 4752,
      length: 33,
      convRule: rule45
  }, {
      start: 4786,
      length: 4,
      convRule: rule45
  }, {
      start: 4792,
      length: 7,
      convRule: rule45
  }, {
      start: 4800,
      length: 1,
      convRule: rule45
  }, {
      start: 4802,
      length: 4,
      convRule: rule45
  }, {
      start: 4808,
      length: 15,
      convRule: rule45
  }, {
      start: 4824,
      length: 57,
      convRule: rule45
  }, {
      start: 4882,
      length: 4,
      convRule: rule45
  }, {
      start: 4888,
      length: 67,
      convRule: rule45
  }, {
      start: 4957,
      length: 3,
      convRule: rule84
  }, {
      start: 4960,
      length: 1,
      convRule: rule13
  }, {
      start: 4961,
      length: 8,
      convRule: rule2
  }, {
      start: 4969,
      length: 20,
      convRule: rule17
  }, {
      start: 4992,
      length: 16,
      convRule: rule45
  }, {
      start: 5008,
      length: 10,
      convRule: rule13
  }, {
      start: 5024,
      length: 85,
      convRule: rule45
  }, {
      start: 5120,
      length: 1,
      convRule: rule7
  }, {
      start: 5121,
      length: 620,
      convRule: rule45
  }, {
      start: 5741,
      length: 2,
      convRule: rule2
  }, {
      start: 5743,
      length: 17,
      convRule: rule45
  }, {
      start: 5760,
      length: 1,
      convRule: rule1
  }, {
      start: 5761,
      length: 26,
      convRule: rule45
  }, {
      start: 5787,
      length: 1,
      convRule: rule4
  }, {
      start: 5788,
      length: 1,
      convRule: rule5
  }, {
      start: 5792,
      length: 75,
      convRule: rule45
  }, {
      start: 5867,
      length: 3,
      convRule: rule2
  }, {
      start: 5870,
      length: 3,
      convRule: rule116
  }, {
      start: 5888,
      length: 13,
      convRule: rule45
  }, {
      start: 5902,
      length: 4,
      convRule: rule45
  }, {
      start: 5906,
      length: 3,
      convRule: rule84
  }, {
      start: 5920,
      length: 18,
      convRule: rule45
  }, {
      start: 5938,
      length: 3,
      convRule: rule84
  }, {
      start: 5941,
      length: 2,
      convRule: rule2
  }, {
      start: 5952,
      length: 18,
      convRule: rule45
  }, {
      start: 5970,
      length: 2,
      convRule: rule84
  }, {
      start: 5984,
      length: 13,
      convRule: rule45
  }, {
      start: 5998,
      length: 3,
      convRule: rule45
  }, {
      start: 6002,
      length: 2,
      convRule: rule84
  }, {
      start: 6016,
      length: 52,
      convRule: rule45
  }, {
      start: 6068,
      length: 2,
      convRule: rule16
  }, {
      start: 6070,
      length: 1,
      convRule: rule114
  }, {
      start: 6071,
      length: 7,
      convRule: rule84
  }, {
      start: 6078,
      length: 8,
      convRule: rule114
  }, {
      start: 6086,
      length: 1,
      convRule: rule84
  }, {
      start: 6087,
      length: 2,
      convRule: rule114
  }, {
      start: 6089,
      length: 11,
      convRule: rule84
  }, {
      start: 6100,
      length: 3,
      convRule: rule2
  }, {
      start: 6103,
      length: 1,
      convRule: rule83
  }, {
      start: 6104,
      length: 3,
      convRule: rule2
  }, {
      start: 6107,
      length: 1,
      convRule: rule3
  }, {
      start: 6108,
      length: 1,
      convRule: rule45
  }, {
      start: 6109,
      length: 1,
      convRule: rule84
  }, {
      start: 6112,
      length: 10,
      convRule: rule8
  }, {
      start: 6128,
      length: 10,
      convRule: rule17
  }, {
      start: 6144,
      length: 6,
      convRule: rule2
  }, {
      start: 6150,
      length: 1,
      convRule: rule7
  }, {
      start: 6151,
      length: 4,
      convRule: rule2
  }, {
      start: 6155,
      length: 3,
      convRule: rule84
  }, {
      start: 6158,
      length: 1,
      convRule: rule1
  }, {
      start: 6160,
      length: 10,
      convRule: rule8
  }, {
      start: 6176,
      length: 35,
      convRule: rule45
  }, {
      start: 6211,
      length: 1,
      convRule: rule83
  }, {
      start: 6212,
      length: 52,
      convRule: rule45
  }, {
      start: 6272,
      length: 41,
      convRule: rule45
  }, {
      start: 6313,
      length: 1,
      convRule: rule84
  }, {
      start: 6314,
      length: 1,
      convRule: rule45
  }, {
      start: 6320,
      length: 70,
      convRule: rule45
  }, {
      start: 6400,
      length: 29,
      convRule: rule45
  }, {
      start: 6432,
      length: 3,
      convRule: rule84
  }, {
      start: 6435,
      length: 4,
      convRule: rule114
  }, {
      start: 6439,
      length: 2,
      convRule: rule84
  }, {
      start: 6441,
      length: 3,
      convRule: rule114
  }, {
      start: 6448,
      length: 2,
      convRule: rule114
  }, {
      start: 6450,
      length: 1,
      convRule: rule84
  }, {
      start: 6451,
      length: 6,
      convRule: rule114
  }, {
      start: 6457,
      length: 3,
      convRule: rule84
  }, {
      start: 6464,
      length: 1,
      convRule: rule13
  }, {
      start: 6468,
      length: 2,
      convRule: rule2
  }, {
      start: 6470,
      length: 10,
      convRule: rule8
  }, {
      start: 6480,
      length: 30,
      convRule: rule45
  }, {
      start: 6512,
      length: 5,
      convRule: rule45
  }, {
      start: 6528,
      length: 44,
      convRule: rule45
  }, {
      start: 6576,
      length: 17,
      convRule: rule114
  }, {
      start: 6593,
      length: 7,
      convRule: rule45
  }, {
      start: 6600,
      length: 2,
      convRule: rule114
  }, {
      start: 6608,
      length: 10,
      convRule: rule8
  }, {
      start: 6618,
      length: 1,
      convRule: rule17
  }, {
      start: 6622,
      length: 34,
      convRule: rule13
  }, {
      start: 6656,
      length: 23,
      convRule: rule45
  }, {
      start: 6679,
      length: 2,
      convRule: rule84
  }, {
      start: 6681,
      length: 3,
      convRule: rule114
  }, {
      start: 6686,
      length: 2,
      convRule: rule2
  }, {
      start: 6688,
      length: 53,
      convRule: rule45
  }, {
      start: 6741,
      length: 1,
      convRule: rule114
  }, {
      start: 6742,
      length: 1,
      convRule: rule84
  }, {
      start: 6743,
      length: 1,
      convRule: rule114
  }, {
      start: 6744,
      length: 7,
      convRule: rule84
  }, {
      start: 6752,
      length: 1,
      convRule: rule84
  }, {
      start: 6753,
      length: 1,
      convRule: rule114
  }, {
      start: 6754,
      length: 1,
      convRule: rule84
  }, {
      start: 6755,
      length: 2,
      convRule: rule114
  }, {
      start: 6757,
      length: 8,
      convRule: rule84
  }, {
      start: 6765,
      length: 6,
      convRule: rule114
  }, {
      start: 6771,
      length: 10,
      convRule: rule84
  }, {
      start: 6783,
      length: 1,
      convRule: rule84
  }, {
      start: 6784,
      length: 10,
      convRule: rule8
  }, {
      start: 6800,
      length: 10,
      convRule: rule8
  }, {
      start: 6816,
      length: 7,
      convRule: rule2
  }, {
      start: 6823,
      length: 1,
      convRule: rule83
  }, {
      start: 6824,
      length: 6,
      convRule: rule2
  }, {
      start: 6912,
      length: 4,
      convRule: rule84
  }, {
      start: 6916,
      length: 1,
      convRule: rule114
  }, {
      start: 6917,
      length: 47,
      convRule: rule45
  }, {
      start: 6964,
      length: 1,
      convRule: rule84
  }, {
      start: 6965,
      length: 1,
      convRule: rule114
  }, {
      start: 6966,
      length: 5,
      convRule: rule84
  }, {
      start: 6971,
      length: 1,
      convRule: rule114
  }, {
      start: 6972,
      length: 1,
      convRule: rule84
  }, {
      start: 6973,
      length: 5,
      convRule: rule114
  }, {
      start: 6978,
      length: 1,
      convRule: rule84
  }, {
      start: 6979,
      length: 2,
      convRule: rule114
  }, {
      start: 6981,
      length: 7,
      convRule: rule45
  }, {
      start: 6992,
      length: 10,
      convRule: rule8
  }, {
      start: 7002,
      length: 7,
      convRule: rule2
  }, {
      start: 7009,
      length: 10,
      convRule: rule13
  }, {
      start: 7019,
      length: 9,
      convRule: rule84
  }, {
      start: 7028,
      length: 9,
      convRule: rule13
  }, {
      start: 7040,
      length: 2,
      convRule: rule84
  }, {
      start: 7042,
      length: 1,
      convRule: rule114
  }, {
      start: 7043,
      length: 30,
      convRule: rule45
  }, {
      start: 7073,
      length: 1,
      convRule: rule114
  }, {
      start: 7074,
      length: 4,
      convRule: rule84
  }, {
      start: 7078,
      length: 2,
      convRule: rule114
  }, {
      start: 7080,
      length: 2,
      convRule: rule84
  }, {
      start: 7082,
      length: 1,
      convRule: rule114
  }, {
      start: 7086,
      length: 2,
      convRule: rule45
  }, {
      start: 7088,
      length: 10,
      convRule: rule8
  }, {
      start: 7104,
      length: 38,
      convRule: rule45
  }, {
      start: 7142,
      length: 1,
      convRule: rule84
  }, {
      start: 7143,
      length: 1,
      convRule: rule114
  }, {
      start: 7144,
      length: 2,
      convRule: rule84
  }, {
      start: 7146,
      length: 3,
      convRule: rule114
  }, {
      start: 7149,
      length: 1,
      convRule: rule84
  }, {
      start: 7150,
      length: 1,
      convRule: rule114
  }, {
      start: 7151,
      length: 3,
      convRule: rule84
  }, {
      start: 7154,
      length: 2,
      convRule: rule114
  }, {
      start: 7164,
      length: 4,
      convRule: rule2
  }, {
      start: 7168,
      length: 36,
      convRule: rule45
  }, {
      start: 7204,
      length: 8,
      convRule: rule114
  }, {
      start: 7212,
      length: 8,
      convRule: rule84
  }, {
      start: 7220,
      length: 2,
      convRule: rule114
  }, {
      start: 7222,
      length: 2,
      convRule: rule84
  }, {
      start: 7227,
      length: 5,
      convRule: rule2
  }, {
      start: 7232,
      length: 10,
      convRule: rule8
  }, {
      start: 7245,
      length: 3,
      convRule: rule45
  }, {
      start: 7248,
      length: 10,
      convRule: rule8
  }, {
      start: 7258,
      length: 30,
      convRule: rule45
  }, {
      start: 7288,
      length: 6,
      convRule: rule83
  }, {
      start: 7294,
      length: 2,
      convRule: rule2
  }, {
      start: 7376,
      length: 3,
      convRule: rule84
  }, {
      start: 7379,
      length: 1,
      convRule: rule2
  }, {
      start: 7380,
      length: 13,
      convRule: rule84
  }, {
      start: 7393,
      length: 1,
      convRule: rule114
  }, {
      start: 7394,
      length: 7,
      convRule: rule84
  }, {
      start: 7401,
      length: 4,
      convRule: rule45
  }, {
      start: 7405,
      length: 1,
      convRule: rule84
  }, {
      start: 7406,
      length: 4,
      convRule: rule45
  }, {
      start: 7410,
      length: 1,
      convRule: rule114
  }, {
      start: 7424,
      length: 44,
      convRule: rule14
  }, {
      start: 7468,
      length: 54,
      convRule: rule83
  }, {
      start: 7522,
      length: 22,
      convRule: rule14
  }, {
      start: 7544,
      length: 1,
      convRule: rule83
  }, {
      start: 7545,
      length: 1,
      convRule: rule117
  }, {
      start: 7546,
      length: 3,
      convRule: rule14
  }, {
      start: 7549,
      length: 1,
      convRule: rule118
  }, {
      start: 7550,
      length: 29,
      convRule: rule14
  }, {
      start: 7579,
      length: 37,
      convRule: rule83
  }, {
      start: 7616,
      length: 39,
      convRule: rule84
  }, {
      start: 7676,
      length: 4,
      convRule: rule84
  }, {
      start: 7680,
      length: 1,
      convRule: rule21
  }, {
      start: 7681,
      length: 1,
      convRule: rule22
  }, {
      start: 7682,
      length: 1,
      convRule: rule21
  }, {
      start: 7683,
      length: 1,
      convRule: rule22
  }, {
      start: 7684,
      length: 1,
      convRule: rule21
  }, {
      start: 7685,
      length: 1,
      convRule: rule22
  }, {
      start: 7686,
      length: 1,
      convRule: rule21
  }, {
      start: 7687,
      length: 1,
      convRule: rule22
  }, {
      start: 7688,
      length: 1,
      convRule: rule21
  }, {
      start: 7689,
      length: 1,
      convRule: rule22
  }, {
      start: 7690,
      length: 1,
      convRule: rule21
  }, {
      start: 7691,
      length: 1,
      convRule: rule22
  }, {
      start: 7692,
      length: 1,
      convRule: rule21
  }, {
      start: 7693,
      length: 1,
      convRule: rule22
  }, {
      start: 7694,
      length: 1,
      convRule: rule21
  }, {
      start: 7695,
      length: 1,
      convRule: rule22
  }, {
      start: 7696,
      length: 1,
      convRule: rule21
  }, {
      start: 7697,
      length: 1,
      convRule: rule22
  }, {
      start: 7698,
      length: 1,
      convRule: rule21
  }, {
      start: 7699,
      length: 1,
      convRule: rule22
  }, {
      start: 7700,
      length: 1,
      convRule: rule21
  }, {
      start: 7701,
      length: 1,
      convRule: rule22
  }, {
      start: 7702,
      length: 1,
      convRule: rule21
  }, {
      start: 7703,
      length: 1,
      convRule: rule22
  }, {
      start: 7704,
      length: 1,
      convRule: rule21
  }, {
      start: 7705,
      length: 1,
      convRule: rule22
  }, {
      start: 7706,
      length: 1,
      convRule: rule21
  }, {
      start: 7707,
      length: 1,
      convRule: rule22
  }, {
      start: 7708,
      length: 1,
      convRule: rule21
  }, {
      start: 7709,
      length: 1,
      convRule: rule22
  }, {
      start: 7710,
      length: 1,
      convRule: rule21
  }, {
      start: 7711,
      length: 1,
      convRule: rule22
  }, {
      start: 7712,
      length: 1,
      convRule: rule21
  }, {
      start: 7713,
      length: 1,
      convRule: rule22
  }, {
      start: 7714,
      length: 1,
      convRule: rule21
  }, {
      start: 7715,
      length: 1,
      convRule: rule22
  }, {
      start: 7716,
      length: 1,
      convRule: rule21
  }, {
      start: 7717,
      length: 1,
      convRule: rule22
  }, {
      start: 7718,
      length: 1,
      convRule: rule21
  }, {
      start: 7719,
      length: 1,
      convRule: rule22
  }, {
      start: 7720,
      length: 1,
      convRule: rule21
  }, {
      start: 7721,
      length: 1,
      convRule: rule22
  }, {
      start: 7722,
      length: 1,
      convRule: rule21
  }, {
      start: 7723,
      length: 1,
      convRule: rule22
  }, {
      start: 7724,
      length: 1,
      convRule: rule21
  }, {
      start: 7725,
      length: 1,
      convRule: rule22
  }, {
      start: 7726,
      length: 1,
      convRule: rule21
  }, {
      start: 7727,
      length: 1,
      convRule: rule22
  }, {
      start: 7728,
      length: 1,
      convRule: rule21
  }, {
      start: 7729,
      length: 1,
      convRule: rule22
  }, {
      start: 7730,
      length: 1,
      convRule: rule21
  }, {
      start: 7731,
      length: 1,
      convRule: rule22
  }, {
      start: 7732,
      length: 1,
      convRule: rule21
  }, {
      start: 7733,
      length: 1,
      convRule: rule22
  }, {
      start: 7734,
      length: 1,
      convRule: rule21
  }, {
      start: 7735,
      length: 1,
      convRule: rule22
  }, {
      start: 7736,
      length: 1,
      convRule: rule21
  }, {
      start: 7737,
      length: 1,
      convRule: rule22
  }, {
      start: 7738,
      length: 1,
      convRule: rule21
  }, {
      start: 7739,
      length: 1,
      convRule: rule22
  }, {
      start: 7740,
      length: 1,
      convRule: rule21
  }, {
      start: 7741,
      length: 1,
      convRule: rule22
  }, {
      start: 7742,
      length: 1,
      convRule: rule21
  }, {
      start: 7743,
      length: 1,
      convRule: rule22
  }, {
      start: 7744,
      length: 1,
      convRule: rule21
  }, {
      start: 7745,
      length: 1,
      convRule: rule22
  }, {
      start: 7746,
      length: 1,
      convRule: rule21
  }, {
      start: 7747,
      length: 1,
      convRule: rule22
  }, {
      start: 7748,
      length: 1,
      convRule: rule21
  }, {
      start: 7749,
      length: 1,
      convRule: rule22
  }, {
      start: 7750,
      length: 1,
      convRule: rule21
  }, {
      start: 7751,
      length: 1,
      convRule: rule22
  }, {
      start: 7752,
      length: 1,
      convRule: rule21
  }, {
      start: 7753,
      length: 1,
      convRule: rule22
  }, {
      start: 7754,
      length: 1,
      convRule: rule21
  }, {
      start: 7755,
      length: 1,
      convRule: rule22
  }, {
      start: 7756,
      length: 1,
      convRule: rule21
  }, {
      start: 7757,
      length: 1,
      convRule: rule22
  }, {
      start: 7758,
      length: 1,
      convRule: rule21
  }, {
      start: 7759,
      length: 1,
      convRule: rule22
  }, {
      start: 7760,
      length: 1,
      convRule: rule21
  }, {
      start: 7761,
      length: 1,
      convRule: rule22
  }, {
      start: 7762,
      length: 1,
      convRule: rule21
  }, {
      start: 7763,
      length: 1,
      convRule: rule22
  }, {
      start: 7764,
      length: 1,
      convRule: rule21
  }, {
      start: 7765,
      length: 1,
      convRule: rule22
  }, {
      start: 7766,
      length: 1,
      convRule: rule21
  }, {
      start: 7767,
      length: 1,
      convRule: rule22
  }, {
      start: 7768,
      length: 1,
      convRule: rule21
  }, {
      start: 7769,
      length: 1,
      convRule: rule22
  }, {
      start: 7770,
      length: 1,
      convRule: rule21
  }, {
      start: 7771,
      length: 1,
      convRule: rule22
  }, {
      start: 7772,
      length: 1,
      convRule: rule21
  }, {
      start: 7773,
      length: 1,
      convRule: rule22
  }, {
      start: 7774,
      length: 1,
      convRule: rule21
  }, {
      start: 7775,
      length: 1,
      convRule: rule22
  }, {
      start: 7776,
      length: 1,
      convRule: rule21
  }, {
      start: 7777,
      length: 1,
      convRule: rule22
  }, {
      start: 7778,
      length: 1,
      convRule: rule21
  }, {
      start: 7779,
      length: 1,
      convRule: rule22
  }, {
      start: 7780,
      length: 1,
      convRule: rule21
  }, {
      start: 7781,
      length: 1,
      convRule: rule22
  }, {
      start: 7782,
      length: 1,
      convRule: rule21
  }, {
      start: 7783,
      length: 1,
      convRule: rule22
  }, {
      start: 7784,
      length: 1,
      convRule: rule21
  }, {
      start: 7785,
      length: 1,
      convRule: rule22
  }, {
      start: 7786,
      length: 1,
      convRule: rule21
  }, {
      start: 7787,
      length: 1,
      convRule: rule22
  }, {
      start: 7788,
      length: 1,
      convRule: rule21
  }, {
      start: 7789,
      length: 1,
      convRule: rule22
  }, {
      start: 7790,
      length: 1,
      convRule: rule21
  }, {
      start: 7791,
      length: 1,
      convRule: rule22
  }, {
      start: 7792,
      length: 1,
      convRule: rule21
  }, {
      start: 7793,
      length: 1,
      convRule: rule22
  }, {
      start: 7794,
      length: 1,
      convRule: rule21
  }, {
      start: 7795,
      length: 1,
      convRule: rule22
  }, {
      start: 7796,
      length: 1,
      convRule: rule21
  }, {
      start: 7797,
      length: 1,
      convRule: rule22
  }, {
      start: 7798,
      length: 1,
      convRule: rule21
  }, {
      start: 7799,
      length: 1,
      convRule: rule22
  }, {
      start: 7800,
      length: 1,
      convRule: rule21
  }, {
      start: 7801,
      length: 1,
      convRule: rule22
  }, {
      start: 7802,
      length: 1,
      convRule: rule21
  }, {
      start: 7803,
      length: 1,
      convRule: rule22
  }, {
      start: 7804,
      length: 1,
      convRule: rule21
  }, {
      start: 7805,
      length: 1,
      convRule: rule22
  }, {
      start: 7806,
      length: 1,
      convRule: rule21
  }, {
      start: 7807,
      length: 1,
      convRule: rule22
  }, {
      start: 7808,
      length: 1,
      convRule: rule21
  }, {
      start: 7809,
      length: 1,
      convRule: rule22
  }, {
      start: 7810,
      length: 1,
      convRule: rule21
  }, {
      start: 7811,
      length: 1,
      convRule: rule22
  }, {
      start: 7812,
      length: 1,
      convRule: rule21
  }, {
      start: 7813,
      length: 1,
      convRule: rule22
  }, {
      start: 7814,
      length: 1,
      convRule: rule21
  }, {
      start: 7815,
      length: 1,
      convRule: rule22
  }, {
      start: 7816,
      length: 1,
      convRule: rule21
  }, {
      start: 7817,
      length: 1,
      convRule: rule22
  }, {
      start: 7818,
      length: 1,
      convRule: rule21
  }, {
      start: 7819,
      length: 1,
      convRule: rule22
  }, {
      start: 7820,
      length: 1,
      convRule: rule21
  }, {
      start: 7821,
      length: 1,
      convRule: rule22
  }, {
      start: 7822,
      length: 1,
      convRule: rule21
  }, {
      start: 7823,
      length: 1,
      convRule: rule22
  }, {
      start: 7824,
      length: 1,
      convRule: rule21
  }, {
      start: 7825,
      length: 1,
      convRule: rule22
  }, {
      start: 7826,
      length: 1,
      convRule: rule21
  }, {
      start: 7827,
      length: 1,
      convRule: rule22
  }, {
      start: 7828,
      length: 1,
      convRule: rule21
  }, {
      start: 7829,
      length: 1,
      convRule: rule22
  }, {
      start: 7830,
      length: 5,
      convRule: rule14
  }, {
      start: 7835,
      length: 1,
      convRule: rule119
  }, {
      start: 7836,
      length: 2,
      convRule: rule14
  }, {
      start: 7838,
      length: 1,
      convRule: rule120
  }, {
      start: 7839,
      length: 1,
      convRule: rule14
  }, {
      start: 7840,
      length: 1,
      convRule: rule21
  }, {
      start: 7841,
      length: 1,
      convRule: rule22
  }, {
      start: 7842,
      length: 1,
      convRule: rule21
  }, {
      start: 7843,
      length: 1,
      convRule: rule22
  }, {
      start: 7844,
      length: 1,
      convRule: rule21
  }, {
      start: 7845,
      length: 1,
      convRule: rule22
  }, {
      start: 7846,
      length: 1,
      convRule: rule21
  }, {
      start: 7847,
      length: 1,
      convRule: rule22
  }, {
      start: 7848,
      length: 1,
      convRule: rule21
  }, {
      start: 7849,
      length: 1,
      convRule: rule22
  }, {
      start: 7850,
      length: 1,
      convRule: rule21
  }, {
      start: 7851,
      length: 1,
      convRule: rule22
  }, {
      start: 7852,
      length: 1,
      convRule: rule21
  }, {
      start: 7853,
      length: 1,
      convRule: rule22
  }, {
      start: 7854,
      length: 1,
      convRule: rule21
  }, {
      start: 7855,
      length: 1,
      convRule: rule22
  }, {
      start: 7856,
      length: 1,
      convRule: rule21
  }, {
      start: 7857,
      length: 1,
      convRule: rule22
  }, {
      start: 7858,
      length: 1,
      convRule: rule21
  }, {
      start: 7859,
      length: 1,
      convRule: rule22
  }, {
      start: 7860,
      length: 1,
      convRule: rule21
  }, {
      start: 7861,
      length: 1,
      convRule: rule22
  }, {
      start: 7862,
      length: 1,
      convRule: rule21
  }, {
      start: 7863,
      length: 1,
      convRule: rule22
  }, {
      start: 7864,
      length: 1,
      convRule: rule21
  }, {
      start: 7865,
      length: 1,
      convRule: rule22
  }, {
      start: 7866,
      length: 1,
      convRule: rule21
  }, {
      start: 7867,
      length: 1,
      convRule: rule22
  }, {
      start: 7868,
      length: 1,
      convRule: rule21
  }, {
      start: 7869,
      length: 1,
      convRule: rule22
  }, {
      start: 7870,
      length: 1,
      convRule: rule21
  }, {
      start: 7871,
      length: 1,
      convRule: rule22
  }, {
      start: 7872,
      length: 1,
      convRule: rule21
  }, {
      start: 7873,
      length: 1,
      convRule: rule22
  }, {
      start: 7874,
      length: 1,
      convRule: rule21
  }, {
      start: 7875,
      length: 1,
      convRule: rule22
  }, {
      start: 7876,
      length: 1,
      convRule: rule21
  }, {
      start: 7877,
      length: 1,
      convRule: rule22
  }, {
      start: 7878,
      length: 1,
      convRule: rule21
  }, {
      start: 7879,
      length: 1,
      convRule: rule22
  }, {
      start: 7880,
      length: 1,
      convRule: rule21
  }, {
      start: 7881,
      length: 1,
      convRule: rule22
  }, {
      start: 7882,
      length: 1,
      convRule: rule21
  }, {
      start: 7883,
      length: 1,
      convRule: rule22
  }, {
      start: 7884,
      length: 1,
      convRule: rule21
  }, {
      start: 7885,
      length: 1,
      convRule: rule22
  }, {
      start: 7886,
      length: 1,
      convRule: rule21
  }, {
      start: 7887,
      length: 1,
      convRule: rule22
  }, {
      start: 7888,
      length: 1,
      convRule: rule21
  }, {
      start: 7889,
      length: 1,
      convRule: rule22
  }, {
      start: 7890,
      length: 1,
      convRule: rule21
  }, {
      start: 7891,
      length: 1,
      convRule: rule22
  }, {
      start: 7892,
      length: 1,
      convRule: rule21
  }, {
      start: 7893,
      length: 1,
      convRule: rule22
  }, {
      start: 7894,
      length: 1,
      convRule: rule21
  }, {
      start: 7895,
      length: 1,
      convRule: rule22
  }, {
      start: 7896,
      length: 1,
      convRule: rule21
  }, {
      start: 7897,
      length: 1,
      convRule: rule22
  }, {
      start: 7898,
      length: 1,
      convRule: rule21
  }, {
      start: 7899,
      length: 1,
      convRule: rule22
  }, {
      start: 7900,
      length: 1,
      convRule: rule21
  }, {
      start: 7901,
      length: 1,
      convRule: rule22
  }, {
      start: 7902,
      length: 1,
      convRule: rule21
  }, {
      start: 7903,
      length: 1,
      convRule: rule22
  }, {
      start: 7904,
      length: 1,
      convRule: rule21
  }, {
      start: 7905,
      length: 1,
      convRule: rule22
  }, {
      start: 7906,
      length: 1,
      convRule: rule21
  }, {
      start: 7907,
      length: 1,
      convRule: rule22
  }, {
      start: 7908,
      length: 1,
      convRule: rule21
  }, {
      start: 7909,
      length: 1,
      convRule: rule22
  }, {
      start: 7910,
      length: 1,
      convRule: rule21
  }, {
      start: 7911,
      length: 1,
      convRule: rule22
  }, {
      start: 7912,
      length: 1,
      convRule: rule21
  }, {
      start: 7913,
      length: 1,
      convRule: rule22
  }, {
      start: 7914,
      length: 1,
      convRule: rule21
  }, {
      start: 7915,
      length: 1,
      convRule: rule22
  }, {
      start: 7916,
      length: 1,
      convRule: rule21
  }, {
      start: 7917,
      length: 1,
      convRule: rule22
  }, {
      start: 7918,
      length: 1,
      convRule: rule21
  }, {
      start: 7919,
      length: 1,
      convRule: rule22
  }, {
      start: 7920,
      length: 1,
      convRule: rule21
  }, {
      start: 7921,
      length: 1,
      convRule: rule22
  }, {
      start: 7922,
      length: 1,
      convRule: rule21
  }, {
      start: 7923,
      length: 1,
      convRule: rule22
  }, {
      start: 7924,
      length: 1,
      convRule: rule21
  }, {
      start: 7925,
      length: 1,
      convRule: rule22
  }, {
      start: 7926,
      length: 1,
      convRule: rule21
  }, {
      start: 7927,
      length: 1,
      convRule: rule22
  }, {
      start: 7928,
      length: 1,
      convRule: rule21
  }, {
      start: 7929,
      length: 1,
      convRule: rule22
  }, {
      start: 7930,
      length: 1,
      convRule: rule21
  }, {
      start: 7931,
      length: 1,
      convRule: rule22
  }, {
      start: 7932,
      length: 1,
      convRule: rule21
  }, {
      start: 7933,
      length: 1,
      convRule: rule22
  }, {
      start: 7934,
      length: 1,
      convRule: rule21
  }, {
      start: 7935,
      length: 1,
      convRule: rule22
  }, {
      start: 7936,
      length: 8,
      convRule: rule121
  }, {
      start: 7944,
      length: 8,
      convRule: rule122
  }, {
      start: 7952,
      length: 6,
      convRule: rule121
  }, {
      start: 7960,
      length: 6,
      convRule: rule122
  }, {
      start: 7968,
      length: 8,
      convRule: rule121
  }, {
      start: 7976,
      length: 8,
      convRule: rule122
  }, {
      start: 7984,
      length: 8,
      convRule: rule121
  }, {
      start: 7992,
      length: 8,
      convRule: rule122
  }, {
      start: 8000,
      length: 6,
      convRule: rule121
  }, {
      start: 8008,
      length: 6,
      convRule: rule122
  }, {
      start: 8016,
      length: 1,
      convRule: rule14
  }, {
      start: 8017,
      length: 1,
      convRule: rule121
  }, {
      start: 8018,
      length: 1,
      convRule: rule14
  }, {
      start: 8019,
      length: 1,
      convRule: rule121
  }, {
      start: 8020,
      length: 1,
      convRule: rule14
  }, {
      start: 8021,
      length: 1,
      convRule: rule121
  }, {
      start: 8022,
      length: 1,
      convRule: rule14
  }, {
      start: 8023,
      length: 1,
      convRule: rule121
  }, {
      start: 8025,
      length: 1,
      convRule: rule122
  }, {
      start: 8027,
      length: 1,
      convRule: rule122
  }, {
      start: 8029,
      length: 1,
      convRule: rule122
  }, {
      start: 8031,
      length: 1,
      convRule: rule122
  }, {
      start: 8032,
      length: 8,
      convRule: rule121
  }, {
      start: 8040,
      length: 8,
      convRule: rule122
  }, {
      start: 8048,
      length: 2,
      convRule: rule123
  }, {
      start: 8050,
      length: 4,
      convRule: rule124
  }, {
      start: 8054,
      length: 2,
      convRule: rule125
  }, {
      start: 8056,
      length: 2,
      convRule: rule126
  }, {
      start: 8058,
      length: 2,
      convRule: rule127
  }, {
      start: 8060,
      length: 2,
      convRule: rule128
  }, {
      start: 8064,
      length: 8,
      convRule: rule121
  }, {
      start: 8072,
      length: 8,
      convRule: rule129
  }, {
      start: 8080,
      length: 8,
      convRule: rule121
  }, {
      start: 8088,
      length: 8,
      convRule: rule129
  }, {
      start: 8096,
      length: 8,
      convRule: rule121
  }, {
      start: 8104,
      length: 8,
      convRule: rule129
  }, {
      start: 8112,
      length: 2,
      convRule: rule121
  }, {
      start: 8114,
      length: 1,
      convRule: rule14
  }, {
      start: 8115,
      length: 1,
      convRule: rule130
  }, {
      start: 8116,
      length: 1,
      convRule: rule14
  }, {
      start: 8118,
      length: 2,
      convRule: rule14
  }, {
      start: 8120,
      length: 2,
      convRule: rule122
  }, {
      start: 8122,
      length: 2,
      convRule: rule131
  }, {
      start: 8124,
      length: 1,
      convRule: rule132
  }, {
      start: 8125,
      length: 1,
      convRule: rule10
  }, {
      start: 8126,
      length: 1,
      convRule: rule133
  }, {
      start: 8127,
      length: 3,
      convRule: rule10
  }, {
      start: 8130,
      length: 1,
      convRule: rule14
  }, {
      start: 8131,
      length: 1,
      convRule: rule130
  }, {
      start: 8132,
      length: 1,
      convRule: rule14
  }, {
      start: 8134,
      length: 2,
      convRule: rule14
  }, {
      start: 8136,
      length: 4,
      convRule: rule134
  }, {
      start: 8140,
      length: 1,
      convRule: rule132
  }, {
      start: 8141,
      length: 3,
      convRule: rule10
  }, {
      start: 8144,
      length: 2,
      convRule: rule121
  }, {
      start: 8146,
      length: 2,
      convRule: rule14
  }, {
      start: 8150,
      length: 2,
      convRule: rule14
  }, {
      start: 8152,
      length: 2,
      convRule: rule122
  }, {
      start: 8154,
      length: 2,
      convRule: rule135
  }, {
      start: 8157,
      length: 3,
      convRule: rule10
  }, {
      start: 8160,
      length: 2,
      convRule: rule121
  }, {
      start: 8162,
      length: 3,
      convRule: rule14
  }, {
      start: 8165,
      length: 1,
      convRule: rule104
  }, {
      start: 8166,
      length: 2,
      convRule: rule14
  }, {
      start: 8168,
      length: 2,
      convRule: rule122
  }, {
      start: 8170,
      length: 2,
      convRule: rule136
  }, {
      start: 8172,
      length: 1,
      convRule: rule107
  }, {
      start: 8173,
      length: 3,
      convRule: rule10
  }, {
      start: 8178,
      length: 1,
      convRule: rule14
  }, {
      start: 8179,
      length: 1,
      convRule: rule130
  }, {
      start: 8180,
      length: 1,
      convRule: rule14
  }, {
      start: 8182,
      length: 2,
      convRule: rule14
  }, {
      start: 8184,
      length: 2,
      convRule: rule137
  }, {
      start: 8186,
      length: 2,
      convRule: rule138
  }, {
      start: 8188,
      length: 1,
      convRule: rule132
  }, {
      start: 8189,
      length: 2,
      convRule: rule10
  }, {
      start: 8192,
      length: 11,
      convRule: rule1
  }, {
      start: 8203,
      length: 5,
      convRule: rule16
  }, {
      start: 8208,
      length: 6,
      convRule: rule7
  }, {
      start: 8214,
      length: 2,
      convRule: rule2
  }, {
      start: 8216,
      length: 1,
      convRule: rule15
  }, {
      start: 8217,
      length: 1,
      convRule: rule19
  }, {
      start: 8218,
      length: 1,
      convRule: rule4
  }, {
      start: 8219,
      length: 2,
      convRule: rule15
  }, {
      start: 8221,
      length: 1,
      convRule: rule19
  }, {
      start: 8222,
      length: 1,
      convRule: rule4
  }, {
      start: 8223,
      length: 1,
      convRule: rule15
  }, {
      start: 8224,
      length: 8,
      convRule: rule2
  }, {
      start: 8232,
      length: 1,
      convRule: rule139
  }, {
      start: 8233,
      length: 1,
      convRule: rule140
  }, {
      start: 8234,
      length: 5,
      convRule: rule16
  }, {
      start: 8239,
      length: 1,
      convRule: rule1
  }, {
      start: 8240,
      length: 9,
      convRule: rule2
  }, {
      start: 8249,
      length: 1,
      convRule: rule15
  }, {
      start: 8250,
      length: 1,
      convRule: rule19
  }, {
      start: 8251,
      length: 4,
      convRule: rule2
  }, {
      start: 8255,
      length: 2,
      convRule: rule11
  }, {
      start: 8257,
      length: 3,
      convRule: rule2
  }, {
      start: 8260,
      length: 1,
      convRule: rule6
  }, {
      start: 8261,
      length: 1,
      convRule: rule4
  }, {
      start: 8262,
      length: 1,
      convRule: rule5
  }, {
      start: 8263,
      length: 11,
      convRule: rule2
  }, {
      start: 8274,
      length: 1,
      convRule: rule6
  }, {
      start: 8275,
      length: 1,
      convRule: rule2
  }, {
      start: 8276,
      length: 1,
      convRule: rule11
  }, {
      start: 8277,
      length: 10,
      convRule: rule2
  }, {
      start: 8287,
      length: 1,
      convRule: rule1
  }, {
      start: 8288,
      length: 5,
      convRule: rule16
  }, {
      start: 8298,
      length: 6,
      convRule: rule16
  }, {
      start: 8304,
      length: 1,
      convRule: rule17
  }, {
      start: 8305,
      length: 1,
      convRule: rule83
  }, {
      start: 8308,
      length: 6,
      convRule: rule17
  }, {
      start: 8314,
      length: 3,
      convRule: rule6
  }, {
      start: 8317,
      length: 1,
      convRule: rule4
  }, {
      start: 8318,
      length: 1,
      convRule: rule5
  }, {
      start: 8319,
      length: 1,
      convRule: rule83
  }, {
      start: 8320,
      length: 10,
      convRule: rule17
  }, {
      start: 8330,
      length: 3,
      convRule: rule6
  }, {
      start: 8333,
      length: 1,
      convRule: rule4
  }, {
      start: 8334,
      length: 1,
      convRule: rule5
  }, {
      start: 8336,
      length: 13,
      convRule: rule83
  }, {
      start: 8352,
      length: 26,
      convRule: rule3
  }, {
      start: 8400,
      length: 13,
      convRule: rule84
  }, {
      start: 8413,
      length: 4,
      convRule: rule109
  }, {
      start: 8417,
      length: 1,
      convRule: rule84
  }, {
      start: 8418,
      length: 3,
      convRule: rule109
  }, {
      start: 8421,
      length: 12,
      convRule: rule84
  }, {
      start: 8448,
      length: 2,
      convRule: rule13
  }, {
      start: 8450,
      length: 1,
      convRule: rule98
  }, {
      start: 8451,
      length: 4,
      convRule: rule13
  }, {
      start: 8455,
      length: 1,
      convRule: rule98
  }, {
      start: 8456,
      length: 2,
      convRule: rule13
  }, {
      start: 8458,
      length: 1,
      convRule: rule14
  }, {
      start: 8459,
      length: 3,
      convRule: rule98
  }, {
      start: 8462,
      length: 2,
      convRule: rule14
  }, {
      start: 8464,
      length: 3,
      convRule: rule98
  }, {
      start: 8467,
      length: 1,
      convRule: rule14
  }, {
      start: 8468,
      length: 1,
      convRule: rule13
  }, {
      start: 8469,
      length: 1,
      convRule: rule98
  }, {
      start: 8470,
      length: 2,
      convRule: rule13
  }, {
      start: 8472,
      length: 1,
      convRule: rule6
  }, {
      start: 8473,
      length: 5,
      convRule: rule98
  }, {
      start: 8478,
      length: 6,
      convRule: rule13
  }, {
      start: 8484,
      length: 1,
      convRule: rule98
  }, {
      start: 8485,
      length: 1,
      convRule: rule13
  }, {
      start: 8486,
      length: 1,
      convRule: rule141
  }, {
      start: 8487,
      length: 1,
      convRule: rule13
  }, {
      start: 8488,
      length: 1,
      convRule: rule98
  }, {
      start: 8489,
      length: 1,
      convRule: rule13
  }, {
      start: 8490,
      length: 1,
      convRule: rule142
  }, {
      start: 8491,
      length: 1,
      convRule: rule143
  }, {
      start: 8492,
      length: 2,
      convRule: rule98
  }, {
      start: 8494,
      length: 1,
      convRule: rule13
  }, {
      start: 8495,
      length: 1,
      convRule: rule14
  }, {
      start: 8496,
      length: 2,
      convRule: rule98
  }, {
      start: 8498,
      length: 1,
      convRule: rule144
  }, {
      start: 8499,
      length: 1,
      convRule: rule98
  }, {
      start: 8500,
      length: 1,
      convRule: rule14
  }, {
      start: 8501,
      length: 4,
      convRule: rule45
  }, {
      start: 8505,
      length: 1,
      convRule: rule14
  }, {
      start: 8506,
      length: 2,
      convRule: rule13
  }, {
      start: 8508,
      length: 2,
      convRule: rule14
  }, {
      start: 8510,
      length: 2,
      convRule: rule98
  }, {
      start: 8512,
      length: 5,
      convRule: rule6
  }, {
      start: 8517,
      length: 1,
      convRule: rule98
  }, {
      start: 8518,
      length: 4,
      convRule: rule14
  }, {
      start: 8522,
      length: 1,
      convRule: rule13
  }, {
      start: 8523,
      length: 1,
      convRule: rule6
  }, {
      start: 8524,
      length: 2,
      convRule: rule13
  }, {
      start: 8526,
      length: 1,
      convRule: rule145
  }, {
      start: 8527,
      length: 1,
      convRule: rule13
  }, {
      start: 8528,
      length: 16,
      convRule: rule17
  }, {
      start: 8544,
      length: 16,
      convRule: rule146
  }, {
      start: 8560,
      length: 16,
      convRule: rule147
  }, {
      start: 8576,
      length: 3,
      convRule: rule116
  }, {
      start: 8579,
      length: 1,
      convRule: rule21
  }, {
      start: 8580,
      length: 1,
      convRule: rule22
  }, {
      start: 8581,
      length: 4,
      convRule: rule116
  }, {
      start: 8585,
      length: 1,
      convRule: rule17
  }, {
      start: 8592,
      length: 5,
      convRule: rule6
  }, {
      start: 8597,
      length: 5,
      convRule: rule13
  }, {
      start: 8602,
      length: 2,
      convRule: rule6
  }, {
      start: 8604,
      length: 4,
      convRule: rule13
  }, {
      start: 8608,
      length: 1,
      convRule: rule6
  }, {
      start: 8609,
      length: 2,
      convRule: rule13
  }, {
      start: 8611,
      length: 1,
      convRule: rule6
  }, {
      start: 8612,
      length: 2,
      convRule: rule13
  }, {
      start: 8614,
      length: 1,
      convRule: rule6
  }, {
      start: 8615,
      length: 7,
      convRule: rule13
  }, {
      start: 8622,
      length: 1,
      convRule: rule6
  }, {
      start: 8623,
      length: 31,
      convRule: rule13
  }, {
      start: 8654,
      length: 2,
      convRule: rule6
  }, {
      start: 8656,
      length: 2,
      convRule: rule13
  }, {
      start: 8658,
      length: 1,
      convRule: rule6
  }, {
      start: 8659,
      length: 1,
      convRule: rule13
  }, {
      start: 8660,
      length: 1,
      convRule: rule6
  }, {
      start: 8661,
      length: 31,
      convRule: rule13
  }, {
      start: 8692,
      length: 268,
      convRule: rule6
  }, {
      start: 8960,
      length: 8,
      convRule: rule13
  }, {
      start: 8968,
      length: 4,
      convRule: rule6
  }, {
      start: 8972,
      length: 20,
      convRule: rule13
  }, {
      start: 8992,
      length: 2,
      convRule: rule6
  }, {
      start: 8994,
      length: 7,
      convRule: rule13
  }, {
      start: 9001,
      length: 1,
      convRule: rule4
  }, {
      start: 9002,
      length: 1,
      convRule: rule5
  }, {
      start: 9003,
      length: 81,
      convRule: rule13
  }, {
      start: 9084,
      length: 1,
      convRule: rule6
  }, {
      start: 9085,
      length: 30,
      convRule: rule13
  }, {
      start: 9115,
      length: 25,
      convRule: rule6
  }, {
      start: 9140,
      length: 40,
      convRule: rule13
  }, {
      start: 9180,
      length: 6,
      convRule: rule6
  }, {
      start: 9186,
      length: 18,
      convRule: rule13
  }, {
      start: 9216,
      length: 39,
      convRule: rule13
  }, {
      start: 9280,
      length: 11,
      convRule: rule13
  }, {
      start: 9312,
      length: 60,
      convRule: rule17
  }, {
      start: 9372,
      length: 26,
      convRule: rule13
  }, {
      start: 9398,
      length: 26,
      convRule: rule148
  }, {
      start: 9424,
      length: 26,
      convRule: rule149
  }, {
      start: 9450,
      length: 22,
      convRule: rule17
  }, {
      start: 9472,
      length: 183,
      convRule: rule13
  }, {
      start: 9655,
      length: 1,
      convRule: rule6
  }, {
      start: 9656,
      length: 9,
      convRule: rule13
  }, {
      start: 9665,
      length: 1,
      convRule: rule6
  }, {
      start: 9666,
      length: 54,
      convRule: rule13
  }, {
      start: 9720,
      length: 8,
      convRule: rule6
  }, {
      start: 9728,
      length: 111,
      convRule: rule13
  }, {
      start: 9839,
      length: 1,
      convRule: rule6
  }, {
      start: 9840,
      length: 144,
      convRule: rule13
  }, {
      start: 9985,
      length: 103,
      convRule: rule13
  }, {
      start: 10088,
      length: 1,
      convRule: rule4
  }, {
      start: 10089,
      length: 1,
      convRule: rule5
  }, {
      start: 10090,
      length: 1,
      convRule: rule4
  }, {
      start: 10091,
      length: 1,
      convRule: rule5
  }, {
      start: 10092,
      length: 1,
      convRule: rule4
  }, {
      start: 10093,
      length: 1,
      convRule: rule5
  }, {
      start: 10094,
      length: 1,
      convRule: rule4
  }, {
      start: 10095,
      length: 1,
      convRule: rule5
  }, {
      start: 10096,
      length: 1,
      convRule: rule4
  }, {
      start: 10097,
      length: 1,
      convRule: rule5
  }, {
      start: 10098,
      length: 1,
      convRule: rule4
  }, {
      start: 10099,
      length: 1,
      convRule: rule5
  }, {
      start: 10100,
      length: 1,
      convRule: rule4
  }, {
      start: 10101,
      length: 1,
      convRule: rule5
  }, {
      start: 10102,
      length: 30,
      convRule: rule17
  }, {
      start: 10132,
      length: 44,
      convRule: rule13
  }, {
      start: 10176,
      length: 5,
      convRule: rule6
  }, {
      start: 10181,
      length: 1,
      convRule: rule4
  }, {
      start: 10182,
      length: 1,
      convRule: rule5
  }, {
      start: 10183,
      length: 4,
      convRule: rule6
  }, {
      start: 10188,
      length: 1,
      convRule: rule6
  }, {
      start: 10190,
      length: 24,
      convRule: rule6
  }, {
      start: 10214,
      length: 1,
      convRule: rule4
  }, {
      start: 10215,
      length: 1,
      convRule: rule5
  }, {
      start: 10216,
      length: 1,
      convRule: rule4
  }, {
      start: 10217,
      length: 1,
      convRule: rule5
  }, {
      start: 10218,
      length: 1,
      convRule: rule4
  }, {
      start: 10219,
      length: 1,
      convRule: rule5
  }, {
      start: 10220,
      length: 1,
      convRule: rule4
  }, {
      start: 10221,
      length: 1,
      convRule: rule5
  }, {
      start: 10222,
      length: 1,
      convRule: rule4
  }, {
      start: 10223,
      length: 1,
      convRule: rule5
  }, {
      start: 10224,
      length: 16,
      convRule: rule6
  }, {
      start: 10240,
      length: 256,
      convRule: rule13
  }, {
      start: 10496,
      length: 131,
      convRule: rule6
  }, {
      start: 10627,
      length: 1,
      convRule: rule4
  }, {
      start: 10628,
      length: 1,
      convRule: rule5
  }, {
      start: 10629,
      length: 1,
      convRule: rule4
  }, {
      start: 10630,
      length: 1,
      convRule: rule5
  }, {
      start: 10631,
      length: 1,
      convRule: rule4
  }, {
      start: 10632,
      length: 1,
      convRule: rule5
  }, {
      start: 10633,
      length: 1,
      convRule: rule4
  }, {
      start: 10634,
      length: 1,
      convRule: rule5
  }, {
      start: 10635,
      length: 1,
      convRule: rule4
  }, {
      start: 10636,
      length: 1,
      convRule: rule5
  }, {
      start: 10637,
      length: 1,
      convRule: rule4
  }, {
      start: 10638,
      length: 1,
      convRule: rule5
  }, {
      start: 10639,
      length: 1,
      convRule: rule4
  }, {
      start: 10640,
      length: 1,
      convRule: rule5
  }, {
      start: 10641,
      length: 1,
      convRule: rule4
  }, {
      start: 10642,
      length: 1,
      convRule: rule5
  }, {
      start: 10643,
      length: 1,
      convRule: rule4
  }, {
      start: 10644,
      length: 1,
      convRule: rule5
  }, {
      start: 10645,
      length: 1,
      convRule: rule4
  }, {
      start: 10646,
      length: 1,
      convRule: rule5
  }, {
      start: 10647,
      length: 1,
      convRule: rule4
  }, {
      start: 10648,
      length: 1,
      convRule: rule5
  }, {
      start: 10649,
      length: 63,
      convRule: rule6
  }, {
      start: 10712,
      length: 1,
      convRule: rule4
  }, {
      start: 10713,
      length: 1,
      convRule: rule5
  }, {
      start: 10714,
      length: 1,
      convRule: rule4
  }, {
      start: 10715,
      length: 1,
      convRule: rule5
  }, {
      start: 10716,
      length: 32,
      convRule: rule6
  }, {
      start: 10748,
      length: 1,
      convRule: rule4
  }, {
      start: 10749,
      length: 1,
      convRule: rule5
  }, {
      start: 10750,
      length: 258,
      convRule: rule6
  }, {
      start: 11008,
      length: 48,
      convRule: rule13
  }, {
      start: 11056,
      length: 21,
      convRule: rule6
  }, {
      start: 11077,
      length: 2,
      convRule: rule13
  }, {
      start: 11079,
      length: 6,
      convRule: rule6
  }, {
      start: 11088,
      length: 10,
      convRule: rule13
  }, {
      start: 11264,
      length: 47,
      convRule: rule112
  }, {
      start: 11312,
      length: 47,
      convRule: rule113
  }, {
      start: 11360,
      length: 1,
      convRule: rule21
  }, {
      start: 11361,
      length: 1,
      convRule: rule22
  }, {
      start: 11362,
      length: 1,
      convRule: rule150
  }, {
      start: 11363,
      length: 1,
      convRule: rule151
  }, {
      start: 11364,
      length: 1,
      convRule: rule152
  }, {
      start: 11365,
      length: 1,
      convRule: rule153
  }, {
      start: 11366,
      length: 1,
      convRule: rule154
  }, {
      start: 11367,
      length: 1,
      convRule: rule21
  }, {
      start: 11368,
      length: 1,
      convRule: rule22
  }, {
      start: 11369,
      length: 1,
      convRule: rule21
  }, {
      start: 11370,
      length: 1,
      convRule: rule22
  }, {
      start: 11371,
      length: 1,
      convRule: rule21
  }, {
      start: 11372,
      length: 1,
      convRule: rule22
  }, {
      start: 11373,
      length: 1,
      convRule: rule155
  }, {
      start: 11374,
      length: 1,
      convRule: rule156
  }, {
      start: 11375,
      length: 1,
      convRule: rule157
  }, {
      start: 11376,
      length: 1,
      convRule: rule158
  }, {
      start: 11377,
      length: 1,
      convRule: rule14
  }, {
      start: 11378,
      length: 1,
      convRule: rule21
  }, {
      start: 11379,
      length: 1,
      convRule: rule22
  }, {
      start: 11380,
      length: 1,
      convRule: rule14
  }, {
      start: 11381,
      length: 1,
      convRule: rule21
  }, {
      start: 11382,
      length: 1,
      convRule: rule22
  }, {
      start: 11383,
      length: 6,
      convRule: rule14
  }, {
      start: 11389,
      length: 1,
      convRule: rule83
  }, {
      start: 11390,
      length: 2,
      convRule: rule159
  }, {
      start: 11392,
      length: 1,
      convRule: rule21
  }, {
      start: 11393,
      length: 1,
      convRule: rule22
  }, {
      start: 11394,
      length: 1,
      convRule: rule21
  }, {
      start: 11395,
      length: 1,
      convRule: rule22
  }, {
      start: 11396,
      length: 1,
      convRule: rule21
  }, {
      start: 11397,
      length: 1,
      convRule: rule22
  }, {
      start: 11398,
      length: 1,
      convRule: rule21
  }, {
      start: 11399,
      length: 1,
      convRule: rule22
  }, {
      start: 11400,
      length: 1,
      convRule: rule21
  }, {
      start: 11401,
      length: 1,
      convRule: rule22
  }, {
      start: 11402,
      length: 1,
      convRule: rule21
  }, {
      start: 11403,
      length: 1,
      convRule: rule22
  }, {
      start: 11404,
      length: 1,
      convRule: rule21
  }, {
      start: 11405,
      length: 1,
      convRule: rule22
  }, {
      start: 11406,
      length: 1,
      convRule: rule21
  }, {
      start: 11407,
      length: 1,
      convRule: rule22
  }, {
      start: 11408,
      length: 1,
      convRule: rule21
  }, {
      start: 11409,
      length: 1,
      convRule: rule22
  }, {
      start: 11410,
      length: 1,
      convRule: rule21
  }, {
      start: 11411,
      length: 1,
      convRule: rule22
  }, {
      start: 11412,
      length: 1,
      convRule: rule21
  }, {
      start: 11413,
      length: 1,
      convRule: rule22
  }, {
      start: 11414,
      length: 1,
      convRule: rule21
  }, {
      start: 11415,
      length: 1,
      convRule: rule22
  }, {
      start: 11416,
      length: 1,
      convRule: rule21
  }, {
      start: 11417,
      length: 1,
      convRule: rule22
  }, {
      start: 11418,
      length: 1,
      convRule: rule21
  }, {
      start: 11419,
      length: 1,
      convRule: rule22
  }, {
      start: 11420,
      length: 1,
      convRule: rule21
  }, {
      start: 11421,
      length: 1,
      convRule: rule22
  }, {
      start: 11422,
      length: 1,
      convRule: rule21
  }, {
      start: 11423,
      length: 1,
      convRule: rule22
  }, {
      start: 11424,
      length: 1,
      convRule: rule21
  }, {
      start: 11425,
      length: 1,
      convRule: rule22
  }, {
      start: 11426,
      length: 1,
      convRule: rule21
  }, {
      start: 11427,
      length: 1,
      convRule: rule22
  }, {
      start: 11428,
      length: 1,
      convRule: rule21
  }, {
      start: 11429,
      length: 1,
      convRule: rule22
  }, {
      start: 11430,
      length: 1,
      convRule: rule21
  }, {
      start: 11431,
      length: 1,
      convRule: rule22
  }, {
      start: 11432,
      length: 1,
      convRule: rule21
  }, {
      start: 11433,
      length: 1,
      convRule: rule22
  }, {
      start: 11434,
      length: 1,
      convRule: rule21
  }, {
      start: 11435,
      length: 1,
      convRule: rule22
  }, {
      start: 11436,
      length: 1,
      convRule: rule21
  }, {
      start: 11437,
      length: 1,
      convRule: rule22
  }, {
      start: 11438,
      length: 1,
      convRule: rule21
  }, {
      start: 11439,
      length: 1,
      convRule: rule22
  }, {
      start: 11440,
      length: 1,
      convRule: rule21
  }, {
      start: 11441,
      length: 1,
      convRule: rule22
  }, {
      start: 11442,
      length: 1,
      convRule: rule21
  }, {
      start: 11443,
      length: 1,
      convRule: rule22
  }, {
      start: 11444,
      length: 1,
      convRule: rule21
  }, {
      start: 11445,
      length: 1,
      convRule: rule22
  }, {
      start: 11446,
      length: 1,
      convRule: rule21
  }, {
      start: 11447,
      length: 1,
      convRule: rule22
  }, {
      start: 11448,
      length: 1,
      convRule: rule21
  }, {
      start: 11449,
      length: 1,
      convRule: rule22
  }, {
      start: 11450,
      length: 1,
      convRule: rule21
  }, {
      start: 11451,
      length: 1,
      convRule: rule22
  }, {
      start: 11452,
      length: 1,
      convRule: rule21
  }, {
      start: 11453,
      length: 1,
      convRule: rule22
  }, {
      start: 11454,
      length: 1,
      convRule: rule21
  }, {
      start: 11455,
      length: 1,
      convRule: rule22
  }, {
      start: 11456,
      length: 1,
      convRule: rule21
  }, {
      start: 11457,
      length: 1,
      convRule: rule22
  }, {
      start: 11458,
      length: 1,
      convRule: rule21
  }, {
      start: 11459,
      length: 1,
      convRule: rule22
  }, {
      start: 11460,
      length: 1,
      convRule: rule21
  }, {
      start: 11461,
      length: 1,
      convRule: rule22
  }, {
      start: 11462,
      length: 1,
      convRule: rule21
  }, {
      start: 11463,
      length: 1,
      convRule: rule22
  }, {
      start: 11464,
      length: 1,
      convRule: rule21
  }, {
      start: 11465,
      length: 1,
      convRule: rule22
  }, {
      start: 11466,
      length: 1,
      convRule: rule21
  }, {
      start: 11467,
      length: 1,
      convRule: rule22
  }, {
      start: 11468,
      length: 1,
      convRule: rule21
  }, {
      start: 11469,
      length: 1,
      convRule: rule22
  }, {
      start: 11470,
      length: 1,
      convRule: rule21
  }, {
      start: 11471,
      length: 1,
      convRule: rule22
  }, {
      start: 11472,
      length: 1,
      convRule: rule21
  }, {
      start: 11473,
      length: 1,
      convRule: rule22
  }, {
      start: 11474,
      length: 1,
      convRule: rule21
  }, {
      start: 11475,
      length: 1,
      convRule: rule22
  }, {
      start: 11476,
      length: 1,
      convRule: rule21
  }, {
      start: 11477,
      length: 1,
      convRule: rule22
  }, {
      start: 11478,
      length: 1,
      convRule: rule21
  }, {
      start: 11479,
      length: 1,
      convRule: rule22
  }, {
      start: 11480,
      length: 1,
      convRule: rule21
  }, {
      start: 11481,
      length: 1,
      convRule: rule22
  }, {
      start: 11482,
      length: 1,
      convRule: rule21
  }, {
      start: 11483,
      length: 1,
      convRule: rule22
  }, {
      start: 11484,
      length: 1,
      convRule: rule21
  }, {
      start: 11485,
      length: 1,
      convRule: rule22
  }, {
      start: 11486,
      length: 1,
      convRule: rule21
  }, {
      start: 11487,
      length: 1,
      convRule: rule22
  }, {
      start: 11488,
      length: 1,
      convRule: rule21
  }, {
      start: 11489,
      length: 1,
      convRule: rule22
  }, {
      start: 11490,
      length: 1,
      convRule: rule21
  }, {
      start: 11491,
      length: 1,
      convRule: rule22
  }, {
      start: 11492,
      length: 1,
      convRule: rule14
  }, {
      start: 11493,
      length: 6,
      convRule: rule13
  }, {
      start: 11499,
      length: 1,
      convRule: rule21
  }, {
      start: 11500,
      length: 1,
      convRule: rule22
  }, {
      start: 11501,
      length: 1,
      convRule: rule21
  }, {
      start: 11502,
      length: 1,
      convRule: rule22
  }, {
      start: 11503,
      length: 3,
      convRule: rule84
  }, {
      start: 11513,
      length: 4,
      convRule: rule2
  }, {
      start: 11517,
      length: 1,
      convRule: rule17
  }, {
      start: 11518,
      length: 2,
      convRule: rule2
  }, {
      start: 11520,
      length: 38,
      convRule: rule160
  }, {
      start: 11568,
      length: 54,
      convRule: rule45
  }, {
      start: 11631,
      length: 1,
      convRule: rule83
  }, {
      start: 11632,
      length: 1,
      convRule: rule2
  }, {
      start: 11647,
      length: 1,
      convRule: rule84
  }, {
      start: 11648,
      length: 23,
      convRule: rule45
  }, {
      start: 11680,
      length: 7,
      convRule: rule45
  }, {
      start: 11688,
      length: 7,
      convRule: rule45
  }, {
      start: 11696,
      length: 7,
      convRule: rule45
  }, {
      start: 11704,
      length: 7,
      convRule: rule45
  }, {
      start: 11712,
      length: 7,
      convRule: rule45
  }, {
      start: 11720,
      length: 7,
      convRule: rule45
  }, {
      start: 11728,
      length: 7,
      convRule: rule45
  }, {
      start: 11736,
      length: 7,
      convRule: rule45
  }, {
      start: 11744,
      length: 32,
      convRule: rule84
  }, {
      start: 11776,
      length: 2,
      convRule: rule2
  }, {
      start: 11778,
      length: 1,
      convRule: rule15
  }, {
      start: 11779,
      length: 1,
      convRule: rule19
  }, {
      start: 11780,
      length: 1,
      convRule: rule15
  }, {
      start: 11781,
      length: 1,
      convRule: rule19
  }, {
      start: 11782,
      length: 3,
      convRule: rule2
  }, {
      start: 11785,
      length: 1,
      convRule: rule15
  }, {
      start: 11786,
      length: 1,
      convRule: rule19
  }, {
      start: 11787,
      length: 1,
      convRule: rule2
  }, {
      start: 11788,
      length: 1,
      convRule: rule15
  }, {
      start: 11789,
      length: 1,
      convRule: rule19
  }, {
      start: 11790,
      length: 9,
      convRule: rule2
  }, {
      start: 11799,
      length: 1,
      convRule: rule7
  }, {
      start: 11800,
      length: 2,
      convRule: rule2
  }, {
      start: 11802,
      length: 1,
      convRule: rule7
  }, {
      start: 11803,
      length: 1,
      convRule: rule2
  }, {
      start: 11804,
      length: 1,
      convRule: rule15
  }, {
      start: 11805,
      length: 1,
      convRule: rule19
  }, {
      start: 11806,
      length: 2,
      convRule: rule2
  }, {
      start: 11808,
      length: 1,
      convRule: rule15
  }, {
      start: 11809,
      length: 1,
      convRule: rule19
  }, {
      start: 11810,
      length: 1,
      convRule: rule4
  }, {
      start: 11811,
      length: 1,
      convRule: rule5
  }, {
      start: 11812,
      length: 1,
      convRule: rule4
  }, {
      start: 11813,
      length: 1,
      convRule: rule5
  }, {
      start: 11814,
      length: 1,
      convRule: rule4
  }, {
      start: 11815,
      length: 1,
      convRule: rule5
  }, {
      start: 11816,
      length: 1,
      convRule: rule4
  }, {
      start: 11817,
      length: 1,
      convRule: rule5
  }, {
      start: 11818,
      length: 5,
      convRule: rule2
  }, {
      start: 11823,
      length: 1,
      convRule: rule83
  }, {
      start: 11824,
      length: 2,
      convRule: rule2
  }, {
      start: 11904,
      length: 26,
      convRule: rule13
  }, {
      start: 11931,
      length: 89,
      convRule: rule13
  }, {
      start: 12032,
      length: 214,
      convRule: rule13
  }, {
      start: 12272,
      length: 12,
      convRule: rule13
  }, {
      start: 12288,
      length: 1,
      convRule: rule1
  }, {
      start: 12289,
      length: 3,
      convRule: rule2
  }, {
      start: 12292,
      length: 1,
      convRule: rule13
  }, {
      start: 12293,
      length: 1,
      convRule: rule83
  }, {
      start: 12294,
      length: 1,
      convRule: rule45
  }, {
      start: 12295,
      length: 1,
      convRule: rule116
  }, {
      start: 12296,
      length: 1,
      convRule: rule4
  }, {
      start: 12297,
      length: 1,
      convRule: rule5
  }, {
      start: 12298,
      length: 1,
      convRule: rule4
  }, {
      start: 12299,
      length: 1,
      convRule: rule5
  }, {
      start: 12300,
      length: 1,
      convRule: rule4
  }, {
      start: 12301,
      length: 1,
      convRule: rule5
  }, {
      start: 12302,
      length: 1,
      convRule: rule4
  }, {
      start: 12303,
      length: 1,
      convRule: rule5
  }, {
      start: 12304,
      length: 1,
      convRule: rule4
  }, {
      start: 12305,
      length: 1,
      convRule: rule5
  }, {
      start: 12306,
      length: 2,
      convRule: rule13
  }, {
      start: 12308,
      length: 1,
      convRule: rule4
  }, {
      start: 12309,
      length: 1,
      convRule: rule5
  }, {
      start: 12310,
      length: 1,
      convRule: rule4
  }, {
      start: 12311,
      length: 1,
      convRule: rule5
  }, {
      start: 12312,
      length: 1,
      convRule: rule4
  }, {
      start: 12313,
      length: 1,
      convRule: rule5
  }, {
      start: 12314,
      length: 1,
      convRule: rule4
  }, {
      start: 12315,
      length: 1,
      convRule: rule5
  }, {
      start: 12316,
      length: 1,
      convRule: rule7
  }, {
      start: 12317,
      length: 1,
      convRule: rule4
  }, {
      start: 12318,
      length: 2,
      convRule: rule5
  }, {
      start: 12320,
      length: 1,
      convRule: rule13
  }, {
      start: 12321,
      length: 9,
      convRule: rule116
  }, {
      start: 12330,
      length: 6,
      convRule: rule84
  }, {
      start: 12336,
      length: 1,
      convRule: rule7
  }, {
      start: 12337,
      length: 5,
      convRule: rule83
  }, {
      start: 12342,
      length: 2,
      convRule: rule13
  }, {
      start: 12344,
      length: 3,
      convRule: rule116
  }, {
      start: 12347,
      length: 1,
      convRule: rule83
  }, {
      start: 12348,
      length: 1,
      convRule: rule45
  }, {
      start: 12349,
      length: 1,
      convRule: rule2
  }, {
      start: 12350,
      length: 2,
      convRule: rule13
  }, {
      start: 12353,
      length: 86,
      convRule: rule45
  }, {
      start: 12441,
      length: 2,
      convRule: rule84
  }, {
      start: 12443,
      length: 2,
      convRule: rule10
  }, {
      start: 12445,
      length: 2,
      convRule: rule83
  }, {
      start: 12447,
      length: 1,
      convRule: rule45
  }, {
      start: 12448,
      length: 1,
      convRule: rule7
  }, {
      start: 12449,
      length: 90,
      convRule: rule45
  }, {
      start: 12539,
      length: 1,
      convRule: rule2
  }, {
      start: 12540,
      length: 3,
      convRule: rule83
  }, {
      start: 12543,
      length: 1,
      convRule: rule45
  }, {
      start: 12549,
      length: 41,
      convRule: rule45
  }, {
      start: 12593,
      length: 94,
      convRule: rule45
  }, {
      start: 12688,
      length: 2,
      convRule: rule13
  }, {
      start: 12690,
      length: 4,
      convRule: rule17
  }, {
      start: 12694,
      length: 10,
      convRule: rule13
  }, {
      start: 12704,
      length: 27,
      convRule: rule45
  }, {
      start: 12736,
      length: 36,
      convRule: rule13
  }, {
      start: 12784,
      length: 16,
      convRule: rule45
  }, {
      start: 12800,
      length: 31,
      convRule: rule13
  }, {
      start: 12832,
      length: 10,
      convRule: rule17
  }, {
      start: 12842,
      length: 39,
      convRule: rule13
  }, {
      start: 12881,
      length: 15,
      convRule: rule17
  }, {
      start: 12896,
      length: 32,
      convRule: rule13
  }, {
      start: 12928,
      length: 10,
      convRule: rule17
  }, {
      start: 12938,
      length: 39,
      convRule: rule13
  }, {
      start: 12977,
      length: 15,
      convRule: rule17
  }, {
      start: 12992,
      length: 63,
      convRule: rule13
  }, {
      start: 13056,
      length: 256,
      convRule: rule13
  }, {
      start: 13312,
      length: 6582,
      convRule: rule45
  }, {
      start: 19904,
      length: 64,
      convRule: rule13
  }, {
      start: 19968,
      length: 20940,
      convRule: rule45
  }, {
      start: 40960,
      length: 21,
      convRule: rule45
  }, {
      start: 40981,
      length: 1,
      convRule: rule83
  }, {
      start: 40982,
      length: 1143,
      convRule: rule45
  }, {
      start: 42128,
      length: 55,
      convRule: rule13
  }, {
      start: 42192,
      length: 40,
      convRule: rule45
  }, {
      start: 42232,
      length: 6,
      convRule: rule83
  }, {
      start: 42238,
      length: 2,
      convRule: rule2
  }, {
      start: 42240,
      length: 268,
      convRule: rule45
  }, {
      start: 42508,
      length: 1,
      convRule: rule83
  }, {
      start: 42509,
      length: 3,
      convRule: rule2
  }, {
      start: 42512,
      length: 16,
      convRule: rule45
  }, {
      start: 42528,
      length: 10,
      convRule: rule8
  }, {
      start: 42538,
      length: 2,
      convRule: rule45
  }, {
      start: 42560,
      length: 1,
      convRule: rule21
  }, {
      start: 42561,
      length: 1,
      convRule: rule22
  }, {
      start: 42562,
      length: 1,
      convRule: rule21
  }, {
      start: 42563,
      length: 1,
      convRule: rule22
  }, {
      start: 42564,
      length: 1,
      convRule: rule21
  }, {
      start: 42565,
      length: 1,
      convRule: rule22
  }, {
      start: 42566,
      length: 1,
      convRule: rule21
  }, {
      start: 42567,
      length: 1,
      convRule: rule22
  }, {
      start: 42568,
      length: 1,
      convRule: rule21
  }, {
      start: 42569,
      length: 1,
      convRule: rule22
  }, {
      start: 42570,
      length: 1,
      convRule: rule21
  }, {
      start: 42571,
      length: 1,
      convRule: rule22
  }, {
      start: 42572,
      length: 1,
      convRule: rule21
  }, {
      start: 42573,
      length: 1,
      convRule: rule22
  }, {
      start: 42574,
      length: 1,
      convRule: rule21
  }, {
      start: 42575,
      length: 1,
      convRule: rule22
  }, {
      start: 42576,
      length: 1,
      convRule: rule21
  }, {
      start: 42577,
      length: 1,
      convRule: rule22
  }, {
      start: 42578,
      length: 1,
      convRule: rule21
  }, {
      start: 42579,
      length: 1,
      convRule: rule22
  }, {
      start: 42580,
      length: 1,
      convRule: rule21
  }, {
      start: 42581,
      length: 1,
      convRule: rule22
  }, {
      start: 42582,
      length: 1,
      convRule: rule21
  }, {
      start: 42583,
      length: 1,
      convRule: rule22
  }, {
      start: 42584,
      length: 1,
      convRule: rule21
  }, {
      start: 42585,
      length: 1,
      convRule: rule22
  }, {
      start: 42586,
      length: 1,
      convRule: rule21
  }, {
      start: 42587,
      length: 1,
      convRule: rule22
  }, {
      start: 42588,
      length: 1,
      convRule: rule21
  }, {
      start: 42589,
      length: 1,
      convRule: rule22
  }, {
      start: 42590,
      length: 1,
      convRule: rule21
  }, {
      start: 42591,
      length: 1,
      convRule: rule22
  }, {
      start: 42592,
      length: 1,
      convRule: rule21
  }, {
      start: 42593,
      length: 1,
      convRule: rule22
  }, {
      start: 42594,
      length: 1,
      convRule: rule21
  }, {
      start: 42595,
      length: 1,
      convRule: rule22
  }, {
      start: 42596,
      length: 1,
      convRule: rule21
  }, {
      start: 42597,
      length: 1,
      convRule: rule22
  }, {
      start: 42598,
      length: 1,
      convRule: rule21
  }, {
      start: 42599,
      length: 1,
      convRule: rule22
  }, {
      start: 42600,
      length: 1,
      convRule: rule21
  }, {
      start: 42601,
      length: 1,
      convRule: rule22
  }, {
      start: 42602,
      length: 1,
      convRule: rule21
  }, {
      start: 42603,
      length: 1,
      convRule: rule22
  }, {
      start: 42604,
      length: 1,
      convRule: rule21
  }, {
      start: 42605,
      length: 1,
      convRule: rule22
  }, {
      start: 42606,
      length: 1,
      convRule: rule45
  }, {
      start: 42607,
      length: 1,
      convRule: rule84
  }, {
      start: 42608,
      length: 3,
      convRule: rule109
  }, {
      start: 42611,
      length: 1,
      convRule: rule2
  }, {
      start: 42620,
      length: 2,
      convRule: rule84
  }, {
      start: 42622,
      length: 1,
      convRule: rule2
  }, {
      start: 42623,
      length: 1,
      convRule: rule83
  }, {
      start: 42624,
      length: 1,
      convRule: rule21
  }, {
      start: 42625,
      length: 1,
      convRule: rule22
  }, {
      start: 42626,
      length: 1,
      convRule: rule21
  }, {
      start: 42627,
      length: 1,
      convRule: rule22
  }, {
      start: 42628,
      length: 1,
      convRule: rule21
  }, {
      start: 42629,
      length: 1,
      convRule: rule22
  }, {
      start: 42630,
      length: 1,
      convRule: rule21
  }, {
      start: 42631,
      length: 1,
      convRule: rule22
  }, {
      start: 42632,
      length: 1,
      convRule: rule21
  }, {
      start: 42633,
      length: 1,
      convRule: rule22
  }, {
      start: 42634,
      length: 1,
      convRule: rule21
  }, {
      start: 42635,
      length: 1,
      convRule: rule22
  }, {
      start: 42636,
      length: 1,
      convRule: rule21
  }, {
      start: 42637,
      length: 1,
      convRule: rule22
  }, {
      start: 42638,
      length: 1,
      convRule: rule21
  }, {
      start: 42639,
      length: 1,
      convRule: rule22
  }, {
      start: 42640,
      length: 1,
      convRule: rule21
  }, {
      start: 42641,
      length: 1,
      convRule: rule22
  }, {
      start: 42642,
      length: 1,
      convRule: rule21
  }, {
      start: 42643,
      length: 1,
      convRule: rule22
  }, {
      start: 42644,
      length: 1,
      convRule: rule21
  }, {
      start: 42645,
      length: 1,
      convRule: rule22
  }, {
      start: 42646,
      length: 1,
      convRule: rule21
  }, {
      start: 42647,
      length: 1,
      convRule: rule22
  }, {
      start: 42656,
      length: 70,
      convRule: rule45
  }, {
      start: 42726,
      length: 10,
      convRule: rule116
  }, {
      start: 42736,
      length: 2,
      convRule: rule84
  }, {
      start: 42738,
      length: 6,
      convRule: rule2
  }, {
      start: 42752,
      length: 23,
      convRule: rule10
  }, {
      start: 42775,
      length: 9,
      convRule: rule83
  }, {
      start: 42784,
      length: 2,
      convRule: rule10
  }, {
      start: 42786,
      length: 1,
      convRule: rule21
  }, {
      start: 42787,
      length: 1,
      convRule: rule22
  }, {
      start: 42788,
      length: 1,
      convRule: rule21
  }, {
      start: 42789,
      length: 1,
      convRule: rule22
  }, {
      start: 42790,
      length: 1,
      convRule: rule21
  }, {
      start: 42791,
      length: 1,
      convRule: rule22
  }, {
      start: 42792,
      length: 1,
      convRule: rule21
  }, {
      start: 42793,
      length: 1,
      convRule: rule22
  }, {
      start: 42794,
      length: 1,
      convRule: rule21
  }, {
      start: 42795,
      length: 1,
      convRule: rule22
  }, {
      start: 42796,
      length: 1,
      convRule: rule21
  }, {
      start: 42797,
      length: 1,
      convRule: rule22
  }, {
      start: 42798,
      length: 1,
      convRule: rule21
  }, {
      start: 42799,
      length: 1,
      convRule: rule22
  }, {
      start: 42800,
      length: 2,
      convRule: rule14
  }, {
      start: 42802,
      length: 1,
      convRule: rule21
  }, {
      start: 42803,
      length: 1,
      convRule: rule22
  }, {
      start: 42804,
      length: 1,
      convRule: rule21
  }, {
      start: 42805,
      length: 1,
      convRule: rule22
  }, {
      start: 42806,
      length: 1,
      convRule: rule21
  }, {
      start: 42807,
      length: 1,
      convRule: rule22
  }, {
      start: 42808,
      length: 1,
      convRule: rule21
  }, {
      start: 42809,
      length: 1,
      convRule: rule22
  }, {
      start: 42810,
      length: 1,
      convRule: rule21
  }, {
      start: 42811,
      length: 1,
      convRule: rule22
  }, {
      start: 42812,
      length: 1,
      convRule: rule21
  }, {
      start: 42813,
      length: 1,
      convRule: rule22
  }, {
      start: 42814,
      length: 1,
      convRule: rule21
  }, {
      start: 42815,
      length: 1,
      convRule: rule22
  }, {
      start: 42816,
      length: 1,
      convRule: rule21
  }, {
      start: 42817,
      length: 1,
      convRule: rule22
  }, {
      start: 42818,
      length: 1,
      convRule: rule21
  }, {
      start: 42819,
      length: 1,
      convRule: rule22
  }, {
      start: 42820,
      length: 1,
      convRule: rule21
  }, {
      start: 42821,
      length: 1,
      convRule: rule22
  }, {
      start: 42822,
      length: 1,
      convRule: rule21
  }, {
      start: 42823,
      length: 1,
      convRule: rule22
  }, {
      start: 42824,
      length: 1,
      convRule: rule21
  }, {
      start: 42825,
      length: 1,
      convRule: rule22
  }, {
      start: 42826,
      length: 1,
      convRule: rule21
  }, {
      start: 42827,
      length: 1,
      convRule: rule22
  }, {
      start: 42828,
      length: 1,
      convRule: rule21
  }, {
      start: 42829,
      length: 1,
      convRule: rule22
  }, {
      start: 42830,
      length: 1,
      convRule: rule21
  }, {
      start: 42831,
      length: 1,
      convRule: rule22
  }, {
      start: 42832,
      length: 1,
      convRule: rule21
  }, {
      start: 42833,
      length: 1,
      convRule: rule22
  }, {
      start: 42834,
      length: 1,
      convRule: rule21
  }, {
      start: 42835,
      length: 1,
      convRule: rule22
  }, {
      start: 42836,
      length: 1,
      convRule: rule21
  }, {
      start: 42837,
      length: 1,
      convRule: rule22
  }, {
      start: 42838,
      length: 1,
      convRule: rule21
  }, {
      start: 42839,
      length: 1,
      convRule: rule22
  }, {
      start: 42840,
      length: 1,
      convRule: rule21
  }, {
      start: 42841,
      length: 1,
      convRule: rule22
  }, {
      start: 42842,
      length: 1,
      convRule: rule21
  }, {
      start: 42843,
      length: 1,
      convRule: rule22
  }, {
      start: 42844,
      length: 1,
      convRule: rule21
  }, {
      start: 42845,
      length: 1,
      convRule: rule22
  }, {
      start: 42846,
      length: 1,
      convRule: rule21
  }, {
      start: 42847,
      length: 1,
      convRule: rule22
  }, {
      start: 42848,
      length: 1,
      convRule: rule21
  }, {
      start: 42849,
      length: 1,
      convRule: rule22
  }, {
      start: 42850,
      length: 1,
      convRule: rule21
  }, {
      start: 42851,
      length: 1,
      convRule: rule22
  }, {
      start: 42852,
      length: 1,
      convRule: rule21
  }, {
      start: 42853,
      length: 1,
      convRule: rule22
  }, {
      start: 42854,
      length: 1,
      convRule: rule21
  }, {
      start: 42855,
      length: 1,
      convRule: rule22
  }, {
      start: 42856,
      length: 1,
      convRule: rule21
  }, {
      start: 42857,
      length: 1,
      convRule: rule22
  }, {
      start: 42858,
      length: 1,
      convRule: rule21
  }, {
      start: 42859,
      length: 1,
      convRule: rule22
  }, {
      start: 42860,
      length: 1,
      convRule: rule21
  }, {
      start: 42861,
      length: 1,
      convRule: rule22
  }, {
      start: 42862,
      length: 1,
      convRule: rule21
  }, {
      start: 42863,
      length: 1,
      convRule: rule22
  }, {
      start: 42864,
      length: 1,
      convRule: rule83
  }, {
      start: 42865,
      length: 8,
      convRule: rule14
  }, {
      start: 42873,
      length: 1,
      convRule: rule21
  }, {
      start: 42874,
      length: 1,
      convRule: rule22
  }, {
      start: 42875,
      length: 1,
      convRule: rule21
  }, {
      start: 42876,
      length: 1,
      convRule: rule22
  }, {
      start: 42877,
      length: 1,
      convRule: rule161
  }, {
      start: 42878,
      length: 1,
      convRule: rule21
  }, {
      start: 42879,
      length: 1,
      convRule: rule22
  }, {
      start: 42880,
      length: 1,
      convRule: rule21
  }, {
      start: 42881,
      length: 1,
      convRule: rule22
  }, {
      start: 42882,
      length: 1,
      convRule: rule21
  }, {
      start: 42883,
      length: 1,
      convRule: rule22
  }, {
      start: 42884,
      length: 1,
      convRule: rule21
  }, {
      start: 42885,
      length: 1,
      convRule: rule22
  }, {
      start: 42886,
      length: 1,
      convRule: rule21
  }, {
      start: 42887,
      length: 1,
      convRule: rule22
  }, {
      start: 42888,
      length: 1,
      convRule: rule83
  }, {
      start: 42889,
      length: 2,
      convRule: rule10
  }, {
      start: 42891,
      length: 1,
      convRule: rule21
  }, {
      start: 42892,
      length: 1,
      convRule: rule22
  }, {
      start: 42893,
      length: 1,
      convRule: rule162
  }, {
      start: 42894,
      length: 1,
      convRule: rule14
  }, {
      start: 42896,
      length: 1,
      convRule: rule21
  }, {
      start: 42897,
      length: 1,
      convRule: rule22
  }, {
      start: 42912,
      length: 1,
      convRule: rule21
  }, {
      start: 42913,
      length: 1,
      convRule: rule22
  }, {
      start: 42914,
      length: 1,
      convRule: rule21
  }, {
      start: 42915,
      length: 1,
      convRule: rule22
  }, {
      start: 42916,
      length: 1,
      convRule: rule21
  }, {
      start: 42917,
      length: 1,
      convRule: rule22
  }, {
      start: 42918,
      length: 1,
      convRule: rule21
  }, {
      start: 42919,
      length: 1,
      convRule: rule22
  }, {
      start: 42920,
      length: 1,
      convRule: rule21
  }, {
      start: 42921,
      length: 1,
      convRule: rule22
  }, {
      start: 43002,
      length: 1,
      convRule: rule14
  }, {
      start: 43003,
      length: 7,
      convRule: rule45
  }, {
      start: 43010,
      length: 1,
      convRule: rule84
  }, {
      start: 43011,
      length: 3,
      convRule: rule45
  }, {
      start: 43014,
      length: 1,
      convRule: rule84
  }, {
      start: 43015,
      length: 4,
      convRule: rule45
  }, {
      start: 43019,
      length: 1,
      convRule: rule84
  }, {
      start: 43020,
      length: 23,
      convRule: rule45
  }, {
      start: 43043,
      length: 2,
      convRule: rule114
  }, {
      start: 43045,
      length: 2,
      convRule: rule84
  }, {
      start: 43047,
      length: 1,
      convRule: rule114
  }, {
      start: 43048,
      length: 4,
      convRule: rule13
  }, {
      start: 43056,
      length: 6,
      convRule: rule17
  }, {
      start: 43062,
      length: 2,
      convRule: rule13
  }, {
      start: 43064,
      length: 1,
      convRule: rule3
  }, {
      start: 43065,
      length: 1,
      convRule: rule13
  }, {
      start: 43072,
      length: 52,
      convRule: rule45
  }, {
      start: 43124,
      length: 4,
      convRule: rule2
  }, {
      start: 43136,
      length: 2,
      convRule: rule114
  }, {
      start: 43138,
      length: 50,
      convRule: rule45
  }, {
      start: 43188,
      length: 16,
      convRule: rule114
  }, {
      start: 43204,
      length: 1,
      convRule: rule84
  }, {
      start: 43214,
      length: 2,
      convRule: rule2
  }, {
      start: 43216,
      length: 10,
      convRule: rule8
  }, {
      start: 43232,
      length: 18,
      convRule: rule84
  }, {
      start: 43250,
      length: 6,
      convRule: rule45
  }, {
      start: 43256,
      length: 3,
      convRule: rule2
  }, {
      start: 43259,
      length: 1,
      convRule: rule45
  }, {
      start: 43264,
      length: 10,
      convRule: rule8
  }, {
      start: 43274,
      length: 28,
      convRule: rule45
  }, {
      start: 43302,
      length: 8,
      convRule: rule84
  }, {
      start: 43310,
      length: 2,
      convRule: rule2
  }, {
      start: 43312,
      length: 23,
      convRule: rule45
  }, {
      start: 43335,
      length: 11,
      convRule: rule84
  }, {
      start: 43346,
      length: 2,
      convRule: rule114
  }, {
      start: 43359,
      length: 1,
      convRule: rule2
  }, {
      start: 43360,
      length: 29,
      convRule: rule45
  }, {
      start: 43392,
      length: 3,
      convRule: rule84
  }, {
      start: 43395,
      length: 1,
      convRule: rule114
  }, {
      start: 43396,
      length: 47,
      convRule: rule45
  }, {
      start: 43443,
      length: 1,
      convRule: rule84
  }, {
      start: 43444,
      length: 2,
      convRule: rule114
  }, {
      start: 43446,
      length: 4,
      convRule: rule84
  }, {
      start: 43450,
      length: 2,
      convRule: rule114
  }, {
      start: 43452,
      length: 1,
      convRule: rule84
  }, {
      start: 43453,
      length: 4,
      convRule: rule114
  }, {
      start: 43457,
      length: 13,
      convRule: rule2
  }, {
      start: 43471,
      length: 1,
      convRule: rule83
  }, {
      start: 43472,
      length: 10,
      convRule: rule8
  }, {
      start: 43486,
      length: 2,
      convRule: rule2
  }, {
      start: 43520,
      length: 41,
      convRule: rule45
  }, {
      start: 43561,
      length: 6,
      convRule: rule84
  }, {
      start: 43567,
      length: 2,
      convRule: rule114
  }, {
      start: 43569,
      length: 2,
      convRule: rule84
  }, {
      start: 43571,
      length: 2,
      convRule: rule114
  }, {
      start: 43573,
      length: 2,
      convRule: rule84
  }, {
      start: 43584,
      length: 3,
      convRule: rule45
  }, {
      start: 43587,
      length: 1,
      convRule: rule84
  }, {
      start: 43588,
      length: 8,
      convRule: rule45
  }, {
      start: 43596,
      length: 1,
      convRule: rule84
  }, {
      start: 43597,
      length: 1,
      convRule: rule114
  }, {
      start: 43600,
      length: 10,
      convRule: rule8
  }, {
      start: 43612,
      length: 4,
      convRule: rule2
  }, {
      start: 43616,
      length: 16,
      convRule: rule45
  }, {
      start: 43632,
      length: 1,
      convRule: rule83
  }, {
      start: 43633,
      length: 6,
      convRule: rule45
  }, {
      start: 43639,
      length: 3,
      convRule: rule13
  }, {
      start: 43642,
      length: 1,
      convRule: rule45
  }, {
      start: 43643,
      length: 1,
      convRule: rule114
  }, {
      start: 43648,
      length: 48,
      convRule: rule45
  }, {
      start: 43696,
      length: 1,
      convRule: rule84
  }, {
      start: 43697,
      length: 1,
      convRule: rule45
  }, {
      start: 43698,
      length: 3,
      convRule: rule84
  }, {
      start: 43701,
      length: 2,
      convRule: rule45
  }, {
      start: 43703,
      length: 2,
      convRule: rule84
  }, {
      start: 43705,
      length: 5,
      convRule: rule45
  }, {
      start: 43710,
      length: 2,
      convRule: rule84
  }, {
      start: 43712,
      length: 1,
      convRule: rule45
  }, {
      start: 43713,
      length: 1,
      convRule: rule84
  }, {
      start: 43714,
      length: 1,
      convRule: rule45
  }, {
      start: 43739,
      length: 2,
      convRule: rule45
  }, {
      start: 43741,
      length: 1,
      convRule: rule83
  }, {
      start: 43742,
      length: 2,
      convRule: rule2
  }, {
      start: 43777,
      length: 6,
      convRule: rule45
  }, {
      start: 43785,
      length: 6,
      convRule: rule45
  }, {
      start: 43793,
      length: 6,
      convRule: rule45
  }, {
      start: 43808,
      length: 7,
      convRule: rule45
  }, {
      start: 43816,
      length: 7,
      convRule: rule45
  }, {
      start: 43968,
      length: 35,
      convRule: rule45
  }, {
      start: 44003,
      length: 2,
      convRule: rule114
  }, {
      start: 44005,
      length: 1,
      convRule: rule84
  }, {
      start: 44006,
      length: 2,
      convRule: rule114
  }, {
      start: 44008,
      length: 1,
      convRule: rule84
  }, {
      start: 44009,
      length: 2,
      convRule: rule114
  }, {
      start: 44011,
      length: 1,
      convRule: rule2
  }, {
      start: 44012,
      length: 1,
      convRule: rule114
  }, {
      start: 44013,
      length: 1,
      convRule: rule84
  }, {
      start: 44016,
      length: 10,
      convRule: rule8
  }, {
      start: 44032,
      length: 11172,
      convRule: rule45
  }, {
      start: 55216,
      length: 23,
      convRule: rule45
  }, {
      start: 55243,
      length: 49,
      convRule: rule45
  }, {
      start: 55296,
      length: 896,
      convRule: rule163
  }, {
      start: 56192,
      length: 128,
      convRule: rule163
  }, {
      start: 56320,
      length: 1024,
      convRule: rule163
  }, {
      start: 57344,
      length: 6400,
      convRule: rule164
  }, {
      start: 63744,
      length: 302,
      convRule: rule45
  }, {
      start: 64048,
      length: 62,
      convRule: rule45
  }, {
      start: 64112,
      length: 106,
      convRule: rule45
  }, {
      start: 64256,
      length: 7,
      convRule: rule14
  }, {
      start: 64275,
      length: 5,
      convRule: rule14
  }, {
      start: 64285,
      length: 1,
      convRule: rule45
  }, {
      start: 64286,
      length: 1,
      convRule: rule84
  }, {
      start: 64287,
      length: 10,
      convRule: rule45
  }, {
      start: 64297,
      length: 1,
      convRule: rule6
  }, {
      start: 64298,
      length: 13,
      convRule: rule45
  }, {
      start: 64312,
      length: 5,
      convRule: rule45
  }, {
      start: 64318,
      length: 1,
      convRule: rule45
  }, {
      start: 64320,
      length: 2,
      convRule: rule45
  }, {
      start: 64323,
      length: 2,
      convRule: rule45
  }, {
      start: 64326,
      length: 108,
      convRule: rule45
  }, {
      start: 64434,
      length: 16,
      convRule: rule10
  }, {
      start: 64467,
      length: 363,
      convRule: rule45
  }, {
      start: 64830,
      length: 1,
      convRule: rule4
  }, {
      start: 64831,
      length: 1,
      convRule: rule5
  }, {
      start: 64848,
      length: 64,
      convRule: rule45
  }, {
      start: 64914,
      length: 54,
      convRule: rule45
  }, {
      start: 65008,
      length: 12,
      convRule: rule45
  }, {
      start: 65020,
      length: 1,
      convRule: rule3
  }, {
      start: 65021,
      length: 1,
      convRule: rule13
  }, {
      start: 65024,
      length: 16,
      convRule: rule84
  }, {
      start: 65040,
      length: 7,
      convRule: rule2
  }, {
      start: 65047,
      length: 1,
      convRule: rule4
  }, {
      start: 65048,
      length: 1,
      convRule: rule5
  }, {
      start: 65049,
      length: 1,
      convRule: rule2
  }, {
      start: 65056,
      length: 7,
      convRule: rule84
  }, {
      start: 65072,
      length: 1,
      convRule: rule2
  }, {
      start: 65073,
      length: 2,
      convRule: rule7
  }, {
      start: 65075,
      length: 2,
      convRule: rule11
  }, {
      start: 65077,
      length: 1,
      convRule: rule4
  }, {
      start: 65078,
      length: 1,
      convRule: rule5
  }, {
      start: 65079,
      length: 1,
      convRule: rule4
  }, {
      start: 65080,
      length: 1,
      convRule: rule5
  }, {
      start: 65081,
      length: 1,
      convRule: rule4
  }, {
      start: 65082,
      length: 1,
      convRule: rule5
  }, {
      start: 65083,
      length: 1,
      convRule: rule4
  }, {
      start: 65084,
      length: 1,
      convRule: rule5
  }, {
      start: 65085,
      length: 1,
      convRule: rule4
  }, {
      start: 65086,
      length: 1,
      convRule: rule5
  }, {
      start: 65087,
      length: 1,
      convRule: rule4
  }, {
      start: 65088,
      length: 1,
      convRule: rule5
  }, {
      start: 65089,
      length: 1,
      convRule: rule4
  }, {
      start: 65090,
      length: 1,
      convRule: rule5
  }, {
      start: 65091,
      length: 1,
      convRule: rule4
  }, {
      start: 65092,
      length: 1,
      convRule: rule5
  }, {
      start: 65093,
      length: 2,
      convRule: rule2
  }, {
      start: 65095,
      length: 1,
      convRule: rule4
  }, {
      start: 65096,
      length: 1,
      convRule: rule5
  }, {
      start: 65097,
      length: 4,
      convRule: rule2
  }, {
      start: 65101,
      length: 3,
      convRule: rule11
  }, {
      start: 65104,
      length: 3,
      convRule: rule2
  }, {
      start: 65108,
      length: 4,
      convRule: rule2
  }, {
      start: 65112,
      length: 1,
      convRule: rule7
  }, {
      start: 65113,
      length: 1,
      convRule: rule4
  }, {
      start: 65114,
      length: 1,
      convRule: rule5
  }, {
      start: 65115,
      length: 1,
      convRule: rule4
  }, {
      start: 65116,
      length: 1,
      convRule: rule5
  }, {
      start: 65117,
      length: 1,
      convRule: rule4
  }, {
      start: 65118,
      length: 1,
      convRule: rule5
  }, {
      start: 65119,
      length: 3,
      convRule: rule2
  }, {
      start: 65122,
      length: 1,
      convRule: rule6
  }, {
      start: 65123,
      length: 1,
      convRule: rule7
  }, {
      start: 65124,
      length: 3,
      convRule: rule6
  }, {
      start: 65128,
      length: 1,
      convRule: rule2
  }, {
      start: 65129,
      length: 1,
      convRule: rule3
  }, {
      start: 65130,
      length: 2,
      convRule: rule2
  }, {
      start: 65136,
      length: 5,
      convRule: rule45
  }, {
      start: 65142,
      length: 135,
      convRule: rule45
  }, {
      start: 65279,
      length: 1,
      convRule: rule16
  }, {
      start: 65281,
      length: 3,
      convRule: rule2
  }, {
      start: 65284,
      length: 1,
      convRule: rule3
  }, {
      start: 65285,
      length: 3,
      convRule: rule2
  }, {
      start: 65288,
      length: 1,
      convRule: rule4
  }, {
      start: 65289,
      length: 1,
      convRule: rule5
  }, {
      start: 65290,
      length: 1,
      convRule: rule2
  }, {
      start: 65291,
      length: 1,
      convRule: rule6
  }, {
      start: 65292,
      length: 1,
      convRule: rule2
  }, {
      start: 65293,
      length: 1,
      convRule: rule7
  }, {
      start: 65294,
      length: 2,
      convRule: rule2
  }, {
      start: 65296,
      length: 10,
      convRule: rule8
  }, {
      start: 65306,
      length: 2,
      convRule: rule2
  }, {
      start: 65308,
      length: 3,
      convRule: rule6
  }, {
      start: 65311,
      length: 2,
      convRule: rule2
  }, {
      start: 65313,
      length: 26,
      convRule: rule9
  }, {
      start: 65339,
      length: 1,
      convRule: rule4
  }, {
      start: 65340,
      length: 1,
      convRule: rule2
  }, {
      start: 65341,
      length: 1,
      convRule: rule5
  }, {
      start: 65342,
      length: 1,
      convRule: rule10
  }, {
      start: 65343,
      length: 1,
      convRule: rule11
  }, {
      start: 65344,
      length: 1,
      convRule: rule10
  }, {
      start: 65345,
      length: 26,
      convRule: rule12
  }, {
      start: 65371,
      length: 1,
      convRule: rule4
  }, {
      start: 65372,
      length: 1,
      convRule: rule6
  }, {
      start: 65373,
      length: 1,
      convRule: rule5
  }, {
      start: 65374,
      length: 1,
      convRule: rule6
  }, {
      start: 65375,
      length: 1,
      convRule: rule4
  }, {
      start: 65376,
      length: 1,
      convRule: rule5
  }, {
      start: 65377,
      length: 1,
      convRule: rule2
  }, {
      start: 65378,
      length: 1,
      convRule: rule4
  }, {
      start: 65379,
      length: 1,
      convRule: rule5
  }, {
      start: 65380,
      length: 2,
      convRule: rule2
  }, {
      start: 65382,
      length: 10,
      convRule: rule45
  }, {
      start: 65392,
      length: 1,
      convRule: rule83
  }, {
      start: 65393,
      length: 45,
      convRule: rule45
  }, {
      start: 65438,
      length: 2,
      convRule: rule83
  }, {
      start: 65440,
      length: 31,
      convRule: rule45
  }, {
      start: 65474,
      length: 6,
      convRule: rule45
  }, {
      start: 65482,
      length: 6,
      convRule: rule45
  }, {
      start: 65490,
      length: 6,
      convRule: rule45
  }, {
      start: 65498,
      length: 3,
      convRule: rule45
  }, {
      start: 65504,
      length: 2,
      convRule: rule3
  }, {
      start: 65506,
      length: 1,
      convRule: rule6
  }, {
      start: 65507,
      length: 1,
      convRule: rule10
  }, {
      start: 65508,
      length: 1,
      convRule: rule13
  }, {
      start: 65509,
      length: 2,
      convRule: rule3
  }, {
      start: 65512,
      length: 1,
      convRule: rule13
  }, {
      start: 65513,
      length: 4,
      convRule: rule6
  }, {
      start: 65517,
      length: 2,
      convRule: rule13
  }, {
      start: 65529,
      length: 3,
      convRule: rule16
  }, {
      start: 65532,
      length: 2,
      convRule: rule13
  }, {
      start: 65536,
      length: 12,
      convRule: rule45
  }, {
      start: 65549,
      length: 26,
      convRule: rule45
  }, {
      start: 65576,
      length: 19,
      convRule: rule45
  }, {
      start: 65596,
      length: 2,
      convRule: rule45
  }, {
      start: 65599,
      length: 15,
      convRule: rule45
  }, {
      start: 65616,
      length: 14,
      convRule: rule45
  }, {
      start: 65664,
      length: 123,
      convRule: rule45
  }, {
      start: 65792,
      length: 2,
      convRule: rule2
  }, {
      start: 65794,
      length: 1,
      convRule: rule13
  }, {
      start: 65799,
      length: 45,
      convRule: rule17
  }, {
      start: 65847,
      length: 9,
      convRule: rule13
  }, {
      start: 65856,
      length: 53,
      convRule: rule116
  }, {
      start: 65909,
      length: 4,
      convRule: rule17
  }, {
      start: 65913,
      length: 17,
      convRule: rule13
  }, {
      start: 65930,
      length: 1,
      convRule: rule17
  }, {
      start: 65936,
      length: 12,
      convRule: rule13
  }, {
      start: 66000,
      length: 45,
      convRule: rule13
  }, {
      start: 66045,
      length: 1,
      convRule: rule84
  }, {
      start: 66176,
      length: 29,
      convRule: rule45
  }, {
      start: 66208,
      length: 49,
      convRule: rule45
  }, {
      start: 66304,
      length: 31,
      convRule: rule45
  }, {
      start: 66336,
      length: 4,
      convRule: rule17
  }, {
      start: 66352,
      length: 17,
      convRule: rule45
  }, {
      start: 66369,
      length: 1,
      convRule: rule116
  }, {
      start: 66370,
      length: 8,
      convRule: rule45
  }, {
      start: 66378,
      length: 1,
      convRule: rule116
  }, {
      start: 66432,
      length: 30,
      convRule: rule45
  }, {
      start: 66463,
      length: 1,
      convRule: rule2
  }, {
      start: 66464,
      length: 36,
      convRule: rule45
  }, {
      start: 66504,
      length: 8,
      convRule: rule45
  }, {
      start: 66512,
      length: 1,
      convRule: rule2
  }, {
      start: 66513,
      length: 5,
      convRule: rule116
  }, {
      start: 66560,
      length: 40,
      convRule: rule165
  }, {
      start: 66600,
      length: 40,
      convRule: rule166
  }, {
      start: 66640,
      length: 78,
      convRule: rule45
  }, {
      start: 66720,
      length: 10,
      convRule: rule8
  }, {
      start: 67584,
      length: 6,
      convRule: rule45
  }, {
      start: 67592,
      length: 1,
      convRule: rule45
  }, {
      start: 67594,
      length: 44,
      convRule: rule45
  }, {
      start: 67639,
      length: 2,
      convRule: rule45
  }, {
      start: 67644,
      length: 1,
      convRule: rule45
  }, {
      start: 67647,
      length: 23,
      convRule: rule45
  }, {
      start: 67671,
      length: 1,
      convRule: rule2
  }, {
      start: 67672,
      length: 8,
      convRule: rule17
  }, {
      start: 67840,
      length: 22,
      convRule: rule45
  }, {
      start: 67862,
      length: 6,
      convRule: rule17
  }, {
      start: 67871,
      length: 1,
      convRule: rule2
  }, {
      start: 67872,
      length: 26,
      convRule: rule45
  }, {
      start: 67903,
      length: 1,
      convRule: rule2
  }, {
      start: 68096,
      length: 1,
      convRule: rule45
  }, {
      start: 68097,
      length: 3,
      convRule: rule84
  }, {
      start: 68101,
      length: 2,
      convRule: rule84
  }, {
      start: 68108,
      length: 4,
      convRule: rule84
  }, {
      start: 68112,
      length: 4,
      convRule: rule45
  }, {
      start: 68117,
      length: 3,
      convRule: rule45
  }, {
      start: 68121,
      length: 27,
      convRule: rule45
  }, {
      start: 68152,
      length: 3,
      convRule: rule84
  }, {
      start: 68159,
      length: 1,
      convRule: rule84
  }, {
      start: 68160,
      length: 8,
      convRule: rule17
  }, {
      start: 68176,
      length: 9,
      convRule: rule2
  }, {
      start: 68192,
      length: 29,
      convRule: rule45
  }, {
      start: 68221,
      length: 2,
      convRule: rule17
  }, {
      start: 68223,
      length: 1,
      convRule: rule2
  }, {
      start: 68352,
      length: 54,
      convRule: rule45
  }, {
      start: 68409,
      length: 7,
      convRule: rule2
  }, {
      start: 68416,
      length: 22,
      convRule: rule45
  }, {
      start: 68440,
      length: 8,
      convRule: rule17
  }, {
      start: 68448,
      length: 19,
      convRule: rule45
  }, {
      start: 68472,
      length: 8,
      convRule: rule17
  }, {
      start: 68608,
      length: 73,
      convRule: rule45
  }, {
      start: 69216,
      length: 31,
      convRule: rule17
  }, {
      start: 69632,
      length: 1,
      convRule: rule114
  }, {
      start: 69633,
      length: 1,
      convRule: rule84
  }, {
      start: 69634,
      length: 1,
      convRule: rule114
  }, {
      start: 69635,
      length: 53,
      convRule: rule45
  }, {
      start: 69688,
      length: 15,
      convRule: rule84
  }, {
      start: 69703,
      length: 7,
      convRule: rule2
  }, {
      start: 69714,
      length: 20,
      convRule: rule17
  }, {
      start: 69734,
      length: 10,
      convRule: rule8
  }, {
      start: 69760,
      length: 2,
      convRule: rule84
  }, {
      start: 69762,
      length: 1,
      convRule: rule114
  }, {
      start: 69763,
      length: 45,
      convRule: rule45
  }, {
      start: 69808,
      length: 3,
      convRule: rule114
  }, {
      start: 69811,
      length: 4,
      convRule: rule84
  }, {
      start: 69815,
      length: 2,
      convRule: rule114
  }, {
      start: 69817,
      length: 2,
      convRule: rule84
  }, {
      start: 69819,
      length: 2,
      convRule: rule2
  }, {
      start: 69821,
      length: 1,
      convRule: rule16
  }, {
      start: 69822,
      length: 4,
      convRule: rule2
  }, {
      start: 73728,
      length: 879,
      convRule: rule45
  }, {
      start: 74752,
      length: 99,
      convRule: rule116
  }, {
      start: 74864,
      length: 4,
      convRule: rule2
  }, {
      start: 77824,
      length: 1071,
      convRule: rule45
  }, {
      start: 92160,
      length: 569,
      convRule: rule45
  }, {
      start: 110592,
      length: 2,
      convRule: rule45
  }, {
      start: 118784,
      length: 246,
      convRule: rule13
  }, {
      start: 119040,
      length: 39,
      convRule: rule13
  }, {
      start: 119081,
      length: 60,
      convRule: rule13
  }, {
      start: 119141,
      length: 2,
      convRule: rule114
  }, {
      start: 119143,
      length: 3,
      convRule: rule84
  }, {
      start: 119146,
      length: 3,
      convRule: rule13
  }, {
      start: 119149,
      length: 6,
      convRule: rule114
  }, {
      start: 119155,
      length: 8,
      convRule: rule16
  }, {
      start: 119163,
      length: 8,
      convRule: rule84
  }, {
      start: 119171,
      length: 2,
      convRule: rule13
  }, {
      start: 119173,
      length: 7,
      convRule: rule84
  }, {
      start: 119180,
      length: 30,
      convRule: rule13
  }, {
      start: 119210,
      length: 4,
      convRule: rule84
  }, {
      start: 119214,
      length: 48,
      convRule: rule13
  }, {
      start: 119296,
      length: 66,
      convRule: rule13
  }, {
      start: 119362,
      length: 3,
      convRule: rule84
  }, {
      start: 119365,
      length: 1,
      convRule: rule13
  }, {
      start: 119552,
      length: 87,
      convRule: rule13
  }, {
      start: 119648,
      length: 18,
      convRule: rule17
  }, {
      start: 119808,
      length: 26,
      convRule: rule98
  }, {
      start: 119834,
      length: 26,
      convRule: rule14
  }, {
      start: 119860,
      length: 26,
      convRule: rule98
  }, {
      start: 119886,
      length: 7,
      convRule: rule14
  }, {
      start: 119894,
      length: 18,
      convRule: rule14
  }, {
      start: 119912,
      length: 26,
      convRule: rule98
  }, {
      start: 119938,
      length: 26,
      convRule: rule14
  }, {
      start: 119964,
      length: 1,
      convRule: rule98
  }, {
      start: 119966,
      length: 2,
      convRule: rule98
  }, {
      start: 119970,
      length: 1,
      convRule: rule98
  }, {
      start: 119973,
      length: 2,
      convRule: rule98
  }, {
      start: 119977,
      length: 4,
      convRule: rule98
  }, {
      start: 119982,
      length: 8,
      convRule: rule98
  }, {
      start: 119990,
      length: 4,
      convRule: rule14
  }, {
      start: 119995,
      length: 1,
      convRule: rule14
  }, {
      start: 119997,
      length: 7,
      convRule: rule14
  }, {
      start: 120005,
      length: 11,
      convRule: rule14
  }, {
      start: 120016,
      length: 26,
      convRule: rule98
  }, {
      start: 120042,
      length: 26,
      convRule: rule14
  }, {
      start: 120068,
      length: 2,
      convRule: rule98
  }, {
      start: 120071,
      length: 4,
      convRule: rule98
  }, {
      start: 120077,
      length: 8,
      convRule: rule98
  }, {
      start: 120086,
      length: 7,
      convRule: rule98
  }, {
      start: 120094,
      length: 26,
      convRule: rule14
  }, {
      start: 120120,
      length: 2,
      convRule: rule98
  }, {
      start: 120123,
      length: 4,
      convRule: rule98
  }, {
      start: 120128,
      length: 5,
      convRule: rule98
  }, {
      start: 120134,
      length: 1,
      convRule: rule98
  }, {
      start: 120138,
      length: 7,
      convRule: rule98
  }, {
      start: 120146,
      length: 26,
      convRule: rule14
  }, {
      start: 120172,
      length: 26,
      convRule: rule98
  }, {
      start: 120198,
      length: 26,
      convRule: rule14
  }, {
      start: 120224,
      length: 26,
      convRule: rule98
  }, {
      start: 120250,
      length: 26,
      convRule: rule14
  }, {
      start: 120276,
      length: 26,
      convRule: rule98
  }, {
      start: 120302,
      length: 26,
      convRule: rule14
  }, {
      start: 120328,
      length: 26,
      convRule: rule98
  }, {
      start: 120354,
      length: 26,
      convRule: rule14
  }, {
      start: 120380,
      length: 26,
      convRule: rule98
  }, {
      start: 120406,
      length: 26,
      convRule: rule14
  }, {
      start: 120432,
      length: 26,
      convRule: rule98
  }, {
      start: 120458,
      length: 28,
      convRule: rule14
  }, {
      start: 120488,
      length: 25,
      convRule: rule98
  }, {
      start: 120513,
      length: 1,
      convRule: rule6
  }, {
      start: 120514,
      length: 25,
      convRule: rule14
  }, {
      start: 120539,
      length: 1,
      convRule: rule6
  }, {
      start: 120540,
      length: 6,
      convRule: rule14
  }, {
      start: 120546,
      length: 25,
      convRule: rule98
  }, {
      start: 120571,
      length: 1,
      convRule: rule6
  }, {
      start: 120572,
      length: 25,
      convRule: rule14
  }, {
      start: 120597,
      length: 1,
      convRule: rule6
  }, {
      start: 120598,
      length: 6,
      convRule: rule14
  }, {
      start: 120604,
      length: 25,
      convRule: rule98
  }, {
      start: 120629,
      length: 1,
      convRule: rule6
  }, {
      start: 120630,
      length: 25,
      convRule: rule14
  }, {
      start: 120655,
      length: 1,
      convRule: rule6
  }, {
      start: 120656,
      length: 6,
      convRule: rule14
  }, {
      start: 120662,
      length: 25,
      convRule: rule98
  }, {
      start: 120687,
      length: 1,
      convRule: rule6
  }, {
      start: 120688,
      length: 25,
      convRule: rule14
  }, {
      start: 120713,
      length: 1,
      convRule: rule6
  }, {
      start: 120714,
      length: 6,
      convRule: rule14
  }, {
      start: 120720,
      length: 25,
      convRule: rule98
  }, {
      start: 120745,
      length: 1,
      convRule: rule6
  }, {
      start: 120746,
      length: 25,
      convRule: rule14
  }, {
      start: 120771,
      length: 1,
      convRule: rule6
  }, {
      start: 120772,
      length: 6,
      convRule: rule14
  }, {
      start: 120778,
      length: 1,
      convRule: rule98
  }, {
      start: 120779,
      length: 1,
      convRule: rule14
  }, {
      start: 120782,
      length: 50,
      convRule: rule8
  }, {
      start: 126976,
      length: 44,
      convRule: rule13
  }, {
      start: 127024,
      length: 100,
      convRule: rule13
  }, {
      start: 127136,
      length: 15,
      convRule: rule13
  }, {
      start: 127153,
      length: 14,
      convRule: rule13
  }, {
      start: 127169,
      length: 15,
      convRule: rule13
  }, {
      start: 127185,
      length: 15,
      convRule: rule13
  }, {
      start: 127232,
      length: 11,
      convRule: rule17
  }, {
      start: 127248,
      length: 31,
      convRule: rule13
  }, {
      start: 127280,
      length: 58,
      convRule: rule13
  }, {
      start: 127344,
      length: 43,
      convRule: rule13
  }, {
      start: 127462,
      length: 29,
      convRule: rule13
  }, {
      start: 127504,
      length: 43,
      convRule: rule13
  }, {
      start: 127552,
      length: 9,
      convRule: rule13
  }, {
      start: 127568,
      length: 2,
      convRule: rule13
  }, {
      start: 127744,
      length: 33,
      convRule: rule13
  }, {
      start: 127792,
      length: 6,
      convRule: rule13
  }, {
      start: 127799,
      length: 70,
      convRule: rule13
  }, {
      start: 127872,
      length: 20,
      convRule: rule13
  }, {
      start: 127904,
      length: 37,
      convRule: rule13
  }, {
      start: 127942,
      length: 5,
      convRule: rule13
  }, {
      start: 127968,
      length: 17,
      convRule: rule13
  }, {
      start: 128000,
      length: 63,
      convRule: rule13
  }, {
      start: 128064,
      length: 1,
      convRule: rule13
  }, {
      start: 128066,
      length: 182,
      convRule: rule13
  }, {
      start: 128249,
      length: 4,
      convRule: rule13
  }, {
      start: 128256,
      length: 62,
      convRule: rule13
  }, {
      start: 128336,
      length: 24,
      convRule: rule13
  }, {
      start: 128507,
      length: 5,
      convRule: rule13
  }, {
      start: 128513,
      length: 16,
      convRule: rule13
  }, {
      start: 128530,
      length: 3,
      convRule: rule13
  }, {
      start: 128534,
      length: 1,
      convRule: rule13
  }, {
      start: 128536,
      length: 1,
      convRule: rule13
  }, {
      start: 128538,
      length: 1,
      convRule: rule13
  }, {
      start: 128540,
      length: 3,
      convRule: rule13
  }, {
      start: 128544,
      length: 6,
      convRule: rule13
  }, {
      start: 128552,
      length: 4,
      convRule: rule13
  }, {
      start: 128557,
      length: 1,
      convRule: rule13
  }, {
      start: 128560,
      length: 4,
      convRule: rule13
  }, {
      start: 128565,
      length: 12,
      convRule: rule13
  }, {
      start: 128581,
      length: 11,
      convRule: rule13
  }, {
      start: 128640,
      length: 70,
      convRule: rule13
  }, {
      start: 128768,
      length: 116,
      convRule: rule13
  }, {
      start: 131072,
      length: 42711,
      convRule: rule45
  }, {
      start: 173824,
      length: 4149,
      convRule: rule45
  }, {
      start: 177984,
      length: 222,
      convRule: rule45
  }, {
      start: 194560,
      length: 542,
      convRule: rule45
  }, {
      start: 917505,
      length: 1,
      convRule: rule16
  }, {
      start: 917536,
      length: 96,
      convRule: rule16
  }, {
      start: 917760,
      length: 240,
      convRule: rule84
  }, {
      start: 983040,
      length: 65534,
      convRule: rule164
  }, {
      start: 1048576,
      length: 65534,
      convRule: rule164
  } ];
  var checkAttr = function (categories) {
      return function ($$char) {
          var numOfBlocks = (function () {
              var $28 = $$char < 256;
              if ($28) {
                  return numLat1Blocks;
              };
              return numBlocks;
          })();
          var maybeConversionRule = getRule(allchars)($$char)(numOfBlocks);
          if (maybeConversionRule instanceof Data_Maybe.Nothing) {
              return false;
          };
          if (maybeConversionRule instanceof Data_Maybe.Just) {
              return Data_Maybe.isJust(Data_Array.elemIndex(Data_Eq.eqInt)(maybeConversionRule.value0.category)(categories));
          };
          throw new Error("Failed pattern match at Data.Char.Unicode.Internal (line 4800, column 8 - line 4802, column 92): " + [ maybeConversionRule.constructor.name ]);
      };
  };                                                                                                                                          
  var uIswalpha = checkAttr([ gencatLL, gencatLU, gencatLT, gencatLM, gencatLO ]);                                                                                                                                                                                    
  var uIswupper = checkAttr([ gencatLU, gencatLT ]);
  exports["uIswupper"] = uIswupper;
  exports["uIswalpha"] = uIswalpha;
  exports["uIswspace"] = uIswspace;
  exports["uTowupper"] = uTowupper;
  exports["uTowlower"] = uTowlower;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.Char.Unicode"] = $PS["Data.Char.Unicode"] || {};
  var exports = $PS["Data.Char.Unicode"];
  var $foreign = $PS["Data.Char.Unicode"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Char = $PS["Data.Char"];
  var Data_Char_Unicode_Internal = $PS["Data.Char.Unicode.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var toUpper = $foreign.withCharCode(Data_Char_Unicode_Internal.uTowupper);
  var toLower = $foreign.withCharCode(Data_Char_Unicode_Internal.uTowlower);
  var isUpper = function ($50) {
      return Data_Char_Unicode_Internal.uIswupper(Data_Char.toCharCode($50));
  };
  var isSpace = function (c) {
      var uc = Data_Char.toCharCode(c);
      var $14 = uc <= 823;
      if ($14) {
          return uc === 32 || (uc >= 9 && uc <= 13 || uc === 160);
      };
      return Data_Char_Unicode_Internal.uIswspace(Data_Char.toCharCode(c));
  };
  var isOctDigit = function (c) {
      var diff = Data_Char.toCharCode(c) - Data_Char.toCharCode("0") | 0;
      return diff <= 7 && diff >= 0;
  };
  var isDigit = function (c) {
      var diff = Data_Char.toCharCode(c) - Data_Char.toCharCode("0") | 0;
      return diff <= 9 && diff >= 0;
  };
  var isHexDigit = function (c) {
      return isDigit(c) || ((function () {
          var diff = Data_Char.toCharCode(c) - Data_Char.toCharCode("A") | 0;
          return diff <= 5 && diff >= 0;
      })() || (function () {
          var diff = Data_Char.toCharCode(c) - Data_Char.toCharCode("a") | 0;
          return diff <= 5 && diff >= 0;
      })());
  };
  var isAlpha = function ($55) {
      return Data_Char_Unicode_Internal.uIswalpha(Data_Char.toCharCode($55));
  }; 
  var digitToInt = function (c) {
      var hexUpper = Data_Char.toCharCode(c) - Data_Char.toCharCode("A") | 0;
      var hexLower = Data_Char.toCharCode(c) - Data_Char.toCharCode("a") | 0;
      var dec = Data_Char.toCharCode(c) - Data_Char.toCharCode("0") | 0;
      var result = (function () {
          if (dec <= 9 && dec >= 0) {
              return new Data_Maybe.Just(dec);
          };
          if (hexLower <= 5 && hexLower >= 0) {
              return Data_Maybe.Just.create(hexLower + 10 | 0);
          };
          if (hexUpper <= 5 && hexUpper >= 0) {
              return Data_Maybe.Just.create(hexUpper + 10 | 0);
          };
          if (Data_Boolean.otherwise) {
              return Data_Maybe.Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Char.Unicode (line 547, column 5 - line 547, column 24): " + [  ]);
      })();
      return result;
  };
  exports["isUpper"] = isUpper;
  exports["isAlpha"] = isAlpha;
  exports["isDigit"] = isDigit;
  exports["isOctDigit"] = isOctDigit;
  exports["isHexDigit"] = isHexDigit;
  exports["isSpace"] = isSpace;
  exports["digitToInt"] = digitToInt;
  exports["toLower"] = toLower;
  exports["toUpper"] = toUpper;
})(PS);
(function(exports) {
  'use strict';

  var mkV4UUID = null;
  exports.getUUIDImpl = function () {
    if (mkV4UUID === null) mkV4UUID = require('uuid/v4');
    return mkV4UUID();
  };
})(PS["Data.UUID"] = PS["Data.UUID"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.UUID"] = $PS["Data.UUID"] || {};
  var exports = $PS["Data.UUID"];
  var $foreign = $PS["Data.UUID"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Effect = $PS["Effect"];                
  var UUID = function (x) {
      return x;
  };
  var toString = function (v) {
      return v;
  };
  var genUUID = Control_Bind.bind(Effect.bindEffect)($foreign.getUUIDImpl)((function () {
      var $24 = Control_Applicative.pure(Effect.applicativeEffect);
      return function ($25) {
          return $24(UUID($25));
      };
  })());
  exports["genUUID"] = genUUID;
  exports["toString"] = toString;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Term"] = $PS["Term"] || {};
  var exports = $PS["Term"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Category = $PS["Control.Category"];
  var Data_Array = $PS["Data.Array"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Data_UUID = $PS["Data.UUID"];
  var Effect = $PS["Effect"];                
  var Var = (function () {
      function Var(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Var.create = function (value0) {
          return function (value1) {
              return new Var(value0, value1);
          };
      };
      return Var;
  })();
  var Fn = (function () {
      function Fn(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Fn.create = function (value0) {
          return function (value1) {
              return new Fn(value0, value1);
          };
      };
      return Fn;
  })();
  var Apply = (function () {
      function Apply(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Apply.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Apply(value0, value1, value2);
              };
          };
      };
      return Apply;
  })();
  var shift = function (inc) {
      return function (term) {
          var go = function (c) {
              return function (t) {
                  if (t instanceof Var) {
                      var $28 = t.value0.index >= c;
                      if ($28) {
                          return new Var({
                              varName: t.value0.varName,
                              index: t.value0.index + inc | 0
                          }, t.value1);
                      };
                      return new Var({
                          varName: t.value0.varName,
                          index: t.value0.index
                      }, t.value1);
                  };
                  if (t instanceof Fn) {
                      return new Fn({
                          paramName: t.value0.paramName,
                          body: go(c + 1 | 0)(t.value0.body)
                      }, t.value1);
                  };
                  if (t instanceof Apply) {
                      return new Apply(go(c)(t.value0), go(c)(t.value1), t.value2);
                  };
                  throw new Error("Failed pattern match at Term (line 80, column 12 - line 87, column 49): " + [ t.constructor.name ]);
              };
          };
          return go(0)(term);
      };
  };
  var substitute = function (k) {
      return function (sub) {
          return function (term) {
              var go = function (c) {
                  return function (t) {
                      if (t instanceof Var) {
                          var $41 = t.value0.index === (k + c | 0);
                          if ($41) {
                              return shift(c)(sub);
                          };
                          return new Var({
                              varName: t.value0.varName,
                              index: t.value0.index
                          }, t.value1);
                      };
                      if (t instanceof Fn) {
                          return new Fn({
                              paramName: t.value0.paramName,
                              body: go(c + 1 | 0)(t.value0.body)
                          }, t.value1);
                      };
                      if (t instanceof Apply) {
                          return new Apply(go(c)(t.value0), go(c)(t.value1), t.value2);
                      };
                      throw new Error("Failed pattern match at Term (line 61, column 12 - line 68, column 49): " + [ t.constructor.name ]);
                  };
              };
              return go(0)(term);
          };
      };
  };
  var subOuter = function (sub) {
      return function (term) {
          return shift(-1 | 0)(substitute(0)(shift(1)(sub))(term));
      };
  };
  var step = function (v) {
      if (v instanceof Apply && v.value0 instanceof Fn) {
          return subOuter(v.value1)(v.value0.value0.body);
      };
      if (v instanceof Apply) {
          return new Apply(step(v.value0), step(v.value1), v.value2);
      };
      if (v instanceof Fn) {
          return new Fn({
              paramName: v.value0.paramName,
              body: step(v.value0.body)
          }, v.value1);
      };
      return v;
  };
  var pickFreshName = function (ctx) {
      return function (name) {
          var go = function ($copy_count) {
              return function ($copy_n) {
                  var $tco_var_count = $copy_count;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(count, n) {
                      var $78 = Data_Maybe.isNothing(Data_Array.elemIndex(Data_Eq.eqString)(n)(ctx));
                      if ($78) {
                          $tco_done = true;
                          return {
                              ctx: Data_Array.snoc(ctx)(n),
                              name: n
                          };
                      };
                      var c = count + 1 | 0;
                      $tco_var_count = c;
                      $copy_n = name + Data_Show.show(Data_Show.showInt)(c);
                      return;
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_count, $copy_n);
                  };
                  return $tco_result;
              };
          };
          return go(0)(name);
      };
  };
  var isRedex = function (v) {
      if (v instanceof Apply && v.value0 instanceof Fn) {
          return true;
      };
      return false;
  };
  var isDescendantOf = function (uuid) {
      return function (term) {
          if (term instanceof Var) {
              return uuid === term.value1.uuid;
          };
          if (term instanceof Fn) {
              return uuid === term.value1.uuid || isDescendantOf(uuid)(term.value0.body);
          };
          if (term instanceof Apply) {
              return uuid === term.value2.uuid || (isDescendantOf(uuid)(term.value0) || isDescendantOf(uuid)(term.value1));
          };
          throw new Error("Failed pattern match at Term (line 223, column 28 - line 226, column 90): " + [ term.constructor.name ]);
      };
  };
  var indexToName = function (ctx) {
      return function (v) {
          var isFresh = Data_Maybe.isNothing(Data_Array.findIndex(function (v1) {
              return v1 === v.name;
          })(ctx));
          var ctxSize = Data_Array.length(ctx);
          var isFree = v.index >= ctxSize;
          var $96 = !isFree;
          if ($96) {
              var offset = (ctxSize - v.index | 0) - 1 | 0;
              return Data_Maybe.maybe(v.name)(Control_Category.identity(Control_Category.categoryFn))(Data_Array.index(ctx)(offset));
          };
          if (isFresh) {
              return v.name;
          };
          return v.name + Data_Show.show(Data_Show.showInt)(v.index);
      };
  };
  var showTermImpl = (function () {
      var go = function (ctx) {
          return function (term) {
              if (term instanceof Var) {
                  return indexToName(ctx)({
                      name: term.value0.varName,
                      index: term.value0.index
                  });
              };
              if (term instanceof Fn) {
                  var fresh = pickFreshName(ctx)(term.value0.paramName);
                  return "(\u03bb" + (fresh.name + ("." + (go(fresh.ctx)(term.value0.body) + ")")));
              };
              if (term instanceof Apply) {
                  return "(" + (go(ctx)(term.value0) + (" " + (go(ctx)(term.value1) + ")")));
              };
              throw new Error("Failed pattern match at Term (line 94, column 17 - line 101, column 75): " + [ term.constructor.name ]);
          };
      };
      return go([  ]);
  })();
  var showTerm = new Data_Show.Show(showTermImpl);
  var getUuid = function (v) {
      if (v instanceof Var) {
          return v.value1.uuid;
      };
      if (v instanceof Fn) {
          return v.value1.uuid;
      };
      if (v instanceof Apply) {
          return v.value2.uuid;
      };
      throw new Error("Failed pattern match at Term (line 252, column 11 - line 255, column 28): " + [ v.constructor.name ]);
  };
  var nearestRedexAncestor = function (uuid) {
      return function (term) {
          var go = function (closest) {
              return function (subterm) {
                  var $120 = getUuid(subterm) === uuid;
                  if ($120) {
                      return closest;
                  };
                  if (subterm instanceof Var) {
                      return Data_Maybe.Nothing.value;
                  };
                  if (subterm instanceof Fn) {
                      return go(closest)(subterm.value0.body);
                  };
                  if (subterm instanceof Apply) {
                      var nextClosest = (function () {
                          var $127 = isRedex(subterm);
                          if ($127) {
                              return new Data_Maybe.Just(subterm);
                          };
                          return closest;
                      })();
                      return Control_Alt.alt(Data_Maybe.altMaybe)(go(nextClosest)(subterm.value0))(go(nextClosest)(subterm.value1));
                  };
                  throw new Error("Failed pattern match at Term (line 238, column 10 - line 249, column 52): " + [ subterm.constructor.name ]);
              };
          };
          var $131 = isRedex(term);
          if ($131) {
              return go(new Data_Maybe.Just(term))(term);
          };
          return go(Data_Maybe.Nothing.value)(term);
      };
  };
  var replaceTerm = function (uuid) {
      return function ($$new) {
          return function (term) {
              var go = function (v) {
                  if (v instanceof Var) {
                      return new Var(v.value0, v.value1);
                  };
                  if (v instanceof Fn) {
                      return new Fn({
                          paramName: v.value0.paramName,
                          body: replaceTerm(uuid)($$new)(v.value0.body)
                      }, v.value1);
                  };
                  if (v instanceof Apply) {
                      return new Apply(replaceTerm(uuid)($$new)(v.value0), replaceTerm(uuid)($$new)(v.value1), v.value2);
                  };
                  throw new Error("Failed pattern match at Term (line 217, column 8 - line 220, column 81): " + [ v.constructor.name ]);
              };
              var $142 = getUuid(term) === uuid;
              if ($142) {
                  return $$new;
              };
              return go(term);
          };
      };
  };
  var genIds = (function () {
      var withUuid = function (ann) {
          return function __do() {
              var v = Data_Functor.map(Effect.functorEffect)(Data_UUID.toString)(Data_UUID.genUUID)();
              return {
                  uuid: v
              };
          };
      };
      return function (v) {
          if (v instanceof Var) {
              return function __do() {
                  var v2 = withUuid(v.value1)();
                  return new Var(v.value0, v2);
              };
          };
          if (v instanceof Fn) {
              return function __do() {
                  var v1 = withUuid(v.value1)();
                  var v2 = genIds(v.value0.body)();
                  return new Fn({
                      paramName: v.value0.paramName,
                      body: v2
                  }, v1);
              };
          };
          if (v instanceof Apply) {
              return function __do() {
                  var v1 = withUuid(v.value2)();
                  var v2 = genIds(v.value0)();
                  var v3 = genIds(v.value1)();
                  return new Apply(v2, v3, v1);
              };
          };
          throw new Error("Failed pattern match at Term (line 142, column 10 - line 154, column 23): " + [ v.constructor.name ]);
      };
  })();
  var findTerm = function (uuid) {
      return function (term) {
          var go = function (v) {
              if (v instanceof Var) {
                  return Data_Maybe.Nothing.value;
              };
              if (v instanceof Fn) {
                  return findTerm(uuid)(v.value0.body);
              };
              if (v instanceof Apply) {
                  return Control_Alt.alt(Data_Maybe.altMaybe)(findTerm(uuid)(v.value0))(findTerm(uuid)(v.value1));
              };
              throw new Error("Failed pattern match at Term (line 205, column 8 - line 208, column 57): " + [ v.constructor.name ]);
          };
          var $169 = getUuid(term) === uuid;
          if ($169) {
              return new Data_Maybe.Just(term);
          };
          return go(term);
      };
  };
  var reduce = function (uuid) {
      return function (term) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(function (nextStep) {
              return {
                  step: nextStep,
                  term: replaceTerm(uuid)(nextStep)(term)
              };
          })(Data_Functor.map(Data_Maybe.functorMaybe)(step)(findTerm(uuid)(term)));
      };
  };
  var emptyAnn = {
      uuid: ""
  };
  exports["Var"] = Var;
  exports["Fn"] = Fn;
  exports["Apply"] = Apply;
  exports["nearestRedexAncestor"] = nearestRedexAncestor;
  exports["emptyAnn"] = emptyAnn;
  exports["findTerm"] = findTerm;
  exports["genIds"] = genIds;
  exports["indexToName"] = indexToName;
  exports["isDescendantOf"] = isDescendantOf;
  exports["isRedex"] = isRedex;
  exports["pickFreshName"] = pickFreshName;
  exports["reduce"] = reduce;
  exports["getUuid"] = getUuid;
  exports["showTerm"] = showTerm;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Alternative"] = $PS["Control.Alternative"] || {};
  var exports = $PS["Control.Alternative"];              
  var Alternative = function (Applicative0, Plus1) {
      this.Applicative0 = Applicative0;
      this.Plus1 = Plus1;
  };
  exports["Alternative"] = Alternative;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.String.Pattern"] = $PS["Data.String.Pattern"] || {};
  var exports = $PS["Data.String.Pattern"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Pattern = function (x) {
      return x;
  };              
  var newtypePattern = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Pattern);
  exports["newtypePattern"] = newtypePattern;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Text.Parsing.Parser.Pos"] = $PS["Text.Parsing.Parser.Pos"] || {};
  var exports = $PS["Text.Parsing.Parser.Pos"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_String_Pattern = $PS["Data.String.Pattern"];
  var updatePosString = function (pos$prime) {
      return function (str) {
          var updatePosChar = function (v) {
              return function (c) {
                  if (c === "\x0a") {
                      return {
                          line: v.line + 1 | 0,
                          column: 1
                      };
                  };
                  if (c === "\x0d") {
                      return {
                          line: v.line + 1 | 0,
                          column: 1
                      };
                  };
                  if (c === "\x09") {
                      return {
                          line: v.line,
                          column: (v.column + 8 | 0) - Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v.column - 1 | 0)(8) | 0
                      };
                  };
                  return {
                      line: v.line,
                      column: v.column + 1 | 0
                  };
              };
          };
          return Data_Foldable.foldl(Data_Foldable.foldableArray)(updatePosChar)(pos$prime)(Data_String_Common.split(Data_Newtype.wrap(Data_String_Pattern.newtypePattern)(""))(str));
      };
  }; 
  var initialPos = {
      line: 1,
      column: 1
  };
  var eqPosition = new Data_Eq.Eq(function (x) {
      return function (y) {
          return x.column === y.column && x.line === y.line;
      };
  });
  exports["initialPos"] = initialPos;
  exports["updatePosString"] = updatePosString;
  exports["eqPosition"] = eqPosition;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Text.Parsing.Parser"] = $PS["Text.Parsing.Parser"] || {};
  var exports = $PS["Text.Parsing.Parser"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Alternative = $PS["Control.Alternative"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_State_Trans = $PS["Control.Monad.State.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Text_Parsing_Parser_Pos = $PS["Text.Parsing.Parser.Pos"];                
  var ParseState = (function () {
      function ParseState(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      ParseState.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new ParseState(value0, value1, value2);
              };
          };
      };
      return ParseState;
  })();
  var ParseError = (function () {
      function ParseError(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ParseError.create = function (value0) {
          return function (value1) {
              return new ParseError(value0, value1);
          };
      };
      return ParseError;
  })();
  var ParserT = function (x) {
      return x;
  }; 
  var parseErrorPosition = function (v) {
      return v.value1;
  };
  var parseErrorMessage = function (v) {
      return v.value0;
  };
  var newtypeParserT = new Data_Newtype.Newtype(function (n) {
      return n;
  }, ParserT);
  var runParserT = function (dictMonad) {
      return function (s) {
          return function (p) {
              var initialState = new ParseState(s, Text_Parsing_Parser_Pos.initialPos, false);
              return Control_Monad_State_Trans.evalStateT(((dictMonad.Bind1()).Apply0()).Functor0())(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p)))(initialState);
          };
      };
  };
  var monadTransParserT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      var $93 = Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Control_Monad_State_Trans.monadStateT(dictMonad));
      var $94 = Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT)(dictMonad);
      return function ($95) {
          return ParserT($93($94($95)));
      };
  });
  var monadThrowParserT = function (dictMonad) {
      return Control_Monad_Except_Trans.monadThrowExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };
  var monadStateParserT = function (dictMonad) {
      return Control_Monad_Except_Trans.monadStateExceptT(Control_Monad_State_Trans.monadStateStateT(dictMonad));
  };
  var position = function (dictMonad) {
      return Control_Monad_State_Class.gets(monadStateParserT(dictMonad))(function (v) {
          return v.value1;
      });
  };   
  var lazyParserT = new Control_Lazy.Lazy(function (f) {
      return Control_Lazy.defer(Control_Monad_State_Trans.lazyStateT)((function () {
          var $98 = Data_Newtype.unwrap(newtypeParserT);
          return function ($99) {
              return Control_Monad_Except_Trans.runExceptT($98(f($99)));
          };
      })());
  });                           
  var functorParserT = function (dictFunctor) {
      return Control_Monad_Except_Trans.functorExceptT(Control_Monad_State_Trans.functorStateT(dictFunctor));
  };
  var failWithPosition = function (dictMonad) {
      return function (message) {
          return function (pos) {
              return Control_Monad_Error_Class.throwError(monadThrowParserT(dictMonad))(new ParseError(message, pos));
          };
      };
  };
  var eqParseError = new Data_Eq.Eq(function (x) {
      return function (y) {
          return x.value0 === y.value0 && Data_Eq.eq(Text_Parsing_Parser_Pos.eqPosition)(x.value1)(y.value1);
      };
  });
  var bindParserT = function (dictMonad) {
      return Control_Monad_Except_Trans.bindExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };
  var fail = function (dictMonad) {
      return function (message) {
          return Control_Bind.bindFlipped(bindParserT(dictMonad))(failWithPosition(dictMonad)(message))(position(dictMonad));
      };
  };
  var applyParserT = function (dictMonad) {
      return Control_Monad_Except_Trans.applyExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };
  var applicativeParserT = function (dictMonad) {
      return Control_Monad_Except_Trans.applicativeExceptT(Control_Monad_State_Trans.monadStateT(dictMonad));
  };
  var altParserT = function (dictMonad) {
      return new Control_Alt.Alt(function () {
          return functorParserT(((dictMonad.Bind1()).Apply0()).Functor0());
      }, function (p1) {
          return function (p2) {
              return ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
                  return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p1)))(new ParseState(v.value0, v.value1, false)))(function (v1) {
                      if (v1.value0 instanceof Data_Either.Left && !v1.value1.value2) {
                          return Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(newtypeParserT)(p2)))(v);
                      };
                      return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
                  });
              })));
          };
      });
  };
  var plusParserT = function (dictMonad) {
      return new Control_Plus.Plus(function () {
          return altParserT(dictMonad);
      }, fail(dictMonad)("No alternative"));
  };
  var alternativeParserT = function (dictMonad) {
      return new Control_Alternative.Alternative(function () {
          return applicativeParserT(dictMonad);
      }, function () {
          return plusParserT(dictMonad);
      });
  };
  exports["ParseError"] = ParseError;
  exports["parseErrorMessage"] = parseErrorMessage;
  exports["parseErrorPosition"] = parseErrorPosition;
  exports["ParseState"] = ParseState;
  exports["ParserT"] = ParserT;
  exports["runParserT"] = runParserT;
  exports["fail"] = fail;
  exports["eqParseError"] = eqParseError;
  exports["newtypeParserT"] = newtypeParserT;
  exports["lazyParserT"] = lazyParserT;
  exports["functorParserT"] = functorParserT;
  exports["applyParserT"] = applyParserT;
  exports["applicativeParserT"] = applicativeParserT;
  exports["bindParserT"] = bindParserT;
  exports["monadStateParserT"] = monadStateParserT;
  exports["altParserT"] = altParserT;
  exports["plusParserT"] = plusParserT;
  exports["alternativeParserT"] = alternativeParserT;
  exports["monadTransParserT"] = monadTransParserT;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Text.Parsing.Parser.Combinators"] = $PS["Text.Parsing.Parser.Combinators"] || {};
  var exports = $PS["Text.Parsing.Parser.Combinators"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Control_Monad_State_Trans = $PS["Control.Monad.State.Trans"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Text_Parsing_Parser = $PS["Text.Parsing.Parser"];                
  var withErrorMessage = function (dictMonad) {
      return function (p) {
          return function (msg) {
              return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(p)(Text_Parsing_Parser.fail(dictMonad)("Expected " + msg));
          };
      };
  };
  var tryRethrow = function (dictMonad) {
      return function (p) {
          return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
              return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(v))(function (v1) {
                  if (v1.value0 instanceof Data_Either.Left) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(new Data_Either.Left(new Text_Parsing_Parser.ParseError(v1.value0.value0.value0, v.value1)), new Text_Parsing_Parser.ParseState(v1.value1.value0, v1.value1.value1, v.value2)));
                  };
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
              });
          })));
      };
  };
  var $$try = function (dictMonad) {
      return function (p) {
          return Text_Parsing_Parser.ParserT(Control_Monad_Except_Trans.ExceptT(Control_Monad_State_Trans.StateT(function (v) {
              return Control_Bind.bind(dictMonad.Bind1())(Control_Monad_State_Trans.runStateT(Control_Monad_Except_Trans.runExceptT(Data_Newtype.unwrap(Text_Parsing_Parser.newtypeParserT)(p)))(v))(function (v1) {
                  if (v1.value0 instanceof Data_Either.Left) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, new Text_Parsing_Parser.ParseState(v1.value1.value0, v1.value1.value1, v.value2)));
                  };
                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Tuple.Tuple(v1.value0, v1.value1));
              });
          })));
      };
  };
  var skipMany1 = function (dictMonad) {
      return function (p) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(skipMany(dictMonad)(p))(function (v1) {
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit);
              });
          });
      };
  };
  var skipMany = function (dictMonad) {
      return function (p) {
          return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(skipMany1(dictMonad)(p))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit));
      };
  };
  var sepBy1 = function (dictMonad) {
      return function (p) {
          return function (sep) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(p)(function (v) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_List.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(sep)(p)))(function (v1) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_List_Types.Cons(v, v1));
                  });
              });
          };
      };
  };
  var sepBy = function (dictMonad) {
      return function (p) {
          return function (sep) {
              return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(sepBy1(dictMonad)(p)(sep))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_List_Types.Nil.value));
          };
      };
  };
  var option = function (dictMonad) {
      return function (a) {
          return function (p) {
              return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(p)(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a));
          };
      };
  };
  var notFollowedBy = function (dictMonad) {
      return function (p) {
          return $$try(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))($$try(dictMonad)(p))(Text_Parsing_Parser.fail(dictMonad)("Negated parser succeeded")))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit)));
      };
  };
  var choice = function (dictFoldable) {
      return function (dictMonad) {
          return Data_Foldable.foldl(dictFoldable)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad)))(Control_Plus.empty(Text_Parsing_Parser.plusParserT(dictMonad)));
      };
  };
  var between = function (dictMonad) {
      return function (open) {
          return function (close) {
              return function (p) {
                  return Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(dictMonad))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(open)(p))(close);
              };
          };
      };
  };
  var asErrorMessage = function (dictMonad) {
      return Data_Function.flip(withErrorMessage(dictMonad));
  };
  exports["withErrorMessage"] = withErrorMessage;
  exports["asErrorMessage"] = asErrorMessage;
  exports["between"] = between;
  exports["option"] = option;
  exports["try"] = $$try;
  exports["tryRethrow"] = tryRethrow;
  exports["sepBy"] = sepBy;
  exports["sepBy1"] = sepBy1;
  exports["choice"] = choice;
  exports["skipMany"] = skipMany;
  exports["skipMany1"] = skipMany1;
  exports["notFollowedBy"] = notFollowedBy;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Text.Parsing.Parser.String"] = $PS["Text.Parsing.Parser.String"] || {};
  var exports = $PS["Text.Parsing.Parser.String"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_String_Pattern = $PS["Data.String.Pattern"];
  var Text_Parsing_Parser = $PS["Text.Parsing.Parser"];
  var Text_Parsing_Parser_Combinators = $PS["Text.Parsing.Parser.Combinators"];
  var Text_Parsing_Parser_Pos = $PS["Text.Parsing.Parser.Pos"];                
  var StringLike = function (drop, indexOf, $$null, uncons) {
      this.drop = drop;
      this.indexOf = indexOf;
      this["null"] = $$null;
      this.uncons = uncons;
  };
  var uncons = function (dict) {
      return dict.uncons;
  };
  var stringLikeString = new StringLike(Data_String_CodePoints.drop, Data_String_CodePoints.indexOf, Data_String_Common["null"], Data_String_CodeUnits.uncons);
  var $$null = function (dict) {
      return dict["null"];
  };
  var indexOf = function (dict) {
      return dict.indexOf;
  };
  var eof = function (dictStringLike) {
      return function (dictMonad) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
              return v.value0;
          }))(function (v) {
              return Control_Applicative.unless(Text_Parsing_Parser.applicativeParserT(dictMonad))($$null(dictStringLike)(v))(Text_Parsing_Parser.fail(dictMonad)("Expected EOF"));
          });
      };
  };
  var drop = function (dict) {
      return dict.drop;
  };
  var string = function (dictStringLike) {
      return function (dictMonad) {
          return function (str) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
                  return v.value0;
              }))(function (v) {
                  var v1 = indexOf(dictStringLike)(Data_Newtype.wrap(Data_String_Pattern.newtypePattern)(str))(v);
                  if (v1 instanceof Data_Maybe.Just && v1.value0 === 0) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v2) {
                          return new Text_Parsing_Parser.ParseState(drop(dictStringLike)(Data_String_CodePoints.length(str))(v), Text_Parsing_Parser_Pos.updatePosString(v2.value1)(str), true);
                      }))(function () {
                          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(str);
                      });
                  };
                  return Text_Parsing_Parser.fail(dictMonad)("Expected " + Data_Show.show(Data_Show.showString)(str));
              });
          };
      };
  };
  var anyChar = function (dictStringLike) {
      return function (dictMonad) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
              return v.value0;
          }))(function (v) {
              var v1 = uncons(dictStringLike)(v);
              if (v1 instanceof Data_Maybe.Nothing) {
                  return Text_Parsing_Parser.fail(dictMonad)("Unexpected EOF");
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v2) {
                      return new Text_Parsing_Parser.ParseState(v1.value0.tail, Text_Parsing_Parser_Pos.updatePosString(v2.value1)(Data_String_CodeUnits.singleton(v1.value0.head)), true);
                  }))(function () {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1.value0.head);
                  });
              };
              throw new Error("Failed pattern match at Text.Parsing.Parser.String (line 56, column 3 - line 63, column 16): " + [ v1.constructor.name ]);
          });
      };
  };
  var satisfy = function (dictStringLike) {
      return function (dictMonad) {
          return function (f) {
              return Text_Parsing_Parser_Combinators.tryRethrow(dictMonad)(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(anyChar(dictStringLike)(dictMonad))(function (v) {
                  var $61 = f(v);
                  if ($61) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v);
                  };
                  return Text_Parsing_Parser.fail(dictMonad)("Character '" + (Data_String_CodeUnits.singleton(v) + "' did not satisfy predicate"));
              }));
          };
      };
  };
  var $$char = function (dictStringLike) {
      return function (dictMonad) {
          return function (c) {
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(function (v) {
                  return v === c;
              }))(Data_Show.show(Data_Show.showChar)(c));
          };
      };
  };
  var noneOf = function (dictStringLike) {
      return function (dictMonad) {
          return function (ss) {
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.notElem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("none of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
          };
      };
  };
  var oneOf = function (dictStringLike) {
      return function (dictMonad) {
          return function (ss) {
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(satisfy(dictStringLike)(dictMonad)(Data_Function.flip(Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqChar))(ss)))("one of " + Data_Show.show(Data_Show.showArray(Data_Show.showChar))(ss));
          };
      };
  };
  exports["eof"] = eof;
  exports["string"] = string;
  exports["satisfy"] = satisfy;
  exports["char"] = $$char;
  exports["oneOf"] = oneOf;
  exports["noneOf"] = noneOf;
  exports["stringLikeString"] = stringLikeString;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Text.Parsing.Parser.Token"] = $PS["Text.Parsing.Parser.Token"] || {};
  var exports = $PS["Text.Parsing.Parser.Token"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Char = $PS["Data.Char"];
  var Data_Char_Unicode = $PS["Data.Char.Unicode"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Ring = $PS["Data.Ring"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Data_Unit = $PS["Data.Unit"];
  var $$Math = $PS["Math"];
  var Text_Parsing_Parser = $PS["Text.Parsing.Parser"];
  var Text_Parsing_Parser_Combinators = $PS["Text.Parsing.Parser.Combinators"];
  var Text_Parsing_Parser_String = $PS["Text.Parsing.Parser.String"];
  var upper = function (dictMonad) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isUpper))("uppercase letter");
  };
  var theReservedNames = function (dictMonad) {
      return function (v) {
          if (v.caseSensitive) {
              return Data_Array.sort(Data_Ord.ordString)(v.reservedNames);
          };
          if (Data_Boolean.otherwise) {
              return Data_Array.sort(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(Data_String_Common.toLower)(v.reservedNames));
          };
          throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 722, column 1 - line 722, column 82): " + [ v.constructor.name ]);
      };
  };
  var space = function (dictMonad) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isSpace))("space");
  };
  var simpleSpace = function (dictMonad) {
      return Text_Parsing_Parser_Combinators.skipMany1(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isSpace));
  };
  var oneLineComment = function (dictMonad) {
      return function (v) {
          return Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentLine)))(Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(function (v1) {
              return v1 !== "\x0a";
          })));
      };
  };
  var octDigit = function (dictMonad) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isOctDigit))("oct digit");
  };
  var isReserved = function ($copy_names) {
      return function ($copy_name) {
          var $tco_var_names = $copy_names;
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(names, name) {
              var v = Data_Array.uncons(names);
              if (v instanceof Data_Maybe.Nothing) {
                  $tco_done = true;
                  return false;
              };
              if (v instanceof Data_Maybe.Just) {
                  var v1 = Data_Ord.compare(Data_Ord.ordString)(v.value0.head)(name);
                  if (v1 instanceof Data_Ordering.LT) {
                      $tco_var_names = v.value0.tail;
                      $copy_name = name;
                      return;
                  };
                  if (v1 instanceof Data_Ordering.EQ) {
                      $tco_done = true;
                      return true;
                  };
                  if (v1 instanceof Data_Ordering.GT) {
                      $tco_done = true;
                      return false;
                  };
                  throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 717, column 39 - line 720, column 53): " + [ v1.constructor.name ]);
              };
              throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 715, column 5 - line 720, column 53): " + [ v.constructor.name ]);
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_names, $copy_name);
          };
          return $tco_result;
      };
  };
  var isReservedName = function (dictMonad) {
      return function (v) {
          return function (name) {
              var caseName = (function () {
                  if (v.caseSensitive) {
                      return name;
                  };
                  if (Data_Boolean.otherwise) {
                      return Data_String_Common.toLower(name);
                  };
                  throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 710, column 5 - line 711, column 57): " + [  ]);
              })();
              return isReserved(theReservedNames(dictMonad)(v))(caseName);
          };
      };
  };
  var inCommentSingle = function (dictMonad) {
      return function (v) {
          var startEnd = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_String_CodeUnits.toCharArray(v.commentEnd))(Data_String_CodeUnits.toCharArray(v.commentStart));
          return Control_Lazy.fix(Text_Parsing_Parser.lazyParserT)(function (p) {
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentEnd))))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators.skipMany1(dictMonad)(Text_Parsing_Parser_String.noneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd)))(p)))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd))(p)))("end of comment");
          });
      };
  };
  var multiLineComment = function (dictMonad) {
      return function (v) {
          return Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentStart)))(inComment(dictMonad)(v));
      };
  };
  var inCommentMulti = function (dictMonad) {
      return function (v) {
          var startEnd = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_String_CodeUnits.toCharArray(v.commentEnd))(Data_String_CodeUnits.toCharArray(v.commentStart));
          return Control_Lazy.fix(Text_Parsing_Parser.lazyParserT)(function (p) {
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentEnd))))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(multiLineComment(dictMonad)(v))(p)))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators.skipMany1(dictMonad)(Text_Parsing_Parser_String.noneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd)))(p)))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd))(p)))("end of comment");
          });
      };
  };
  var inComment = function (dictMonad) {
      return function (v) {
          if (v.nestedComments) {
              return inCommentMulti(dictMonad)(v);
          };
          return inCommentSingle(dictMonad)(v);
      };
  };
  var whiteSpace$prime = function (dictMonad) {
      return function (v) {
          if (Data_String_Common["null"](v.commentLine) && Data_String_Common["null"](v.commentStart)) {
              return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(simpleSpace(dictMonad))(""));
          };
          if (Data_String_Common["null"](v.commentLine)) {
              return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(simpleSpace(dictMonad))(multiLineComment(dictMonad)(v)))(""));
          };
          if (Data_String_Common["null"](v.commentStart)) {
              return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(simpleSpace(dictMonad))(oneLineComment(dictMonad)(v)))(""));
          };
          if (Data_Boolean.otherwise) {
              return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(simpleSpace(dictMonad))(oneLineComment(dictMonad)(v)))(multiLineComment(dictMonad)(v)))(""));
          };
          throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 731, column 1 - line 731, column 86): " + [ v.constructor.name ]);
      };
  };
  var hexDigit = function (dictMonad) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isHexDigit))("hex digit");
  };
  var digit = function (dictMonad) {
      return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isDigit))("digit");
  };
  var makeTokenParser = function (dictMonad) {
      return function (v) {
          var stringLetter = Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(function (c) {
              return c !== "\"" && (c !== "\\" && c > "\x1a");
          });
          var sign = function (dictRing) {
              return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("-"))(Data_Ring.negate(dictRing)))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("+"))(Control_Category.identity(Control_Category.categoryFn))))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Control_Category.identity(Control_Category.categoryFn)));
          };
          var oper = (function () {
              var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(v.opStart)(function (v1) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(v.opLetter))(function (v2) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.singleton(v1) + Data_String_CodeUnits.fromCharArray(v2));
                  });
              });
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(go)("operator");
          })();
          var number = function (base) {
              return function (baseDigit) {
                  var folder = function (v1) {
                      return function (v2) {
                          if (v1 instanceof Data_Maybe.Nothing) {
                              return Data_Maybe.Nothing.value;
                          };
                          if (v1 instanceof Data_Maybe.Just) {
                              return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                                  return (base * v1.value0 | 0) + v3 | 0;
                              })(Data_Char_Unicode.digitToInt(v2));
                          };
                          throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 600, column 9 - line 600, column 49): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                  };
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(baseDigit))(function (v1) {
                      return Data_Maybe.maybe(Text_Parsing_Parser.fail(dictMonad)("not digits"))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad)))(Data_Foldable.foldl(Data_Foldable.foldableArray)(folder)(new Data_Maybe.Just(0))(v1));
                  });
              };
          };
          var octal = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)([ "o", "O" ]))(number(8)(octDigit(dictMonad)));
          var lexeme = function (p) {
              return Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(dictMonad))(p)(whiteSpace$prime(dictMonad)(v));
          };
          var reservedOp = function (name) {
              var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(name))(function (v1) {
                  return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_Combinators.notFollowedBy(dictMonad)(v.opLetter))("end of " + name);
              });
              return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
          };
          var symbol = function (name) {
              return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(lexeme(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(name)))(name);
          };
          var parens = function (p) {
              return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("("))(symbol(")"))(p);
          };
          var semi = symbol(";");
          var semiSep = function (p) {
              return Text_Parsing_Parser_Combinators.sepBy(dictMonad)(p)(semi);
          };
          var semiSep1 = function (p) {
              return Text_Parsing_Parser_Combinators.sepBy1(dictMonad)(p)(semi);
          };
          var isReservedOp = function (name) {
              return isReserved(Data_Array.sort(Data_Ord.ordString)(v.reservedOpNames))(name);
          };
          var operator = (function () {
              var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(oper)(function (v1) {
                  var $115 = isReservedOp(v1);
                  if ($115) {
                      return Text_Parsing_Parser.fail(dictMonad)("reserved operator " + v1);
                  };
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1);
              });
              return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
          })();
          var ident = (function () {
              var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(v.identStart)(function (v1) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(v.identLetter))(function (v2) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.singleton(v1) + Data_String_CodeUnits.fromCharArray(v2));
                  });
              });
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(go)("identifier");
          })();
          var identifier = (function () {
              var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(ident)(function (v1) {
                  var $119 = isReservedName(dictMonad)(v)(v1);
                  if ($119) {
                      return Text_Parsing_Parser.fail(dictMonad)("reserved word " + Data_Show.show(Data_Show.showString)(v1));
                  };
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1);
              });
              return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
          })();
          var hexadecimal = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)([ "x", "X" ]))(number(16)(hexDigit(dictMonad)));
          var fraction = (function () {
              var op = function (v1) {
                  return function (v2) {
                      if (v2 instanceof Data_Maybe.Nothing) {
                          return Data_Maybe.Nothing.value;
                      };
                      if (v2 instanceof Data_Maybe.Just) {
                          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Char_Unicode.digitToInt(v1))(function (v3) {
                              return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((v2.value0 + Data_Int.toNumber(v3)) / 10.0);
                          });
                      };
                      throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 550, column 9 - line 550, column 51): " + [ v1.constructor.name, v2.constructor.name ]);
                  };
              };
              return Text_Parsing_Parser_Combinators.asErrorMessage(dictMonad)("fraction")(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("."))(function (v1) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(digit(dictMonad)))("fraction"))(function (v2) {
                      return Data_Maybe.maybe(Text_Parsing_Parser.fail(dictMonad)("not digit"))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad)))(Data_Foldable.foldr(Data_Foldable.foldableArray)(op)(new Data_Maybe.Just(0.0))(v2));
                  });
              }));
          })();
          var escapeGap = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(space(dictMonad)))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\\")))("end of string gap");
          var escapeEmpty = Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("&");
          var escMap = Data_Array.zip([ "a", "b", "f", "n", "r", "t", "v", "\\", "\"", "'" ])([ "\x07", "\x08", "\x0c", "\x0a", "\x0d", "\x09", "\x0b", "\\", "\"", "'" ]);
          var dot = symbol(".");
          var decimal = number(10)(digit(dictMonad));
          var exponent$prime = (function () {
              var power = function (e) {
                  if (e < 0) {
                      return 1.0 / power(-e | 0);
                  };
                  if (Data_Boolean.otherwise) {
                      return $$Math.pow(10.0)(Data_Int.toNumber(e));
                  };
                  throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 563, column 9 - line 563, column 31): " + [ e.constructor.name ]);
              };
              return Text_Parsing_Parser_Combinators.asErrorMessage(dictMonad)("exponent")(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)([ "e", "E" ]))(function (v1) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(sign(Data_Ring.ringInt))(function (v2) {
                      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(decimal)("exponent"))(function (v3) {
                          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(power(v2(v3)));
                      });
                  });
              }));
          })();
          var fractExponent = function (n) {
              var justExponent = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(exponent$prime)(function (v1) {
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Int.toNumber(n) * v1);
              });
              var fractExponent$prime = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(fraction)(function (v1) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.option(dictMonad)(1.0)(exponent$prime))(function (v2) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))((Data_Int.toNumber(n) + v1) * v2);
                  });
              });
              return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(fractExponent$prime)(justExponent);
          };
          var fractFloat = function (n) {
              return Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Either.Right.create)(fractExponent(n));
          };
          var decimalFloat = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(decimal)(function (v1) {
              return Text_Parsing_Parser_Combinators.option(dictMonad)(new Data_Either.Left(v1))(fractFloat(v1));
          });
          var zeroNumFloat = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Either.Left.create)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(hexadecimal)(octal)))(decimalFloat))(fractFloat(0)))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_Either.Left(0)));
          var natFloat = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("0"))(zeroNumFloat))(decimalFloat);
          var naturalOrFloat = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(natFloat))("number");
          var floating = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(decimal)(fractExponent);
          var $$float = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(floating))("float");
          var zeroNumber = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("0"))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(hexadecimal)(octal))(decimal))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(0))))("");
          var nat = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(zeroNumber)(decimal);
          var $$int = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(lexeme(sign(Data_Ring.ringInt)))(function (v1) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(nat)(function (v2) {
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1(v2));
              });
          });
          var integer = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme($$int))("integer");
          var natural = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(nat))("natural");
          var comma = symbol(",");
          var commaSep = function (p) {
              return Text_Parsing_Parser_Combinators.sepBy(dictMonad)(p)(comma);
          };
          var commaSep1 = function (p) {
              return Text_Parsing_Parser_Combinators.sepBy1(dictMonad)(p)(comma);
          };
          var colon = symbol(":");
          var charNum = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(decimal)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("o"))(number(8)(octDigit(dictMonad)))))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("x"))(number(16)(hexDigit(dictMonad)))))(function (v1) {
              var $135 = v1 > 1114111;
              if ($135) {
                  return Text_Parsing_Parser.fail(dictMonad)("invalid escape sequence");
              };
              var v2 = Data_Char.fromCharCode(v1);
              if (v2 instanceof Data_Maybe.Just) {
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v2.value0);
              };
              if (v2 instanceof Data_Maybe.Nothing) {
                  return Text_Parsing_Parser.fail(dictMonad)("invalid character code (should not happen)");
              };
              throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 448, column 17 - line 450, column 81): " + [ v2.constructor.name ]);
          });
          var charLetter = Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(function (c) {
              return c !== "'" && (c !== "\\" && c > "\x1a");
          });
          var charEsc = (function () {
              var parseEsc = function (v1) {
                  return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v1.value0))(v1.value1);
              };
              return Text_Parsing_Parser_Combinators.choice(Data_Foldable.foldableArray)(dictMonad)(Data_Functor.map(Data_Functor.functorArray)(parseEsc)(escMap));
          })();
          var charControl = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("^"))(function (v1) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(upper(dictMonad))(function (v2) {
                  var v3 = Data_Char.fromCharCode((Data_Char.toCharCode(v2) - Data_Char.toCharCode("A") | 0) + 1 | 0);
                  if (v3 instanceof Data_Maybe.Just) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v3.value0);
                  };
                  if (v3 instanceof Data_Maybe.Nothing) {
                      return Text_Parsing_Parser.fail(dictMonad)("invalid character code (should not happen)");
                  };
                  throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 437, column 9 - line 439, column 73): " + [ v3.constructor.name ]);
              });
          });
          var caseString = function (name) {
              if (v.caseSensitive) {
                  return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(name))(name);
              };
              if (Data_Boolean.otherwise) {
                  var msg = Data_Show.show(Data_Show.showString)(name);
                  var caseChar = function (c) {
                      if (Data_Char_Unicode.isAlpha(c)) {
                          return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.toLower(c)))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.toUpper(c)));
                      };
                      if (Data_Boolean.otherwise) {
                          return Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(c);
                      };
                      throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 658, column 9 - line 658, column 50): " + [ c.constructor.name ]);
                  };
                  var walk = function (name$prime) {
                      var v1 = Data_String_CodeUnits.uncons(name$prime);
                      if (v1 instanceof Data_Maybe.Nothing) {
                          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit);
                      };
                      if (v1 instanceof Data_Maybe.Just) {
                          return Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(caseChar(v1.value0.head))(msg))(walk(v1.value0.tail));
                      };
                      throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 654, column 22 - line 656, column 86): " + [ v1.constructor.name ]);
                  };
                  return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(walk(name))(name);
              };
              throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 649, column 5 - line 649, column 52): " + [ name.constructor.name ]);
          };
          var reserved = function (name) {
              var go = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(caseString(name))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_Combinators.notFollowedBy(dictMonad)(v.identLetter))("end of " + name));
              return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
          };
          var brackets = function (p) {
              return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("["))(symbol("]"))(p);
          };
          var braces = function (p) {
              return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("{"))(symbol("}"))(p);
          };
          var ascii3codes = [ "NUL", "SOH", "STX", "ETX", "EOT", "ENQ", "ACK", "BEL", "DLE", "DC1", "DC2", "DC3", "DC4", "NAK", "SYN", "ETB", "CAN", "SUB", "ESC", "DEL" ];
          var ascii3 = [ "\x00", "\x01", "\x02", "\x03", "\x04", "\x05", "\x06", "\x07", "\x10", "\x11", "\x12", "\x13", "\x14", "\x15", "\x16", "\x17", "\x18", "\x1a", "\x1b", "\x7f" ];
          var ascii2codes = [ "BS", "HT", "LF", "VT", "FF", "CR", "SO", "SI", "EM", "FS", "GS", "RS", "US", "SP" ];
          var ascii2 = [ "\x08", "\x09", "\x0a", "\x0b", "\x0c", "\x0d", "\x0e", "\x0f", "\x19", "\x1c", "\x1d", "\x1e", "\x1f", " " ];
          var asciiMap = Data_Array.zip(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ascii3codes)(ascii2codes))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ascii3)(ascii2));
          var charAscii = (function () {
              var parseAscii = function (v1) {
                  return Text_Parsing_Parser_Combinators["try"](dictMonad)(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v1.value0))(v1.value1));
              };
              return Text_Parsing_Parser_Combinators.choice(Data_Foldable.foldableArray)(dictMonad)(Data_Functor.map(Data_Functor.functorArray)(parseAscii)(asciiMap));
          })();
          var escapeCode = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(charEsc)(charNum))(charAscii))(charControl))("escape code");
          var charEscape = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\\"))(escapeCode);
          var characterChar = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(charLetter)(charEscape))("literal character");
          var charLiteral = (function () {
              var go = Text_Parsing_Parser_Combinators.between(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("'"))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("'"))("end of character"))(characterChar);
              return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(go))("character");
          })();
          var stringEscape = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\\"))(function (v1) {
              return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(escapeGap)(Data_Maybe.Nothing.value))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(escapeEmpty)(Data_Maybe.Nothing.value)))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Maybe.Just.create)(escapeCode));
          });
          var stringChar = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Maybe.Just.create)(stringLetter))(stringEscape))("string character");
          var stringLiteral = (function () {
              var folder = function (v1) {
                  return function (chars) {
                      if (v1 instanceof Data_Maybe.Nothing) {
                          return chars;
                      };
                      if (v1 instanceof Data_Maybe.Just) {
                          return new Data_List_Types.Cons(v1.value0, chars);
                      };
                      throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 404, column 9 - line 404, column 55): " + [ v1.constructor.name, chars.constructor.name ]);
                  };
              };
              var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.between(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\""))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\""))("end of string"))(Data_List.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(stringChar)))(function (v1) {
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.fromCharArray(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Foldable.foldr(Data_List_Types.foldableList)(folder)(Data_List_Types.Nil.value)(v1))));
              });
              return lexeme(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(go)("literal string"));
          })();
          var angles = function (p) {
              return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("<"))(symbol(">"))(p);
          };
          return {
              identifier: identifier,
              reserved: reserved,
              operator: operator,
              reservedOp: reservedOp,
              charLiteral: charLiteral,
              stringLiteral: stringLiteral,
              natural: natural,
              integer: integer,
              "float": $$float,
              naturalOrFloat: naturalOrFloat,
              decimal: decimal,
              hexadecimal: hexadecimal,
              octal: octal,
              symbol: symbol,
              lexeme: lexeme,
              whiteSpace: whiteSpace$prime(dictMonad)(v),
              parens: parens,
              braces: braces,
              angles: angles,
              brackets: brackets,
              semi: semi,
              comma: comma,
              colon: colon,
              dot: dot,
              semiSep: semiSep,
              semiSep1: semiSep1,
              commaSep: commaSep,
              commaSep1: commaSep1
          };
      };
  };
  exports["makeTokenParser"] = makeTokenParser;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Parse"] = $PS["Parse"] || {};
  var exports = $PS["Parse"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Lazy = $PS["Control.Lazy"];
  var Control_Monad_State = $PS["Control.Monad.State"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_State_Trans = $PS["Control.Monad.State.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Char_Unicode = $PS["Data.Char.Unicode"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Term = $PS["Term"];
  var Text_Parsing_Parser = $PS["Text.Parsing.Parser"];
  var Text_Parsing_Parser_Combinators = $PS["Text.Parsing.Parser.Combinators"];
  var Text_Parsing_Parser_String = $PS["Text.Parsing.Parser.String"];
  var Text_Parsing_Parser_Token = $PS["Text.Parsing.Parser.Token"];                
  var initialContext = {
      bound: [  ],
      free: [  ]
  };
  var implicitParens = function (t) {
      var toApply = function (left) {
          return function (right) {
              return new Term.Apply(left, right, Term.emptyAnn);
          };
      };
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(t)(function (v) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Text_Parsing_Parser.lazyParserT)(t))(function (v1) {
              if (v1.length === 0) {
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(v);
              };
              return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Data_Foldable.foldl(Data_Foldable.foldableArray)(toApply)(v)(v1));
          });
      });
  };
  var errPos = function ($48) {
      return (function (v) {
          return v;
      })(Text_Parsing_Parser.parseErrorPosition($48));
  };
  var errMsg = Text_Parsing_Parser.parseErrorMessage;
  var assignIndex = function (varName) {
      var nextFreeIndex = Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Control_Monad_Trans_Class.lift(Text_Parsing_Parser.monadTransParserT)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.modify(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (ctx) {
          var $24 = {};
          for (var $25 in ctx) {
              if ({}.hasOwnProperty.call(ctx, $25)) {
                  $24[$25] = ctx[$25];
              };
          };
          $24.free = Data_Array.cons(varName)(ctx.free);
          return $24;
      })))(function (v) {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))((Data_Array.length(v.free) + Data_Array.length(v.bound) | 0) - 1 | 0);
      });
      var findIndex = Data_Array.findIndex(function (v) {
          return v === varName;
      });
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Control_Monad_Trans_Class.lift(Text_Parsing_Parser.monadTransParserT)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.get(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))))(function (v) {
          var boundIndex = findIndex(v.bound);
          var freeIndex = findIndex(v.free);
          var v1 = Control_Alt.alt(Data_Maybe.altMaybe)(boundIndex)(freeIndex);
          if (v1 instanceof Data_Maybe.Nothing) {
              return nextFreeIndex;
          };
          if (v1 instanceof Data_Maybe.Just) {
              return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(v1.value0);
          };
          throw new Error("Failed pattern match at Parse (line 117, column 3 - line 119, column 29): " + [ v1.constructor.name ]);
      });
  };
  var applyParser = function (t) {
      var toApply = function (t1) {
          return function (t2) {
              return new Term.Apply(t1, t2, Term.emptyAnn);
          };
      };
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(t)(function (v) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(t)(function (v1) {
              var innerMost = new Term.Apply(v, v1, Term.emptyAnn);
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Text_Parsing_Parser.lazyParserT)(t))(function (v2) {
                  if (v2.length === 0) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(innerMost);
                  };
                  return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Data_Foldable.foldl(Data_Foldable.foldableArray)(toApply)(innerMost)(v2));
              });
          });
      });
  };
  var alphaNotLambda = Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(function (c) {
      return Data_Char_Unicode.isAlpha(c) && c !== "\u03bb";
  });
  var langDef = {
      commentStart: "",
      commentEnd: "",
      commentLine: "",
      nestedComments: true,
      identLetter: Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(Data_String_CodeUnits.toCharArray("0123456789")),
      identStart: alphaNotLambda,
      opStart: Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))([  ]),
      opLetter: Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))([  ]),
      reservedOpNames: [  ],
      reservedNames: [  ],
      caseSensitive: true
  };
  var lexer = Text_Parsing_Parser_Token.makeTokenParser(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(langDef);
  var fnParser = function (t) {
      var withLocalCtx = function (p) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Control_Monad_Trans_Class.lift(Text_Parsing_Parser.monadTransParserT)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.get(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))))(function (v) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(p)(function (v1) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Control_Monad_Trans_Class.lift(Text_Parsing_Parser.monadTransParserT)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.put(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(v)))(function () {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(v1);
                  });
              });
          });
      };
      var bindParam = function (name) {
          return Control_Monad_Trans_Class.lift(Text_Parsing_Parser.monadTransParserT)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(Control_Monad_State_Class.modify_(Control_Monad_State_Trans.monadStateStateT(Data_Identity.monadIdentity))(function (ctx) {
              var $41 = {};
              for (var $42 in ctx) {
                  if ({}.hasOwnProperty.call(ctx, $42)) {
                      $41[$42] = ctx[$42];
                  };
              };
              $41.bound = Data_Array.cons(name)(ctx.bound);
              return $41;
          }));
      };
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(lexer.lexeme(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))([ "\u03bb", "\\" ])))(function (v) {
          return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(lexer.identifier)(function (v1) {
              return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(lexer.lexeme(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(".")))(function (v2) {
                  return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(withLocalCtx(Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(bindParam(v1))(function () {
                      return implicitParens(t);
                  })))(function (v3) {
                      return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(new Term.Fn({
                          paramName: v1,
                          body: v3
                      }, Term.emptyAnn));
                  });
              });
          });
      });
  };
  var varParser = Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(lexer.identifier)(function (v) {
      return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(assignIndex(v))(function (v1) {
          return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(new Term.Var({
              varName: v,
              index: v1
          }, Term.emptyAnn));
      });
  });
  var termParser = (function () {
      var tryParens = (function () {
          var $49 = Text_Parsing_Parser_Combinators["try"](Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity));
          return function ($50) {
              return $49(lexer.parens($50));
          };
      })();
      return implicitParens(Control_Lazy.fix(Text_Parsing_Parser.lazyParserT)(function (t) {
          return Control_Alt.alt(Text_Parsing_Parser.altParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(Control_Alt.alt(Text_Parsing_Parser.altParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(varParser)(tryParens(fnParser(t))))(fnParser(t)))(tryParens(applyParser(t)));
      }));
  })();
  var parse = (function () {
      var parser = Text_Parsing_Parser_Combinators.between(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity))(lexer.whiteSpace)(Text_Parsing_Parser_String.eof(Text_Parsing_Parser_String.stringLikeString)(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(termParser);
      var $51 = Data_Function.flip(Control_Monad_State.evalState)(initialContext);
      var $52 = Data_Function.flip(Text_Parsing_Parser.runParserT(Control_Monad_State_Trans.monadStateT(Data_Identity.monadIdentity)))(parser);
      return function ($53) {
          return $51($52($53));
      };
  })();
  exports["parse"] = parse;
  exports["errMsg"] = errMsg;
  exports["errPos"] = errPos;
})(PS);
(function(exports) {
  "use strict";

  var getEffProp = function (name) {
    return function (node) {
      return function () {
        return node[name];
      };
    };
  };                                                   

  exports._parentNode = getEffProp("parentNode");

  exports.childNodes = getEffProp("childNodes");           

  exports._nextSibling = getEffProp("nextSibling");

  exports.textContent = getEffProp("textContent");

  exports.setTextContent = function (value) {
    return function (node) {
      return function () {
        node.textContent = value;
        return {};
      };
    };
  };

  exports.insertBefore = function (node1) {
    return function (node2) {
      return function (parent) {
        return function () {
          return parent.insertBefore(node1, node2);
        };
      };
    };
  };

  exports.appendChild = function (node) {
    return function (parent) {
      return function () {
        return parent.appendChild(node);
      };
    };
  };

  exports.removeChild = function (node) {
    return function (parent) {
      return function () {
        return parent.removeChild(node);
      };
    };
  };
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});
(function(exports) {
  "use strict";

  exports._unsafeReadProtoTagged = function (nothing, just, name, value) {
    if (typeof window !== "undefined") {
      var ty = window[name];
      if (ty != null && value instanceof ty) {
        return just(value);
      }
      return nothing;
    } 
    var obj = value;
    while (obj != null) {
      var proto = Object.getPrototypeOf(obj);
      var constructorName = proto.constructor.name;
      if (constructorName === name) {
        return just(value);
      } else if (constructorName === "Object") {
        return nothing;
      }
      obj = proto;
    }
    return nothing;
  };
})(PS["Web.Internal.FFI"] = PS["Web.Internal.FFI"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.Internal.FFI"] = $PS["Web.Internal.FFI"] || {};
  var exports = $PS["Web.Internal.FFI"];
  var $foreign = $PS["Web.Internal.FFI"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var unsafeReadProtoTagged = function (name) {
      return function (value) {
          return $foreign["_unsafeReadProtoTagged"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, name, value);
      };
  };
  exports["unsafeReadProtoTagged"] = unsafeReadProtoTagged;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.DOM.Node"] = $PS["Web.DOM.Node"] || {};
  var exports = $PS["Web.DOM.Node"];
  var $foreign = $PS["Web.DOM.Node"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var Web_Internal_FFI = $PS["Web.Internal.FFI"];
  var parentNode = (function () {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($4) {
          return $3($foreign["_parentNode"]($4));
      };
  })();
  var nextSibling = (function () {
      var $14 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($15) {
          return $14($foreign["_nextSibling"]($15));
      };
  })();
  var fromEventTarget = Web_Internal_FFI.unsafeReadProtoTagged("Node");
  exports["fromEventTarget"] = fromEventTarget;
  exports["parentNode"] = parentNode;
  exports["nextSibling"] = nextSibling;
  exports["childNodes"] = $foreign.childNodes;
  exports["textContent"] = $foreign.textContent;
  exports["setTextContent"] = $foreign.setTextContent;
  exports["insertBefore"] = $foreign.insertBefore;
  exports["appendChild"] = $foreign.appendChild;
  exports["removeChild"] = $foreign.removeChild;
})(PS);
(function(exports) {
  "use strict";

  exports._target = function (e) {
    return e.target;
  };

  exports.preventDefault = function (e) {
    return function () {
      return e.preventDefault();
    };
  };

  exports.stopPropagation = function (e) {
    return function () {
      return e.stopPropagation();
    };
  };
})(PS["Web.Event.Event"] = PS["Web.Event.Event"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.Event.Event"] = $PS["Web.Event.Event"] || {};
  var exports = $PS["Web.Event.Event"];
  var $foreign = $PS["Web.Event.Event"];
  var Data_Nullable = $PS["Data.Nullable"];
  var target = function ($4) {
      return Data_Nullable.toMaybe($foreign["_target"]($4));
  };
  exports["target"] = target;
  exports["stopPropagation"] = $foreign.stopPropagation;
  exports["preventDefault"] = $foreign.preventDefault;
})(PS);
(function(exports) {
  "use strict";

  exports.key = function (e) {
    return e.key;
  };
})(PS["Web.UIEvent.KeyboardEvent"] = PS["Web.UIEvent.KeyboardEvent"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.UIEvent.KeyboardEvent"] = $PS["Web.UIEvent.KeyboardEvent"] || {};
  var exports = $PS["Web.UIEvent.KeyboardEvent"];
  var $foreign = $PS["Web.UIEvent.KeyboardEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];  
  var toEvent = Unsafe_Coerce.unsafeCoerce;
  exports["toEvent"] = toEvent;
  exports["key"] = $foreign.key;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.UIEvent.MouseEvent"] = $PS["Web.UIEvent.MouseEvent"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];  
  var toEvent = Unsafe_Coerce.unsafeCoerce;
  exports["toEvent"] = toEvent;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Component.Step"] = $PS["Component.Step"] || {};
  var exports = $PS["Component.Step"];
  var $foreign = $PS["Component.Step"];
  var Component_Util = $PS["Component.Util"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Uncurried = $PS["Effect.Uncurried"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_Query_EventSource = $PS["Halogen.Query.EventSource"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Parse = $PS["Parse"];
  var Term = $PS["Term"];
  var Text_Parsing_Parser = $PS["Text.Parsing.Parser"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_UIEvent_KeyboardEvent = $PS["Web.UIEvent.KeyboardEvent"];
  var Web_UIEvent_MouseEvent = $PS["Web.UIEvent.MouseEvent"];                
  var First = (function () {
      function First() {

      };
      First.value = new First();
      return First;
  })();
  var Last = (function () {
      function Last() {

      };
      Last.value = new Last();
      return Last;
  })();
  var Middle = (function () {
      function Middle() {

      };
      Middle.value = new Middle();
      return Middle;
  })();
  var Only = (function () {
      function Only() {

      };
      Only.value = new Only();
      return Only;
  })();
  var Disable = (function () {
      function Disable(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Disable.create = function (value0) {
          return function (value1) {
              return new Disable(value0, value1);
          };
      };
      return Disable;
  })();
  var Enable = (function () {
      function Enable(value0) {
          this.value0 = value0;
      };
      Enable.create = function (value0) {
          return new Enable(value0);
      };
      return Enable;
  })();
  var Reduced = (function () {
      function Reduced(value0) {
          this.value0 = value0;
      };
      Reduced.create = function (value0) {
          return new Reduced(value0);
      };
      return Reduced;
  })();
  var EditorOpened = (function () {
      function EditorOpened(value0) {
          this.value0 = value0;
      };
      EditorOpened.create = function (value0) {
          return new EditorOpened(value0);
      };
      return EditorOpened;
  })();
  var EditorClosed = (function () {
      function EditorClosed() {

      };
      EditorClosed.value = new EditorClosed();
      return EditorClosed;
  })();
  var NewTerm = (function () {
      function NewTerm(value0) {
          this.value0 = value0;
      };
      NewTerm.create = function (value0) {
          return new NewTerm(value0);
      };
      return NewTerm;
  })();
  var TermFocusOn = (function () {
      function TermFocusOn(value0) {
          this.value0 = value0;
      };
      TermFocusOn.create = function (value0) {
          return new TermFocusOn(value0);
      };
      return TermFocusOn;
  })();
  var TermFocusOff = (function () {
      function TermFocusOff() {

      };
      TermFocusOff.value = new TermFocusOff();
      return TermFocusOff;
  })();
  var Disabled = (function () {
      function Disabled() {

      };
      Disabled.value = new Disabled();
      return Disabled;
  })();
  var Read = (function () {
      function Read() {

      };
      Read.value = new Read();
      return Read;
  })();
  var Write = (function () {
      function Write(value0) {
          this.value0 = value0;
      };
      Write.create = function (value0) {
          return new Write(value0);
      };
      return Write;
  })();
  var Done = (function () {
      function Done() {

      };
      Done.value = new Done();
      return Done;
  })();
  var Success = (function () {
      function Success() {

      };
      Success.value = new Success();
      return Success;
  })();
  var Todo = (function () {
      function Todo() {

      };
      Todo.value = new Todo();
      return Todo;
  })();
  var EnterKey = (function () {
      function EnterKey() {

      };
      EnterKey.value = new EnterKey();
      return EnterKey;
  })();
  var ApplyClicked = (function () {
      function ApplyClicked(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ApplyClicked.create = function (value0) {
          return function (value1) {
              return new ApplyClicked(value0, value1);
          };
      };
      return ApplyClicked;
  })();
  var EditBtnClicked = (function () {
      function EditBtnClicked(value0) {
          this.value0 = value0;
      };
      EditBtnClicked.create = function (value0) {
          return new EditBtnClicked(value0);
      };
      return EditBtnClicked;
  })();
  var EditorContentChanged = (function () {
      function EditorContentChanged(value0) {
          this.value0 = value0;
      };
      EditorContentChanged.create = function (value0) {
          return new EditorContentChanged(value0);
      };
      return EditorContentChanged;
  })();
  var EditorBlurred = (function () {
      function EditorBlurred() {

      };
      EditorBlurred.value = new EditorBlurred();
      return EditorBlurred;
  })();
  var EditorKeyDown = (function () {
      function EditorKeyDown(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      EditorKeyDown.create = function (value0) {
          return function (value1) {
              return new EditorKeyDown(value0, value1);
          };
      };
      return EditorKeyDown;
  })();
  var InputUpdated = (function () {
      function InputUpdated(value0) {
          this.value0 = value0;
      };
      InputUpdated.create = function (value0) {
          return new InputUpdated(value0);
      };
      return InputUpdated;
  })();
  var TermMouseEnter = (function () {
      function TermMouseEnter(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      TermMouseEnter.create = function (value0) {
          return function (value1) {
              return new TermMouseEnter(value0, value1);
          };
      };
      return TermMouseEnter;
  })();
  var TermMouseLeave = (function () {
      function TermMouseLeave(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      TermMouseLeave.create = function (value0) {
          return function (value1) {
              return new TermMouseLeave(value0, value1);
          };
      };
      return TermMouseLeave;
  })();
  var updatePendingContent = function (pendingContent) {
      return function (interaction) {
          if (interaction instanceof Disabled) {
              return Disabled.value;
          };
          if (interaction instanceof Read) {
              return Read.value;
          };
          if (interaction instanceof Write) {
              return new Write({
                  pendingContent: pendingContent,
                  parseErr: Data_Maybe.Nothing.value
              });
          };
          throw new Error("Failed pattern match at Component.Step (line 486, column 51 - line 489, column 57): " + [ interaction.constructor.name ]);
      };
  };
  var toStepPos = function (v) {
      var $52 = v.total === 1;
      if ($52) {
          return Only.value;
      };
      var $53 = v.current === 0;
      if ($53) {
          return First.value;
      };
      var $54 = v.current === (v.total - 1 | 0);
      if ($54) {
          return Last.value;
      };
      return Middle.value;
  };
  var toPendingContent = function (state) {
      if (state.interaction instanceof Disabled) {
          return Data_Maybe.Nothing.value;
      };
      if (state.interaction instanceof Read) {
          return Data_Maybe.Nothing.value;
      };
      if (state.interaction instanceof Write) {
          return new Data_Maybe.Just(state.interaction.value0.pendingContent);
      };
      throw new Error("Failed pattern match at Component.Step (line 573, column 26 - line 576, column 50): " + [ state.interaction.constructor.name ]);
  };
  var toEditorKey = function (v) {
      if (v === "Enter") {
          return new Data_Maybe.Just(EnterKey.value);
      };
      return Data_Maybe.Nothing.value;
  };
  var toEditorId = function (v) {
      return Data_Show.show(Data_Show.showInt)(v.stepIndex) + Term.getUuid(v.term);
  };
  var stopProp = (function () {
      var $171 = Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM);
      var $172 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff));
      return function ($173) {
          return $171($172(Web_Event_Event.stopPropagation(Web_UIEvent_MouseEvent.toEvent($173))));
      };
  })();
  var setEditorTextContent = Effect_Uncurried.runEffectFn2($foreign["_setEditorTextContent"]);
  var renderVar = function (v) {
      return Halogen_HTML_Elements.span([ Component_Util.className("variable") ])([ Halogen_HTML_Core.text(v.varName) ]);
  };
  var renderErrMsg = function (err) {
      var errMsg = Parse.errMsg(err);
      var col = (function (v) {
          return v.column;
      })(Parse.errPos(err));
      var pointer = Data_String_CodeUnits.fromCharArray(Data_Function.flip(Data_Array.snoc)("^")(Data_Array.replicate(col - 1 | 0)("-")));
      return Halogen_HTML_Elements.div([ Component_Util.className("error") ])([ Halogen_HTML_Elements.div([ Component_Util.className("error-pointer") ])([ Halogen_HTML_Core.text(pointer) ]), Halogen_HTML_Elements.div([ Component_Util.className("error-msg") ])([ Halogen_HTML_Core.text(errMsg) ]) ]);
  };
  var renderEditBtn = Halogen_HTML_Elements.button([ Component_Util.className("edit"), Halogen_HTML_Events.onClick(function ($174) {
      return Data_Maybe.Just.create(EditBtnClicked.create($174));
  }) ])([ Halogen_HTML_Core.text("edit") ]);
  var markEditorErrPos = Effect_Uncurried.runEffectFn2($foreign["_markEditorErrPos"]);
  var initState = function (i) {
      return {
          focus: i.focus,
          interaction: Read.value,
          reducedTermId: i.reducedTermId,
          stepIndex: i.stepIndex,
          stepPos: i.stepPos,
          term: i.term
      };
  };
  var highlightErrPos = function (termText) {
      return function (v) {
          var markText = Data_Maybe.maybe("")(Data_String_CodePoints.singleton)(Data_String_CodePoints.codePointAt(v.column - 1 | 0)(termText));
          var before = Data_String_CodePoints.take(v.column - 1 | 0)(termText);
          var after = Data_String_CodePoints.drop(v.column)(termText);
          return {
              before: before,
              markText: markText,
              after: after
          };
      };
  };
  var highlightClassName = function (v) {
      if (v instanceof Done) {
          return "done";
      };
      if (v instanceof Success) {
          return "success";
      };
      if (v instanceof Todo) {
          return "todo";
      };
      throw new Error("Failed pattern match at Component.Step (line 319, column 22 - line 322, column 17): " + [ v.constructor.name ]);
  };
  var termClassNames = function (v) {
      var base = [ "node" ];
      if (v.focus instanceof Data_Maybe.Just) {
          var $72 = v.focus.value0.termId === Term.getUuid(v.term);
          if ($72) {
              return Data_Array.cons(highlightClassName(v.focus.value0.highlight))(base);
          };
          return base;
      };
      if (v.focus instanceof Data_Maybe.Nothing) {
          return base;
      };
      throw new Error("Failed pattern match at Component.Step (line 308, column 34 - line 314, column 18): " + [ v.focus.constructor.name ]);
  };
  var handleTermMouseLeave = function (termId) {
      return function (event) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(stopProp(event))(function () {
              return Halogen_Query_HalogenM.raise(TermFocusOff.value);
          });
      };
  };
  var handleTermMouseEnter = function (termId) {
      return function (event) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(stopProp(event))(function () {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                  return Halogen_Query_HalogenM.raise(new TermFocusOn({
                      stepIndex: v.stepIndex,
                      termId: termId
                  }));
              });
          });
      };
  };
  var handleInputUpdated = function (i) {
      return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
          var $80 = {};
          for (var $81 in s) {
              if ({}.hasOwnProperty.call(s, $81)) {
                  $80[$81] = s[$81];
              };
          };
          $80.term = i.term;
          $80.stepIndex = i.stepIndex;
          $80.stepPos = i.stepPos;
          $80.focus = i.focus;
          $80.reducedTermId = i.reducedTermId;
          return $80;
      });
  };
  var handleEnableQuery = function (next) {
      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
          var $83 = {};
          for (var $84 in state) {
              if ({}.hasOwnProperty.call(state, $84)) {
                  $83[$84] = state[$84];
              };
          };
          $83.interaction = Read.value;
          return $83;
      }))(function () {
          return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(next));
      });
  };
  var handleDisableQuery = function (v) {
      return function (next) {
          var setDisabledState = Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
              var $88 = {};
              for (var $89 in state) {
                  if ({}.hasOwnProperty.call(state, $89)) {
                      $88[$89] = state[$89];
                  };
              };
              $88.interaction = Disabled.value;
              return $88;
          });
          var returnNext = Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(next));
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
              return v1.stepIndex;
          }))(function (v1) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(v1 !== v.stepIndex)(setDisabledState))(function () {
                  return returnNext;
              });
          });
      };
  };
  var handleQuery = function (v) {
      if (v instanceof Disable) {
          return handleDisableQuery({
              stepIndex: v.value0.stepIndex
          })(v.value1);
      };
      if (v instanceof Enable) {
          return handleEnableQuery(v.value0);
      };
      throw new Error("Failed pattern match at Component.Step (line 349, column 15 - line 351, column 40): " + [ v.constructor.name ]);
  };
  var handleApplyClicked = function (termId) {
      return function (event) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(stopProp(event))(function () {
              return Halogen_Query_HalogenM.raise(new Reduced(termId));
          });
      };
  };
  var getParseErr = function (v) {
      if (v instanceof Disabled) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Read) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Write) {
          return v.value0.parseErr;
      };
      throw new Error("Failed pattern match at Component.Step (line 480, column 15 - line 483, column 34): " + [ v.constructor.name ]);
  };
  var eqStepPos = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof First && y instanceof First) {
              return true;
          };
          if (x instanceof Last && y instanceof Last) {
              return true;
          };
          if (x instanceof Middle && y instanceof Middle) {
              return true;
          };
          if (x instanceof Only && y instanceof Only) {
              return true;
          };
          return false;
      };
  });
  var eqInteraction = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Disabled && y instanceof Disabled) {
              return true;
          };
          if (x instanceof Read && y instanceof Read) {
              return true;
          };
          if (x instanceof Write && y instanceof Write) {
              return Data_Eq.eq(Data_Maybe.eqMaybe(Text_Parsing_Parser.eqParseError))(x.value0.parseErr)(y.value0.parseErr) && x.value0.pendingContent === y.value0.pendingContent;
          };
          return false;
      };
  });
  var renderReadTermBody = function (ctx) {
      return function (state) {
          if (state.term instanceof Term.Var) {
              var varName = Term.indexToName(ctx)({
                  name: state.term.value0.varName,
                  index: state.term.value0.index
              });
              return renderVar({
                  varName: varName
              });
          };
          if (state.term instanceof Term.Fn) {
              var fresh = Term.pickFreshName(ctx)(state.term.value0.paramName);
              return renderFn(fresh.ctx)({
                  param: fresh.name,
                  body: state.term.value0.body
              })(state);
          };
          if (state.term instanceof Term.Apply) {
              return renderApply(ctx)({
                  fn: state.term.value0,
                  arg: state.term.value1
              })(state);
          };
          throw new Error("Failed pattern match at Component.Step (line 294, column 32 - line 305, column 56): " + [ state.term.constructor.name ]);
      };
  };
  var renderReadTerm = function (ctx) {
      return function (state) {
          var uuid = Term.getUuid(state.term);
          var handleMouseLeave = function (event) {
              return Data_Maybe.Just.create(new TermMouseLeave(uuid, event));
          };
          var handleMouseEnter = function (event) {
              return Data_Maybe.Just.create(new TermMouseEnter(uuid, event));
          };
          var handleClick = function (event) {
              var $114 = Term.isRedex(state.term);
              if ($114) {
                  return Data_Maybe.Just.create(new ApplyClicked(uuid, event));
              };
              return Data_Maybe.Nothing.value;
          };
          var cn = (function () {
              var $115 = Term.isRedex(state.term) && Data_Maybe.isNothing(state.focus);
              if ($115) {
                  return Data_Semigroup.append(Data_Semigroup.semigroupArray)(termClassNames(state))([ "redex" ]);
              };
              return termClassNames(state);
          })();
          var $116 = Data_Eq.eq(eqInteraction)(state.interaction)(Disabled.value);
          if ($116) {
              return Halogen_HTML_Elements.span([ Component_Util.className("disabled") ])([ renderReadTermBody(ctx)(state) ]);
          };
          return Halogen_HTML_Elements.span([ Component_Util.classNames(cn), Halogen_HTML_Events.onClick(handleClick), Halogen_HTML_Events.onMouseOver(handleMouseEnter), Halogen_HTML_Events.onMouseOut(handleMouseLeave) ])([ renderReadTermBody(ctx)(state) ]);
      };
  };
  var renderFn = function (ctx) {
      return function (v) {
          return function (state) {
              return Halogen_HTML_Elements.span([ Component_Util.className("lambda") ])([ Halogen_HTML_Core.text("\u03bb"), Halogen_HTML_Core.text(v.param + "."), renderReadTerm(ctx)({
                  focus: state.focus,
                  interaction: state.interaction,
                  reducedTermId: state.reducedTermId,
                  stepIndex: state.stepIndex,
                  stepPos: state.stepPos,
                  term: v.body
              }) ]);
          };
      };
  };
  var renderApply = function (ctx) {
      return function (v) {
          return function (v1) {
              return Halogen_HTML_Elements.span([ Component_Util.className("apply") ])([ Halogen_HTML_Core.text("("), renderReadTerm(ctx)({
                  focus: v1.focus,
                  interaction: v1.interaction,
                  reducedTermId: v1.reducedTermId,
                  stepIndex: v1.stepIndex,
                  stepPos: v1.stepPos,
                  term: v.fn
              }), Halogen_HTML_Core.text(" "), renderReadTerm(ctx)({
                  focus: v1.focus,
                  interaction: v1.interaction,
                  reducedTermId: v1.reducedTermId,
                  stepIndex: v1.stepIndex,
                  stepPos: v1.stepPos,
                  term: v.arg
              }), Halogen_HTML_Core.text(")") ]);
          };
      };
  };
  var renderReadStep = function (state) {
      return Halogen_HTML_Elements.div([  ])([ renderReadTerm(Data_Monoid.mempty(Data_Monoid.monoidArray))(state) ]);
  };
  var editorRef = "editor";
  var handleEditBtnClicked = function (event) {
      var setTextContent = function (textContent) {
          return function (node) {
              return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff))(Web_DOM_Node.setTextContent(textContent)(node));
          };
      };
      var setFocus = (function () {
          var $175 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff));
          return function ($176) {
              return $175(Component_Util.setFocus($176));
          };
      })();
      var setEditorWriteState = Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
          var $130 = {};
          for (var $131 in state) {
              if ({}.hasOwnProperty.call(state, $131)) {
                  $130[$131] = state[$131];
              };
          };
          $130.interaction = new Write({
              parseErr: Data_Maybe.Nothing.value,
              pendingContent: ""
          });
          return $130;
      });
      var setEditorFocus = Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(toEditorId))(setFocus);
      var setEditorContent = function (element) {
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
              return v.term;
          }))(function (v) {
              return setTextContent(Data_Show.show(Term.showTerm)(v))(Web_HTML_HTMLElement.toNode(element));
          });
      };
      var raiseEditorOpened = Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
          return v.stepIndex;
      }))(function (v) {
          return Halogen_Query_HalogenM.raise(new EditorOpened({
              stepIndex: v
          }));
      });
      var onEvent = function (name) {
          return function (handler) {
              return function (target) {
                  var eventSource = Halogen_Query_EventSource.eventListenerEventSource(Effect_Aff_Class.monadAffAff)(name)(target)(handler);
                  return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.subscribe(eventSource));
              };
          };
      };
      var handleInput = function ($177) {
          return Data_Maybe.Just.create(EditorContentChanged.create($177));
      };
      var handleBlur = function (v) {
          return new Data_Maybe.Just(EditorBlurred.value);
      };
      var getEditorElement = Halogen_Query.getHTMLElementRef(editorRef);
      var bindEditorEvents = function (element) {
          var eventTarget = Web_HTML_HTMLElement.toEventTarget(element);
          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(onEvent("blur")(handleBlur)(eventTarget))(function () {
              return onEvent("input")(handleInput)(eventTarget);
          });
      };
      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(stopProp(event))(function () {
          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(raiseEditorOpened)(function () {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(setEditorWriteState)(function () {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(getEditorElement)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (element) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(setEditorContent(element))(function () {
                          return bindEditorEvents(element);
                      });
                  })))(function () {
                      return setEditorFocus;
                  });
              });
          });
      });
  };
  var renderWriteTerm = function (v) {
      return function (state) {
          var termText = Data_Show.show(Term.showTerm)(state.term);
          var handleKeyDown = function (event) {
              var editorKey = toEditorKey(Web_UIEvent_KeyboardEvent.key(event));
              return Data_Maybe.Just.create(new EditorKeyDown(editorKey, event));
          };
          var errPos = Data_Functor.map(Data_Maybe.functorMaybe)(Parse.errPos)(v.parseErr);
          return Halogen_HTML_Elements.p([ Halogen_HTML_Properties.attr("contenteditable")("true"), Component_Util.className("editor"), Halogen_HTML_Events.onKeyDown(handleKeyDown), Halogen_HTML_Properties.id_(toEditorId(state)), Halogen_HTML_Properties.ref(editorRef) ])([ Halogen_HTML_Core.text("") ]);
      };
  };
  var renderEditStep = function (state) {
      if (state.interaction instanceof Disabled) {
          return Halogen_HTML_Elements.div([  ])([ renderReadTerm(Data_Monoid.mempty(Data_Monoid.monoidArray))(state) ]);
      };
      if (state.interaction instanceof Read) {
          return Halogen_HTML_Elements.div([  ])([ renderReadTerm(Data_Monoid.mempty(Data_Monoid.monoidArray))(state), renderEditBtn ]);
      };
      if (state.interaction instanceof Write) {
          var errMsg = Data_Maybe.maybe(Halogen_HTML_Core.text(""))(renderErrMsg)(state.interaction.value0.parseErr);
          return Halogen_HTML_Elements.div([  ])([ renderWriteTerm(state.interaction.value0)(state), errMsg ]);
      };
      throw new Error("Failed pattern match at Component.Step (line 176, column 24 - line 196, column 10): " + [ state.interaction.constructor.name ]);
  };
  var render = function (state) {
      if (state.stepPos instanceof First) {
          return renderEditStep(state);
      };
      if (state.stepPos instanceof Only) {
          return renderEditStep(state);
      };
      if (state.stepPos instanceof Middle) {
          return renderReadStep(state);
      };
      if (state.stepPos instanceof Last) {
          return renderReadStep(state);
      };
      throw new Error("Failed pattern match at Component.Step (line 162, column 16 - line 166, column 31): " + [ state.stepPos.constructor.name ]);
  };
  var deleteEditorContent = Effect_Uncurried.runEffectFn1($foreign["_deleteEditorContent"]);
  var handleEditorContentChanged = function (event) {
      var updateInteraction = function (content) {
          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
              var $141 = {};
              for (var $142 in state) {
                  if ({}.hasOwnProperty.call(state, $142)) {
                      $141[$142] = state[$142];
                  };
              };
              $141.interaction = updatePendingContent(content)(state.interaction);
              return $141;
          });
      };
      var removeHighlightIfErr = function (content) {
          return function (node) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function ($178) {
                  return getParseErr((function (v) {
                      return v.interaction;
                  })($178));
              }))(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (v) {
                  return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff))(setEditorTextContent(content)(node));
              }));
          };
      };
      var getPendingContent = (function () {
          var $179 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff));
          return function ($180) {
              return $179(Web_DOM_Node.textContent($180));
          };
      })();
      var getEditorNode = Control_Bind.bind(Data_Maybe.bindMaybe)(Web_Event_Event.target(event))(Web_DOM_Node.fromEventTarget);
      var deleteEditorContentIfEmpty = function (content) {
          return function (node) {
              var $144 = content === "";
              if ($144) {
                  return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff))(deleteEditorContent(node));
              };
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
          };
      };
      return Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (node) {
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(getPendingContent(node))(function (v) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(removeHighlightIfErr(v)(node))(function () {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(deleteEditorContentIfEmpty(v)(node))(function () {
                      return updateInteraction(v);
                  });
              });
          });
      })(getEditorNode);
  };
  var attemptCreateNewTerm = (function () {
      var showTerm = (function () {
          var $181 = Data_Show.show(Term.showTerm);
          return function ($182) {
              return $181((function (v) {
                  return v.term;
              })($182));
          };
      })();
      var setEditorErrState = function (pendingContent) {
          return function (err) {
              return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
                  var $146 = {};
                  for (var $147 in state) {
                      if ({}.hasOwnProperty.call(state, $147)) {
                          $146[$147] = state[$147];
                      };
                  };
                  $146.interaction = new Write({
                      parseErr: new Data_Maybe.Just(err),
                      pendingContent: pendingContent
                  });
                  return $146;
              });
          };
      };
      var raiseNewTerm = function (term) {
          return Halogen_Query_HalogenM.raise(new NewTerm({
              term: term
          }));
      };
      var raiseEditorClosed = Halogen_Query_HalogenM.raise(EditorClosed.value);
      var setEditorReadState = Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
          var $149 = {};
          for (var $150 in state) {
              if ({}.hasOwnProperty.call(state, $150)) {
                  $149[$150] = state[$150];
              };
          };
          $149.interaction = Read.value;
          return $149;
      }))(function () {
          return raiseEditorClosed;
      });
      var getPendingContent = Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(toPendingContent);
      var getEditorElement = Halogen_Query.getHTMLElementRef(editorRef);
      var markErrPosInEditor = function (pendingContent) {
          return function (err) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(getEditorElement)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (element) {
                  var parts = highlightErrPos(pendingContent)(Parse.errPos(err));
                  var node = Web_HTML_HTMLElement.toNode(element);
                  return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff))(markEditorErrPos(parts)(node));
              }));
          };
      };
      var getCurrentContent = Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(showTerm);
      var isValidContent = function ($$new) {
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(getCurrentContent)(function (v) {
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)($$new !== "" && $$new !== v);
          });
      };
      var attemptParseContent = function ($$new) {
          var v = Parse.parse($$new);
          if (v instanceof Data_Either.Left) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(setEditorErrState($$new)(v.value0))(function () {
                  return markErrPosInEditor($$new)(v.value0);
              });
          };
          if (v instanceof Data_Either.Right) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(raiseNewTerm(v.value0))(function () {
                  return setEditorReadState;
              });
          };
          throw new Error("Failed pattern match at Component.Step (line 441, column 29 - line 447, column 25): " + [ v.constructor.name ]);
      };
      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(getPendingContent)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function ($$new) {
          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(isValidContent($$new))(function (v) {
              if (v) {
                  return attemptParseContent($$new);
              };
              return setEditorReadState;
          });
      }));
  })();
  var handleEditorBlurred = attemptCreateNewTerm;
  var handleEditorKeyDown = function (editorKey) {
      return function (event) {
          var preventDefault = (function () {
              var $183 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff));
              return function ($184) {
                  return $183(Web_Event_Event.preventDefault(Web_UIEvent_KeyboardEvent.toEvent($184)));
              };
          })();
          return Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function (v) {
              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(preventDefault(event))(function () {
                  return attemptCreateNewTerm;
              });
          })(editorKey);
      };
  };
  var handleAction = function (v) {
      if (v instanceof InputUpdated) {
          return handleInputUpdated(v.value0);
      };
      if (v instanceof ApplyClicked) {
          return handleApplyClicked(v.value0)(v.value1);
      };
      if (v instanceof TermMouseEnter) {
          return handleTermMouseEnter(v.value0)(v.value1);
      };
      if (v instanceof TermMouseLeave) {
          return handleTermMouseLeave(v.value0)(v.value1);
      };
      if (v instanceof EditBtnClicked) {
          return handleEditBtnClicked(v.value0);
      };
      if (v instanceof EditorBlurred) {
          return handleEditorBlurred;
      };
      if (v instanceof EditorContentChanged) {
          return handleEditorContentChanged(v.value0);
      };
      if (v instanceof EditorKeyDown) {
          return handleEditorKeyDown(v.value0)(v.value1);
      };
      throw new Error("Failed pattern match at Component.Step (line 369, column 16 - line 380, column 59): " + [ v.constructor.name ]);
  };
  var component = Halogen_Component.mkComponent({
      initialState: initState,
      render: render,
      "eval": Halogen_Component.mkEval({
          handleAction: handleAction,
          handleQuery: handleQuery,
          receive: function ($185) {
              return Data_Maybe.Just.create(InputUpdated.create($185));
          },
          initialize: Halogen_Component.defaultEval.initialize,
          finalize: Halogen_Component.defaultEval.finalize
      })
  });
  exports["Last"] = Last;
  exports["Only"] = Only;
  exports["Reduced"] = Reduced;
  exports["EditorOpened"] = EditorOpened;
  exports["EditorClosed"] = EditorClosed;
  exports["NewTerm"] = NewTerm;
  exports["TermFocusOn"] = TermFocusOn;
  exports["TermFocusOff"] = TermFocusOff;
  exports["Done"] = Done;
  exports["Success"] = Success;
  exports["Todo"] = Todo;
  exports["Disable"] = Disable;
  exports["Enable"] = Enable;
  exports["component"] = component;
  exports["toStepPos"] = toStepPos;
  exports["eqStepPos"] = eqStepPos;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.HTML"] = $PS["Halogen.HTML"] || {};
  var exports = $PS["Halogen.HTML"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];        
  var slot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (component) {
                          return function (input) {
                              return function (outputQuery) {
                                  return Halogen_HTML_Core.widget(new Halogen_Component.ComponentSlot(Halogen_Component.componentSlot()(dictIsSymbol)(dictOrd)(label)(p)(component)(input)(outputQuery)));
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["slot"] = slot;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Component.Redexer"] = $PS["Component.Redexer"] || {};
  var exports = $PS["Component.Redexer"];
  var Component_Step = $PS["Component.Step"];
  var Component_Util = $PS["Component.Util"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Parse = $PS["Parse"];
  var Term = $PS["Term"];                
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var Reduced = (function () {
      function Reduced(value0) {
          this.value0 = value0;
      };
      Reduced.create = function (value0) {
          return new Reduced(value0);
      };
      return Reduced;
  })();
  var NewTerm = (function () {
      function NewTerm(value0) {
          this.value0 = value0;
      };
      NewTerm.create = function (value0) {
          return new NewTerm(value0);
      };
      return NewTerm;
  })();
  var CurrentTermChanged = (function () {
      function CurrentTermChanged(value0) {
          this.value0 = value0;
      };
      CurrentTermChanged.create = function (value0) {
          return new CurrentTermChanged(value0);
      };
      return CurrentTermChanged;
  })();
  var EditorOpened = (function () {
      function EditorOpened(value0) {
          this.value0 = value0;
      };
      EditorOpened.create = function (value0) {
          return new EditorOpened(value0);
      };
      return EditorOpened;
  })();
  var EditorClosed = (function () {
      function EditorClosed() {

      };
      EditorClosed.value = new EditorClosed();
      return EditorClosed;
  })();
  var initState = function (v) {
      return {
          currentTerm: Data_Maybe.Nothing.value,
          defaultContent: v.defaultContent,
          steps: Data_Monoid.mempty(Data_Monoid.monoidArray),
          parseErr: Data_Maybe.Nothing.value,
          reductionOrder: Data_Monoid.mempty(Data_Monoid.monoidArray),
          reductions: Data_Monoid.mempty(Data_Map_Internal.monoidMap(Data_Ord.ordString))
      };
  };
  var handleReduced = function (termId) {
      var updateState = function (v) {
          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
              var $34 = {};
              for (var $35 in s) {
                  if ({}.hasOwnProperty.call(s, $35)) {
                      $34[$35] = s[$35];
                  };
              };
              $34.steps = Data_Array.snoc(s.steps)(v.term);
              $34.currentTerm = Data_Maybe.Just.create({
                  stepIndex: Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(s.steps) - 1 | 0,
                  termId: termId
              });
              $34.reductionOrder = Data_Array.snoc(s.reductionOrder)(termId);
              $34.reductions = Data_Map_Internal.insert(Data_Ord.ordString)(termId)(Term.getUuid(v.step))(s.reductions);
              return $34;
          });
      };
      var $$return = Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
      var reduceLastStep = Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(Control_Bind.composeKleisli(Data_Maybe.bindMaybe)(function ($100) {
          return Data_Array.last((function (v) {
              return v.steps;
          })($100));
      })(Term.reduce(termId)));
      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(reduceLastStep)(function (v) {
          return Data_Maybe.maybe($$return)(updateState)(v);
      });
  };
  var handleNewTerm = function (term) {
      var genIds = (function () {
          var $101 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff));
          return function ($102) {
              return $101(Term.genIds($102));
          };
      })();
      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(genIds(term))(function (v) {
          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (state) {
              var $41 = {};
              for (var $42 in state) {
                  if ({}.hasOwnProperty.call(state, $42)) {
                      $41[$42] = state[$42];
                  };
              };
              $41.currentTerm = Data_Maybe.Nothing.value;
              $41.steps = Control_Applicative.pure(Control_Applicative.applicativeArray)(v);
              $41.parseErr = Data_Maybe.Nothing.value;
              $41.reductionOrder = Data_Monoid.mempty(Data_Monoid.monoidArray);
              $41.reductions = Data_Monoid.mempty(Data_Map_Internal.monoidMap(Data_Ord.ordString));
              return $41;
          });
      });
  };
  var handleMessage = function (v) {
      if (v instanceof Component_Step.Reduced) {
          return new Data_Maybe.Just(new Reduced(v.value0));
      };
      if (v instanceof Component_Step.EditorOpened) {
          return new Data_Maybe.Just(new EditorOpened({
              stepIndex: v.value0.stepIndex
          }));
      };
      if (v instanceof Component_Step.EditorClosed) {
          return new Data_Maybe.Just(EditorClosed.value);
      };
      if (v instanceof Component_Step.NewTerm) {
          return new Data_Maybe.Just(new NewTerm({
              term: v.value0.term
          }));
      };
      if (v instanceof Component_Step.TermFocusOn) {
          return Data_Maybe.Just.create(new CurrentTermChanged(new Data_Maybe.Just(v.value0)));
      };
      if (v instanceof Component_Step.TermFocusOff) {
          return Data_Maybe.Just.create(new CurrentTermChanged(Data_Maybe.Nothing.value));
      };
      throw new Error("Failed pattern match at Component.Redexer (line 161, column 17 - line 167, column 57): " + [ v.constructor.name ]);
  };
  var handleInitialize = (function () {
      var genIds = (function () {
          var $103 = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Effect_Aff.monadEffectAff));
          return function ($104) {
              return $103(Term.genIds($104));
          };
      })();
      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
          return v.defaultContent;
      }))(function (v) {
          var v1 = Parse.parse(v);
          if (v1 instanceof Data_Either.Left) {
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
          };
          if (v1 instanceof Data_Either.Right) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(genIds(v1.value0))(function (v2) {
                  return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                      var $55 = {};
                      for (var $56 in s) {
                          if ({}.hasOwnProperty.call(s, $56)) {
                              $55[$56] = s[$56];
                          };
                      };
                      $55.steps = Control_Applicative.pure(Control_Applicative.applicativeArray)(v2);
                      return $55;
                  });
              });
          };
          throw new Error("Failed pattern match at Component.Redexer (line 211, column 3 - line 215, column 43): " + [ v1.constructor.name ]);
      });
  })();
  var handleCurrentTerm = function (currentTerm) {
      return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
          var $59 = {};
          for (var $60 in s) {
              if ({}.hasOwnProperty.call(s, $60)) {
                  $59[$60] = s[$60];
              };
          };
          $59.currentTerm = currentTerm;
          return $59;
      });
  }; 
  var getTodoFocus = function (stepIndex) {
      return function (state) {
          var makeFocus = function (termId) {
              return {
                  termId: termId,
                  highlight: Component_Step.Todo.value
              };
          };
          return Control_Bind.bind(Data_Maybe.bindMaybe)(state.currentTerm)(function (v) {
              return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.steps)(stepIndex))(function (v1) {
                  return Control_Bind.bind(Data_Maybe.bindMaybe)(Term.findTerm(v.termId)(v1))(function (v2) {
                      return Control_Bind.bind(Data_Maybe.bindMaybe)(Term.nearestRedexAncestor(Term.getUuid(v2))(v1))(function (v3) {
                          var isReduced = Data_Maybe.isJust(Data_Array.index(state.reductionOrder)(stepIndex));
                          var $69 = stepIndex === v.stepIndex && !isReduced;
                          if ($69) {
                              return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(makeFocus(Term.getUuid(v3)));
                          };
                          return Data_Maybe.Nothing.value;
                      });
                  });
              });
          });
      };
  };
  var getSuccessFocus = function (stepIndex) {
      return function (state) {
          var makeFocus = function (termId) {
              return {
                  termId: termId,
                  highlight: Component_Step.Success.value
              };
          };
          return Control_Bind.bind(Data_Maybe.bindMaybe)(state.currentTerm)(function (v) {
              var $71 = v.stepIndex === stepIndex;
              if ($71) {
                  return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.steps)(stepIndex))(function (v1) {
                      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.reductionOrder)(stepIndex))(function (v2) {
                          return Control_Bind.bind(Data_Maybe.bindMaybe)(Term.findTerm(v2)(v1))(function (v3) {
                              var $75 = Term.isDescendantOf(v.termId)(v3);
                              if ($75) {
                                  return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(makeFocus(v2));
                              };
                              return Data_Maybe.Nothing.value;
                          });
                      });
                  });
              };
              var $76 = (v.stepIndex + 1 | 0) === stepIndex;
              if ($76) {
                  return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.steps)(v.stepIndex))(function (v1) {
                      return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.reductionOrder)(v.stepIndex))(function (v2) {
                          return Control_Bind.bind(Data_Maybe.bindMaybe)(Term.findTerm(v2)(v1))(function (v3) {
                              return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(Data_Ord.ordString)(v2)(state.reductions))(function (v4) {
                                  var $81 = Term.isDescendantOf(v.termId)(v3);
                                  if ($81) {
                                      return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(makeFocus(v4));
                                  };
                                  return Data_Maybe.Nothing.value;
                              });
                          });
                      });
                  });
              };
              return Data_Maybe.Nothing.value;
          });
      };
  };
  var getDoneFocus = function (stepIndex) {
      return function (state) {
          return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.index(state.reductionOrder)(stepIndex))(function (v) {
              return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                  termId: v,
                  highlight: Component_Step.Done.value
              });
          });
      };
  };
  var getStepFocus = function (v) {
      return function (state) {
          return Control_Alt.alt(Data_Maybe.altMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(getSuccessFocus(v.stepIndex)(state))(getTodoFocus(v.stepIndex)(state)))(getDoneFocus(v.stepIndex)(state));
      };
  };
  var _stepSlot = Data_Symbol.SProxy.value;
  var handleEditorClosed = Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.queryAll()(new Data_Symbol.IsSymbol(function () {
      return "stepSlot";
  }))(Data_Ord.ordInt)(_stepSlot)(Halogen_Query.tell(Component_Step.Enable.create)));
  var handleEditorOpened = function (stepIndex) {
      return Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.queryAll()(new Data_Symbol.IsSymbol(function () {
          return "stepSlot";
      }))(Data_Ord.ordInt)(_stepSlot)(Halogen_Query.tell(Component_Step.Disable.create({
          stepIndex: stepIndex
      }))));
  };
  var handleAction = function (action) {
      if (action instanceof Initialize) {
          return handleInitialize;
      };
      if (action instanceof Reduced) {
          return handleReduced(action.value0);
      };
      if (action instanceof EditorOpened) {
          return handleEditorOpened(action.value0.stepIndex);
      };
      if (action instanceof EditorClosed) {
          return handleEditorClosed;
      };
      if (action instanceof NewTerm) {
          return handleNewTerm(action.value0.term);
      };
      if (action instanceof CurrentTermChanged) {
          return handleCurrentTerm(action.value0);
      };
      throw new Error("Failed pattern match at Component.Redexer (line 170, column 23 - line 176, column 66): " + [ action.constructor.name ]);
  };
  var renderStep = function (v) {
      return function (i) {
          return function (term) {
              var stepPos = Component_Step.toStepPos({
                  current: i,
                  total: Data_Foldable.length(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(v.steps)
              });
              var stepInput = {
                  term: term,
                  focus: getStepFocus({
                      stepIndex: i
                  })(v),
                  stepIndex: i,
                  stepPos: stepPos,
                  reducedTermId: Data_Array.index(v.reductionOrder)(i)
              };
              var cn = [ {
                  name: "step",
                  cond: true
              }, {
                  name: "last",
                  cond: Data_Eq.eq(Component_Step.eqStepPos)(stepPos)(Component_Step.Last.value) || Data_Eq.eq(Component_Step.eqStepPos)(stepPos)(Component_Step.Only.value)
              } ];
              return Halogen_HTML_Elements.div([ Component_Util.classNames_(cn) ])([ Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "stepSlot";
              }))(Data_Ord.ordInt)(_stepSlot)(i)(Component_Step.component)(stepInput)(handleMessage) ]);
          };
      };
  };
  var render = function (state) {
      return Halogen_HTML_Elements.div([ Component_Util.className("stepper") ])(Data_Array.mapWithIndex(renderStep(state))(state.steps));
  };
  var component = Halogen_Component.mkComponent({
      "eval": Halogen_Component.mkEval({
          handleAction: handleAction,
          handleQuery: Halogen_Component.defaultEval.handleQuery,
          receive: Halogen_Component.defaultEval.receive,
          initialize: new Data_Maybe.Just(Initialize.value),
          finalize: Halogen_Component.defaultEval.finalize
      }),
      initialState: initState,
      render: render
  });
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];                        
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var singleton = (function () {
      var $168 = Data_NonEmpty.singleton(Data_List_Types.plusList);
      return function ($169) {
          return Data_List_Types.NonEmptyList($168($169));
      };
  })();
  var cons = function (y) {
      return function (v) {
          return new Data_NonEmpty.NonEmpty(y, new Data_List_Types.Cons(v.value0, v.value1));
      };
  };
  exports["singleton"] = singleton;
  exports["cons"] = cons;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Applicative.Free"] = $PS["Control.Applicative.Free"] || {};
  var exports = $PS["Control.Applicative.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Pure = (function () {
      function Pure(value0) {
          this.value0 = value0;
      };
      Pure.create = function (value0) {
          return new Pure(value0);
      };
      return Pure;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var Ap = (function () {
      function Ap(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Ap.create = function (value0) {
          return function (value1) {
              return new Ap(value0, value1);
          };
      };
      return Ap;
  })();
  var mkAp = function (fba) {
      return function (fb) {
          return new Ap(fba, fb);
      };
  };
  var liftFreeAp = Lift.create;
  var goLeft = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_valStack) {
              return function ($copy_nat) {
                  return function ($copy_func) {
                      return function ($copy_count) {
                          var $tco_var_dictApplicative = $copy_dictApplicative;
                          var $tco_var_fStack = $copy_fStack;
                          var $tco_var_valStack = $copy_valStack;
                          var $tco_var_nat = $copy_nat;
                          var $tco_var_func = $copy_func;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(dictApplicative, fStack, valStack, nat, func, count) {
                              if (func instanceof Pure) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: Control_Applicative.pure(dictApplicative)(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Lift) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: nat(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Ap) {
                                  $tco_var_dictApplicative = dictApplicative;
                                  $tco_var_fStack = fStack;
                                  $tco_var_valStack = Data_List_NonEmpty.cons(func.value1)(valStack);
                                  $tco_var_nat = nat;
                                  $tco_var_func = func.value0;
                                  $copy_count = count + 1 | 0;
                                  return;
                              };
                              throw new Error("Failed pattern match at Control.Applicative.Free (line 102, column 41 - line 105, column 81): " + [ func.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_valStack, $tco_var_nat, $tco_var_func, $copy_count);
                          };
                          return $tco_result;
                      };
                  };
              };
          };
      };
  };
  var goApply = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_vals) {
              return function ($copy_gVal) {
                  var $tco_var_dictApplicative = $copy_dictApplicative;
                  var $tco_var_fStack = $copy_fStack;
                  var $tco_var_vals = $copy_vals;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(dictApplicative, fStack, vals, gVal) {
                      if (fStack instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return new Data_Either.Left(gVal);
                      };
                      if (fStack instanceof Data_List_Types.Cons) {
                          var gRes = Control_Apply.apply(dictApplicative.Apply0())(fStack.value0.func)(gVal);
                          var $14 = fStack.value0.count === 1;
                          if ($14) {
                              if (fStack.value1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return new Data_Either.Left(gRes);
                              };
                              $tco_var_dictApplicative = dictApplicative;
                              $tco_var_fStack = fStack.value1;
                              $tco_var_vals = vals;
                              $copy_gVal = gRes;
                              return;
                          };
                          if (vals instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Data_Either.Left(gRes);
                          };
                          if (vals instanceof Data_List_Types.Cons) {
                              $tco_done = true;
                              return Data_Either.Right.create(new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                  func: gRes,
                                  count: fStack.value0.count - 1 | 0
                              }, fStack.value1), new Data_NonEmpty.NonEmpty(vals.value0, vals.value1)));
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 83, column 11 - line 88, column 50): " + [ vals.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 72, column 3 - line 88, column 50): " + [ fStack.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_vals, $copy_gVal);
                  };
                  return $tco_result;
              };
          };
      };
  };
  var functorFreeAp = new Data_Functor.Functor(function (f) {
      return function (x) {
          return mkAp(new Pure(f))(x);
      };
  });
  var foldFreeAp = function (dictApplicative) {
      return function (nat) {
          return function (z) {
              var go = function ($copy_v) {
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(v) {
                      if (v.value1.value0 instanceof Pure) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(Control_Applicative.pure(dictApplicative)(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 54, column 17 - line 56, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Lift) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(nat(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 57, column 17 - line 59, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Ap) {
                          var nextVals = new Data_NonEmpty.NonEmpty(v.value1.value0.value1, v.value1.value1);
                          $copy_v = goLeft(dictApplicative)(v.value0)(nextVals)(nat)(v.value1.value0.value0)(1);
                          return;
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 53, column 5 - line 62, column 47): " + [ v.value1.value0.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($copy_v);
                  };
                  return $tco_result;
              };
              return go(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_NonEmpty.singleton(z)));
          };
      };
  };
  var retractFreeAp = function (dictApplicative) {
      return foldFreeAp(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  };
  var applyFreeAp = new Control_Apply.Apply(function () {
      return functorFreeAp;
  }, function (fba) {
      return function (fb) {
          return mkAp(fba)(fb);
      };
  });
  var applicativeFreeAp = new Control_Applicative.Applicative(function () {
      return applyFreeAp;
  }, Pure.create);
  var hoistFreeAp = function (f) {
      return foldFreeAp(applicativeFreeAp)(function ($37) {
          return liftFreeAp(f($37));
      });
  };
  exports["retractFreeAp"] = retractFreeAp;
  exports["hoistFreeAp"] = hoistFreeAp;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Monad.Fork.Class"] = $PS["Control.Monad.Fork.Class"] || {};
  var exports = $PS["Control.Monad.Fork.Class"];
  var Effect_Aff = $PS["Effect.Aff"];
  var MonadFork = function (Functor1, Monad0, fork, join, suspend) {
      this.Functor1 = Functor1;
      this.Monad0 = Monad0;
      this.fork = fork;
      this.join = join;
      this.suspend = suspend;
  };
  var monadForkAff = new MonadFork(function () {
      return Effect_Aff.functorFiber;
  }, function () {
      return Effect_Aff.monadAff;
  }, Effect_Aff.forkAff, Effect_Aff.joinFiber, Effect_Aff.suspendAff);
  var fork = function (dict) {
      return dict.fork;
  };
  exports["fork"] = fork;
  exports["monadForkAff"] = monadForkAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Control.Parallel"] = $PS["Control.Parallel"] || {};
  var exports = $PS["Control.Parallel"];
  var Control_Category = $PS["Control.Category"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Foldable = $PS["Data.Foldable"];                      
  var parTraverse_ = function (dictParallel) {
      return function (dictFoldable) {
          return function (f) {
              var $17 = Control_Parallel_Class.sequential(dictParallel);
              var $18 = Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)((function () {
                  var $20 = Control_Parallel_Class.parallel(dictParallel);
                  return function ($21) {
                      return $20(f($21));
                  };
              })());
              return function ($19) {
                  return $17($18($19));
              };
          };
      };
  };
  var parSequence_ = function (dictParallel) {
      return function (dictFoldable) {
          return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["parSequence_"] = parSequence_;
})(PS);
(function(exports) {
  "use strict";

  exports.warn = function (s) {
    return function () {
      console.warn(s);
      return {};
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  exports["warn"] = $foreign.warn;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Aff.Driver.State"] = $PS["Halogen.Aff.Driver.State"] || {};
  var exports = $PS["Halogen.Aff.Driver.State"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var unDriverStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX_ = function (dictApplicative) {
      return function (f) {
          return unDriverStateX(function (st) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Foldable.foldableMaybe)(f)(st.rendering);
          });
      };
  };
  var mkRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX = function (dictFunctor) {
      return function (f) {
          return unDriverStateX(function (st) {
              return mkRenderStateX(f(st.rendering));
          });
      };
  };
  var mkDriverStateXRef = Unsafe_Coerce.unsafeCoerce;
  var mapDriverState = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var initDriverState = function (component) {
      return function (input) {
          return function (handler) {
              return function (lchs) {
                  return function __do() {
                      var v = Effect_Ref["new"]({})();
                      var v1 = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var v2 = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var v3 = Effect_Ref["new"](handler)();
                      var v4 = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var v5 = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var v6 = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                      var v7 = Effect_Ref["new"](1)();
                      var v8 = Effect_Ref["new"](new Data_Maybe.Just(Data_Map_Internal.empty))();
                      var v9 = Effect_Ref["new"](Data_Map_Internal.empty)();
                      var ds = {
                          component: component,
                          state: component.initialState(input),
                          refs: Data_Map_Internal.empty,
                          children: Halogen_Data_Slot.empty,
                          childrenIn: v1,
                          childrenOut: v2,
                          selfRef: v,
                          handlerRef: v3,
                          pendingQueries: v4,
                          pendingOuts: v5,
                          pendingHandlers: v6,
                          rendering: Data_Maybe.Nothing.value,
                          fresh: v7,
                          subscriptions: v8,
                          forks: v9,
                          lifecycleHandlers: lchs
                      };
                      Effect_Ref.write(ds)(v)();
                      return mkDriverStateXRef(v);
                  };
              };
          };
      };
  };
  exports["mapDriverState"] = mapDriverState;
  exports["unDriverStateX"] = unDriverStateX;
  exports["renderStateX"] = renderStateX;
  exports["renderStateX_"] = renderStateX_;
  exports["unRenderStateX"] = unRenderStateX;
  exports["initDriverState"] = initDriverState;
})(PS);
(function(exports) {
  "use strict";

  exports.reallyUnsafeRefEq = function (a) {
    return function (b) {
      return a === b;
    };
  };
})(PS["Unsafe.Reference"] = PS["Unsafe.Reference"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Unsafe.Reference"] = $PS["Unsafe.Reference"] || {};
  var exports = $PS["Unsafe.Reference"];
  var $foreign = $PS["Unsafe.Reference"];
  var unsafeRefEq = $foreign.reallyUnsafeRefEq;
  exports["unsafeRefEq"] = unsafeRefEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Aff.Driver.Eval"] = $PS["Halogen.Aff.Driver.Eval"] || {};
  var exports = $PS["Halogen.Aff.Driver.Eval"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Coroutine = $PS["Control.Coroutine"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_Free_Trans = $PS["Control.Monad.Free.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];
  var Halogen_Query_EventSource = $PS["Halogen.Query.EventSource"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];                
  var unsubscribe = function (sid) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v.subscriptions)))(function (v1) {
                  return Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Halogen_Query_EventSource.finalize)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(v1));
              });
          });
      };
  };
  var queueOrRun = function (ref) {
      return function (au) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return au;
              };
              if (v instanceof Data_Maybe.Just) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write(new Data_Maybe.Just(new Data_List_Types.Cons(au, v.value0)))(ref));
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 195, column 33 - line 197, column 57): " + [ v.constructor.name ]);
          });
      };
  };
  var handleLifecycle = function (lchs) {
      return function (f) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
              initializers: Data_List_Types.Nil.value,
              finalizers: Data_List_Types.Nil.value
          })(lchs)))(function () {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(function (v) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(lchs)))(function (v1) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(v1.finalizers))(function () {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(v1.initializers))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v);
                          });
                      });
                  });
              });
          });
      };
  };
  var fresh = function (f) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["modify'"](function (i) {
                  return {
                      state: i + 1 | 0,
                      value: f(i)
                  };
              })(v.fresh));
          });
      };
  };
  var evalQ = function (render) {
      return function (ref) {
          return function (q) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
                  return evalM(render)(ref)(v["component"]["eval"](new Halogen_Query_HalogenQ.Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(Data_Maybe.Just.create)(Data_Coyoneda.liftCoyoneda(q)), Data_Function["const"](Data_Maybe.Nothing.value))));
              });
          };
      };
  };
  var evalM = function (render) {
      return function (initRef) {
          return function (v) {
              var evalChildQuery = function (ref) {
                  return function (cqb) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                          return Halogen_Query_ChildQuery.unChildQueryBox(function (v2) {
                              var evalChild = function (v3) {
                                  return Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3)))(function (v4) {
                                      return Halogen_Aff_Driver_State.unDriverStateX(function (ds) {
                                          return evalQ(render)(ds.selfRef)(v2.value1);
                                      })(v4);
                                  }));
                              };
                              return Data_Functor.map(Effect_Aff.functorAff)(v2.value2)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(v2.value0(Effect_Aff.applicativeParAff)(evalChild)(v1.children)));
                          })(cqb);
                      });
                  };
              };
              var go = function (ref) {
                  return function (v1) {
                      if (v1 instanceof Halogen_Query_HalogenM.State) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              var v3 = v1.value0(v2.state);
                              if (Unsafe_Reference.unsafeRefEq(v2.state)(v3.value1)) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                              };
                              if (Data_Boolean.otherwise) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
                                      component: v2.component,
                                      state: v3.value1,
                                      refs: v2.refs,
                                      children: v2.children,
                                      childrenIn: v2.childrenIn,
                                      childrenOut: v2.childrenOut,
                                      selfRef: v2.selfRef,
                                      handlerRef: v2.handlerRef,
                                      pendingQueries: v2.pendingQueries,
                                      pendingOuts: v2.pendingOuts,
                                      pendingHandlers: v2.pendingHandlers,
                                      rendering: v2.rendering,
                                      fresh: v2.fresh,
                                      subscriptions: v2.subscriptions,
                                      forks: v2.forks,
                                      lifecycleHandlers: v2.lifecycleHandlers
                                  })(ref)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleLifecycle(v2.lifecycleHandlers)(render(v2.lifecycleHandlers)(ref)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                                      });
                                  });
                              };
                              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 85, column 7 - line 91, column 21): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Subscribe) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.SubscriptionId)(ref))(function (v2) {
                              var v3 = v1.value0(v2);
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v4) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Control_Bind.bind(Effect_Aff.bindAff)(v3)(function (v5) {
                                      var done = Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v4.subscriptions)))(function (v6) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordSubscriptionId)(v2)))(v4.subscriptions)))(function () {
                                              return Control_Applicative.when(Effect_Aff.applicativeAff)(Data_Maybe.maybe(false)(Data_Map_Internal.member(Halogen_Query_HalogenM.ordSubscriptionId)(v2))(v6))(Halogen_Query_EventSource.finalize(v5.finalizer));
                                          });
                                      });
                                      var consumer = Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Control_Coroutine["await"](Effect_Aff.monadAff))(function (v6) {
                                          return Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(Control_Coroutine.functorAwait))(Effect_Aff.monadAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v4.subscriptions))))(function (v7) {
                                              return Control_Applicative.when(Control_Monad_Free_Trans.applicativeFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.member(Halogen_Query_HalogenM.ordSubscriptionId)(v2))(v7))(new Data_Maybe.Just(true)))(Control_Bind.bind(Control_Monad_Free_Trans.bindFreeT(Control_Coroutine.functorAwait)(Effect_Aff.monadAff))(Control_Monad_Trans_Class.lift(Control_Monad_Free_Trans.monadTransFreeT(Control_Coroutine.functorAwait))(Effect_Aff.monadAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(evalF(render)(ref)(new Halogen_Query_Input.Action(v6)))))(function (v8) {
                                                  return consumer;
                                              }));
                                          });
                                      });
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordSubscriptionId)(v2)(done)))(v4.subscriptions)))(function () {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Coroutine.runProcess(Effect_Aff.monadRecAff)(Control_Coroutine.pullFrom(Effect_Aff.monadRecAff)(consumer)(v5.producer)))(function () {
                                              return Halogen_Query_EventSource.finalize(done);
                                          });
                                      });
                                  })))(function (v5) {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(v2));
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Unsubscribe) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(unsubscribe(v1.value0)(ref))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Lift) {
                          return v1.value0;
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.ChildQuery) {
                          return evalChildQuery(ref)(v1.value0);
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Raise) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.handlerRef)))(function (v3) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(queueOrRun(v2.pendingOuts)(v3(v1.value0)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Par) {
                          return Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Control_Applicative_Free.retractFreeAp(Effect_Aff.applicativeParAff)(Control_Applicative_Free.hoistFreeAp((function () {
                              var $111 = Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
                              var $112 = evalM(render)(ref);
                              return function ($113) {
                                  return $111($112($113));
                              };
                          })())(v1.value0)));
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Fork) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.ForkId)(ref))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v3) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (v4) {
                                      return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Effect_Aff["finally"](Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                          Effect_Ref.modify_(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordForkId)(v2))(v3.forks)();
                                          return Effect_Ref.write(true)(v4)();
                                      }))(evalM(render)(ref)(v1.value0))))(function (v5) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Monad.unlessM(Effect.monadEffect)(Effect_Ref.read(v4))(Effect_Ref.modify_(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordForkId)(v2)(v5))(v3.forks))))(function () {
                                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(v2));
                                          });
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Kill) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.forks)))(function (v3) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Effect_Aff.killFiber(Effect_Exception.error("Cancelled")))(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordForkId)(v1.value0)(v3)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.GetRef) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(Data_Map_Internal.lookup(Data_Ord.ordString)(v1.value0)(v2.refs)));
                          });
                      };
                      throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 82, column 12 - line 146, column 33): " + [ v1.constructor.name ]);
                  };
              };
              return Control_Monad_Free.foldFree(Effect_Aff.monadRecAff)(go(initRef))(v);
          };
      };
  };
  var evalF = function (render) {
      return function (ref) {
          return function (v) {
              if (v instanceof Halogen_Query_Input.RefUpdate) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Function.flip(Effect_Ref.modify_)(ref)(Halogen_Aff_Driver_State.mapDriverState(function (st) {
                      return {
                          component: st.component,
                          state: st.state,
                          refs: Data_Map_Internal.alter(Data_Ord.ordString)(Data_Function["const"](v.value1))(v.value0)(st.refs),
                          children: st.children,
                          childrenIn: st.childrenIn,
                          childrenOut: st.childrenOut,
                          selfRef: st.selfRef,
                          handlerRef: st.handlerRef,
                          pendingQueries: st.pendingQueries,
                          pendingOuts: st.pendingOuts,
                          pendingHandlers: st.pendingHandlers,
                          rendering: st.rendering,
                          fresh: st.fresh,
                          subscriptions: st.subscriptions,
                          forks: st.forks,
                          lifecycleHandlers: st.lifecycleHandlers
                      };
                  })));
              };
              if (v instanceof Halogen_Query_Input.Action) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                      return evalM(render)(ref)(v1["component"]["eval"](new Halogen_Query_HalogenQ.Action(v.value0, Data_Unit.unit)));
                  });
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 51, column 20 - line 57, column 62): " + [ v.constructor.name ]);
          };
      };
  };
  exports["evalF"] = evalF;
  exports["evalQ"] = evalQ;
  exports["evalM"] = evalM;
  exports["handleLifecycle"] = handleLifecycle;
  exports["queueOrRun"] = queueOrRun;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Aff.Driver"] = $PS["Halogen.Aff.Driver"] || {};
  var exports = $PS["Halogen.Aff.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Coroutine = $PS["Control.Coroutine"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_AVar = $PS["Effect.Aff.AVar"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_Eval = $PS["Halogen.Aff.Driver.Eval"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_EventSource = $PS["Halogen.Query.EventSource"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];                
  var newLifecycleHandlers = Effect_Ref["new"]({
      initializers: Data_List_Types.Nil.value,
      finalizers: Data_List_Types.Nil.value
  });
  var handleAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var handlePending = function (ref) {
      return function __do() {
          var v = Effect_Ref.read(ref)();
          Effect_Ref.write(Data_Maybe.Nothing.value)(ref)();
          return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v)((function () {
              var $78 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
              return function ($79) {
                  return handleAff($78(Data_List.reverse($79)));
              };
          })())();
      };
  };
  var cleanupSubscriptionsAndForks = function (v) {
      return function __do() {
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
              var $80 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Map_Internal.foldableMap)((function () {
                  var $82 = Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff);
                  return function ($83) {
                      return $82(Halogen_Query_EventSource.finalize($83));
                  };
              })());
              return function ($81) {
                  return handleAff($80($81));
              };
          })()))(Effect_Ref.read(v.subscriptions))();
          Effect_Ref.write(Data_Maybe.Nothing.value)(v.subscriptions)();
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
              var $84 = Effect_Aff.killFiber(Effect_Exception.error("finalized"));
              return function ($85) {
                  return handleAff($84($85));
              };
          })()))(Effect_Ref.read(v.forks))();
          return Effect_Ref.write(Data_Map_Internal.empty)(v.forks)();
      };
  };
  var runUI = function (renderSpec) {
      return function (component) {
          return function (i) {
              var subscribe = function (fresh) {
                  return function (ref) {
                      return function (consumer) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff_AVar.empty)(function (v) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                  var v1 = Effect_Ref.read(fresh)();
                                  Effect_Ref.modify_(function (v2) {
                                      return v2 + 1 | 0;
                                  })(fresh)();
                                  Effect_Ref.modify_(Data_Map_Internal.insert(Data_Ord.ordInt)(v1)(v))(ref)();
                                  return v1;
                              }))(function (v1) {
                                  var producer = Control_Coroutine.producer(Effect_Aff.monadAff)(Data_Functor.map(Effect_Aff.functorAff)(Data_Either.either(Data_Function["const"](new Data_Either.Right(Data_Unit.unit)))(Data_Either.Left.create))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Effect_Aff_AVar.take(v))));
                                  return Data_Functor["void"](Effect_Aff.functorAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Coroutine.runProcess(Effect_Aff.monadRecAff)(Control_Coroutine.connect(Effect_Aff.monadRecAff)(Effect_Aff.parallelAff)(producer)(consumer)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Map_Internal["delete"](Data_Ord.ordInt)(v1))(ref)))(function () {
                                          return Effect_Aff_AVar.kill(Effect_Exception.error("ended"))(v);
                                      });
                                  })));
                              });
                          });
                      };
                  };
              };
              var rootHandler = function (ref) {
                  return function (message) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
                          return Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Map_Internal.foldableMap)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(Data_Functor.map(Data_Map_Internal.functorMap)(Effect_Aff_AVar.put(message))(v));
                      });
                  };
              };
              var squashChildInitializers = function (lchs) {
                  return function (preInits) {
                      return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                          var parentInitializer = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Initialize(Data_Unit.unit)));
                          return Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: new Data_List_Types.Cons(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(Data_List.reverse(handlers.initializers)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(parentInitializer)(function () {
                                          return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                              handlePending(st.pendingQueries)();
                                              return handlePending(st.pendingOuts)();
                                          });
                                      });
                                  }), preInits),
                                  finalizers: handlers.finalizers
                              };
                          })(lchs);
                      });
                  };
              };
              var runComponent = function (lchs) {
                  return function (handler) {
                      return function (j) {
                          return Halogen_Component.unComponent(function (c) {
                              return function __do() {
                                  var v = newLifecycleHandlers();
                                  var v1 = Halogen_Aff_Driver_State.initDriverState(c)(j)(handler)(v)();
                                  var v2 = Effect_Ref.read(lchs)();
                                  Effect_Ref.write({
                                      initializers: Data_List_Types.Nil.value,
                                      finalizers: v2.finalizers
                                  })(lchs)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $86 = render(lchs);
                                      return function ($87) {
                                          return $86((function (v3) {
                                              return v3.selfRef;
                                          })($87));
                                      };
                                  })()))(Effect_Ref.read(v1))();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(squashChildInitializers(lchs)(v2.initializers))(Effect_Ref.read(v1))();
                                  return v1;
                              };
                          });
                      };
                  };
              };
              var renderChild = function (lchs) {
                  return function (handler) {
                      return function (childrenInRef) {
                          return function (childrenOutRef) {
                              return Halogen_Component.unComponentSlot(function (slot) {
                                  return function __do() {
                                      var v = Data_Functor.map(Effect.functorEffect)(slot.pop)(Effect_Ref.read(childrenInRef))();
                                      var v1 = (function () {
                                          if (v instanceof Data_Maybe.Just) {
                                              Effect_Ref.write(v.value0.value1)(childrenInRef)();
                                              var v1 = Effect_Ref.read(v.value0.value0)();
                                              Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                                  return function __do() {
                                                      Data_Function.flip(Effect_Ref.write)(st.handlerRef)((function () {
                                                          var $88 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                          return function ($89) {
                                                              return $88(slot.output($89));
                                                          };
                                                      })())();
                                                      return handleAff(Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](slot.input)))();
                                                  };
                                              })(v1)();
                                              return v.value0.value0;
                                          };
                                          if (v instanceof Data_Maybe.Nothing) {
                                              if (slot.input instanceof Halogen_Query_HalogenQ.Receive) {
                                                  return runComponent(lchs)((function () {
                                                      var $90 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                      return function ($91) {
                                                          return $90(slot.output($91));
                                                      };
                                                  })())(slot.input.value0)(slot.component)();
                                              };
                                              return Effect_Exception["throw"]("Halogen internal error: slot input was not a Receive query")();
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 236, column 14 - line 249, column 81): " + [ v.constructor.name ]);
                                      })();
                                      var v2 = Data_Functor.map(Effect.functorEffect)(function ($92) {
                                          return Data_Maybe.isJust(slot.get($92));
                                      })(Effect_Ref.read(childrenOutRef))();
                                      Control_Applicative.when(Effect.applicativeEffect)(v2)(Effect_Console.warn("Halogen: Duplicate slot address was detected during rendering, unexpected results may occur"))();
                                      Effect_Ref.modify_(slot.set(v1))(childrenOutRef)();
                                      return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(v1))(Halogen_Aff_Driver_State.renderStateX(Effect.functorEffect)(function (v3) {
                                          if (v3 instanceof Data_Maybe.Nothing) {
                                              return Effect_Exception["throw"]("Halogen internal error: child was not initialized in renderChild");
                                          };
                                          if (v3 instanceof Data_Maybe.Just) {
                                              return Control_Applicative.pure(Effect.applicativeEffect)(renderSpec.renderChild(v3.value0));
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 254, column 37 - line 256, column 50): " + [ v3.constructor.name ]);
                                      }))();
                                  };
                              });
                          };
                      };
                  };
              };
              var render = function (lchs) {
                  return function ($$var) {
                      return function __do() {
                          var v = Effect_Ref.read($$var)();
                          var v1 = Data_Functor.map(Effect.functorEffect)(Data_Maybe.isNothing)(Effect_Ref.read(v.pendingHandlers))();
                          Control_Applicative.when(Effect.applicativeEffect)(v1)(Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(v.pendingHandlers))();
                          Effect_Ref.write(Halogen_Data_Slot.empty)(v.childrenOut)();
                          Effect_Ref.write(v.children)(v.childrenIn)();
                          var selfRef = Control_Category.identity(Control_Category.categoryFn)(v.selfRef);
                          var pendingQueries = Control_Category.identity(Control_Category.categoryFn)(v.pendingQueries);
                          var pendingHandlers = Control_Category.identity(Control_Category.categoryFn)(v.pendingHandlers);
                          var handler = (function () {
                              var $93 = Halogen_Aff_Driver_Eval.queueOrRun(pendingHandlers);
                              var $94 = Data_Functor["void"](Effect_Aff.functorAff);
                              var $95 = Halogen_Aff_Driver_Eval.evalF(render)(selfRef);
                              return function ($96) {
                                  return $93($94($95($96)));
                              };
                          })();
                          var childHandler = (function () {
                              var $97 = Halogen_Aff_Driver_Eval.queueOrRun(pendingQueries);
                              return function ($98) {
                                  return $97(handler(Halogen_Query_Input.Action.create($98)));
                              };
                          })();
                          var v2 = renderSpec.render(function ($99) {
                              return handleAff(handler($99));
                          })(renderChild(lchs)(childHandler)(v.childrenIn)(v.childrenOut))(v.component.render(v.state))(v.rendering)();
                          var v3 = Effect_Ref.read(v.childrenOut)();
                          var v4 = Effect_Ref.read(v.childrenIn)();
                          Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(v4)(function (v5) {
                              return function __do() {
                                  var v6 = Effect_Ref.read(v5)();
                                  Halogen_Aff_Driver_State.renderStateX_(Effect.applicativeEffect)(renderSpec.removeChild)(v6)();
                                  return finalize(lchs)(v6)();
                              };
                          })();
                          Data_Function.flip(Effect_Ref.modify_)(v.selfRef)(Halogen_Aff_Driver_State.mapDriverState(function (ds$prime) {
                              return {
                                  component: ds$prime.component,
                                  state: ds$prime.state,
                                  refs: ds$prime.refs,
                                  children: v3,
                                  childrenIn: ds$prime.childrenIn,
                                  childrenOut: ds$prime.childrenOut,
                                  selfRef: ds$prime.selfRef,
                                  handlerRef: ds$prime.handlerRef,
                                  pendingQueries: ds$prime.pendingQueries,
                                  pendingOuts: ds$prime.pendingOuts,
                                  pendingHandlers: ds$prime.pendingHandlers,
                                  rendering: new Data_Maybe.Just(v2),
                                  fresh: ds$prime.fresh,
                                  subscriptions: ds$prime.subscriptions,
                                  forks: ds$prime.forks,
                                  lifecycleHandlers: ds$prime.lifecycleHandlers
                              };
                          }))();
                          return Control_Applicative.when(Effect.applicativeEffect)(v1)(Data_Function.flip(Control_Monad_Rec_Class.tailRecM(Control_Monad_Rec_Class.monadRecEffect))(Data_Unit.unit)(function (v5) {
                              return function __do() {
                                  var v6 = Effect_Ref.read(pendingHandlers)();
                                  Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(pendingHandlers)();
                                  Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
                                      var $100 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
                                      return function ($101) {
                                          return handleAff($100(Data_List.reverse($101)));
                                      };
                                  })())(v6)();
                                  var v7 = Effect_Ref.read(pendingHandlers)();
                                  var $68 = Data_Maybe.maybe(false)(Data_List["null"])(v7);
                                  if ($68) {
                                      return Data_Functor.voidLeft(Effect.functorEffect)(Effect_Ref.write(Data_Maybe.Nothing.value)(pendingHandlers))(new Control_Monad_Rec_Class.Done(Data_Unit.unit))();
                                  };
                                  return new Control_Monad_Rec_Class.Loop(Data_Unit.unit);
                              };
                          }))();
                      };
                  };
              };
              var finalize = function (lchs) {
                  return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                      return function __do() {
                          cleanupSubscriptionsAndForks(st)();
                          var f = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Finalize(Data_Unit.unit)));
                          Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: handlers.initializers,
                                  finalizers: new Data_List_Types.Cons(f, handlers.finalizers)
                              };
                          })(lchs)();
                          return Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(st.children)(function (v) {
                              return function __do() {
                                  var v1 = Effect_Ref.read(v)();
                                  return finalize(lchs)(v1)();
                              };
                          })();
                      };
                  });
              };
              var evalDriver = function (disposed) {
                  return function (ref) {
                      return function (q) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(disposed)))(function (v) {
                              if (v) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                              };
                              return Halogen_Aff_Driver_Eval.evalQ(render)(ref)(q);
                          });
                      };
                  };
              };
              var dispose = function (disposed) {
                  return function (lchs) {
                      return function (dsx) {
                          return function (subsRef) {
                              return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                                  var v = Effect_Ref.read(disposed)();
                                  if (v) {
                                      return Data_Unit.unit;
                                  };
                                  Effect_Ref.write(true)(disposed)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
                                      var $102 = Effect_Aff_AVar.kill(Effect_Exception.error("disposed"));
                                      return function ($103) {
                                          return Effect_Aff.launchAff_($102($103));
                                      };
                                  })()))(Effect_Ref.read(subsRef))();
                                  finalize(lchs)(dsx)();
                                  return Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $104 = Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(renderSpec.dispose);
                                      return function ($105) {
                                          return $104((function (v1) {
                                              return v1.rendering;
                                          })($105));
                                      };
                                  })())(dsx)();
                              });
                          };
                      };
                  };
              };
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(newLifecycleHandlers))(function (v) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](0)))(function (v1) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (v2) {
                          return Halogen_Aff_Driver_Eval.handleLifecycle(v)(function __do() {
                              var v3 = Effect_Ref["new"](Data_Map_Internal.empty)();
                              var v4 = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref.read)(runComponent(v)(rootHandler(v3))(i)(component))();
                              return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                  return Control_Applicative.pure(Effect.applicativeEffect)({
                                      query: evalDriver(v2)(st.selfRef),
                                      subscribe: subscribe(v1)(v3),
                                      dispose: dispose(v2)(v)(v4)(v3)
                                  });
                              })(v4)();
                          });
                      });
                  });
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.Aff.Util"] = $PS["Halogen.Aff.Util"] || {};
  var exports = $PS["Halogen.Aff.Util"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Exception = $PS["Effect.Exception"];
  var runHalogenAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  exports["runHalogenAff"] = runHalogenAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.DOM.Element"] = $PS["Web.DOM.Element"] || {};
  var exports = $PS["Web.DOM.Element"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  exports["toNode"] = toNode;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.DOM"] = $PS["Halogen.VDom.DOM"] || {};
  var exports = $PS["Halogen.VDom.DOM"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var haltWidget = function (v) {
      return Halogen_VDom_Machine.halt(v.widget);
  };
  var patchWidget = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchWidget(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Widget) {
          var v = Halogen_VDom_Machine.step(state.widget, vdom.value0);
          var res$prime = Halogen_VDom_Machine.unStep(function (v1) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v1.value0, {
                  build: state.build,
                  widget: v
              }, patchWidget, haltWidget));
          })(v);
          return res$prime;
      };
      haltWidget(state);
      return state.build(vdom);
  };
  var haltText = function (v) {
      var v1 = Halogen_VDom_Util.parentNode(v.node);
      return Halogen_VDom_Util.removeChild(v.node, v1);
  };
  var patchText = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchText(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Text) {
          if (state.value === vdom.value0) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, state, patchText, haltText));
          };
          if (Data_Boolean.otherwise) {
              var nextState = {
                  build: state.build,
                  node: state.node,
                  value: vdom.value0
              };
              Halogen_VDom_Util.setTextContent(vdom.value0, state.node);
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchText, haltText));
          };
      };
      haltText(state);
      return state.build(vdom);
  };
  var haltKeyed = function (v) {
      var v1 = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, v1);
      Halogen_VDom_Util.forInE(v.children, function (v2, s) {
          return Halogen_VDom_Machine.halt(s);
      });
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var haltElem = function (v) {
      var v1 = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, v1);
      Halogen_VDom_Util.forEachE(v.children, Halogen_VDom_Machine.halt);
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var eqElemSpec = function (ns1, v, ns2, v1) {
      var $84 = v === v1;
      if ($84) {
          if (ns1 instanceof Data_Maybe.Just && (ns2 instanceof Data_Maybe.Just && ns1.value0 === ns2.value0)) {
              return true;
          };
          if (ns1 instanceof Data_Maybe.Nothing && ns2 instanceof Data_Maybe.Nothing) {
              return true;
          };
          return false;
      };
      return false;
  };
  var patchElem = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchElem(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Elem && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          var v1 = Data_Array.length(state.children);
          if (v1 === 0 && v === 0) {
              var v2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: v2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
          };
          var onThis = function (ix, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (ix, s, v2) {
              var v3 = Halogen_VDom_Machine.step(s, v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(v3), state.node);
              return v3;
          };
          var onThat = function (ix, v2) {
              var v3 = state.build(v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(v3), state.node);
              return v3;
          };
          var v2 = Halogen_VDom_Util.diffWithIxE(state.children, vdom.value3, onThese, onThis, onThat);
          var v3 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: v3,
              ns: vdom.value0,
              name: vdom.value1,
              children: v2
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
      };
      haltElem(state);
      return state.build(vdom);
  };
  var patchKeyed = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchKeyed(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Keyed && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          if (state.length === 0 && v === 0) {
              var v2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: v2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children,
                  length: 0
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (v2, ix$prime, s, v3) {
              var v5 = Halogen_VDom_Machine.step(s, v3.value1);
              Halogen_VDom_Util.insertChildIx(ix$prime, Halogen_VDom_Machine.extract(v5), state.node);
              return v5;
          };
          var onThat = function (v2, ix, v3) {
              var v5 = state.build(v3.value1);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(v5), state.node);
              return v5;
          };
          var v2 = Halogen_VDom_Util.diffWithKeyAndIxE(state.children, vdom.value3, Data_Tuple.fst, onThese, onThis, onThat);
          var v3 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: v3,
              ns: vdom.value0,
              name: vdom.value1,
              children: v2,
              length: v
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
      };
      haltKeyed(state);
      return state.build(vdom);
  };
  var buildWidget = function (v, build, w) {
      var v1 = v.buildWidget(v)(w);
      var res$prime = Halogen_VDom_Machine.unStep(function (v2) {
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v2.value0, {
              build: build,
              widget: v1
          }, patchWidget, haltWidget));
      })(v1);
      return res$prime;
  };
  var buildText = function (v, build, s) {
      var v1 = Halogen_VDom_Util.createTextNode(s, v.document);
      var state = {
          build: build,
          node: v1,
          value: s
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v1, state, patchText, haltText));
  };
  var buildKeyed = function (v, build, ns1, name1, as1, ch1) {
      var v1 = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(v1);
      var onChild = function (k, ix, v2) {
          var v3 = build(v2.value1);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(v3), node);
          return v3;
      };
      var v2 = Halogen_VDom_Util.strMapWithIxE(ch1, Data_Tuple.fst, onChild);
      var v3 = v.buildAttributes(v1)(as1);
      var state = {
          build: build,
          node: node,
          attrs: v3,
          ns: ns1,
          name: name1,
          children: v2,
          length: Data_Array.length(ch1)
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchKeyed, haltKeyed));
  };
  var buildElem = function (v, build, ns1, name1, as1, ch1) {
      var v1 = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(v1);
      var onChild = function (ix, child) {
          var v2 = build(child);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(v2), node);
          return v2;
      };
      var v2 = Halogen_VDom_Util.forE(ch1, onChild);
      var v3 = v.buildAttributes(v1)(as1);
      var state = {
          build: build,
          node: node,
          attrs: v3,
          ns: ns1,
          name: name1,
          children: v2
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchElem, haltElem));
  };
  var buildVDom = function (spec) {
      var build = function (v) {
          if (v instanceof Halogen_VDom_Types.Text) {
              return buildText(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Elem) {
              return buildElem(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Keyed) {
              return buildKeyed(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Widget) {
              return buildWidget(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Grafted) {
              return build(Halogen_VDom_Types.runGraft(v.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.DOM (line 58, column 27 - line 63, column 52): " + [ v.constructor.name ]);
      };
      return build;
  };
  exports["buildVDom"] = buildVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.Thunk"] = $PS["Halogen.VDom.Thunk"] || {};
  var exports = $PS["Halogen.VDom.Thunk"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];        
  var Thunk = (function () {
      function Thunk(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Thunk.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Thunk(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Thunk;
  })();                                          
  var unsafeEqThunk = function (v, v1) {
      return Halogen_VDom_Util.refEq(v.value0, v1.value0) && (Halogen_VDom_Util.refEq(v.value1, v1.value1) && Halogen_VDom_Util.refEq(v.value3, v1.value3));
  };
  var thunk = function (tid, eqFn, f, a) {
      return new Thunk(tid, eqFn, f, a);
  };
  var runThunk = function (v) {
      return v.value2(v.value3);
  };
  var buildThunk = function (toVDom) {
      var haltThunk = function (state) {
          return Halogen_VDom_Machine.halt(state.vdom);
      };
      var patchThunk = function (state, t2) {
          var $45 = unsafeEqThunk(state.thunk, t2);
          if ($45) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(state.vdom), state, patchThunk, haltThunk));
          };
          var v = Halogen_VDom_Machine.step(state.vdom, toVDom(runThunk(t2)));
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(v), {
              vdom: v,
              thunk: t2
          }, patchThunk, haltThunk));
      };
      var renderThunk = function (spec) {
          return function (t) {
              var v = Halogen_VDom_DOM.buildVDom(spec)(toVDom(runThunk(t)));
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(v), {
                  thunk: t,
                  vdom: v
              }, patchThunk, haltThunk));
          };
      };
      return renderThunk;
  };
  exports["buildThunk"] = buildThunk;
})(PS);
(function(exports) {
  /* global window */
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.HTML"] = $PS["Web.HTML"] || {};
  var exports = $PS["Web.HTML"];
  var $foreign = $PS["Web.HTML"];
  exports["window"] = $foreign.window;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.HTML.HTMLDocument"] = $PS["Web.HTML.HTMLDocument"] || {};
  var exports = $PS["Web.HTML.HTMLDocument"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                      
  var toParentNode = Unsafe_Coerce.unsafeCoerce; 
  var toDocument = Unsafe_Coerce.unsafeCoerce;
  exports["toDocument"] = toDocument;
  exports["toParentNode"] = toParentNode;
})(PS);
(function(exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.HTML.Window"] = $PS["Web.HTML.Window"] || {};
  var exports = $PS["Web.HTML.Window"];
  var $foreign = $PS["Web.HTML.Window"];
  exports["document"] = $foreign.document;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Halogen.VDom.Driver"] = $PS["Halogen.VDom.Driver"] || {};
  var exports = $PS["Halogen.VDom.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver = $PS["Halogen.Aff.Driver"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var substInParent = function (v) {
      return function (v1) {
          return function (v2) {
              if (v1 instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.insertBefore(v)(v1.value0)(v2.value0));
              };
              if (v1 instanceof Data_Maybe.Nothing && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(v)(v2.value0));
              };
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
      };
  };
  var removeChild = function (v) {
      return function __do() {
          var v1 = Web_DOM_Node.parentNode(v.node)();
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (pn) {
              return Web_DOM_Node.removeChild(v.node)(pn);
          })(v1)();
      };
  };
  var mkSpec = function (handler) {
      return function (renderChildRef) {
          return function (document) {
              var getNode = Halogen_Aff_Driver_State.unRenderStateX(function (v) {
                  return v.node;
              });
              var done = function (st) {
                  if (st instanceof Data_Maybe.Just) {
                      return Halogen_VDom_Machine.halt(st.value0);
                  };
                  return Data_Unit.unit;
              };
              var buildWidget = function (spec) {
                  var buildThunk = Halogen_VDom_Thunk.buildThunk(Data_Newtype.unwrap(Halogen_HTML_Core.newtypeHTML))(spec);
                  var renderComponentSlot = function (cs) {
                      var v = Effect_Ref.read(renderChildRef)();
                      var v1 = v(cs)();
                      var node = getNode(v1);
                      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, Data_Maybe.Nothing.value, patch, done));
                  };
                  var render = function (slot) {
                      if (slot instanceof Halogen_Component.ComponentSlot) {
                          return renderComponentSlot(slot.value0);
                      };
                      if (slot instanceof Halogen_Component.ThunkSlot) {
                          var v = buildThunk(slot.value0);
                          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(v), new Data_Maybe.Just(v), patch, done));
                      };
                      throw new Error("Failed pattern match at Halogen.VDom.Driver (line 85, column 7 - line 90, column 75): " + [ slot.constructor.name ]);
                  };
                  var patch = function (st, slot) {
                      if (st instanceof Data_Maybe.Just) {
                          if (slot instanceof Halogen_Component.ComponentSlot) {
                              Halogen_VDom_Machine.halt(st.value0);
                              return renderComponentSlot(slot.value0);
                          };
                          if (slot instanceof Halogen_Component.ThunkSlot) {
                              var v = Halogen_VDom_Machine.step(st.value0, slot.value0);
                              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(v), new Data_Maybe.Just(v), patch, done));
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 98, column 22 - line 104, column 79): " + [ slot.constructor.name ]);
                      };
                      return render(slot);
                  };
                  return render;
              };
              var buildAttributes = Halogen_VDom_DOM_Prop.buildProp(handler);
              return {
                  buildWidget: buildWidget,
                  buildAttributes: buildAttributes,
                  document: document
              };
          };
      };
  };
  var renderSpec = function (document) {
      return function (container) {
          var render = function (handler) {
              return function (child) {
                  return function (v) {
                      return function (v1) {
                          if (v1 instanceof Data_Maybe.Nothing) {
                              return function __do() {
                                  var v2 = Effect_Ref["new"](child)();
                                  var spec = mkSpec(handler)(v2)(document);
                                  var v3 = Halogen_VDom_DOM.buildVDom(spec)(v);
                                  var node = Halogen_VDom_Machine.extract(v3);
                                  Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(node)(Web_HTML_HTMLElement.toNode(container)))();
                                  return {
                                      machine: v3,
                                      node: node,
                                      renderChildRef: v2
                                  };
                              };
                          };
                          if (v1 instanceof Data_Maybe.Just) {
                              return function __do() {
                                  Effect_Ref.write(child)(v1.value0.renderChildRef)();
                                  var v2 = Web_DOM_Node.parentNode(v1.value0.node)();
                                  var v3 = Web_DOM_Node.nextSibling(v1.value0.node)();
                                  var v4 = Halogen_VDom_Machine.step(v1.value0.machine, v);
                                  var newNode = Halogen_VDom_Machine.extract(v4);
                                  Control_Applicative.when(Effect.applicativeEffect)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Unsafe_Reference.unsafeRefEq)(v1.value0.node)(newNode))(substInParent(newNode)(v3)(v2))();
                                  return {
                                      machine: v4,
                                      node: newNode,
                                      renderChildRef: v1.value0.renderChildRef
                                  };
                              };
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 159, column 5 - line 175, column 80): " + [ v1.constructor.name ]);
                      };
                  };
              };
          };
          return {
              render: render,
              renderChild: Control_Category.identity(Control_Category.categoryFn),
              removeChild: removeChild,
              dispose: removeChild
          };
      };
  };
  var runUI = function (component) {
      return function (i) {
          return function (element) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))))(function (v) {
                  return Halogen_Aff_Driver.runUI(renderSpec(v)(element))(component)(i);
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";

  exports.toArray = function (list) {
    return function () {
      return [].slice.call(list);
    };
  };
})(PS["Web.DOM.NodeList"] = PS["Web.DOM.NodeList"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.DOM.NodeList"] = $PS["Web.DOM.NodeList"] || {};
  var exports = $PS["Web.DOM.NodeList"];
  var $foreign = $PS["Web.DOM.NodeList"];
  exports["toArray"] = $foreign.toArray;
})(PS);
(function(exports) {
  "use strict";

  exports.querySelectorAll = function (selector) {
    return function (node) {
      return function () {
        return node.querySelectorAll(selector);
      };
    };
  };
})(PS["Web.DOM.ParentNode"] = PS["Web.DOM.ParentNode"] || {});
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Web.DOM.ParentNode"] = $PS["Web.DOM.ParentNode"] || {};
  var exports = $PS["Web.DOM.ParentNode"];
  var $foreign = $PS["Web.DOM.ParentNode"];
  exports["querySelectorAll"] = $foreign.querySelectorAll;
})(PS);
(function($PS) {
  // Generated by purs version 0.13.3
  "use strict";
  $PS["Main"] = $PS["Main"] || {};
  var exports = $PS["Main"];
  var Component_Redexer = $PS["Component.Redexer"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Halogen_Aff_Util = $PS["Halogen.Aff.Util"];
  var Halogen_VDom_Driver = $PS["Halogen.VDom.Driver"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_DOM_NodeList = $PS["Web.DOM.NodeList"];
  var Web_DOM_ParentNode = $PS["Web.DOM.ParentNode"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var removeChildren = function (node) {
      return Data_Functor["void"](Effect.functorEffect)(function __do() {
          var v = Control_Bind.bind(Effect.bindEffect)(Web_DOM_Node.childNodes(node))(Web_DOM_NodeList.toArray)();
          return Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect.applicativeEffect)(Data_Function.flip(Web_DOM_Node.removeChild)(node))(v)();
      });
  };
  var initRedexers = (function () {
      var init = function (node) {
          var v = Web_HTML_HTMLElement.fromNode(node);
          if (v instanceof Data_Maybe.Nothing) {
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
          if (v instanceof Data_Maybe.Just) {
              return function __do() {
                  var v1 = Data_Functor.map(Effect.functorEffect)(Data_String_Common.trim)(Web_DOM_Node.textContent(node))();
                  removeChildren(node)();
                  return Halogen_Aff_Util.runHalogenAff(Halogen_VDom_Driver.runUI(Component_Redexer.component)({
                      defaultContent: v1
                  })(v.value0))();
              };
          };
          throw new Error("Failed pattern match at Main (line 38, column 15 - line 43, column 76): " + [ v.constructor.name ]);
      };
      var $11 = Data_Functor["void"](Effect.functorEffect);
      var $12 = Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect.applicativeEffect)(init);
      return function ($13) {
          return $11($12($13));
      };
  })();
  var getNodes = Control_Bind.composeKleisliFlipped(Effect.bindEffect)(Web_DOM_NodeList.toArray)((function () {
      var $14 = Web_DOM_ParentNode.querySelectorAll(".redexer");
      return function ($15) {
          return $14(Web_HTML_HTMLDocument.toParentNode($15));
      };
  })());
  var getDocument = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.document);
  var main = function __do() {
      var v = getDocument();
      var v1 = getNodes(v)();
      return initRedexers(v1)();
  };
  exports["main"] = main;
  exports["getDocument"] = getDocument;
  exports["getNodes"] = getNodes;
  exports["initRedexers"] = initRedexers;
  exports["removeChildren"] = removeChildren;
})(PS);
PS["Main"].main();